export enum multiOptions {
  "AGENCY_PROFILE_BRANCH" = 0,
  "AGENCY_PROFILE_MARKETER" = 1,
  "MARITAL_STATUS" = 2,
  "STATE" = 3,
  "LIFE_INSURANCE_LIST" = 4,
  "AGENCY_ORG_STRUCTURE" = 5,
  "EDUCATION" = 6,
  "AGENCY_PROFILE_NO_OF_CHILDREN" = 7,
  "AGENCY_TIME_IN_BUSINESS" = 8,
  "AGENCY_PROFILE_NO_OF_EMPLOYEE" = 9,
  "PERSONAL_PROFILE_STATE" = 10,
  "COUNTRY" = 11,
  "CLUB_CATEGORIES" = 12,
  "USER_TYPE" = 13,
  "PERSON_NO_OF_CHILDREN" = 14,
  "NATIONALITY" = 15,
  "GENDER" = 16,
  "MARKETER_TEAM" = 17,
  "PRODUCTS_ALL_LIST" = 18,
  "PRODUCTS_ALL_WITHOUT_DEFER" = 19,
  "PRODUCTS_ALL_CURRENT_USER" = 20,
  "PRODUCER_CODE" = 23,
  "ORGANISATION_LIST_BY_USER" = 24,
  "INSURER_INSURANCECOMPANY" = 25,
  "TYPE" = 26,
  "PDFPATHS" = 27,
  "SKINS" = 28,
  "SALUTATIONS" = 29,
  "ORGANISATIONGROUPS" = 30,
  "PAYMENTPATTERN" = 31,
  "SYSTEM_TYPE" = 32,
  "Agency_Code" = 33,
  "SALES_VIEW" = 34,
  "REGION" = 35,
  "AGENCY_TYPE" = 36,
  "PRODUCTS_BY_USER" = 37,
  "PROPOSAL_STATUSES_ALL_LIST" = 38,
  "PROPOSAL_MODIFIER" = 39,
  "PRODUCTS_ALL_CURRENT_USER_WITHOUT_DEFER" = 40,
  "PENETRATIONCHARTTYPE" = 41,
  "PENETRATION_CHART_TYPE_HIGH_LOW" = 42,
  "ORGANISATION_BY_BRANCH" = 43,
  "COUNTRIES" = 44, //TODO - to be removed once the country service is ready
  "GWPPeriod1GWPType" = 45,
  "GWPPeriod2GWPType" = 46,
  "REGION_CAMPAIGN" = 47,
  "BRANCH_CAMPAIGN" = 48,
  "PRODUCER_TYPE_CAMPAIGN" = 49,
  "PRODUCT_TYPE_CAMPAIGN" = 50,
  "CONTRACT_TYPE_CAMPAIGN" = 51,
  "GEM_CLUB_CAMPAIGN" = 52,
  "PRODUCT_TYPE_CAMPAIGN_SEARCH" = 53,
  "CONTRACT_TYPE_CAMPAIGN_SEARCH" = 54,
  "CONFIDENCE_LEVEL" = 55,
  "CAMPAIGN_TYPE" = 56,
  "REGION_CAMPAIGN_SEARCH" = 57,
  "BRANCH_CAMPAIGN_SEARCH" = 58,
  "SEGMENT" = 59,
  "PENETRATIONCHARTTYPE_THAILAND" = 60,
  "SYSTEM_TYPE_THAI" = 61,
  "CLUB_CATEGORIES_TH" = 62,
  "AGENCY_TYPE_TH" = 63,
  "SALESPLAN_COUNTRIES" = 64,
  // "Roles"= 65,
  "COUNTRYISO" = 66,
}

export enum classOfBusiness {
  "PERSONAL_ACCIDENT" = 1,
  "MEDICAL" = 2,
  "TRAVEL_PA" = 3,
  "MARINE_CARGO" = 4,
  "MARINE_HULL" = 5,
  "ENGINEERING" = 6,
  "MISC_ACCIDENT" = 7,
  "LIABILITY" = 8,
  "BOND" = 9,
  "WORKMAN" = 10,
  "SME" = 11,
  "RESIDENTIAL" = 12,
  "MOTOR" = 13,
  "FIRE" = 14,
  "OTHERS" = 15,
}

export enum addressCategory {
  "AGENCY" = 1,
  "ACE_JERNEH_BRANCH" = 2,
}

export enum addressType {
  "HOME" = 1,
  "OFFICE" = 2,
  "CORRESPONDENT" = 3,
}

export enum contactTypes {
  "TELEPHONE_1" = 1,
  "MOBILE_1" = 2,
  "FASCIMILE" = 3,
  "TELEPHONE_2" = 4,
  "MOBILE_2" = 5,
  "EMAIL_1" = 6,
  "WHATSAPP" = 7,
  "LINE_ID" = 8,
  "EMAIL_2" = 9,
}
export enum agencyClassOfBusinessType {
  "TOTAL" = 1,
  "ACE" = 2,
}
export enum entityStatus {
  "ACTIVE" = 1,
  "INACTIVE" = 2,
  "DELETED" = 3,
}
export enum organisationGroup {
  "BROKER" = 2,
  "CLIENT" = 4,
  "AGENCY" = 5,
}
export enum pageMode {
  view = 1,
  edit = 2,
  add = 3,
}
export enum competitiveEdge {
  PROMPTPOLICYISSUANCE = 1,
  PROMPTCLAIMSSETTLEMENT = 2,
  USERFRIENDLINESS = 3,
  PROMPTSERVICEDELIVERY = 4,
  PROMPTCOMMSNPAYMENT = 5,
  WIDEPRODUCTRANGE = 6,
  PROMPTUWASSESMENT = 7,
  OTHERS = 8,
}
export enum displayMode {
  VIEW = 1,
  ADD = 2,
  EDIT = 3,
}
export enum confirmationBoxType {
  productListingAction = 1,
}
export enum proposalCommandSection {
  CreateOrEdit = 1,
  Endorsement = 33,
  Cancellation = 36,
}
export enum proposalCommandAction {
  CreateOrEdit = 1,
  Save = 2,
  Submit = 3,
  Recalculate = 4,
  Exit = 5,
  Approve = 6,
  Accept = 7,
  Open = 8,
  Decline = 9,
  MakeRedundant = 10,
  SendEmail = 11,
  Unsuccessful = 12,
  Download = 13,
  AnswerReset = 14,
  AnswerResetAll = 15,
  Endorse = 16,
  Cancel = 17,
  Copy = 18,
  EditQuotation = 19,
  Renew = 20,
  AllowRenewal = 21,
  DeclineRenewal = 22,
  PremiumCalculationAndReferralQuickProcess = 23,
  EndorsementTypeChange = 24,
  SubmissionStatusChange = 25,
  UnsuccessfulWithReason = 26,
  ForceReferral = 28,
  EditDeclinedProposal = 41,
}

interface IntegerMap {
  [key: string]: string;
}
export const proposalColumnDataType: IntegerMap = {
  1: "String",
  2: "Numeric",
  3: "Action",
};

export enum transactionType {
  "new" = 1, //new business
  "endorse" = 2, //endorsement
  "cancel" = 3, //cancellation
  "renewal" = 4, //renewal
}

export const proposalCommandImage: IntegerMap = {
  1: "",
  2: "commandButtonSave",
  3: "commandButtonSubmit",
  4: "commandButtonCalc",
  5: "commandButtonExit",
  6: "commandButtonApprove",
  7: "commandButtonAccept",
  8: "commandButtonOpen",
  9: "commandButtonDecline",
  10: "commandButtonRedundant",
  11: "commandButtonEmail",
  12: "commandButtonRedundant",
  13: "commandButtonDownload",
};

export enum userType {
  Undefined = 0,
  Admin = 1,
  Underwriter = 3,
  Marketer = 6,
  Broker = 2,
  Agent = 5,
  Client = 4,
}
export enum proposalStatusId {
  /// <summary>
  /// No status has been assigned
  /// </summary>
  None = 0,

  /// <summary>
  /// Proposal has been approved
  /// </summary>
  Approved = 1,

  /// <summary>
  /// Proposal has been bound
  /// </summary>
  Accepted = 2,

  /// <summary>
  /// Proposal has been referred to an underwriter
  /// </summary>
  Referred = 3,

  /// <summary>
  /// Proposal has been declined
  /// </summary>
  Declined = 4,

  /// <summary>
  /// Proposal has been made redundant
  /// </summary>
  Replaced = 5,

  /// <summary>
  /// Proposal is incomplete
  /// </summary>
  Incomplete = 6,

  /// <summary>
  /// Proposal has given a quick quote only
  /// </summary>
  QuickQuoteApprovedOnly = 7,

  /// <summary>
  /// Proposal has been referred from a client to a broker
  /// </summary>
  ReferredToBroker = 8,

  /// <summary>
  /// Quotation has lapsed
  /// </summary>
  Lapsed = 9,

  /// <summary>
  /// Policy has been endorsed
  /// </summary>
  Endorsed = 10,

  /// <summary>
  /// Policy has been renewed
  /// </summary>
  Renewed = 11,

  /// <summary>
  /// Policy has been cancelled
  /// </summary>
  Cancelled = 12,

  /// <summary>
  /// Quotation has not been taken out (unsuccessful)
  /// </summary>
  Unsuccessful = 13,

  /// <summary>
  /// Broker submitted proposal for review
  /// </summary>
  Submitted = 14,

  /// <summary>
  /// Pending for underwriter to determine the referral case.
  /// </summary>
  PendingUnderwriter = 15,

  /// <summary>
  /// Pending for broker to submit supporting document.
  /// </summary>
  PendingBroker = 16,
}
export enum proposalStatus {
  /// <summary>
  /// No status has been assigned
  /// </summary>
  None = "None",

  /// <summary>
  /// Proposal has been approved
  /// </summary>
  Approved = "Approved",

  /// <summary>
  /// Proposal has been bound
  /// </summary>
  Accepted = "Accepted",

  /// <summary>
  /// Proposal has been referred to an underwriter
  /// </summary>
  Referred = "Referred",

  /// <summary>
  /// Proposal has been declined
  /// </summary>
  Declined = "Declined",

  /// <summary>
  /// Proposal has been made redundant
  /// </summary>
  Replaced = "Replaced",

  /// <summary>
  /// Proposal is incomplete
  /// </summary>
  Incomplete = "Incomplete",

  /// <summary>
  /// Proposal has given a quick quote only
  /// </summary>
  QuickQuoteApprovedOnly = "QuickQuoteApprovedOnly",

  /// <summary>
  /// Proposal has been referred from a client to a broker
  /// </summary>
  ReferredToBroker = "ReferredToBroker",

  /// <summary>
  /// Quotation has lapsed
  /// </summary>
  Lapsed = "Lapsed",

  /// <summary>
  /// Policy has been endorsed
  /// </summary>
  Endorsed = "Endorsed",

  /// <summary>
  /// Policy has been renewed
  /// </summary>
  AllowRenewed = "AllowRenewed",

  /// <summary>
  /// Policy has been renewed
  /// </summary>
  DeclineRenewed = "DeclineRenewed",

  /// <summary>
  /// Policy has been renewed
  /// </summary>
  Renewed = "Renewed",

  /// <summary>
  /// Policy has been cancelled
  /// </summary>
  Cancelled = "Cancelled",

  /// <summary>
  /// Quotation has not been taken out (unsuccessful)
  /// </summary>
  Unsuccessful = "Unsuccessful",

  /// <summary>
  /// Broker submitted proposal for review
  /// </summary>
  Submitted = "Submitted",

  /// <summary>
  /// Pending for underwriter to determine the referral case.
  /// </summary>
  PendingUnderwriter = "PendingUnderwriter",

  /// <summary>
  /// Pending for broker to submit supporting document.
  /// </summary>
  PendingBroker = "PendingBroker",

  /// <summary>
  ///
  /// </summary>
  Complete = "Complete",

  /// <summary>
  /// Manual Rating Required.
  /// </summary>
  MRR = "M.R.R",

  /// <summary>
  /// Decline by the system.
  /// </summary>
  AutoDecline = "AutoDecline",
}

//https://stackoverflow.com/questions/56568423/typescript-no-index-signature-with-a-parameter-of-type-string-was-found-on-ty
interface StringMap {
  [key: string]: string;
}

export const productsRoute: StringMap = {
  //flexiguard
  "1d6ae343-f479-43fe-bc6a-5a22803b3da7|1": "flexiguard.master",
  "1d6ae343-f479-43fe-bc6a-5a22803b3da7|35": "flexiguard.insuredlocation", // location details
  "1d6ae343-f479-43fe-bc6a-5a22803b3da7|32": "flexiguard.premiumsummarypage", // premium summary
  "1d6ae343-f479-43fe-bc6a-5a22803b3da7|33": "flexiguard.endorsement",
  "1d6ae343-f479-43fe-bc6a-5a22803b3da7|36": "flexiguard.cancellation",
  "1d6ae343-f479-43fe-bc6a-5a22803b3da7|34": "flexiguard.premiumOverwritten",

  LocationListing: "flexiguard.locations", // child/master location listing- 30,31

  "a362c274-7a1f-453c-9a62-db35b99422fa|1": "flexiguard.firerisks",
  "a8d50aec-5e45-47b7-94a7-22de192df072|1": "flexiguard.conlossext",
  "022e2fce-535a-49a5-859e-cb2ee287f558|1": "flexiguard.allrisks",
  "d403c766-2c5a-433a-b127-4313ee94e298|1": "flexiguard.burglary",
  "5f7ba256-7caf-4201-9be5-a88fafe76e16|1": "flexiguard.addmoney",
  "3821b43f-9bd1-4b9c-b739-1b6f901053ed|1": "flexiguard.plateglass",
  "19dcb88f-1dd6-46c4-8fb9-cbe14617d45f|1": "flexiguard.publicliability",
  "cba0e878-3cf4-4218-9602-5c102b27a612|1": "flexiguard.employerliability",
  "1a7b359c-a900-46d4-b89f-a4bd51e538f4|1": "flexiguard.fidelityguarantee",
  "1126398d-2747-44bd-a755-cb62761871cd|1": "flexiguard.gpa",

  //myhome
  "766ea862-df3d-4a83-8e6c-3128813956b9|1": "myAMS.myHomeMaster",
  "766ea862-df3d-4a83-8e6c-3128813956b9|2": "myAMS.myHomeQuestion",

  //gcn
  "90498eba-2cc6-4e6f-a468-2ee2cd46b1a8|1": "myAMS.gcn",
  "90498eba-2cc6-4e6f-a468-2ee2cd46b1a8|0": "myAMS.gcnCoverNote",

  //bta
  "8391c36c-14c8-40e8-90c0-4f8b0d475247|1": "proposal.businessTravel",
  "8391c36c-14c8-40e8-90c0-4f8b0d475247|2": "myAMS.btEmployeeDetails",

  //aml
  "f64a7d46-18f4-421a-931e-43b7fc198cd4|1":
    "proposal.australiaManagementLiability",

  //sunrise
  "f6c1bcb1-d045-4db3-b947-0e50fe0d49eb|1": "sunrise.bizPack",

  //hk EC
  "1ccfa1a3-23bd-4afd-9df5-d88687c3254c|1": "myAMS.hongkongEC",

  // Houseowner Plus HDB
  "2396885e-49f6-43f6-a314-47d727ab888f|1": "proposal.dbssghdb",

  // Houseowner Plus PTE
  "6faf6bee-f5fc-4dc2-9f75-37fe2d61611d|1": "proposal.dbssgpte",

  // Mortgage Fire Hong Kong - CBG
  "944439aa-4c28-4a69-999e-0731de0c795a|1": "proposal.dbshkcbg",
  // Mortgage Fire Hong Kong - IBG
  "cc290dc8-a0be-4818-9557-c2b725a3f4f8|1": "proposal.dbshkibg",

  // Mortgage Fire Hong Kong - CBG
  // "cc290dc8-a0be-4818-9557-c2b725a3f4f8|1" : "proposal.dbshkcbg",

  // Mortgage Fire SG
  "06cb09d3-6fe0-4695-92a8-a61dfb627c61|1": "proposal.dbssgibgmf",

  // Mortgage Machinery All Risk SG
  "8533fa05-f190-4a29-9613-3db7f693dd32|1": "proposal.dbssgibgmac",

  // Mortgagee Interest Plus
  "565e9075-9f73-45c0-ba5c-a78ac05ba837|1": "proposal.dbssgmip",

  //Citi Houseowner Plus HDB
  "a8cdac65-3ec4-4138-974a-d18c3ef8536c|1": "proposal.citisghdb",

  //Citi Mortgage Fire Insurance PTE
  "1f7ee805-c8ce-416c-af29-292e920878f8|1": "proposal.citisgmfpte",

  //Citi Mortgage Fire Insureance Condo
  "b8a348ca-491c-48da-988a-fcc52f7fc5c5|1": "proposal.citisgmfcondo",
};

export enum singleProduct {
  "foreginMaid" = "31b5b1af-887e-4ee1-8c68-042ff8ff50e5",
  "myHome" = "766ea862-df3d-4a83-8e6c-3128813956b9",
  "GeneralCoverNote" = "90498eba-2cc6-4e6f-a468-2ee2cd46b1a8",
  "businessTravel" = "8391c36c-14c8-40e8-90c0-4f8b0d475247",
  "hongkongEC" = "1ccfa1a3-23bd-4afd-9df5-d88687c3254c",
  "MortgageeInterestPlus" = "565e9075-9f73-45c0-ba5c-a78ac05ba837",
  "HouseOwnerPlusPTE" = "6faf6bee-f5fc-4dc2-9f75-37fe2d61611d",
  "HouseOwnerPlusHDB" = "2396885e-49f6-43f6-a314-47d727ab888f",
  "CitiHouseOwnerPlusHDB" = "A8CDAC65-3EC4-4138-974A-D18C3EF8536C",
  "CitiMortgageFireInsurancePTE" = "1F7EE805-C8CE-416C-AF29-292E920878F8",
  "CitiMortgageFireInsuranceCondo" = "B8A348CA-491C-48DA-988A-FCC52F7FC5C5",
}
export enum product {
  "FlexiguardMaster" = "1d6ae343-f479-43fe-bc6a-5a22803b3da7",
  "MyHome" = "766ea862-df3d-4a83-8e6c-3128813956b9",
  "GeneralCoverNote" = "90498eba-2cc6-4e6f-a468-2ee2cd46b1a8",
  "ManagementLiablity" = "f64a7d46-18f4-421a-931e-43b7fc198cd4",
}
export enum productName {
  "022e2fce-535a-49a5-859e-cb2ee287f558" = "All Risk",
  "61f6835e-8791-4876-9745-e084ffa6ccd7" = "Insured Location",
  "d403c766-2c5a-433a-b127-4313ee94e298" = "Burglary",
  "cba0e878-3cf4-4218-9602-5c102b27a612" = "Employer's Liability",
  "1a7b359c-a900-46d4-b89f-a4bd51e538f4" = "Fidelity Guarantee",
  "a362c274-7a1f-453c-9a62-db35b99422fa" = "Fire",
  "1d6ae343-f479-43fe-bc6a-5a22803b3da7" = "Master",
  "1126398d-2747-44bd-a755-cb62761871cd" = "GPA",
  "5f7ba256-7caf-4201-9be5-a88fafe76e16" = "Money",
  "3821b43f-9bd1-4b9c-b739-1b6f901053ed" = "Plate Glass",
  "19dcb88f-1dd6-46c4-8fb9-cbe14617d45f" = "Public Liability",
  "a8d50aec-5e45-47b7-94a7-22de192df072" = "Con Loss",
  "a9c0623f-b17b-4b3d-abd6-017cc059e258" = "Premium Summary",
}
export enum endorsementType {
  "ExtensionOfPeriod" = "e5715c71-a396-40e4-9740-7494ee073829",
  "ChangeOfFinancialInstitution" = "0c79a250-0e02-47f7-a00e-5b2a51f2ccea",
  "AddDeleteOfSectionRisk" = "58fba217-a665-441f-b222-ad680f33bcb5",
  "AddDeletePerils" = "9099078a-46ef-4a2e-b321-c67c1c83aab1",
  "ChangeOfLocationAddress" = "8c7f6ade-f141-46ae-a39c-181cfa634818",
  "AddDeleteNonFinancialClause" = "8382aab7-ac88-4556-8c39-3f85a60d8e96",
  "Others" = "f2d96910-b057-4b3f-b0ad-e25901f7028f",
  "AddDeleteOfInterestInsured" = "c3f5655f-cb4b-42f1-8595-b92b0664cd4b",
  "ChangeOfCorrespondenceAddress" = "2b75b59c-7447-4cc1-b470-158a098ff21e",
  "AmendItemInsuredDescription" = "6bc36bed-8733-4326-a96d-70ea6d8a8806",
  "ChangeOfSumInsured" = "62914003-7bbe-4c4a-ba14-8f3c3c03ff6b",
  "Cancellation" = "CANCELLATION_ENDORSEMENT_ID",
}
export enum productListingType {
  "Submission" = 1,
  "Quotation" = 2,
  "Policy" = 3,
  "Cancellation" = 4,
  "Endorsement" = 5,
  "Renewal" = 6,
  "RenewalListing" = 7,
}

export const productOrder: IntegerMap = {
  1: "a362c274-7a1f-453c-9a62-db35b99422fa",
  2: "a8d50aec-5e45-47b7-94a7-22de192df072",
  3: "022e2fce-535a-49a5-859e-cb2ee287f558",
  4: "d403c766-2c5a-433a-b127-4313ee94e298",
  5: "5f7ba256-7caf-4201-9be5-a88fafe76e16",
  6: "3821b43f-9bd1-4b9c-b739-1b6f901053ed",
  7: "19dcb88f-1dd6-46c4-8fb9-cbe14617d45f",
  8: "cba0e878-3cf4-4218-9602-5c102b27a612",
  9: "1a7b359c-a900-46d4-b89f-a4bd51e538f4",
  10: "1126398d-2747-44bd-a755-cb62761871cd",
  11: "834cc5a1-270c-466c-927f-f4134565cf6b",
};

export const documentType: IntegerMap = {
  1: "Debit Note",
  2: "Credit Note",
  3: "Credit Advice",
  4: "Debit Advice",
  5: "Tax Invoice",
  6: "Quotation",
  7: "Policy Schedule",
  8: "Endorsement",
  9: "Cancellation",
  10: "Renewal",
  11: "Renewal Notice",
  12: "Policy Wording",
  13: "CreditAdvice_AgentCommission",
  14: "DebitAdvice_AgentCommission",
  15: "Payment Receipt",
  16: "PolicyWording_ENG",
  17: "PolicyWording_BM",
  19: "Certificate Of Currency",
  20: "Policy Schedule As Agreed",
  21: "Quotation As Agreed",
  22: "Cancellation As Agreed",
  23: "Certificate Of Currency As Agreed",
  24: "Proposal Form",
  25: "Binder Letter",
  26: "Quote",
  27: "Endorsement As Agreed",
  28: "Renewal Quote",
  29: "RenewalNoticeNonRenewableOccupation",
  30: "CoverNote_Draft",
  31: "CoverNote",
  32: "QuickIndication",
  33: "Cancellation Binder",
  34: "Multi Quote",
  35: "Redemption Letter",
  36: "Change In Building Sum Insured",
  37: "Change In Correspondance Address",
};

//DBS Admin Screen Enable Changes
//Added New Error Enum
export const passwordErrorsEnum: IntegerMap = {
  0: "Undefined",
  1: "Your passwords do not match.",
  2: "Your password cannot be the same as your username.",
  3: "The password must contain a minimum of 8 characters.",
  4: "The password must not be longer than 20 characters.",
  5: "The password cannot be the same as old password.",
  6: "The password cannot contain the word 'password'.",
  7: "The password cannot contain whitespaces or punctuation marks.",
  8: "The password must contain at least one number.",
  9: "At least one lower, one upper, and one special character required.",
  10: "Current Password is not correct. Kindly enter correct password.",
  11: "New Password is required. Kindly enter a password.",
  12: "RequestId is invalid or already been used.",
  13: "The display name cannot contain number.",
  14: "The display name cannot contain special character.",
};
// //End

export const lineOfBusiness: IntegerMap = {
  0: "Undefined",
  1: "A&H PA",
  2: "A&H Medical",
  3: "A&H FWHS",
  4: "A&H Travel",
  5: "Motor / Auto Franchise",
  6: "Motor / Auto Non Franchise",
  7: "SME Premia",
  8: "Fire Residential",
  9: "Specialty",
  10: "Cargo",
  11: "Hull",
  12: "Aviation",
  13: "Fire",
  14: "Misc Acct / Fire",
  15: "Liability / Casualty",
  16: "Financial Line",
  17: "Workmen Comp",
  18: "Environment",
  19: "Engineering",
  20: "Energy O&G",
  21: "Bond / Surety",
};

export const lineOfBusinessGroup: IntegerMap = {
  0: "Undefined",
  1: "A&H",
  2: "PBI",
  3: "P&C",
};

export const salesPlanType: IntegerMap = {
  1: "RENEWAL",
  2: "NEW BUSINESS (CURRENT AGENTS)",
  3: "NEW BUSINESS (RECRUITMENT)",
};

export const salesPlanFilterBy: IntegerMap = {
  0: "Undefined",
  1: "Region",
  2: "Branch",
  3: "Marketer",
  4: "Version",
};

export enum fileType {
  "Undefined" = 0,
  "PropsalAttachment" = 1,
  "Bulletin" = 2,
  "MessageBoard" = 3,
  "VisitAppointment" = 4,
}

export const afterUploadMessages: IntegerMap = {
  1: "Data Uploaded Successfully",
  2: "Uploaded Successfully but with few users left",
  3: "No Records To Update",
  4: "Something wrong. Please reachout to Helpdesk",
  5: "Please select a file to upload",
};
export enum productList {
  flexiguard = 1,
  myHome = 2,
  PC = 3,
  marineCargo = 4,
  eliteMgntLiability = 5,
  construction = 6,
}
export const productListDesc: IntegerMap = {
  1: "Flexiguard",
  2: "My Home",
  3: "P&C",
  4: "Marine Cargo",
  5: "Elite Management Liability",
  6: "Construction",
};

export enum hideCustomerOrganisation { // 1- hide 2 - show
  "766ea862-df3d-4a83-8e6c-3128813956b9" = 1, //myhome
  "1d6ae343-f479-43fe-bc6a-5a22803b3da7" = 2, //flexiguard
}
export enum headRoomChartType {
  HeadRoomSegementationProfile = 1,
  HeadRoomPerformanceTracking = 2,
}
export enum authTypes {
  Normal = 0,
  Vestpin = 1,
  ADB2C = 2,
}

export enum sessionstorageEnum {
  AccessToken = "AccessToken",
  RefreshToken = "RefreshToken",
  B2CAccessToken = "adal.access.token.key",
}
