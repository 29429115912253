import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { DatePickerComponent } from "@progress/kendo-angular-dateinputs";
import { BaseComponent } from "src/app/components/shared/base/base.component";
import { IProduct } from "src/app/framework/interface.product";
import { BroadcastService } from "src/app/services/broadcast.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { ExcelTemplateService } from "src/app/services/excel-template.service";
import { PresetExportService } from "src/app/services/preset-export.service";
import { DataPostPrintSearchCriteria } from "./print-datapost.model";
import {
  CellClickEvent,
  GridDataResult,
  PageChangeEvent,
  PageSizeItem,
  SelectionEvent,
} from "@progress/kendo-angular-grid";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { DataPostPrintEntity } from "../preset-report.model";
import { warningZoneArgs } from "../../warning-zone/warning-zone.component";
import { UntypedFormGroup } from "@angular/forms";
@Component({
  selector: "app-print-datapost-report",
  templateUrl: "./print-datapost.component.html",
  styleUrls: ["./print-datapost.component.scss"],
})
export class PrintDatapostReportComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild("heroForm", { static: false })
  private form!: UntypedFormGroup;
  @ViewChild("boundDateTo", { static: false })
  public boundDateTo!: DatePickerComponent;

  public boundDateTo_min!: Date;
  public boundDateTo_max!: Date;
  @Output() warningZoneEvent = new EventEmitter<any>();
  public searchCriteria = new DataPostPrintSearchCriteria();
  public userProductList!: IProduct[];
  public showReportType: boolean = false;
  public isCitiProduct: boolean = false;
  public productRadioControl!: string;
  public loading!: boolean;

  public gridView!: GridDataResult;
  public pageSize = 10;
  public skip = 0;
  public take = 10;
  public pageSizes: (PageSizeItem | number)[] = [10, 50, 100];
  public listing: DataPostPrintEntity[] = [];
  private totalListing: number = 0;
  submitted = false;

  constructor(
    public override router: Router,
    public override broadcastService: BroadcastService,
    public override identityRolesService: IdentityRolesService,
    private presetExportService: PresetExportService,
    private excelTemplateService: ExcelTemplateService
  ) {
    super(router, broadcastService, identityRolesService);
  }
  override ngOnInit(): void {
    this.productRadioControl = "dbs";
    if (
      this.identityRolesService.isChecker ||
      this.identityRolesService.isMaker
    ) {
      this.productRadioControl = "dbs";
      this.onChange_ProductRadio();
    } else {
      this.showReportType = true;
    }
    this.fetchUserList();
    this.defaultDataPostPrintSearchCriteria();
  }
  private readProductRadio() {
    this.isCitiProduct = this.productRadioControl === "citi";
  }
  private fetchUserList() {
    // get user productsList
    if (this.isCitiProduct) {
      this.userProductList = this.identityRolesService.productListing.filter(
        (element: { pdid: string }) => {
          return (
            element.pdid.toUpperCase() ===
              "A8CDAC65-3EC4-4138-974A-D18C3EF8536C" ||
            element.pdid.toUpperCase() ===
              "1F7EE805-C8CE-416C-AF29-292E920878F8" ||
            element.pdid.toUpperCase() ===
              "B8A348CA-491C-48DA-988A-FCC52F7FC5C5"
          );
        }
      );
    } else {
      this.userProductList = this.identityRolesService.productListing.filter(
        (element: { pdid: string }) => {
          return !(
            element.pdid.toUpperCase() ===
              "A8CDAC65-3EC4-4138-974A-D18C3EF8536C" ||
            element.pdid.toUpperCase() ===
              "1F7EE805-C8CE-416C-AF29-292E920878F8" ||
            element.pdid.toUpperCase() ===
              "B8A348CA-491C-48DA-988A-FCC52F7FC5C5"
          );
        }
      );
    }
  }
  private defaultDataPostPrintSearchCriteria(): void {
    const boundDateFrom: Date = new Date();
    boundDateFrom.setDate(boundDateFrom.getDate() - 14);
    this.searchCriteria.boundDateFrom = boundDateFrom;

    // dateTo: 6 months after dateFrom
    const boundDateTo: Date = new Date(boundDateFrom);
    boundDateTo.setMonth(boundDateTo.getMonth() + 6);
    this.searchCriteria.boundDateTo = boundDateTo;

    //this.printDocumentGrid.dataSource.data([]);

    let isValidField: boolean = false;
    isValidField = this.validateDataPostPrintFields();
    if (isValidField) {
      this.refresh();
    }
  }
  private validateDataPostPrintFields(): boolean {
    let isValid: boolean = false;
    //// dates
    const dates: boolean = this.searchCriteria.boundDateFrom !== null;
    isValid = dates; // || etc...
    return isValid;
  }
  public onChange_ProductRadio(): void {
    this.searchCriteria = new DataPostPrintSearchCriteria();
    this.isCitiProduct = this.productRadioControl === "citi" ? true : false;
    this.searchCriteria.productRadio = this.productRadioControl;
    this.defaultDataPostPrintSearchCriteria();
    this.readProductRadio();
    this.fetchUserList();
  }
  public on_boundDateFrom_change(value: Date): void {
    if (this.searchCriteria.boundDateFrom === null) {
      this.searchCriteria.boundDateTo = undefined;
      this.boundDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.boundDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
      return;
    }
    if (
      this.searchCriteria.boundDateTo &&
      this.searchCriteria.boundDateFrom &&
      this.searchCriteria.boundDateTo < this.searchCriteria.boundDateFrom
    ) {
      this.searchCriteria.boundDateTo = undefined;
      this.boundDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.boundDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
    }
    //  set range 6 months
    const maxDate = new Date(value);
    maxDate.setMonth(maxDate.getMonth() + 6);
    this.boundDateTo_min = value;
    this.boundDateTo_max = maxDate;

    // open datepicker
    this.boundDateTo.toggle();
  }
  public download(): void {
    if (this.validateDataPostPrintFields()) {
      let warningParamters: warningZoneArgs = {
        showWarning: false,
        warningText: "",
      };
      this.warningZoneEvent.emit(warningParamters);
      this.downloadDataPostPrintReport();
    } else {
      let warningParamters: warningZoneArgs = {
        showWarning: true,
        warningText: "Please select a month.",
      };
      this.warningZoneEvent.emit(warningParamters);
    }
  }
  public gridSelectionChange(selection: SelectionEvent) {
    // console.log("gridSelectionChanged");
  }
  public cellClickHandler({
    sender,
    rowIndex,
    columnIndex,
    dataItem,
    isEdited,
  }: CellClickEvent): void {
    //https://www.telerik.com/kendo-angular-ui/components/grid/api/CellClickEvent/
    // console.log("cell clicked");
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.take = event.take;
    this.pageSize = event.take; //this is the one that update the <kendo-pager-info>
    this.refresh();
  }
  public sort: SortDescriptor[] = [
    {
      field: "policyNo",
      dir: "asc",
    },
  ];
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadData();
  }
  private loadData(): void {
    this.gridView = {
      data: orderBy(this.listing, this.sort),
      total: this.listing.length,
    };
  }
  private refresh(): void {
    this.loading = true;
    //todo: future enhancement
    //var other_data = $('form').serialize(); //page_id=&category_id=15&method=upload&required%5Bcategory_id%5D=Category+ID
    const formData: FormData = new FormData();
    formData.append("take", this.take.toString());
    formData.append("skip", this.skip.toString());
    formData.append("page", "1");
    formData.append("pageSize", this.pageSize.toString());
    formData.append("sort[0][field]", "policyNo");
    formData.append("sort[0][dir]", "asc");

    let array = [
      ["boundDateFrom", "eq", this.searchCriteria.boundDateFrom],
      ["boundDateTo", "eq", this.searchCriteria.boundDateTo],
      ["productRadio", "eq", this.productRadioControl],
      ["policyNo", "eq", this.searchCriteria.policyNo],
      ["insuredName", "eq", this.searchCriteria.insuredName],
      ["productIds", "eq", this.searchCriteria.productIds],
    ];

    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < array[i].length; j++) {
        if (j == 0) {
          formData.append(
            `filter[filters][${i}][field]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else if (j == 1) {
          formData.append(
            `filter[filters][${i}][operator]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else {
          formData.append(
            `filter[filters][${i}][value]`,
            JSON.parse(JSON.stringify(array[i][j] || ""))
          );
        }
      }
    }
    //https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
    //IT HAS TO BE IN SINGLE CHUNK OF STRING
    var querystring: string = "";
    for (var pair of formData.entries()) {
      querystring +=
        encodeURIComponent(pair[0]) +
        "=" +
        encodeURIComponent(pair[1] as string) +
        "&";
    }

    this.presetExportService.printDatapostReportSearch(querystring).subscribe(
      (result: any) => {
        if (
          result != undefined &&
          result.body != undefined &&
          result.body != null
        ) {
          this.listing = result.body.d?.results;
          this.totalListing = result.body.d?.__count;
          this.loadItems();
          this.loading = false;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  private loadItems(): void {
    this.gridView = {
      data: orderBy(this.listing, this.sort), //.slice(this.skip, this.skip + this.pageSize),
      total: this.totalListing,
    };
  }
  public search(): void {
    if (this.form.dirty) {
      //whenever the form is dirty, reset the skip to 0
      //paging event will not, and should not reach here
      this.skip = 0;
    }
    const isValid = this.validateDataPostPrintFields();

    if (isValid) {
      let warningParamters: warningZoneArgs = {
        showWarning: false,
        warningText: "",
      };
      this.warningZoneEvent.emit(warningParamters);
      this.submitted = true;
      this.refresh();
    } else {
      let warningParamters: warningZoneArgs = {
        showWarning: true,
        warningText: "Please select a month.",
      };
      this.warningZoneEvent.emit(warningParamters);
    }
  }
  private downloadDataPostPrintReport() {
    this.searchCriteria.productRadio = this.isCitiProduct ? "citi" : "dbs";
    this.presetExportService
      .printDatapostReport(
        this.searchCriteria.productRadio,
        this.searchCriteria.boundDateFrom,
        this.searchCriteria.boundDateTo,
        this.searchCriteria.policyNo,
        this.searchCriteria.insuredName,
        this.searchCriteria.productIds
      )
      .subscribe((response: any) => {
        if (
          response != undefined &&
          response.body != undefined &&
          response.body != null
        ) {
          this.excelTemplateService.downloadDataPostPrintReport(
            printDatapostColumn,
            "DataPost Document Print Report",
            "DataPost Document Print Report",
            response.body.d?.results
          );
        }
      });
  }
}
const printDatapostColumn: string[] = [
  "Type",
  "Name Of Folder",
  "Link",
  "No.Of Pages",
  "Combine PDF Name",
  "Product",
  "Policy Number",
  "Document Type",
  "Transaction Bound Date",
  "Transacted By User Name",
  "Saved By Organisation",
  "Channel Of Doc",
  "Correspondence Address",
];
