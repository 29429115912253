import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { GUID } from "src/app/framework/domain-entity/guid";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { ProposalEntity } from "src/app/models/new-product/base/proposal-entity.model";
import { MortgageFireSingaporeProposal } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { MasterDataService } from "src/app/services/master-data.service";
import {
  MasterPolicyEntity,
  MasterPolicyService,
} from "src/app/services/master-policy.service";
import { Global } from "src/app/shared/global";
import { MfSgBaseComponent } from "../mf-sg-base.component";
import * as EnumSG from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-enums";
import * as Enum from "src/app/framework/enum.shared";
import { common } from "src/app/framework/utils/common";
import { ProposalActivityService } from "src/app/services/new-product/proposal-activity.service";
import { MfSgProposalService } from "src/app/services/new-product/sme/mortgage-fire/singapore/mf-sg-proposal.service";
import { Proposal } from "src/app/models/new-product/base/proposal.model";
import { QuestionEntity } from "src/app/models/new-product/base/question-entity.model";
import { Echos } from "src/app/models/new-product/base/echos.model";
import { componentHelper } from "src/app/models/new-product/utils/component.helper";

@Component({
  selector: "app-mf-sg-main",
  templateUrl: "./mf-sg-main.component.html",
  styleUrls: ["./mf-sg-main.component.scss"],
})
export class MfSgMainComponent extends MfSgBaseComponent implements OnInit {
  sub: any;

  /** Flag to determine if document is completely rendered */
  documentReady: boolean = false;
  proposal!: MortgageFireSingaporeProposal;
  productListing: any;
  productName!: string;
  category: string = "";
  submittedDate!: string;
  isCitiProduct: boolean = false;
  isNewBusiness: boolean = false;
  pphRecordCount: number = 0;
  productId?: string;

  constructor(
    private route: ActivatedRoute,
    private proposalService: MfSgProposalService,
    private proposalActivityService: ProposalActivityService,
    private masterPolicyService: MasterPolicyService,
    public identityRolesService: IdentityRolesService,
    public masterDataService: MasterDataService,
    private cdRef: ChangeDetectorRef
  ) {
    super();
    super.subscribePageLoadedNotifier(this.pageLoaded);
    super.subscribeProposalLoadedNotifier(this.proposalLoaded);
    super.subscribeProposalFailedNotifier(this.onProposalFailed);
    super.subscribeProposalSavedNotifier(this.proposalSaved);
  }

  ngOnInit(): void {
    this.sub = this.route.queryParams.subscribe((params) => {
      this.productId = params["pdid"];
      //loadProposal() depends on this, hence must load before it in subscribe()
      this.instantiateProposalObject();

      // Instantiate DBS master data
      this.initProposalDateSubmitted(params).subscribe((date) => {
        this.loadProposal();
      });
    });
  }

  initProposalDateSubmitted(params: { [x: string]: any }): Observable<any> {
    const proposalId =
      params["pid"] === "00000000-0000-0000-0000-000000000000"
        ? null
        : params["pid"];
    let sDate: any;
    if (Global.isDefined(proposalId)) {
      return this.masterPolicyService.getProposalDateSubmitted(proposalId).pipe(
        map(
          (date: any) => {
            sDate = date;
            this.submittedDate = sDate;
            return this.submittedDate;
          },
          (error: any) => {
            return error;
          }
        )
      );
    } else {
      sDate = new Date();
      this.submittedDate = sDate;
      return of(this.submittedDate);
    }
  }

  public $postLink = () => {
    // do nothing
  };

  ngOnDestroy() {
    this.sub?.unsubscribe();
    componentHelper.notifyPageUnload("mf-sg-main.component", this.proposal);
    super.unsubscribeRxjs();
  }

  //only show the <ct-sg-premium-period-history> control when it's not NB
  public showPremiumPeriodHistory() {
    return this.isCitiProduct && this.pphRecordCount > 0 && !this.isNewBusiness;
  }

  scrollToTop = () => {
    //$("html, body").animate({ scrollTop: 0 }, 500);
    window.scrollTo(0, 0);
  };

  /** Method to create a proposal instance */
  private instantiateProposalObject() {
    const productId = new GUID(this.proposalActivityService.readProductId()!);
    const proposal = new MortgageFireSingaporeProposal(
      productId,
      this.identityRolesService,
      this.masterDataService
    );
    this.proposal = proposal;
    this.proposalService.initialize(proposal);
    //Set isCitiProduct
    if (
      proposal.productType === EnumSG.MortgageFireSgProduct.CTHDB ||
      proposal.productType === EnumSG.MortgageFireSgProduct.CTMFPTE ||
      proposal.productType === EnumSG.MortgageFireSgProduct.CTMFCONDO
    ) {
      this.isCitiProduct = true;
    }
  }

  private proposalLoaded = (event: KeyValue<Proposal>) => {
    var updatedProposal = event.value as MortgageFireSingaporeProposal;
    //20230202 GKH: update the proposal with newly updated
    this.proposal = updatedProposal;
    //do at $onInit event will not be the latest, thus do it here
    //search only will reach here
    this.getLastestProposalTransStatus(updatedProposal);
  };

  //https://stackoverflow.com/questions/34547127/angular2-equivalent-of-document-ready
  ngAfterViewInit() {
    //todo: check if this works
    this.pageLoaded();
    //https://stackoverflow.com/questions/53767289/angular-error-error-expressionchangedafterithasbeencheckederror-expression-ha
    this.cdRef.detectChanges();
  }

  private pageLoaded = (/*event: KeyValue<Proposal>*/) => {
    //todo
    //$(document).ready(() => {
    setTimeout(() => {
      this.productListing =
        this.identityRolesService.productListing !== undefined
          ? this.identityRolesService.productListing
          : "";

      this.productListing.forEach((product: { pdid: string; pd: string }) => {
        if (product.pdid.toLowerCase() === this.productId!.toLowerCase()) {
          this.productName = product.pd;
        }
      });

      this.documentReady = true;
    }, 60);
    //});
  };

  private loadProposal() {
    // get Master Policies
    this.masterPolicyService
      .getListByProduct(this.proposal.productId, this.submittedDate)
      .subscribe((masterPolicyList: MasterPolicyEntity[]) => {
        const options = new Array<KeyValue<string>>();
        if (common.isDefined(masterPolicyList)) {
          masterPolicyList.forEach((mp) => {
            options.push(new KeyValue<string>(mp.description, mp.id));
          });
        }
        this.proposal.qsMaster.qs_master_masterPolicyNumber!.options = options;
      });

    // Populate all requires master data for listing type questions before assigning the answer
    this.masterDataService
      .getSgIbgOccupancyRateList()
      .subscribe((sgIgbOccupancyRateListResponse) => {
        this.proposal.qsMaster.qs_master_occupancyRate!.options =
          sgIgbOccupancyRateListResponse;
        this.masterDataService
          .getSGIOccupationList()
          .subscribe((sgiOccupationListResponse) => {
            this.proposal.qsMaster.qs_master_insuredIndustry!.options =
              sgiOccupationListResponse;
            this.masterDataService
              .getSGIBGMachineryTypeList()
              .subscribe((sgiMachineryListResponse) => {
                this.proposal.qsMaster.qs_machinery_equipment!.options =
                  sgiMachineryListResponse;
                if (
                  this.proposal.productType ===
                  EnumSG.MortgageFireSgProduct.IBGMAC
                ) {
                  this.category = "BUPC";
                }
                this.masterDataService
                  .getFinancialInstitutionBranches(this.category)
                  .subscribe((codeResponse) => {
                    const options = new Array<KeyValue<string>>();
                    codeResponse.forEach(
                      (mp: { key: string; value: string }) => {
                        options.push(new KeyValue<string>(mp.key, mp.value));
                      }
                    );
                    if (this.category === "BUPC") {
                      this.proposal.qsMaster.qs_master_buPcCode!.options =
                        options;
                    } else {
                      if (
                        this.proposal.productType ===
                        EnumSG.MortgageFireSgProduct.IBGMF
                      ) {
                        this.proposal.qsMaster.qs_master_buPcCode!.options =
                          options;
                      } else {
                        this.proposal.qsMaster.qs_master_financialBranch!.options =
                          options;
                      }
                    }

                    //this is to init kendo's notifier
                    this.proposalService.setVCR(this.baseNotifierAppendTo);
                    // actual load of proposal from back end
                    this.proposalService.createOrEdit();
                  });
              });
          });
      });
  }

  private onProposalFailed = (event: KeyValue<any>) => {
    // const echos = event.value as Echos<QuestionEntity>[];
    // this.resetMandatoryTabColor();
    // this.setMandatoryTabColor(echos);
  };

  private setMandatoryTabColor = (echos: any) => {
    // if (common.isDefined(echos) && echos.length > 0) {
    //     echos.forEach((echo, index) => {
    //         if (echo.echos.length > 0) {
    //             echo.echos.forEach(echoItem => {
    //                 switch (echoItem.info) {
    //                     case 0:
    //                         angular.element('li#master').addClass('k-state-mandatory');
    //                         break;
    //                     case 1:
    //                         angular.element('li#premiumSummary').addClass('k-state-mandatory');
    //                         break;
    //                 }
    //             });
    //         }
    //     });
    // }
  };

  private resetMandatoryTabColor = () => {
    // // reset all the kendo tab for mandatory class
    // angular.element('li#master').removeClass('k-state-mandatory');
    // angular.element('li#premiumSummary').removeClass('k-state-mandatory');
  };

  private proposalSaved = (event: KeyValue<any>) => {
    const echos = event.value as Echos<QuestionEntity>[];
    this.resetMandatoryTabColor();
    this.setMandatoryTabColor(
      this.proposalService.fieldValidationManager.echos
    );
    var updatedProposal = event.value as MortgageFireSingaporeProposal;
    //20230202 GKH: update the proposal with newly updated
    this.proposal = updatedProposal;
    //user could have created a NB and directly enter END status, thus without go thru proposalLoaded() event
    this.getLastestProposalTransStatus(updatedProposal);
  };

  private getLastestProposalTransStatus(
    updatedProposal: MortgageFireSingaporeProposal
  ) {
    const premiumData = this.proposal.auxiliary["qt"];
    if (common.isDefined(premiumData) && premiumData.length > 0) {
      var pph = premiumData[0].premiumPeriodHistory;
      if (common.isDefined(pph) && pph.length > 0) {
        this.pphRecordCount = pph.length;
      }
    }
    this.isNewBusiness = updatedProposal.transType === Enum.TransType.NEW;
  }

  private doTransition(returnEntity: ProposalEntity, action: any): void {
    // // refresh the URL only
    // const productId: string = returnEntity.productId.toString().toLocaleUpperCase();
    // let productRuote = '';
    // // let url = window.location.origin + window.location.pathname + '#/proposal';
    // switch (productId) {
    //     case Const.ProductID.PRODUCT_ID_DBS_MORTGAGEINTERESTPLUS:
    //         productRuote = 'proposal.dbssgpte';
    //         // url = url + '/MortgageeInterestPlusMIP/' + productId + '/' + returnEntity.proposalId + '/' + action;
    //         break;
    //     case Const.ProductID.PRODUCT_ID_DBS_HOUSEOWNERPLUSPTE:
    //         productRuote = 'proposal.dbssgpte';
    //         break;
    //     case Const.ProductID.PRODUCT_ID_DBS_HOUSEOWNERPLUSHDB:
    //         productRuote = 'proposal.dbssghdb';
    //         break;
    //     case Const.ProductID.PRODUCT_ID_DBS_MF_IBG:
    //         productRuote = 'proposal.dbssgibgmf';
    //         break;
    //     case Const.ProductID.PRODUCT_ID_DBS_MACH_IBG:
    //         productRuote = 'proposal.dbssgibgmac';
    //         break;
    //     case Const.ProductID.PRODUCT_ID_CITI_HOUSEOWNERPLUSHDB:
    //         productRuote='proposal.citisghdb';
    //         break;
    //     case Const.ProductID.PRODUCT_ID_CITI_MORTGAGEFIREINSURANCEPTE:
    //         productRuote= 'proposal.citisgmfpte';
    //         break;
    //     case Const.ProductID.PRODUCT_ID_CITI_MORTGAGEFIREINSURANCECONDO:
    //         productRuote = 'proposal.citisgmfcondo';
    //         break;
    //     default:
    // }
    // // history.pushState(null, '', url);
    // this.$state.transitionTo(productRuote, {
    //     pdid: productId,
    //     mpid: returnEntity.proposalId,
    //     pid: returnEntity.proposalId,
    //     action: '',
    //     type: '1',
    // }, { reload: false });
  }
}
