import { Component, Inject, OnInit } from "@angular/core";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { DialogRef } from "../dialog/dialog-ref";
import { DIALOG_DATA } from "../dialog/dialog-tokens";
import { dlgModelArgs } from "../md-confirmation-message-v2/md-confirmation-message-v2.component";

@Component({
  selector: "app-md-confirmation-message-v4",
  templateUrl: "./md-confirmation-message-v4.component.html",
  styleUrls: ["./md-confirmation-message-v4.component.scss"],
})
export class MdConfirmationMessageV4Component implements OnInit {
  data: any;
  model = {};
  options: KeyValue<string>[] = [
    {
      key: "Email",
      value: "2",
    },
    {
      key: "SMS",
      value: "5",
    },
    {
      key: "Mail",
      value: "1",
    },
    {
      key: "No Action Required",
      value: "4",
    },
  ];
  selectedPrintType: any;

  isValid = true;
  isIBG = false;
  isInvalidEmailAddress = false;
  response = "";

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public inputData: v4DlgInputArgs
  ) {}

  ngOnInit(): void {
    if (this.inputData) {
      this.data = this.inputData;

      this.data.selectedPrintType = this.options.filter((item) => {
        return item.value == this.data.preferredDeliveryMethod;
      })[0];

      if (
        this.data.selectedPrintType != null &&
        this.data.selectedPrintType.value === "2"
      ) {
        this.data.printCorrespondence = this.inputData.emailId;
      } else if (
        this.data.selectedPrintType != null &&
        this.data.selectedPrintType.value === "5"
      ) {
        this.data.printCorrespondence = this.inputData.mobileNo;
      } else if (
        this.data.selectedPrintType != null &&
        this.data.selectedPrintType.value === "1"
      ) {
        this.data.printCorrespondence = this.inputData.contactInfo;
      }
      if (this.data.selectedPrintType == null) {
        this.data.selectedPrintType = this.options[1];
      }

      //https://github.com/udos86/ng-dynamic-forms/issues/362
      //overhead for non-primitive
      this.selectedPrintType = this.data.selectedPrintType.value;

      if (
        this.data.productId == "06CB09D3-6FE0-4695-92A8-A61DFB627C61" ||
        this.data.productId == "8533FA05-F190-4A29-9613-3DB7F693DD32"
      ) {
        this.isIBG = true;
      }

      this.data.isPasswordProtected = true;

      setTimeout(function () {
        //angular.element('#selectReprintConfirmationBoard').kendoDropDownList();
      }, 100);
    }
  }

  validateEmail(email: string) {
    var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    var valid = false;
    if (email !== "" && reg.test(email)) {
      this.isInvalidEmailAddress = false;
      this.response = "";
      valid = true;
      this.data.printCorrespondence = email;
    } else {
      this.isInvalidEmailAddress = true;
      this.response = "Invalid Email Address!";
      valid = false;
      //todo
      // angular.element('.alertLogin.invalidEmail').fadeIn(500);
      // setTimeout("$('.alertLogin.invalidEmail').fadeOut(1500);", 3000);
    }
    return valid;
  }

  optionChange(event: any) {
    //https://github.com/udos86/ng-dynamic-forms/issues/362
    //overhead for non-primitive
    this.data.selectedPrintType = this.options.filter((item) => {
      return item.value == this.selectedPrintType;
    })[0];

    this.response = "";
    if (this.data.selectedPrintType.value == 2) {
      this.validateEmail(this.data.emailId);
    }
    if (this.data.selectedPrintType.value == 5) {
      this.validateMobile(this.data.mobileNo);
    }
  }

  validateAddress(address: string | null) {
    var valid = false;
    if (address !== "" && address !== null) {
      this.isInvalidEmailAddress = false;
      this.data.printCorrespondence = address;
      valid = true;
    } else {
      this.isInvalidEmailAddress = true;
      valid = false;
      //todo
      // angular.element('.alertLogin.invalidEmail').fadeIn(500);
      // setTimeout("$('.alertLogin.invalidEmail').fadeOut(1500);", 3000);
    }
    return valid;
  }

  validateMobile(mobile: string) {
    var reg = /^\+?[0-9]+$/;
    var valid = false;
    if (
      mobile.length == 8 &&
      mobile !== "" &&
      reg.test(mobile) &&
      (mobile.toString()[0] == "9" || mobile.toString()[0] == "8")
    ) {
      this.isInvalidEmailAddress = false;
      valid = true;
      this.response = "";
      this.data.printCorrespondence = mobile;
    } else {
      this.isInvalidEmailAddress = true;
      this.response = "Invalid Mobile Number!";
      valid = false;
      //todo
      // angular.element('.alertLogin.invalidEmail').fadeIn(500);
      // setTimeout("$('.alertLogin.invalidEmail').fadeOut(1500);", 3000);
    }
    return valid;
  }

  confirmation(flag: any) {
    if (flag) {
      if (
        this.data.selectedPrintType.value == 2 &&
        !this.validateEmail(this.data.emailId)
      ) {
        return;
      }

      if (
        this.data.selectedPrintType.value == 5 &&
        !this.validateMobile(this.data.mobileNo)
      ) {
        return;
      }

      if (
        this.data.selectedPrintType.value == 1 &&
        !this.validateAddress(this.data.contactInfo)
      ) {
        this.response = "Invalid Mail Adress!";
        return;
      }
    }

    this.data.confirmation = flag;
    this.close("yes");
  }

  public close(status: any) {
    let savParamters: dlgModelArgs = {
      status: status,
      data: this.data,
    };
    this.dialogRef?.close(savParamters);
  }
}

export interface v4DlgInputArgs {
  preferredDeliveryMethod: any;
  emailId: any;
  mobileNo: any;
  contactInfo: any;
  confimationMsg: any;
}
