import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { DatePickerComponent } from "@progress/kendo-angular-dateinputs";
import { BaseComponent } from "src/app/components/shared/base/base.component";
import { IProduct } from "src/app/framework/interface.product";
import { BroadcastService } from "src/app/services/broadcast.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { ExcelTemplateService } from "src/app/services/excel-template.service";
import { PresetExportService } from "src/app/services/preset-export.service";
import { LoanRedemptionSearchCriteria } from "./loan-redemption.model";
import {
    CellClickEvent,
    GridDataResult,
    PageChangeEvent,
    PageSizeItem,
    SelectionEvent,
} from "@progress/kendo-angular-grid";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import {
    LoanRedemptionFailureEntity,
    LoanRedemptionSuccessEntity,
} from "../preset-report.model";
import { warningZoneArgs } from "../../warning-zone/warning-zone.component";
import { UntypedFormGroup } from "@angular/forms";
@Component({
    selector: "app-loan-redemption-report",
    templateUrl: "./loan-redemption.component.html",
    styleUrls: ["./loan-redemption.component.scss"],
})
export class LoanRedemptionReportComponent
    extends BaseComponent
    implements OnInit {
    @ViewChild("heroForm", { static: false })
    private form!: UntypedFormGroup;
    @ViewChild("fileUploadDateTo", { static: false })
    public fileUploadDateTo!: DatePickerComponent;

    public fileUploadDateTo_min!: Date;
    public fileUploadDateTo_max!: Date;
    @Input() presetReportType!: number;
    @Output() warningZoneEvent = new EventEmitter<any>();
    public searchCriteria = new LoanRedemptionSearchCriteria();
    public userProductList!: IProduct[];
    public showReportType: boolean = false;
    public isCitiProduct: boolean = false;
    public productRadioControl!: string;
    public loading!: boolean;

    public gridView!: GridDataResult;
    public pageSize = 10;
    public skip = 0;
    public take = 10;
    public pageSizes: (PageSizeItem | number)[] = [10, 50, 100];
    public listingSuccess: LoanRedemptionSuccessEntity[] = [];
    public listingFailure: LoanRedemptionFailureEntity[] = [];
    private totalListing: number = 0;
    submitted = false;
    isMSBRSearch = false;
    public segmentList!: Array<{ text: string; value: string }>;
    public channelList!: Array<{ text: string; value: string }>;

    constructor(
        public override router: Router,
        public override broadcastService: BroadcastService,
        public override identityRolesService: IdentityRolesService,
        private presetExportService: PresetExportService,
        private excelTemplateService: ExcelTemplateService
    ) {
        super(router, broadcastService, identityRolesService);
    }
    override ngOnInit(): void {
        this.searchCriteria.reportType = this.presetReportType.toString();
        //segments
        this.segmentList = [
            { text: "CBG", value: "CBG" },
            { text: "IBG", value: "IBG" },
        ];
        this.channelList = [
            { text: "via UI", value: "web" },
            { text: "via DSG", value: "console" },
        ];
        this.productRadioControl = "dbs";
        if (
            this.identityRolesService.isChecker ||
            this.identityRolesService.isMaker
        ) {
            this.productRadioControl = "dbs";
            this.onChange_ProductRadio();
        } else {
            this.showReportType = true;
        }
        this.fetchUserList();
        this.defaultLoanRedemptionSearchCriteria();
    }
    ngOnChanges(changes: SimpleChanges): void {
        this.searchCriteria = new LoanRedemptionSearchCriteria();
        this.defaultLoanRedemptionSearchCriteria();
    }
    private readProductRadio() {
        this.isCitiProduct = this.productRadioControl === "citi";
    }
    private fetchUserList() {
        // get user productsList
        if (this.isCitiProduct) {
            this.userProductList = this.identityRolesService.productListing.filter(
                (element: { pdid: string }) => {
                    return (
                        element.pdid.toUpperCase() ===
                        "A8CDAC65-3EC4-4138-974A-D18C3EF8536C" ||
                        element.pdid.toUpperCase() ===
                        "1F7EE805-C8CE-416C-AF29-292E920878F8" ||
                        element.pdid.toUpperCase() ===
                        "B8A348CA-491C-48DA-988A-FCC52F7FC5C5"
                    );
                }
            );
        } else {
            this.userProductList = this.identityRolesService.productListing.filter(
                (element: { pdid: string }) => {
                    return !(
                        element.pdid.toUpperCase() ===
                        "A8CDAC65-3EC4-4138-974A-D18C3EF8536C" ||
                        element.pdid.toUpperCase() ===
                        "1F7EE805-C8CE-416C-AF29-292E920878F8" ||
                        element.pdid.toUpperCase() ===
                        "B8A348CA-491C-48DA-988A-FCC52F7FC5C5"
                    );
                }
            );
        }
    }
    private defaultLoanRedemptionSearchCriteria(): void {
        const initialDate: Date = new Date();
        const endDate: Date = new Date();
        initialDate.setDate(initialDate.getDate() - 1);
        endDate.setDate(endDate.getDate());

        this.searchCriteria.effectiveDateFrom = initialDate;
        this.searchCriteria.effectiveDateTo = endDate;
        this.searchCriteria.segmentDetails = "CBG";
        this.searchCriteria.dateSearchType = "MSBR";
        this.searchCriteria.reportType = this.presetReportType.toString();
        this.searchCriteria.productRadio = this.productRadioControl;
        this.searchCriteria.channel = "web";

        let isValidField: boolean = false;
        isValidField = this.validateLoanRedemptionFields();
        if (isValidField) {
            this.refresh();
        }
    }
    private validateLoanRedemptionFields(): boolean {
        let isValid: boolean = false;
        //// dates
        const dates: boolean =
            this.searchCriteria.effectiveDateFrom !== null &&
            this.searchCriteria.effectiveDateTo !== null;
        isValid = dates; // || etc...
        return isValid;
    }
    public onChange_ProductRadio(): void {
        this.searchCriteria = new LoanRedemptionSearchCriteria();
        this.isCitiProduct = this.productRadioControl === "citi" ? true : false;
        this.searchCriteria.productRadio = this.productRadioControl;
        this.defaultLoanRedemptionSearchCriteria();
        this.readProductRadio();
        this.fetchUserList();
    }

    public download(): void {
        if (this.validateLoanRedemptionFields()) {
            let warningParamters: warningZoneArgs = {
                showWarning: false,
                warningText: "",
            };
            this.warningZoneEvent.emit(warningParamters);
            this.downloadLoanRedemptionReport();
        } else {
            let warningParamters: warningZoneArgs = {
                showWarning: true,
                warningText: "Please select a date range.",
            };
            this.warningZoneEvent.emit(warningParamters);
        }
    }
    public gridSelectionChange(selection: SelectionEvent) {
        // console.log("gridSelectionChanged");
    }
    public cellClickHandler({
        sender,
        rowIndex,
        columnIndex,
        dataItem,
        isEdited,
    }: CellClickEvent): void {
        //https://www.telerik.com/kendo-angular-ui/components/grid/api/CellClickEvent/
        // console.log("cell clicked");
    }
    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.take = event.take;
        this.pageSize = event.take; //this is the one that update the <kendo-pager-info>
        this.refresh();
    }
    public sort: SortDescriptor[] = [
        {
            field: "policyNo",
            dir: "asc",
        },
    ];
    public sortChange(sort: SortDescriptor[]): void {
        this.sort = sort;
        this.loadData();
    }
    private loadData(): void {
        if (this.presetReportType === 17) {
            this.gridView = {
                data: orderBy(this.listingSuccess, this.sort),
                total: this.listingSuccess.length,
            };
        } else {
            this.gridView = {
                data: orderBy(this.listingFailure, this.sort),
                total: this.listingFailure.length,
            };
        }
    }
    private refresh(): void {
        this.loading = true;
        //todo: future enhancement
        //var other_data = $('form').serialize(); //page_id=&category_id=15&method=upload&required%5Bcategory_id%5D=Category+ID
        const formData: FormData = new FormData();
        formData.append("take", this.take.toString());
        formData.append("skip", this.skip.toString());
        formData.append("page", "1");
        formData.append("pageSize", this.pageSize.toString());
        formData.append("sort[0][field]", "policyNo");
        formData.append("sort[0][dir]", "asc");

        let array = [
            ["productRadio", "eq", this.searchCriteria.productRadio],
            ["productIds", "eq", this.searchCriteria.productIds],
            ["segmentDetails", "eq", this.searchCriteria.segmentDetails],
            ["effectiveDateFrom", "eq", this.searchCriteria.effectiveDateFrom],
            ["effectiveDateTo", "eq", this.searchCriteria.effectiveDateTo],
            ["dateSearchType", "eq", this.searchCriteria.dateSearchType],
            ["reportType", "eq", this.searchCriteria.reportType],
            ["channel", "eq", this.searchCriteria.channel],
        ];

        for (let i = 0; i < array.length; i++) {
            for (let j = 0; j < array[i].length; j++) {
                if (j == 0) {
                    formData.append(
                        `filter[filters][${i}][field]`,
                        JSON.parse(JSON.stringify(array[i][j]))
                    );
                } else if (j == 1) {
                    formData.append(
                        `filter[filters][${i}][operator]`,
                        JSON.parse(JSON.stringify(array[i][j]))
                    );
                } else {
                    formData.append(
                        `filter[filters][${i}][value]`,
                        JSON.parse(JSON.stringify(array[i][j] || ""))
                    );
                }
            }
        }
        //https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
        //IT HAS TO BE IN SINGLE CHUNK OF STRING
        var querystring: string = "";
        for (var pair of formData.entries()) {
            querystring +=
                encodeURIComponent(pair[0]) +
                "=" +
                encodeURIComponent(pair[1] as string) +
                "&";
        }

        this.presetExportService.loanRedemptionReportSearch(querystring).subscribe(
            (result: any) => {
                if (
                    result != undefined &&
                    result.body != undefined &&
                    result.body != null
                ) {
                    if (this.presetReportType === 17)
                        this.listingSuccess = result.body.d?.results;
                    else this.listingFailure = result.body.d?.results;
                    this.totalListing = result.body.d?.__count;
                    this.loadItems();
                    this.loading = false;
                }
            },
            (err: any) => {
                this.loading = false;
                console.log(err);
            }
        );
    }

    private loadItems(): void {
        if (this.presetReportType === 17) {
            this.gridView = {
                data: orderBy(this.listingSuccess, this.sort), //.slice(this.skip, this.skip + this.pageSize),
                total: this.totalListing,
            };
        } else {
            this.gridView = {
                data: orderBy(this.listingFailure, this.sort), //.slice(this.skip, this.skip + this.pageSize),
                total: this.totalListing,
            };
        }
    }
    public search(): void {
        if (this.form?.dirty) {
            //whenever the form is dirty, reset the skip to 0
            //paging event will not, and should not reach here
            this.skip = 0;
        }
        const isValid = this.validateLoanRedemptionFields();

        if (isValid) {
            let warningParamters: warningZoneArgs = {
                showWarning: false,
                warningText: "",
            };
            this.warningZoneEvent.emit(warningParamters);
            this.submitted = true;
            this.refresh();
        } else {
            let warningParamters: warningZoneArgs = {
                showWarning: true,
                warningText: "Please select a date range.",
            };
            this.warningZoneEvent.emit(warningParamters);
        }
    }
    private downloadLoanRedemptionReport() {
        this.searchCriteria.productRadio = this.isCitiProduct ? "citi" : "dbs";
        this.searchCriteria.reportType = this.presetReportType.toString();
        if (this.presetReportType === 17) {
            this.presetExportService
                .loanRedemptionSuccess(
                    this.searchCriteria.dateSearchType,
                    this.searchCriteria.effectiveDateFrom,
                    this.searchCriteria.effectiveDateTo,
                    this.searchCriteria.segmentDetails,
                    this.searchCriteria.reportType,
                    this.searchCriteria.productIds,
                    this.searchCriteria.productRadio,
                    this.searchCriteria.channel
                )
                .subscribe((response: any) => {
                    if (
                        response != undefined &&
                        response.body != undefined &&
                        response.body != null
                    ) {
                        if (this.searchCriteria.segmentDetails === "CBG") {
                            if (this.searchCriteria.productRadio === "citi") {
                                this.excelTemplateService.downloadCitiLoanRedemptionSuccessReport(
                                    successCitiCbgColumn,
                                    "Loan Redemption Success Report",
                                    "Loan Redemption Success Report Citibank",
                                    response.body.d?.results
                                );
                            } else {
                                this.excelTemplateService.downloadDbsCbgLoanRedemptionSuccessReport(
                                    successDbsCbgColumn,
                                    "Loan Redemption Success Report",
                                    "Loan Redemption Success Report" +
                                    " - " +
                                    this.searchCriteria.segmentDetails,
                                    response.body.d?.results
                                );
                            }
                        } else {
                            this.excelTemplateService.downloadDbsIbgLoanRedemptionSuccessReport(
                                successIbgColumn,
                                "Loan Redemption Success Report",
                                "Loan Redemption Success Report" +
                                " - " +
                                this.searchCriteria.segmentDetails,
                                response.body.d?.results
                            );
                        }
                    }
                });
        } else if (this.presetReportType === 18) {
            this.presetExportService
                .loanRedemptionFailure(
                    this.searchCriteria.dateSearchType,
                    this.searchCriteria.effectiveDateFrom,
                    this.searchCriteria.effectiveDateTo,
                    this.searchCriteria.segmentDetails,
                    this.searchCriteria.reportType,
                    this.searchCriteria.productIds,
                    this.searchCriteria.productRadio,
                    this.searchCriteria.channel
                )
                .subscribe((response: any) => {
                    if (this.searchCriteria.segmentDetails === "CBG") {
                        if (this.searchCriteria.productRadio === "citi") {
                            this.excelTemplateService.downloadCitiLoanRedemptionFailureReport(
                                failureCitiCbgColumn,
                                "Loan Redemption Exception Report Citibank",
                                "Loan Redemption Exception Report Citibank",
                                response.body.d?.results
                            );
                        } else {
                            this.excelTemplateService.downloadDbsCbgLoanRedemptionFailureReport(
                                failureDbsCbgColumn,
                                "Loan Redemption Exception Report",
                                "Loan Redemption Exception Report" +
                                " - " +
                                this.searchCriteria.segmentDetails,
                                response.body.d?.results
                            );
                        }
                    } else {
                        this.excelTemplateService.downloadDbsIbgLoanRedemptionFailureReport(
                            failureIbgColumn,
                            "Loan Redemption Exception Report",
                            "Loan Redemption Exception Report" +
                            " - " +
                            this.searchCriteria.segmentDetails,
                            response.body.d?.results
                        );
                    }
                });
        }
    }
    public on_segment_change(value: any): void {
        this.refresh();
    }
    public setRequiredProperties(e: any): void {
        if (e === 1) {
            this.isMSBRSearch = true;
            this.searchCriteria.dateSearchType = "MSBR";
        } else {
            this.isMSBRSearch = false;
            this.searchCriteria.dateSearchType = "FileUpload";
        }
    }
    public on_FileUploadDateFrom_change(value: Date): void {
        if (this.searchCriteria.effectiveDateFrom === null) {
            this.searchCriteria.effectiveDateTo = undefined;
            this.fileUploadDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
            this.fileUploadDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
            return;
        }
        if (
            this.searchCriteria.effectiveDateTo &&
            this.searchCriteria.effectiveDateFrom &&
            this.searchCriteria.effectiveDateTo <
            this.searchCriteria.effectiveDateFrom
        ) {
            this.searchCriteria.effectiveDateTo = undefined;
            this.fileUploadDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
            this.fileUploadDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
        }
        //  set range 6 months
        const maxDate = new Date(value);
        maxDate.setMonth(maxDate.getMonth() + 1);
        this.fileUploadDateTo_min = value;
        this.fileUploadDateTo_max = maxDate;

        // open datepicker
        this.fileUploadDateTo.toggle();
        this.on_searchFrom_focus();
    }
    private on_searchFrom_focus(): void {
        if (this.fileUploadDateTo.isOpen) {
            this.fileUploadDateTo.toggle();
        }
    }
}
const successCitiCbgColumn: string[] = [
    "SI No",
    "Policy Number",
    "Policy Status",
    "Risk Address",
    "Collateral No",
    "Insured Name",
    "Cancellation/Endorsement Effective Date",
    "Refund Premium",
    "Remarks",
    "Transaction Bound Date/Upload Date",
    "Email Date Received",
];
const successDbsCbgColumn: string[] = [
    "SI No",
    "Policy Number",
    "Policy Status",
    "Risk Address",
    "Insured Name",
    "Cancellation/Endorsement Effective Date",
    "Refund Premium",
    "Remarks",
    "Transaction Bound Date/Upload Date",
    "MSBR Date Received",
];
const successIbgColumn: string[] = [
    "SI No",
    "Bank Reference Number",
    "Policy Number",
    "Policy Status",
    "Risk Address",
    "Insured Name",
    "Cancellation/Endorsement Effective Date",
    "Refund Premium",
    "Remarks",
    "Transaction Bound Date/Upload Date",
    "MSBR Date Received",
];
const failureCitiCbgColumn: string[] = [
    "SI No",
    "Policy Number",
    "Collateral No",
    "Borrower/Property owner",
    "Risk Address",
    "Effective Date",
    "Remarks",
    "Email Date Received",
];
const failureDbsCbgColumn: string[] = [
    "SI No",
    "Policy Number",
    "Risk Address",
    "MITD Date",
    "Insurance Action",
    "Remarks",
    "MSBR Date Received",
];
const failureIbgColumn: string[] = [
    "SI No",
    "Bank Reference Number",
    "Policy Number",
    "MITD Date",
    "Insurance Action",
    "Remarks",
    "MSBR Date Received",
];