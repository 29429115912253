<div id="{{ question.key }}" [ngClass]="{ 'st-danger': question.theme === 1 }">
  <label
    for="{{ question.key }}"
    [ngClass]="{ required: question.required && !question.readOnly }"
    [textContent]="question.displayText"
  ></label>

  <div *ngIf="!question.readOnly">
    <kendo-numerictextbox
      id="{{ question.key }}_percentageBox"
      name="{{ question.key }}"
      type="text"
      [maxlength]="question.maxLength!"
      [(ngModel)]="QuestionAnswerFormatted"
      [disabled]="question.isDisabled!"
      [spinners]="false"
      [format]="'##.00 \\%'"
    >
    </kendo-numerictextbox>
  </div>

  <div *ngIf="question.readOnly">
    <p *ngIf="question.isAnswered()">{{ question.answer | percentage }}</p>
    <span class="unattended" *ngIf="!question.isAnswered()">{{
      noAnswerDefaultText
    }}</span>
  </div>
</div>
