<form (ngSubmit)="onSubmit()" #heroForm="ngForm">
  <div class="container-fluid">
    <!-- Search filters -->
    <div class="row">
      <div class="col-md-6">
        <!-- Left search section -->
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="toDoQuoteNo">Quote No</label>
          </div>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              id="toDoQuoteNo"
              placeholder="Quote No"
              ng-keypress="model.on_quoteNumber_keyup($event)"
              [(ngModel)]="model.quoteNo"
              name="quoteNo"
              #name="ngModel"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="toDoPolicyNo">Policy No</label>
          </div>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              id="toDoPolicyNo"
              placeholder="Policy No"
              ng-keypress="model.on_quoteNumber_keyup($event)"
              [(ngModel)]="model.policyNo"
              name="policyNo"
              #name="ngModel"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="toDoCIN">CIN</label>
          </div>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              id="toDoCIN"
              placeholder="CIN (Customer Identification No)"
              ng-keypress="model.on_quoteNumber_keyup($event)"
              [(ngModel)]="model.cin"
              name="cin"
              #name="ngModel"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="toDoInsuredName">Insured Name</label>
          </div>
          <div class="col-md-6">
            <input
              type="text"
              class="form-control"
              id="toDoInsuredName"
              placeholder="Insured Name"
              ng-keypress="model.on_quoteNumber_keyup($event)"
              [(ngModel)]="model.insuredName"
              name="insuredName"
              #name="ngModel"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <!-- Right search section -->
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="toDoStartDateFrom"
              >Policy Start Date
            </label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              id="toDoStartDateFrom"
              class="maxWidth"
              format="dd/MM/yyyy"
              (focus)="on_searchStartDateFrom_focus()"
              (valueChange)="on_searchStartDateFrom_change($event)"
              placeholder=""
              [(ngModel)]="model.startDateFrom"
              name="startDateFrom"
              #name="ngModel"
            ></kendo-datepicker>
          </div>
          <div class="col-md-1 textAlignCenter">
            <label class="marginTop5" for="toDoStartDateTo">to</label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              #toDoStartDateTo
              id="toDoStartDateTo"
              class="maxWidth"
              format="dd/MM/yyyy"
              placeholder=""
              [min]="toDoStartDateTo_min"
              [max]="toDoStartDateTo_max"
              [(ngModel)]="model.startDateTo"
              name="startDateTo"
              #name="ngModel"
            ></kendo-datepicker>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="toDoExpiryDateFrom"
              >Policy Expiry Date</label
            >
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              id="toDoExpiryDateFrom"
              class="maxWidth"
              format="dd/MM/yyyy"
              (focus)="on_searchExpiryDateFrom_focus()"
              (valueChange)="on_searchExpiryDateFrom_change($event)"
              placeholder=""
              [(ngModel)]="model.expiryDateFrom"
              name="expiryDateFrom"
              #name="ngModel"
            ></kendo-datepicker>
          </div>
          <div class="col-md-1 textAlignCenter">
            <label class="marginTop5" for="toDoExpiryDateTo">to</label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              #toDoExpiryDateTo
              id="toDoExpiryDateTo"
              class="maxWidth"
              format="dd/MM/yyyy"
              placeholder=""
              [min]="toDoExpiryDateTo_min"
              [max]="toDoExpiryDateTo_max"
              [(ngModel)]="model.expiryDateTo"
              name="expiryDateTo"
              #name="ngModel"
            ></kendo-datepicker>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="exportExpiryDateFrom"
              >Policy Bound Date</label
            >
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              id="exportBoundDateFrom"
              class="maxWidth"
              format="dd/MM/yyyy"
              placeholder=""
              (focus)="on_searchBoundDateFrom_focus()"
              (valueChange)="on_searchBoundDateFrom_change($event)"
              [(ngModel)]="model.boundDateFrom"
              name="boundDateFrom"
              #name="ngModel"
            ></kendo-datepicker>
          </div>
          <div class="col-md-1 textAlignCenter">
            <label class="marginTop5" for="boundDateTo">to</label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              #boundDateTo
              id="boundDateTo"
              class="maxWidth"
              format="dd/MM/yyyy"
              placeholder=""
              [min]="boundDateTo_min"
              [max]="boundDateTo_max"
              [(ngModel)]="model.boundDateTo"
              name="boundDateTo"
              #name="ngModel"
            ></kendo-datepicker>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-3">
            <label class="marginTop5" for="toDoEffectiveDateFrom"
              >Effective Date</label
            >
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              id="toDoEffectiveDateFrom"
              class="maxWidth"
              format="dd/MM/yyyy"
              (focus)="on_searchEffectiveDateFrom_focus()"
              (valueChange)="on_searchEffectiveDateFrom_change($event)"
              [(ngModel)]="model.effectiveDateFrom"
              name="effectiveDateFrom"
              #name="ngModel"
            ></kendo-datepicker>
          </div>
          <div class="col-md-1 textAlignCenter">
            <label class="marginTop5" for="toDoEffectiveDateTo">to</label>
          </div>
          <div class="col-md-4">
            <kendo-datepicker
              #toDoEffectiveDateTo
              id="toDoEffectiveDateTo"
              class="maxWidth"
              format="dd/MM/yyyy"
              (valueChange)="on_searchEffectiveDateTo_change($event)"
              [(ngModel)]="model.effectiveDateTo"
              name="effectiveDateTo"
              #name="ngModel"
            ></kendo-datepicker>
          </div>
        </div>
      </div>
    </div>
    <div id="todoAdvancedSearch" class="main-container collapse">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-md-4">
              <label class="marginTop5" for="toDoCreatedBy">Created By</label>
            </div>
            <div class="col-md-6">
              <kendo-dropdownlist
                id="toDoCreatedBy"
                [defaultItem]="defaultUserListItem"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [data]="userList"
                textField="text"
                valueField="value"
                [valuePrimitive]="true"
                [(ngModel)]="model.createdByUserId"
                name="createdByUserId"
              ></kendo-dropdownlist>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-md-3">
              <label class="marginTop5" for="exportSubmmissionDateFrom"
                >Maker Submission Date</label
              >
            </div>
            <div class="col-md-4">
              <kendo-datepicker
                id="exportSubmmissionDateFrom"
                class="maxWidth"
                format="dd/MM/yyyy"
                placeholder=""
                (focus)="on_searchSubmmissionDateFrom_focus()"
                (valueChange)="on_searchSubmmissionDateFrom_change($event)"
                [(ngModel)]="model.submissionDateFrom"
                name="submissionDateFrom"
                #name="ngModel"
              ></kendo-datepicker>
            </div>
            <div class="col-md-1 textAlignCenter">
              <label class="marginTop5" for="exportSubmmissionDateTo">to</label>
            </div>
            <div class="col-md-4">
              <kendo-datepicker
                #exportSubmmissionDateTo
                id="exportSubmmissionDateTo"
                class="maxWidth"
                format="dd/MM/yyyy"
                placeholder=""
                [min]="submmissionDateTo_min"
                [max]="submmissionDateTo_max"
                [(ngModel)]="model.submissionDateTo"
                name="submissionDateTo"
                #name="ngModel"
              ></kendo-datepicker>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-md-4">
              <label class="marginTop5" for="exportBankRefNo"
                >Bank Reference No</label
              >
            </div>
            <div class="col-md-6">
              <input
                type="text"
                class="form-control"
                id="exportBankRefNo"
                placeholder="Bank Reference No"
                ng-keypress="model.on_quoteNumber_keyup($event)"
                [(ngModel)]="model.bankReferenceNo"
                name="bankReferenceNo"
                #name="ngModel"
              />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-md-3">
              <label class="marginTop5" for="exportApprovalDateFrom"
                >Checker Approval Date</label
              >
            </div>
            <div class="col-md-4">
              <kendo-datepicker
                id="exportApprovalDateFrom"
                class="maxWidth"
                format="dd/MM/yyyy"
                placeholder=""
                (focus)="on_searchApprovalDateFrom_focus()"
                (valueChange)="on_searchApprovalDateFrom_change($event)"
                [(ngModel)]="model.approvalDateFrom"
                name="approvalDateFrom"
                #name="ngModel"
              ></kendo-datepicker>
            </div>
            <div class="col-md-1 textAlignCenter">
              <label class="marginTop5" for="exportApprovalDateTo">to</label>
            </div>
            <div class="col-md-4">
              <kendo-datepicker
                #approvalDateTo
                id="exportApprovalDateTo"
                class="maxWidth"
                format="dd/MM/yyyy"
                placeholder=""
                [min]="approvalDateTo_min"
                [max]="approvalDateTo_max"
                [(ngModel)]="model.approvalDateTo"
                name="approvalDateTo"
                #name="ngModel"
              ></kendo-datepicker>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-md-4">
              <label class="marginTop5" for="exportSegment">Segment</label>
            </div>
            <div class="col-md-6">
              <kendo-multiselect
                id="exportSegment"
                placeholder="Select segment..."
                [autoClose]="false"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [data]="segmentOptions"
                textField="text"
                valueField="value"
                [valuePrimitive]="true"
                [(ngModel)]="model.segment"
                name="segment"
              >
              </kendo-multiselect>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-md-3">
              <label class="marginTop5" for="toDoUserTeam">User Team</label>
            </div>
            <div class="col-md-9">
              <kendo-multiselect
                id="toDoUserTeam"
                placeholder="Select user team..."
                [autoClose]="false"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [data]="userTeamOptions"
                textField="text"
                valueField="value"
                [valuePrimitive]="true"
                [(ngModel)]="model.userTeam"
                name="userTeam"
              >
              </kendo-multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-md-4">
              <label class="marginTop5" for="exportTrans"
                >Transaction Type</label
              >
            </div>
            <div class="col-md-6">
              <kendo-dropdownlist
                id="exportTrans"
                [defaultItem]="defaultTransTypeItem"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [data]="transTypeOptions"
                textField="text"
                valueField="value"
                [valuePrimitive]="true"
                [(ngModel)]="model.transType"
                name="transType"
              ></kendo-dropdownlist>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group row">
            <div class="col-md-3">
              <label class="marginTop5" for="toDoMasterPolicyNo"
                >Master Policy No</label
              >
            </div>
            <div class="col-md-9">
              <kendo-dropdownlist
                id="toDoMasterPolicyNo"
                [defaultItem]="defaultMasterPolicyOptionsItem"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [data]="masterPolicyOptions"
                textField="text"
                valueField="value"
                [valuePrimitive]="true"
                [(ngModel)]="model.masterPolicyId"
                name="masterPolicyId"
              ></kendo-dropdownlist>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group row">
            <div class="col-md-2">
              <label class="marginTop5" for="toDoStatus">Proposal Status</label>
            </div>
            <div class="col-md-10">
              <kendo-multiselect
                id="toDoStatus"
                placeholder="Select status..."
                [autoClose]="false"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [data]="proposalStatusOptions"
                textField="text"
                valueField="value"
                [valuePrimitive]="true"
                [(ngModel)]="model.proposalStatus"
                name="proposalStatus"
              >
              </kendo-multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group row">
            <div class="col-md-2">
              <label class="marginTop5" for="toDoProduct">Product</label>
            </div>
            <div class="col-md-10">
              <kendo-multiselect
                id="toDoProduct"
                placeholder="Select product..."
                [autoClose]="false"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [data]="userProductList"
                textField="pd"
                valueField="pdid"
                [valuePrimitive]="true"
                [(ngModel)]="model.productId"
                name="productId"
              >
              </kendo-multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--HTML5 button element. Remember the reason is this button has a default behavior of submit, as stated in the W3 specification-->
  <button
    id="todoAdvancedToggler"
    type="button"
    class="btn btn-primary ld-ext-left chubbDarkBlue_bg chubbDarkBlue_border_btn"
    data-toggle="collapse"
    data-target="#todoAdvancedSearch"
    (click)="toggleCaption()"
  >
    <span>{{ advancedSearchCaption }}</span>
    <i
      class="fa fa-caret-down paddingLeft5 verticalAlignSub"
      aria-hidden="true"
    ></i>
  </button>
  <button
    type="submit"
    class="btn btn-primary chubbGreen_bg chubbGreen_border_btn"
    [disabled]="!heroForm.form.valid"
  >
    <i class="fa fa-search ng-scope" aria-hidden="true"></i>
    Search
  </button>
  <button
    type="button"
    class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
    on-click="export()"
    event="2"
  >
    <i class="fa fa-download ng-scope" aria-hidden="true"></i> Export
  </button>
</form>

<br />

<div class="grid-wrapper">
  <kendo-grid
    style="cursor: pointer"
    [data]="gridView"
    [pageSize]="pageSize"
    [skip]="skip"
    [pageable]="true"
    [height]="400"
    [resizable]="true"
    (pageChange)="pageChange($event)"
    [selectable]="true"
    mode="multiple"
    kendoGridSelectBy
    [(selectedKeys)]="selectedRowIndexes"
    (selectionChange)="gridSelectionChange($event)"
    (cellClick)="cellClickHandler($event)"
  >
    <kendo-grid-column field="serialNo" title="S/N " [width]="70">
    </kendo-grid-column
    ><kendo-grid-column
      field="buPcCode"
      title="Financial Institution Branch"
      [width]="220"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="masterPolicyNo"
      title="Master Policy No"
      [width]="160"
    >
    </kendo-grid-column>
    <kendo-grid-column field="productType" title="Product Type" [width]="170">
    </kendo-grid-column>
    <kendo-grid-column field="policyNo" title="Policy No" [width]="180">
    </kendo-grid-column
    ><kendo-grid-column
      field="MSIGPolicyNo"
      title="MSIG Policy No"
      [width]="170"
    >
    </kendo-grid-column
    ><kendo-grid-column field="quoteNo" title="Quote No" [width]="170">
    </kendo-grid-column
    ><kendo-grid-column field="statusDisplayText" title="Status" [width]="110">
      <ng-template kendoGridCellTemplate let-dataItem>
        <label>{{ getStatusDisplayText(dataItem) }}</label>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="insuredName" title="Insured Name" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column field="cin" title="CIN" [width]="150">
    </kendo-grid-column>
    <kendo-grid-column field="riskAddress" title="Risk Address" [width]="200">
    </kendo-grid-column>
    <kendo-grid-column field="city" title="City / Suburb" [width]="200">
    </kendo-grid-column>
    <kendo-grid-column field="country" title="Country" [width]="200">
    </kendo-grid-column>
    <kendo-grid-column
      field="riskAddressPostCode"
      title="Postal Code"
      [width]="150"
    >
    </kendo-grid-column>
    <kendo-grid-column field="sumInsured" title="Sum Insured" [width]="130">
    </kendo-grid-column>
    <kendo-grid-column
      field="bankReference"
      title="Bank Reference"
      [width]="150"
    >
    </kendo-grid-column>
    <kendo-grid-column field="boundDate" title="Bound Date" [width]="145">
    </kendo-grid-column>
    <kendo-grid-column
      field="inceptionDate"
      title="Inception Date"
      [width]="145"
    >
    </kendo-grid-column>
    <kendo-grid-column field="expiryDate" title="Expiry Date" [width]="145">
    </kendo-grid-column>
    <kendo-grid-column field="makerUserName" title="Maker's ID" [width]="150">
    </kendo-grid-column>
    <kendo-grid-column
      field="submittedDateTime"
      title="Date / Time"
      [width]="120"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="checkerUserName"
      title="Checker's ID"
      [width]="150"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="checkedDateTime"
      title="Date / Time"
      [width]="120"
    >
    </kendo-grid-column>
    <ng-template
      kendoPagerTemplate
      let-totalPages="totalPages"
      let-currentPage="currentPage"
    >
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons
        [buttonCount]="10"
      ></kendo-pager-numeric-buttons>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
    </ng-template>
  </kendo-grid>
  <div *ngIf="loading" class="k-i-loading"></div>
</div>
