export class SearchCriteria{
    productRadio: string='dbs';
    invoiceNumber: string='';
    autoDebitPeriodYear: string='';
    autoDebitPeriodMonth: string='';
    autoDebitPeriodDate: string='';
    policyNumber: string='';
    autoDebitPeriod: any;
}

export interface ReconcilePoliciesDownloadEntity {
    serialNumber: any;
    invoiceNo: string;
    meridianOpenItemSequenceNo: string;
    action: string;
    status: string;
    total: string;
    gst: string;
    savedBy: string;
    openItemId: string;
    policyCertificateNumber: string;
    Id: any;
}