import { Component, OnInit } from "@angular/core";
import {
  FileInfo,
  FileRestrictions,
  RemoveEvent,
  SelectEvent,
} from "@progress/kendo-angular-upload";
import { UploadExcelService } from "../../../services/upload-excel.service";
import { ExcelService } from "../../../services/excel.service";
import { dlgModelArgs } from "../../shared/partials/md-successful-file-upload/md-successful-file-upload.component";
import { Global } from "../../../shared/global";
import { UploadExcel } from "../auto-loan-redemption/auto-loan-redemption.entity";
import { ActivatedRoute, Router } from "@angular/router";
import { ROUTE_HOME } from "../../../app.module";
import { SearchCriteria } from "../../new-product/home/base/search-criteria";
import { PresetExportService } from "src/app/services/preset-export.service";
import { BaseUploadComponent } from "../../accounting/base-upload/base-upload.component";
import { ExcelTemplateService } from "src/app/services/excel-template.service";

@Component({
  selector: "cbg-cls-loan-redemption-upload",
  templateUrl: "./cbg-cls-loan-redemption-upload.component.html",
  styleUrls: ["./cbg-cls-loan-redemption-upload.component.scss"],
})
export class CbgCLSLoanRedemptionUploadComponent
  extends BaseUploadComponent
  implements OnInit
{
  public searchCriteria = new SearchCriteria();
  private presetExportService: PresetExportService | undefined;
  fileDate: string = "";

  constructor(
    public override uploadService: UploadExcelService,
    public override router: Router,
    public override route: ActivatedRoute,
    public override excelTemplate: ExcelTemplateService,
    private excelService: ExcelService
  ) {
    super(uploadService, router, route, excelTemplate);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    //this would most likely override the base's Subscription, which is fine, for now
    this.sub = this.route.data.subscribe(
      (v) => (this.uploadExcel.segmentDetails = v["type"]) //read from route param, as in app.route.js
    );
  }

  getUploadedClaimFilesTotalSize(files: FileInfo[]): number {
    let totalFileSize: number = 0;

    files.forEach((file: FileInfo) => {
      if (file.rawFile) {
        totalFileSize += file.rawFile.size;
      }
    });

    return totalFileSize;
  }

  override add() {
    this.hasError = false;
    if (this.uploadExcel?.files?.length > 0) {
      this.getUploadedExcelFiles(this.uploadExcel);
      this.addExcel();
      let fileName = this.uploadExcel?.files[0]?.name;
      this.setFileDate(fileName.substring(0, fileName.lastIndexOf(".")));
    } else {
      this.excelFilesErrorMessage = "At least one file needs to be uploaded.";
      this.hasError = true;
    }
  }
  setFileDate(fileName: string): void {
    let idx = fileName.lastIndexOf("_");
    this.fileDate = fileName.substring(idx + 1);
  }

  ddmmyyyy(date: Date) {
    let yyyy = date.getFullYear();
    let mm =
      date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1; // getMonth() is zero-based
    let dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    return "".concat(String(dd)).concat(String(mm)).concat(String(yyyy));
  }

  override addExcel(): void {
    this.isProcessing = true;
    const uploadedExcelFormData = this.getUploadedExcelFiles(this.uploadExcel);
    uploadedExcelFormData.append(
      "selectedSection",
      JSON.parse(JSON.stringify(this.uploadExcel.segmentDetails))
    );
    this.uploadService
      .uploadCbgCLSLoanRedemptionUploadExcel(uploadedExcelFormData)
      .subscribe(
        (result: any) => {
          let tempData: any = null;
          if (result.body.Status === false) {
            tempData = {
              message: result.body.Message,
              validationMessage: result.body.ValidationMessage,
              status: false,
              cBGExceptionTable: result.body.CBGExceptionTable,
              cBGSuccessTable: result.body.CBGSuccessTable,
            };
          } else {
            tempData = {
              message: result.body.Message,
              status: true,
              cBGExceptionTable: result.body.CBGExceptionTable,
              cBGSuccessTable: result.body.CBGSuccessTable,
            };
          }

          if (tempData.status === true) {
            if (tempData.cBGExceptionTable.length > 0) {
              this.excelTemplate.downloadFailureResponse(
                Exceptioncolumns,
                "Loan Redemption Report",
                "Loan Redemption Exception Report - CBG_" + this.fileDate,
                tempData,
                tempData.cBGExceptionTable,
                "CBG"
              );
            }

            if (tempData.cBGSuccessTable.length > 0) {
              this.excelTemplate.downloadSuccessResponse(
                Successcolumns,
                "Loan Redemption Report",
                "Loan Redemption Success Report - CBG_" + this.fileDate,
                tempData,
                tempData.cBGSuccessTable,
                "CBG"
              );
            }
          }

          // Clear uploaded file once, the file is successfuly uploaded.
          //this.uploadExcel.files = {};//maybe no need to clear, just like original

          this.redirectOpened = true;
          this.redirectData = tempData;
          this.isProcessing = false;
        },
        (error) => {
          // Prompt fail
          this.errorLogs.Logs = new Array<string>(); // Clear error logs
          this.errorLogs.Title = "Error";

          const log: string =
            Global.isDefined(error?.data?.ExceptionMessage) &&
            error.data.ExceptionMessage !== ""
              ? error.data.ExceptionMessage
              : "Failed to upload excel file. Please contact the system administrator.";

          this.errorLogs.Logs.push(log);
          this.isProcessing = false;
        }
      );
  }

  confirmationModelForSuccessfulExcelUpload = (args: dlgModelArgs): void => {
    this.redirectOpened = false;
    if (args.status === "return") {
      this.router.navigate(["/" + ROUTE_HOME]);
    }
  };
}

const Exceptioncolumns: string[] = [
  "SI No",
  "Policy Number",
  "Risk Address",
  "Owner Name",
  "Mortgage Interest Termination date",
  "Policy Expiry Date",
  "Insurance Action",
  "Reason",
  "FILE/Email Date SENT/Received",
  "Reject Reason",
  "Date of Reject",
];
const Successcolumns: string[] = [
  "SI No",
  "Policy Number",
  "Policy Status",
  "Risk Address",
  "Owner Name",
  "Cancellation/Endorsement Effective Date",
  "Refund Premium",
  "Insurance Action",
  "Reason",
  "EPL Remarks",
  "Transaction Bound Date/Upload Date",
  "MSBR Date Received",
];
