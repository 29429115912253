import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { SelectEvent } from "@progress/kendo-angular-layout";
import { BroadcastService } from "src/app/services/broadcast.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { BaseComponent } from "../../shared/base/base.component";
import { warningZoneArgs } from "./warning-zone/warning-zone.component";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent extends BaseComponent implements OnInit {
  public warningZoneOpened = false;
  public warningText: string = "";

  constructor(
    public override router: Router,
    public override broadcastService: BroadcastService,
    public override identityRolesService: IdentityRolesService
  ) {
    super(router, broadcastService, identityRolesService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  public onTabSelect(e: SelectEvent) {}

  showWarningZoneEvent(args: warningZoneArgs) {
    if (args.showWarning) {
      this.warningZoneOpened = true;
      this.warningText = args.warningText;
    } else {
      this.warningZoneOpened = false;
    }
  }
}
