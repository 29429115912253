import { Component, ViewChild, ViewContainerRef } from "@angular/core";
import { Subscription } from "rxjs";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { common } from "src/app/framework/utils/common";
import { Proposal } from "src/app/models/new-product/base/proposal.model";
import { QuestionEntity } from "src/app/models/new-product/base/question-entity.model";
import { pageLifeCycleNotifier } from "src/app/models/new-product/notifier/page-life-cycle-notifier";
import { proposalServiceNotifier } from "src/app/models/new-product/notifier/proposal-service-notifier";
import { questionNotifier } from "src/app/models/new-product/notifier/question-notifier";
import { MortgageFireSingaporeProposal } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal";

@Component({
  selector: "app-mf-sg-base",
  templateUrl: "./mf-sg-base.component.html",
  styleUrls: ["./mf-sg-base.component.scss"],
})
export class MfSgBaseComponent {
  @ViewChild("baseNotifierAppendTo", { read: ViewContainerRef, static: false })
  public baseNotifierAppendTo!: ViewContainerRef;

  // component tab index
  tabId!: string;
  // flag to indicate whether to render the component
  tabRequested: boolean = false;
  // flag to indicate whether to show the component
  tabShow: boolean = false;

  /** Tab on_load() event */
  protected tab_load_notifier_subscription!: Subscription;
  /** Page on_load() event */
  protected page_loaded_notifier_subscription!: Subscription;
  /** Proposal after_load() event */
  protected proposal_loaded_notifier_subscription!: Subscription;
  /** Proposal after_saved() event */
  protected proposal_saved_notifier_subscription!: Subscription;
  /** Proposal after_saved() event */
  protected proposal_failed_notifier_subscription!: Subscription;

  protected answer_changed_subscription!: Subscription;

  //#region migrate from old angularjs
  /** Subscribe Tab on_load() event */
  protected subscribeTabLoadRenderer() {
    this.tab_load_notifier_subscription =
      pageLifeCycleNotifier.tab_load_notifier$.subscribe(this.tabLoadRenderer);
  }

  /** Subscribe Page on_load() event */
  protected subscribePageLoadedNotifier(
    callback: (event: KeyValue<Proposal>) => void
  ) {
    this.page_loaded_notifier_subscription =
      pageLifeCycleNotifier.page_loaded_notifier$.subscribe(callback);
  }

  /** Subscribe Proposal after_load() event */
  protected subscribeProposalLoadedNotifier(
    callback: (event: KeyValue<Proposal>) => void
  ) {
    this.proposal_loaded_notifier_subscription =
      proposalServiceNotifier.loaded_notifier$.subscribe(callback);
  }

  /** Subscribe Proposal after_saved() event */
  protected subscribeProposalSavedNotifier(
    callback: (event: KeyValue<Proposal>) => void
  ) {
    this.proposal_saved_notifier_subscription =
      proposalServiceNotifier.saved_notifier$.subscribe(callback);
  }

  /** Subscribe Proposal after_saved() event */
  protected subscribeProposalFailedNotifier(
    callback: (event: KeyValue<Proposal>) => void
  ) {
    this.proposal_failed_notifier_subscription =
      proposalServiceNotifier.failed_notifier$.subscribe(callback);
  }

  /** Subscribe Proposal after_saved() event */
  protected subscribeAnswerChangedNotifier(
    callback: (event: KeyValue<QuestionEntity>) => void
  ) {
    this.answer_changed_subscription =
      questionNotifier.answer_change_notifier$.subscribe(callback);
  }

  /** Method that needs to be invoke on $destroy */
  protected unsubscribeRxjs() {
    if (common.isDefined(this.tab_load_notifier_subscription)) {
      this.tab_load_notifier_subscription.unsubscribe();
    }

    if (common.isDefined(this.page_loaded_notifier_subscription)) {
      this.page_loaded_notifier_subscription.unsubscribe();
    }

    if (common.isDefined(this.proposal_saved_notifier_subscription)) {
      this.proposal_saved_notifier_subscription.unsubscribe();
    }

    if (common.isDefined(this.proposal_loaded_notifier_subscription)) {
      this.proposal_loaded_notifier_subscription.unsubscribe();
    }

    if (common.isDefined(this.proposal_failed_notifier_subscription)) {
      this.proposal_failed_notifier_subscription.unsubscribe();
    }

    if (common.isDefined(this.answer_changed_subscription)) {
      this.answer_changed_subscription.unsubscribe();
    }
  }

  private tabLoadRenderer = (response: any) => {
    let event = response as KeyValue<MortgageFireSingaporeProposal>;
    const tabIndex = event.key;
    if (tabIndex === this.tabId) {
      this.render();
      this.show();
    } else {
      this.hide();
    }
  };

  protected show() {
    this.tabShow = true;
  }

  protected hide() {
    this.tabShow = false;
  }

  protected render() {
    if (!this.tabRequested) {
      this.tabRequested = true;
    }
  }
  //#endregion
}
