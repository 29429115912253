<div class="form-group row">
  <div class="col-md-4" [ngClass]="{ 'st-danger': invalidPayerName }">
    <label class="marginTop5 required" for="payerName">Payer Name</label>
  </div>
  <div class="col-md-8" [ngClass]="{ 'st-danger': invalidPayerName }">
    <input
      type="text"
      class="form-control"
      id="payerName"
      placeholder="Payer Name"
      [(ngModel)]="parentRefundPaynowEntity.payerName"
    />
  </div>
</div>
<div class="form-group row">
  <div class="col-md-4" [ngClass]="{ 'st-danger': invalidPaynowType }">
    <label class="marginTop5 required" for="paynowType">PayNow ID Type</label>
  </div>
  <div class="col-md-8" [ngClass]="{ 'st-danger': invalidPaynowType }">
    <kendo-dropdownlist
      id="paynowTypeId"
      [defaultItem]="defaultPaynowTypeItem"
      [kendoDropDownFilter]="{
        operator: 'contains'
      }"
      [data]="paynowTypeOptions"
      textField="text"
      valueField="value"
      [valuePrimitive]="true"
      [(ngModel)]="parentRefundPaynowEntity.paynowType"
      name="name_paynowType"
    ></kendo-dropdownlist>
  </div>
</div>
<div class="form-group row">
  <div class="col-md-4" [ngClass]="{ 'st-danger': invalidPaynowLast4Chars }">
    <label class="marginTop5 required" for="paynowLast4Chars">PayNow ID</label>
  </div>
  <div class="col-md-8" [ngClass]="{ 'st-danger': invalidPaynowLast4Chars }">
    <input
      type="text"
      class="form-control"
      maxlength="4"
      id="paynowLast4Chars"
      placeholder="Enter Last 4 digits of selected PayNow ID Type"
      [(ngModel)]="parentRefundPaynowEntity.paynowLast4Chars"
    />
  </div>
</div>
<div class="form-group row">
  <div class="col-md-4" [ngClass]="{ 'st-danger': invalidPaynowDate }">
    <label class="marginTop5 required" for="paynowDate">PayNow Date</label>
  </div>
  <div class="col-md-8" [ngClass]="{ 'st-danger': invalidPaynowDate }">
    <kendo-datepicker
      class="maxWidth"
      id="paynowDate"
      format="dd/MM/yyyy"
      placeholder=""
      [(ngModel)]="parentRefundPaynowEntity.paynowDate"
      name="name_paynowDate"
      #name="ngModel"
    ></kendo-datepicker>
  </div>
</div>
<div class="form-group row">
  <div class="col-md-4">
    <label class="marginTop5" for="issuanceBank">Issuing Bank</label>
  </div>
  <div class="col-md-8">
    <input
      type="text"
      class="form-control"
      id="issuanceBank"
      placeholder="Issuing Bank"
      [(ngModel)]="parentRefundPaynowEntity.issuanceBank"
    />
  </div>
</div>
