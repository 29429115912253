import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment"; //use the new one in src folder, old one mainly for guid.ts only
import { OpenItemEntity } from "../components/accounting/open-item/models/open-item-list.model";
import { ApiService } from "./dal/api.service";
import { DialogWrapperService } from "./dialog/dialog-wrapper.service";
import { ErrorHandlerService } from "./error-handler/error-handler.service";

@Injectable({
  providedIn: "root",
})
export class OpenItemListService extends ApiService {
  SERVER_URL_OPENITEMSEARCH: string = "api/openitem/search";
  SERVER_URL_OPENITEMSHISTORY = "api/openitem/getOpenItemHistory/";
  SERVER_URL_OPENITEMSEXPORT = "api/openitem/export";
  SERVER_URL_OPENITEMDETAILS = "api/openitem/get/";
  constructor(
    httpClient: HttpClient,
    errorHandlerService: ErrorHandlerService,
    dialog: DialogWrapperService
  ) {
    super(httpClient, errorHandlerService, dialog);
  }

  public searchOpenItemListing(querystring: string) {
    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      //Authorization: "Bearer " + this.BEARER, //authData.token,
      //https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
      "Content-Type": "application/x-www-form-urlencoded", //"application/json",
      //Session: this.SESSION_TOKEN, //see request() in: ~\app\library\module\interceptor\http-interceptor.ts
    });
    return this.httpClient.post<any>(
      /*AppSettings.appSettings.apiBaseUri*/
      environment.serviceBase + this.SERVER_URL_OPENITEMSEARCH,
      querystring,
      {
        headers: headers,
        reportProgress: true,
        observe: "events",
      }
    );
  }
  getOpenItem(openItemId: string): Observable<OpenItemEntity> {
    const uri = "api/openitem/get/";
    return this.httpClient.get<any>(
      /*AppSettings.appSettings.apiBaseUri*/
      environment.serviceBase + uri + `${openItemId}`
    );
  }
  public getOpenItemHistory(openItemId: string) {
    return this.httpClient.get<any>(
      /*AppSettings.appSettings.apiBaseUri*/
      environment.serviceBase +
        this.SERVER_URL_OPENITEMSHISTORY +
        `${openItemId}`
    );
  }
  public getOpenItemDetails(openItemId: string) {
    return this.httpClient.get<any>(
      /*AppSettings.appSettings.apiBaseUri*/
      environment.serviceBase +
        this.SERVER_URL_OPENITEMDETAILS +
        `${openItemId}`
    );
  }
  public exportOpenItemListing(querystring: string) {
    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      //Authorization: "Bearer " + this.BEARER, //authData.token,
      //https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
      "Content-Type": "application/x-www-form-urlencoded", //"application/json",
      //Session: this.SESSION_TOKEN, //see request() in: ~\app\library\module\interceptor\http-interceptor.ts
    });
    return this.httpClient.post<any>(
      /*AppSettings.appSettings.apiBaseUri*/
      environment.serviceBase + this.SERVER_URL_OPENITEMSEXPORT,
      querystring,
      {
        headers: headers,
        reportProgress: true,
        observe: "events",
      }
    );
  }
  doManualPay(data: any) {
    const url = "api/openitem/manualPay/";
    const postData = JSON.stringify(data);
    return this.httpPostCall(
      this.getHeaderAsJson(),
      this.constructEndPoint(url),
      postData
    );
  }
  doRefundReview(data: any) {
    const url = "api/openitem/refundReview/";
    const postData = JSON.stringify(data);
    return this.httpPostCall(
      this.getHeaderAsJson(),
      this.constructEndPoint(url),
      postData
    );
  }
  doRefund(data: any) {
    const url = "api/openitem/refund/";
    const postData = JSON.stringify(data);
    return this.httpPostCall(
      this.getHeaderAsJson(),
      this.constructEndPoint(url),
      postData
    );
  }
  doBounceCheque(data: any) {
    const url = "api/openitem/doBounceCheque/";
    const postData = JSON.stringify(data);
    return this.httpPostCall(
      this.getHeaderAsJson(),
      this.constructEndPoint(url),
      postData
    );
  }
}
