import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  PolicyTypeEntity,
  PolicyWordingEntity,
} from "../components/product/policyWordingSetUp/policy-wording.entity";
import { ApiService } from "./dal/api.service";
import { DialogWrapperService } from "./dialog/dialog-wrapper.service";
import { ErrorHandlerService } from "./error-handler/error-handler.service";
import * as Interface from "./../framework/interface.shared";

@Injectable({
  providedIn: "root",
})
export class PolicyWordingSetupService extends ApiService {
  isPolicyWording: boolean = false;

  constructor(
    httpClient: HttpClient,
    errorHandlerService: ErrorHandlerService,
    dialog: DialogWrapperService,
    private api: ApiService
  ) {
    super(httpClient, errorHandlerService, dialog);
  }
  public getPolicyTypeList(): Observable<PolicyTypeEntity[]> {
    const url = "api/policyWordingSetUp/getPolicyTypeList";
    return this.httpGetCall(
      this.getHeaderAsJson(),
      this.constructEndPoint(url)
    );
  }
  public getDocumentTitleList(): Observable<PolicyWordingEntity[]> {
    const url = "api/policyWordingSetUp/getDocumentTitleList";
    return this.httpGetCall(
      this.getHeaderAsJson(),
      this.constructEndPoint(url)
    );
  }

  public noAuthDownloadDocument(id: string): Observable<any> {
    let params = new HttpParams();
    params = params.append("id", id);
    return this.api.noAuthDownload("api/policyDocument/getDocument", params);
  }

  public downloadDocument(id: string) {
    let params = new HttpParams();
    params = params.append("id", id);
    this.download("api/policyWordingSetUp/getDocument", params).subscribe(
      (response: HttpResponse<Blob>) => {
        if (response && response.body) {
          var file = new Blob([response.body], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL, "_blank");
        }
      }
    );
  }
  public getPolicyWordingSetUpList(
    policyType: any,
    productType: any
  ): Observable<PolicyWordingEntity[]> {
    const url = "api/policyWordingSetUp/getPolicyWordingSetUpList";
    let params = new HttpParams();
    // Begin assigning parameters
    params = params.append("policyType", policyType);
    params = params.append("productType", productType);
    return this.httpGetCall(
      this.getHeaderAsJson(),
      this.constructEndPoint(url),
      params
    );
  }
  public getPolicyListOfWordingSetUp(
    id: string
  ): Observable<PolicyWordingEntity[]> {
    const url = "api/policyWordingSetUp/getPolicyListOfWordingSetUp";
    let params = new HttpParams();
    params = params.append("id", id);
    return this.httpGetCall(
      this.getHeaderAsJson(),
      this.constructEndPoint(url),
      params
    );
  }
}
