import { Component, Inject, Input, OnInit } from "@angular/core";
import { DialogRef } from "../../dialog/dialog-ref";
import { DIALOG_DATA } from "../../dialog/dialog-tokens";
import { dialogActionNotifier } from "src/app/models/new-product/notifier/dialog-action-notifier";

@Component({
  selector: "app-client-view",
  templateUrl: "./client-view.component.html",
  styleUrls: ["./client-view.component.scss"],
})
export class ClientViewComponent implements OnInit {
  isProcessing = false;
  //model = {};
  showCreateButton = true;
  key = "";
  isEditable = this.inputData!.isEditable;
  isNewClient = false;
  isDisabled = this.inputData!.isDisabled;
  public dlgWidth: number = window.innerWidth * 0.6;
  public dlgMaxHeight: number = window.innerHeight * 0.9;

  clientViewData: any;

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public inputData: clientViewDlgInputArgs
  ) {}

  ngOnInit(): void {
    if (this.inputData) {
      this.isEditable = this.inputData.isEditable;
      //this.confirmation = this.inputData.confirmation;
      this.isDisabled = this.inputData.isDisabled;

      dialogActionNotifier.ok_notifier$.subscribe((event) => {
        this.showCreateButton = event.value === "failed" ? true : false;

        if (event.value["status"] !== "failed") {
          this.clientViewData.clientId = event.value["clientId"];
          this.clientViewData.clientAddressId = event.value["clientAddressID"];
          this.clientViewData.clientCode = event.value["clientCode"];
          this.close("yes");
        } else {
          this.showCreateButton = true;
        }
      });
    }
  }

  public close(status: any) {
    let savParamters: clientViewDlgModelArgs = {
      status: status,
      data: this.clientViewData,
    };
    this.dialogRef?.close(savParamters);
  }

  update() {
    dialogActionNotifier.ok_notifier$.next({
      key: "client-edit-save",
      value: {
        isNewClient: this.isNewClient,
      },
    });
    this.inputData.confirmation = true;
    // $modalInstance.dismiss(this.data);//perform outside already: (click)="update(); close('yes')"
  }
}

export interface clientViewDlgInputArgs {
  dialogWidth: number;
  isEditable: boolean;
  confirmation: any;
  isDisabled: boolean;
}

export interface clientViewDlgModelArgs {
  status: string;
  data: any; //ProposalEntity[]
}
