<div class="col-md-12">
  <div class="card-head chubbWhite_bg chubbLightGray_border">
    <div class="tools">
      <div class="btn-group"></div>
    </div>
    <header class="chubbDarkBlue" [innerHtml]="getTitle()"></header>
  </div>
  <div class="card-body chubbLightGray_border">
    <form (ngSubmit)="onSubmit()" #userForm="ngForm">
      <div *ngIf="!isTab2" style="padding: 10px">
        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label class="required" [innerHtml]="'User Login'"></label>
          </div>
          <div class="col-md-3">
            <kendo-formfield orientation="horizontal">
              <input
                id="userName"
                name="userName"
                placeholder=""
                type="text"
                [(ngModel)]="userDetails.userName"
                class="k-textbox form-control"
                (blur)="checkUser()"
                (keypress)="onKeypressEvent($event)"
                required
              />
              <kendo-formerror style="width: 14vw"
                ><span class="k-icon-custom k-i-warning"></span>Enter
                UserName</kendo-formerror
              >
            </kendo-formfield>
          </div>
          <div
            class="col-md-3"
            [ngClass]="isUserExists ? 'lblColorRed' : 'lblColorBlack'"
          >
            {{ isUserAvailable }}
          </div>
        </div>

        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'Team'"></label>
          </div>
          <div class="form-group col-md-3">
            <kendo-formfield orientation="horizontal">
              <kendo-combobox
                [data]="teamsData"
                textField="text"
                valueField="value"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                placeholder="--Type to search"
                [valuePrimitive]="true"
                [(ngModel)]="userDetails.userTeam"
                name="userTeam"
              >
              </kendo-combobox>
            </kendo-formfield>
          </div>
        </div>

        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label class="required" [innerHtml]="'Email'"></label>
          </div>
          <div class="col-md-4">
            <kendo-formfield orientation="horizontal">
              <input
                id="email"
                name="email"
                placeholder=""
                type="text"
                [(ngModel)]="userDetails.email"
                class="k-textbox form-control"
                (blur)="checkEmailIDInADB2C()"
                required
              />
              <kendo-formerror style="width: 14vw"
                ><span class="k-icon-custom k-i-warning"></span> Email is
                required
              </kendo-formerror>
            </kendo-formfield>
            <span class="error-custom" *ngIf="isUserEmailIDAvailable">{{
              isUserEmailIDAvailable
            }}</span>
            <span class="error-custom" *ngIf="isUserEmailRoleAlreadyPresent">{{
              isUserEmailRoleAlreadyPresent
            }}</span>
            <span class="error-custom" *ngIf="isInvalidEmailAddress"
              >Please enter a valid email</span
            >
          </div>
        </div>
        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'Receive Email'"></label>
          </div>
          <div class="col-md-3">
            <input
              name="receiveEmail"
              type="checkbox"
              value="{{ userDetails.receiveEmail }}"
              [(ngModel)]="userDetails.receiveEmail"
              id="receiveEmail"
            />
          </div>
        </div>
        <div
          *ngIf="userDetails.isNotifyCreation"
          class="col-md-12 row k-bottom-lightgray"
          style="padding: inherit"
        >
          <div class="col-md-3">
            <label [innerHtml]="'Mobile Number'"></label>
          </div>
          <div class="col-md-4">
            <input
              id="mobileNumber"
              name="mobileNumber"
              placeholder=""
              type="text"
              [(ngModel)]="userDetails.mobileNumber"
              class="k-textbox form-control"
              disabled
            />
          </div>
        </div>
        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label class="required" [innerHtml]="'User Type'"></label>
          </div>
          <div class="form-group col-md-3">
            <kendo-formfield orientation="horizontal">
              <kendo-combobox
                [data]="userType"
                textField="text"
                valueField="value"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [valuePrimitive]="true"
                placeholder="--Type to search"
                [(ngModel)]="userDetails.userType"
                (valueChange)="changeUserType($event)"
                name="userType"
                required
              >
              </kendo-combobox>
              <kendo-formerror style="width: 23vw"
                ><span class="k-icon-custom k-i-warning"></span>Please Select
                Atleast One UserType</kendo-formerror
              >
            </kendo-formfield>
          </div>
        </div>
        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'Notify user of account creation'"></label>
          </div>
          <div class="col-md-3">
            <input
              name="notifyUserCreation"
              type="checkbox"
              value="{{ userDetails.active }}"
              [(ngModel)]="userDetails.notifyUserCreation"
              id="notifyUserCreation"
            />
          </div>
        </div>
        <div
          *ngIf="
            !identityRolesService.showInterfaceMaintenance &&
            userDetails.displayUserCountry
          "
          class="col-md-12 row k-bottom-lightgray"
          style="padding: inherit"
        >
          <div class="col-md-3">
            <label class="required" [innerHtml]="'Country'"></label>
          </div>
          <div class="col-md-3">{{ userDetails.displayUserCountry }}</div>
        </div>
        <div
          *ngIf="
            identityRolesService.showInterfaceMaintenance &&
            userDetails.userType != ''
          "
          class="col-md-12 row k-bottom-lightgray"
          style="padding: inherit"
        >
          <div class="col-md-3">
            <label class="required" [innerHtml]="'Country'"></label>
          </div>
          <div class="form-group col-md-3">
            <kendo-formfield orientation="horizontal">
              <kendo-combobox
                [data]="countryData"
                textField="text"
                valueField="value"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [valuePrimitive]="true"
                placeholder="--Type to search"
                [(ngModel)]="userDetails.userCountry"
                (valueChange)="changeOrganizationList()"
                name="userCountry"
                required
              >
              </kendo-combobox>
              <kendo-formerror
                ><span class="k-icon-custom k-i-warning"></span> You must select
                a Country for this user.</kendo-formerror
              >
            </kendo-formfield>
          </div>
        </div>
        <div
          *ngIf="organizationsData && organizationsData.length > 0"
          class="col-md-12 row k-bottom-lightgray"
          style="padding: inherit"
        >
          <div class="col-md-3">
            <label class="required" [innerHtml]="'Organisation'"></label>
          </div>
          <div class="form-group col-md-3">
            <kendo-formfield orientation="horizontal">
              <kendo-combobox
                [data]="organizationsData"
                textField="text"
                valueField="value"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [valuePrimitive]="true"
                placeholder="--Type to search"
                [(ngModel)]="userDetails.userOrganisation"
                (valueChange)="onChangeOrg($event)"
                name="userOrganisation"
                required
              >
              </kendo-combobox>
              <kendo-formerror style="width: 28vw"
                ><span class="k-icon-custom k-i-warning"></span> You must select
                an Organisation for this user.</kendo-formerror
              >
            </kendo-formfield>
          </div>
        </div>
        <div
          *ngIf="productData && productData.length > 0"
          class="col-md-12 row k-bottom-lightgray"
          style="padding: inherit"
        >
          <div class="col-md-3">
            <label class="required" [innerHtml]="title"></label>
          </div>
          <div class="col-md-4" style="display: grid">
            <kendo-formfield orientation="horizontal">
              <kendo-multiselect
                id="product"
                #product
                placeholder="Select product..."
                [autoClose]="false"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [data]="productData"
                textField="text"
                valueField="value"
                [valuePrimitive]="true"
                [(ngModel)]="userDetails.productIds"
                (valueChange)="onProductSelectionChange($event)"
                name="productIds"
                required
              >
              </kendo-multiselect>
              <kendo-formerror
                ><span class="k-icon-custom k-i-warning"></span>You must select
                at least one product for this user.</kendo-formerror
              >
            </kendo-formfield>
          </div>
        </div>
        <div
          class="col-md-12 row k-bottom-lightgray"
          style="padding: inherit"
          *ngIf="showNoProductsMessage"
        >
          <div class="col-md-3 chubbRed">
            No Products for the Selected Organisation
          </div>
        </div>
        <div
          *ngIf="roleData && roleData.length > 0"
          class="col-md-12 row k-bottom-lightgray"
          style="padding: inherit"
        >
          <div class="col-md-3">
            <label
              [ngClass]="userDetails.userType != 3 ? 'required' : ''"
              [innerHtml]="'Roles'"
            ></label>
          </div>
          <div
            *ngIf="userDetails.userType != 3"
            class="col-md-4"
            style="display: grid"
          >
            <kendo-formfield orientation="horizontal">
              <kendo-multiselect
                id="roleIds"
                placeholder="Select roles..."
                [autoClose]="false"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [data]="roleData"
                textField="text"
                valueField="value"
                [valuePrimitive]="true"
                [(ngModel)]="userDetails.roleIds"
                name="roleIds"
                required
              >
              </kendo-multiselect>
              <kendo-formerror
                ><span class="k-icon-custom k-i-warning"></span>You must select
                at least one role for this user.</kendo-formerror
              >
            </kendo-formfield>
          </div>
          <div
            *ngIf="userDetails.userType == 3"
            class="col-md-4"
            style="display: grid"
          >
            <kendo-formfield orientation="horizontal">
              <kendo-multiselect
                id="roleIds"
                placeholder="Select roles..."
                [autoClose]="false"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [data]="roleData"
                textField="text"
                valueField="value"
                [valuePrimitive]="true"
                [(ngModel)]="userDetails.roleIds"
                name="roleIds"
              >
              </kendo-multiselect>
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
        </div>
        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'Active'"></label>
          </div>
          <div class="col-md-3">
            <input
              name="active"
              type="checkbox"
              [value]="userDetails.active"
              [(ngModel)]="userDetails.active"
              (change)="toggleDisabled()"
              id="active"
            />
          </div>
        </div>
        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'Disabled'"></label>
          </div>
          <div class="col-md-3">
            <input
              name="disabled"
              type="checkbox"
              [value]="userDetails.disabled"
              [(ngModel)]="userDetails.disabled"
              id="disabled"
              [disabled]="userDetails.active"
            />
          </div>
        </div>
        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'Suspended'"></label>
          </div>
          <div class="col-md-3">
            <input
              name="suspended"
              type="checkbox"
              value="{{ userDetails.suspended }}"
              [(ngModel)]="userDetails.suspended"
              id="suspended"
            />
          </div>
        </div>
        <div
          class="row k-bottom-lightgray"
          style="padding: inherit"
          [hidden]="userDetails.userType != '1'"
        >
          <div class="col-md-3">
            <label [innerHtml]="'Access Group'"></label>
          </div>
          <div class="form-group col-md-3">
            <kendo-combobox
              [data]="accessControlList"
              textField="text"
              valueField="value"
              [kendoDropDownFilter]="{
                operator: 'contains'
              }"
              placeholder="--Type to search"
              [valuePrimitive]="true"
              [(ngModel)]="userDetails.accessGroupId"
              name="accessGroupId"
            >
            </kendo-combobox>
          </div>
        </div>
      </div>
      <div *ngIf="isTab2" style="padding: 10px">
        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'Display Name'"></label>
          </div>
          <div class="col-md-3">
            <kendo-formfield orientation="horizontal">
              <input
                id="displayName"
                name="displayName"
                placeholder=""
                type="text"
                [(ngModel)]="personalDetails.displayName"
                class="k-textbox form-control"
              />
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
          <div class="col-md-3">
            <label [innerHtml]="'Salutation'"></label>
          </div>
          <div class="col-md-3">
            <kendo-formfield orientation="horizontal">
              <input
                id="salutation"
                name="salutation"
                placeholder=""
                type="text"
                [(ngModel)]="personalDetails.salutation"
                class="k-textbox form-control"
              />
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
        </div>

        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'First Name'"></label>
          </div>
          <div class="col-md-3">
            <kendo-formfield orientation="horizontal">
              <input
                id="firstName"
                name="firstName"
                placeholder=""
                type="text"
                [(ngModel)]="personalDetails.firstName"
                class="k-textbox form-control"
              />
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
          <div class="col-md-3">
            <label class="required" [innerHtml]="'Last Name'"></label>
          </div>
          <div class="col-md-3">
            <kendo-formfield orientation="horizontal">
              <input
                id="lastName"
                name="lastName"
                placeholder=""
                type="text"
                [(ngModel)]="personalDetails.lastName"
                class="k-textbox form-control"
                required
              />
              <kendo-formhint></kendo-formhint>
              <kendo-formerror
                ><span class="k-icon-custom k-i-warning"></span> Please Enter
                LastName</kendo-formerror
              >
            </kendo-formfield>
          </div>
        </div>

        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'Date of Birth'"></label>
          </div>
          <div class="col-md-3">
            <kendo-formfield orientation="horizontal">
              <kendo-datepicker
                class="maxWidth"
                id="dateOfBirth"
                format="dd/MM/yyyy"
                placeholder=""
                [max]="dob_max"
                [(ngModel)]="personalDetails.dateOfBirth"
                name="dateOfBirth"
              >
              </kendo-datepicker>
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
          <div class="col-md-3">
            <label [innerHtml]="'Gender'"></label>
          </div>
          <div class="form-group col-md-3">
            <kendo-formfield orientation="horizontal">
              <kendo-dropdownlist
                [data]="genderList"
                textField="text"
                valueField="value"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [valuePrimitive]="true"
                [(ngModel)]="personalDetails.gender"
                name="gender"
              >
              </kendo-dropdownlist>
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
        </div>

        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'Nationality'"></label>
          </div>
          <div class="form-group col-md-3">
            <kendo-dropdownlist
              [data]="nationality"
              textField="text"
              valueField="value"
              [kendoDropDownFilter]="{
                operator: 'contains'
              }"
              [valuePrimitive]="true"
              [(ngModel)]="personalDetails.nationality"
              name="nationality"
            >
            </kendo-dropdownlist>
          </div>
          <div class="col-md-3">
            <label [innerHtml]="'Race'"></label>
          </div>
          <div class="col-md-3">
            <kendo-formfield orientation="horizontal">
              <input
                id="race"
                name="race"
                placeholder=""
                type="text"
                [(ngModel)]="personalDetails.race"
                class="k-textbox form-control"
              />
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
        </div>

        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'Passport No.'"></label>
          </div>
          <div class="col-md-3">
            <kendo-formfield orientation="horizontal">
              <input
                id="passportNo"
                name="passportNo"
                placeholder=""
                type="text"
                [(ngModel)]="personalDetails.passportNo"
                class="k-textbox form-control"
              />
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
          <div class="col-md-3">
            <label [innerHtml]="'Marital Status'"></label>
          </div>
          <div class="form-group col-md-3">
            <kendo-formfield orientation="horizontal">
              <kendo-combobox
                [data]="statusList"
                textField="text"
                valueField="value"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                placeholder="--Type to search"
                [valuePrimitive]="true"
                [(ngModel)]="personalDetails.maritalStatus"
                name="maritalStatus"
              >
              </kendo-combobox>
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
        </div>

        <div
          [hidden]="personalDetails.maritalStatus == 1"
          class="col-md-12 row k-bottom-lightgray"
          style="padding: inherit"
        >
          <div class="col-md-3">
            <label [innerHtml]="'Name of Spouse'"></label>
          </div>
          <div class="col-md-3">
            <kendo-formfield orientation="horizontal">
              <input
                id="nameOfSpouse"
                name="nameOfSpouse"
                placeholder=""
                type="text"
                [(ngModel)]="personalDetails.nameOfSpouse"
                class="k-textbox form-control"
              />
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
          <div class="col-md-3">
            <label [innerHtml]="'No of Children'"></label>
          </div>
          <div class="form-group col-md-3">
            <kendo-dropdownlist
              [data]="noOfChilds"
              textField="text"
              valueField="value"
              [kendoDropDownFilter]="{
                operator: 'contains'
              }"
              [valuePrimitive]="true"
              [(ngModel)]="personalDetails.noOfChildren"
              name="noOfChildren"
            >
            </kendo-dropdownlist>
          </div>
        </div>

        <div class="col-md-12 row k-bottom-lightgray" style="padding: inherit">
          <div class="col-md-3">
            <label [innerHtml]="'Education'"></label>
          </div>
          <div class="col-md-3">
            <kendo-formfield orientation="horizontal">
              <input
                id="education"
                name="education"
                placeholder=""
                type="text"
                [(ngModel)]="personalDetails.education"
                class="k-textbox form-control"
              />
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
          <div class="col-md-3">
            <label [innerHtml]="'Hobbies'"></label>
          </div>
          <div class="col-md-3">
            <kendo-formfield orientation="horizontal">
              <input
                id="hobbies"
                name="hobbies"
                placeholder=""
                type="text"
                [(ngModel)]="personalDetails.hobbies"
                class="k-textbox form-control"
              />
              <kendo-formhint></kendo-formhint>
            </kendo-formfield>
          </div>
        </div>
        <div
          class="col-md-12 row k-bottom-lightgray"
          style="padding: inherit"
          *ngIf="serverErrorMsg"
        >
          <div class="col-md-6">
            <ul style="padding-left: 6px">
              <li class="lblColorRed">
                {{ serverErrorMsg }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
