<header class="plain-header">
  <img class="logo" src="assets\Chubb-turquoise.png" alt="chubb logo" />
</header>
<div class="centered-container">
  <p style="color: black; margin-top: 15%">The Document will be downloaded</p>
  <br />
</div>
<div id="footerdiv" class="" style="margin-left: 11px">
  <footer style="margin-top: 15px">
    <p>{{ footerTitle }}</p>
  </footer>
</div>
