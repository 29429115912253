<div ng-if="tabRequested" id="masterTab">
  <div *ngIf="tabShow">
    <div class="container-fluid-customized">
      <div class="row" *ngIf="isEndorsement || isCancel">
        <div class="col-md-12 card card-mod cardNoBorder ng-scope">
          <div class="card-head chubbLightGray_bg">
            <header
              class="fontBold chubbWhite"
              id="client_panel_end"
              *ngIf="isEndorsement"
            >
              Endorsement
            </header>
            <header
              class="fontBold chubbWhite"
              id="client_panel_can"
              *ngIf="isCancel"
            >
              Cancellation
            </header>
          </div>
          <div class="card-body card-body-mod cardBodyBorder cardNoBorder">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6">
                  <form role="form ">
                    <div class="form-group">
                      <date-picker-v1-question
                        [question]="proposal!.qsMaster.qs_master_effectiveDate!"
                        answer="proposal!.qsMaster.qs_master_effectiveDate.answer"
                        [minDate]="qs_master_effectiveDate_datePicker_minDate"
                        [maxDate]="qs_master_effectiveDate_datePicker_maxDate"
                      >
                      </date-picker-v1-question>
                    </div>
                    <div class="form-group">
                      <div
                        id="{{ proposal!.qsMaster.qs_master_refund!.key }}"
                        [ngClass]="{
                          'st-danger':
                            proposal!.qsMaster.qs_master_refund!.theme === 1
                        }"
                      >
                        <label
                          for="{{ proposal!.qsMaster.qs_master_refund!.key }}"
                          [textContent]="
                            proposal!.qsMaster.qs_master_refund!.displayText
                          "
                          [ngClass]="{
                            required:
                              proposal!.qsMaster.qs_master_refund!.required &&
                              !proposal!.qsMaster.qs_master_refund!.readOnly
                          }"
                        ></label>
                        <div
                          *ngIf="!proposal!.qsMaster.qs_master_refund!.readOnly"
                        >
                          <label class="radio-inline">
                            <input
                              name="{{
                                proposal!.qsMaster.qs_master_refund!.key
                              }}"
                              type="radio"
                              value="1"
                              [(ngModel)]="
                                proposal!.qsMaster.qs_master_refund!.answer
                              "
                              [disabled]="
                                proposal!.qsMaster.qs_master_refund!.isDisabled!
                              "
                            />
                            <span>Default</span>
                          </label>
                          <label class="radio-inline">
                            <input
                              name="{{
                                proposal!.qsMaster.qs_master_refund!.key
                              }}"
                              type="radio"
                              value="2"
                              [(ngModel)]="
                                proposal!.qsMaster.qs_master_refund!.answer
                              "
                              [disabled]="
                                proposal!.qsMaster.qs_master_refund!.isDisabled!
                              "
                            />
                            <span>Bank</span>
                          </label>
                          <label class="radio-inline">
                            <input
                              name="{{
                                proposal!.qsMaster.qs_master_refund!.key
                              }}"
                              type="radio"
                              value="3"
                              [(ngModel)]="
                                proposal!.qsMaster.qs_master_refund!.answer
                              "
                              [disabled]="
                                proposal!.qsMaster.qs_master_refund!.isDisabled!
                              "
                            />
                            <span>Policy Holder</span>
                          </label>
                          <label class="radio-inline">
                            <input
                              name="{{
                                proposal!.qsMaster.qs_master_refund!.key
                              }}"
                              type="radio"
                              value="4"
                              [(ngModel)]="
                                proposal!.qsMaster.qs_master_refund!.answer
                              "
                              [disabled]="
                                proposal!.qsMaster.qs_master_refund!.isDisabled!
                              "
                            />
                            <span>No Refund</span>
                          </label>
                        </div>
                        <div
                          *ngIf="proposal!.qsMaster.qs_master_refund!.readOnly"
                        >
                          <p
                            *ngIf="
                              proposal!.qsMaster.qs_master_refund!.isAnswered()
                            "
                          >
                            {{ refundDisplayText }}
                          </p>
                          <span
                            class="unattended"
                            *ngIf="
                              !proposal!.qsMaster.qs_master_refund!.isAnswered()
                            "
                            >{{ noAnswerDefaultText }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-md-6">
                  <form role="form ">
                    <div class="form-group">
                      <text-area-question
                        [question]="
                          proposal!.qsMaster
                            .qs_master_endorse_endorsementReason!
                        "
                      >
                      </text-area-question>
                    </div>
                    <div class="form-group" *ngIf="isCancel">
                      <div>
                        <label
                          for="{{
                            proposal!.qsMaster
                              .qs_master_cancellation_masterpiece!.key
                          }}"
                          [textContent]="
                            proposal!.qsMaster
                              .qs_master_cancellation_masterpiece!.displayText
                          "
                        ></label>
                        <div
                          *ngIf="
                            !proposal!.qsMaster
                              .qs_master_cancellation_masterpiece!.readOnly &&
                            isCancel
                          "
                        >
                          <div
                            class="radio clearMarginTopBottom clearfix"
                            *ngIf="isIncomplete || isReferred"
                          >
                            <input
                              *ngIf="
                                !proposal!.qsMaster
                                  .qs_master_cancellation_masterpiece!.readOnly
                              "
                              #qs_master_cancellation_masterpiece_checkbox
                              name="{{
                                proposal!.qsMaster
                                  .qs_master_cancellation_masterpiece!.key
                              }} "
                              type="checkbox"
                              value="{{
                                proposal!.qsMaster
                                  .qs_master_cancellation_masterpiece!.answer
                              }}"
                              id="{{
                                proposal!.qsMaster
                                  .qs_master_cancellation_masterpiece!.key
                              }}_checkbox"
                              (click)="
                                updateModelAnswer(
                                  proposal!.qsMaster
                                    .qs_master_cancellation_masterpiece!
                                )
                              "
                              [checked]="
                                compareQuestionAnswer(
                                  proposal!.qsMaster
                                    .qs_master_cancellation_masterpiece!
                                )
                              "
                              [disabled]="
                                proposal!.qsMaster
                                  .qs_master_cancellation_masterpiece!
                                  .isDisabled!
                              "
                            />
                          </div>
                          <div *ngIf="!isIncomplete && !isReferred">
                            <div
                              class=""
                              *ngIf="
                                proposal!.qsMaster
                                  .qs_master_cancellation_masterpiece!
                                  .answer === 'True' ||
                                proposal!.qsMaster
                                  .qs_master_cancellation_masterpiece!
                                  .answer === true
                              "
                              style="padding-left: 5px"
                            >
                              Yes
                            </div>
                            <div
                              class=""
                              *ngIf="
                                proposal!.qsMaster
                                  .qs_master_cancellation_masterpiece!
                                  .answer !== 'True' &&
                                proposal!.qsMaster
                                  .qs_master_cancellation_masterpiece!
                                  .answer !== true
                              "
                              style="padding-left: 5px"
                            >
                              No
                            </div>
                          </div>
                        </div>
                        <div
                          *ngIf="
                            proposal!.qsMaster
                              .qs_master_cancellation_masterpiece!.readOnly
                          "
                        >
                          <div
                            *ngIf="
                              proposal!.qsMaster
                                .qs_master_cancellation_masterpiece!.answer ===
                                'True' ||
                              proposal!.qsMaster
                                .qs_master_cancellation_masterpiece!.answer ===
                                true
                            "
                          >
                            Yes
                          </div>
                          <div
                            *ngIf="
                              proposal!.qsMaster
                                .qs_master_cancellation_masterpiece!.answer !==
                                'True' &&
                              proposal!.qsMaster
                                .qs_master_cancellation_masterpiece!.answer !==
                                true
                            "
                          >
                            No
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group" *ngIf="editMSIGPolicy">
                      <text-box-question
                        [question]="
                          proposal!.qsMaster.qs_master_replacePolicyNo!
                        "
                      >
                      </text-box-question>
                    </div>
                    <div class="row">
                      <div class="col-md-5">
                        <form role="form "></form>
                      </div>
                    </div>
                  </form>
                  <form role="form " class="form-inline">
                    <div
                      class="form-group"
                      *ngIf="
                        proposal!.qsMaster.qs_master_waiveMinimumPremium!
                          .isVisible
                      "
                    >
                      <div class="checkbox form-inline">
                        <label>
                          <input
                            #qs_master_waiveMinimumPremium_checkbox
                            name="{{
                              proposal!.qsMaster.qs_master_waiveMinimumPremium!
                                .key
                            }}"
                            type="checkbox"
                            id="{{
                              proposal!.qsMaster.qs_master_waiveMinimumPremium!
                                .key
                            }}_checkbox"
                            (click)="
                              updateModelAnswer(
                                proposal!.qsMaster
                                  .qs_master_waiveMinimumPremium!
                              )
                            "
                            [checked]="
                              compareQuestionAnswer(
                                proposal!.qsMaster
                                  .qs_master_waiveMinimumPremium!
                              )
                            "
                            [disabled]="
                              proposal!.qsMaster.qs_master_waiveMinimumPremium!
                                .isDisabled!
                            "
                          />
                          {{
                            proposal!.qsMaster.qs_master_waiveMinimumPremium!
                              .displayText
                          }}
                        </label>
                      </div>
                    </div>
                  </form>
                  <form role="form">
                    <div class="form-group">
                      <div
                        *ngIf="
                          isIBGProduct &&
                          proposal!.IsCancellationAdjustment &&
                          proposalService.proposal!.auxiliary[
                            'isPremiumManualOverridden'
                          ]
                        "
                      >
                        <label
                          [textContent]="
                            proposal!.qsMaster.qs_master_manualUnderwritting!
                              .displayText
                          "
                        ></label>
                        <div>
                          <div
                            class=""
                            *ngIf="
                              proposalService.proposal!.auxiliary[
                                'isPremiumManualOverridden'
                              ]
                            "
                            style="padding-left: 5px"
                          >
                            Yes
                          </div>
                          <div
                            class=""
                            *ngIf="
                              !proposalService.proposal!.auxiliary[
                                'isPremiumManualOverridden'
                              ]
                            "
                            style="padding-left: 5px"
                          >
                            No
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <form role="form " class="">
                    <div
                      class="form-group"
                      *ngIf="
                        proposal!.qsMaster
                          .qs_master_cancellation_adjustmentAmount!.isVisible
                      "
                    >
                      <div class=" ">
                        <label
                          class=""
                          for="cancellation_adjustmentAmount_{{
                            proposal!.qsMaster
                              .qs_master_cancellation_adjustmentAmount!.key
                          }}"
                          [ngClass]="{
                            required:
                              proposal!.qsMaster
                                .qs_master_cancellation_adjustmentAmount!
                                .required &&
                              !proposal!.qsMaster
                                .qs_master_cancellation_adjustmentAmount!
                                .readOnly
                          }"
                          [textContent]="
                            proposal!.qsMaster
                              .qs_master_cancellation_adjustmentAmount!
                              .displayText
                          "
                        ></label>
                        <div
                          class=""
                          *ngIf="
                            !proposal!.qsMaster
                              .qs_master_cancellation_adjustmentAmount!.readOnly
                          "
                        >
                          <kendo-numerictextbox
                            [(ngModel)]="
                              proposal!.qsMaster
                                .qs_master_cancellation_adjustmentAmount!.answer
                            "
                            class="textAlignLeft form-inline"
                            style="width: 100px"
                            *ngIf="
                              !proposal!.qsMaster
                                .qs_master_cancellation_adjustmentAmount!
                                .readOnly
                            "
                            [format]="'c2'"
                            [spinners]="false"
                            id="cancellation_adjustmentAmount_{{
                              proposal!.qsMaster
                                .qs_master_cancellation_adjustmentAmount!.key
                            }}"
                            name="cancellation_adjustmentAmount_{{
                              proposal!.qsMaster
                                .qs_master_cancellation_adjustmentAmount!.key
                            }}"
                            [decimals]="2"
                          >
                          </kendo-numerictextbox>
                        </div>
                        <div>
                          <span
                            [textContent]="
                              proposal!.qsMaster
                                .qs_master_cancellation_adjustmentAmount!.answer
                                | ausCurrency
                            "
                            *ngIf="
                              proposal!.qsMaster
                                .qs_master_cancellation_adjustmentAmount!
                                .readOnly &&
                              proposal!.qsMaster.qs_master_cancellation_adjustmentAmount!.isAnswered()
                            "
                          >
                          </span>
                          <span
                            class="unattended"
                            *ngIf="
                              proposal!.qsMaster
                                .qs_master_cancellation_adjustmentAmount!
                                .readOnly &&
                              !proposal!.qsMaster.qs_master_cancellation_adjustmentAmount!.isAnswered()
                            "
                            >{{ noAnswerDefaultText }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-md-6">
                  <form role="form " class="">
                    <div
                      class="form-group"
                      *ngIf="
                        proposal!.qsMaster
                          .qs_master_cancellation_maximumAdjustmentAmount!
                          .isVisible
                      "
                    >
                      <div class="">
                        <label
                          for="{{
                            proposal!.qsMaster
                              .qs_master_cancellation_maximumAdjustmentAmount!
                              .key
                          }}"
                          [textContent]="
                            proposal!.qsMaster
                              .qs_master_cancellation_maximumAdjustmentAmount!
                              .displayText
                          "
                        >
                        </label>
                        <div>
                          <span
                            attr.for="{{
                              proposal!.qsMaster
                                .qs_master_cancellation_maximumAdjustmentAmount!
                                .key
                            }}"
                            >{{
                              proposal!.qsMaster
                                .qs_master_cancellation_maximumAdjustmentAmount!
                                .answer | ausCurrency
                            }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!isChecker && !isMaker">
        <div class="col-md-12 card card-mod cardNoBorder ng-scope">
          <kendo-expansionpanel
            id="broker_panel"
            title="Relationship Manager Information"
            [expanded]="true"
          >
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6">
                  <form role="form">
                    <div class="form-group">
                      <text-box-question
                        [question]="proposal!.qsMaster.qs_master_rmName!"
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-6">
                  <form role="form">
                    <div class="form-group">
                      <text-box-question
                        [question]="proposal!.qsMaster.qs_master_rmContactNo!"
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <form role="form">
                    <div class="form-group">
                      <text-area-question
                        [question]="proposal!.qsMaster.qs_master_rmRemarks!"
                      >
                      </text-area-question>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </kendo-expansionpanel>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 card card-mod cardNoBorder ng-scope">
          <kendo-expansionpanel
            id="broker_panel"
            title="Client Information"
            [expanded]="true"
          >
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 card card-mod cardNoBorder ng-scope">
                  <div class="card-head chubbLightGray_bg">
                    <header class="fontBold chubbWhite" id="client_panel">
                      Bank Reference
                    </header>
                  </div>

                  <div class="card-body card-body-mod cardBodyBorder">
                    <div class="">
                      <div class="row" *ngIf="isIBGProduct">
                        <div class="col-md-12">
                          <form role="form ">
                            <div class="form-group">
                              <text-box-question
                                [question]="
                                  proposal!.qsMaster.qs_master_uploadBuPcCode!
                                "
                              >
                              </text-box-question>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <form role="form ">
                            <div class="form-group" *ngIf="!isIBGProduct">
                              <drop-down-list-filter-question
                                [question]="
                                  proposal!.qsMaster.qs_master_financialBranch!
                                "
                              >
                              </drop-down-list-filter-question>
                            </div>
                            <div class="form-group" *ngIf="isIBGProduct">
                              <drop-down-list-filter-question
                                [question]="
                                  proposal!.qsMaster.qs_master_buPcCode!
                                "
                              >
                              </drop-down-list-filter-question>
                            </div>
                          </form>
                        </div>
                        <div class="col-md-6">
                          <form role="form ">
                            <div class="form-group">
                              <text-box-question
                                [question]="
                                  proposal!.qsMaster.qs_master_bankReferNumber!
                                "
                              >
                              </text-box-question>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <form role="form ">
                            <div class="form-group">
                              <text-box-question
                                [question]="
                                  proposal!.qsMaster.qs_master_assetClassCode!
                                "
                              >
                              </text-box-question>
                            </div>
                          </form>
                        </div>
                        <div class="col-md-6">
                          <form role="form ">
                            <div class="form-group">
                              <text-box-question
                                [question]="
                                  proposal!.qsMaster
                                    .qs_master_assetSerialNumber!
                                "
                              >
                              </text-box-question>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="row"
                [hidden]="
                  !(
                    !proposal!.qsMaster.qs_master_masterPolicyNumber!
                      .readOnly &&
                    !isIBGProduct &&
                    !isEndorsement &&
                    !isCancel
                  )
                "
              >
                <div class="col-md-3">
                  <form role="form">
                    <div
                      class="btn-box chubbWhite chubbLightOrange_bg"
                      (click)="clientSearch()"
                    >
                      Client Search
                    </div>
                  </form>
                </div>
                <div class="col-md-3">
                  <form role="form">
                    <div
                      class="btn-box chubbWhite chubbLightOrange_bg"
                      (click)="clientCreate()"
                    >
                      Create New Client
                    </div>
                  </form>
                </div>
              </div>

              <div class="row">
                <div class="col-md-3" *ngIf="!isIBGProduct">
                  <form role="form">
                    <div class="form-group">
                      <text-box-question
                        [question]="proposal!.qsMaster.qs_master_insuredNo!"
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-3" *ngIf="isIBGProduct">
                  <form role="form">
                    <div class="form-group">
                      <text-box-question
                        [question]="proposal!.qsMaster.qs_master_borrowerCIN!"
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-4">
                  <form role="form ">
                    <div class="form-group" *ngIf="isIBGProduct">
                      <text-box-question
                        [question]="proposal!.qsMaster.qs_master_insuredName!"
                      >
                      </text-box-question>
                    </div>
                    <div
                      class="form-group"
                      id="qs_master_insuredName"
                      [ngClass]="{
                        'st-danger':
                          proposal!.qsMaster.qs_master_insuredName!.theme === 1
                      }"
                      *ngIf="!isIBGProduct"
                    >
                      <label
                        [ngClass]="{
                          required:
                            proposal!.qsMaster.qs_master_insuredName!
                              .required &&
                            !proposal!.qsMaster.qs_master_insuredName!.readOnly
                        }"
                        [textContent]="
                          proposal!.qsMaster.qs_master_insuredName!.displayText
                        "
                        class="fontSize14px"
                      ></label>
                      <span
                        class="marginLeft10 cursorPointer cursorPointer chubbWhite fontSize12px chubbRed_bg"
                        (click)="viewClientDetails()"
                        *ngIf="
                          proposal!.qsMaster.qs_master_insuredName!.isAnswered() &&
                          !isIBGProduct
                        "
                        style="padding-left: 5px; padding-right: 5px"
                        >View Details</span
                      >
                      <span
                        class="marginLeft10 cursorPointer cursorPointer chubbWhite fontSize12px chubbRed_bg"
                        (click)="GotoUpdateClientInfo()"
                        *ngIf="!isIBGProduct && isChangeOfClientInfo"
                        style="padding-left: 5px; padding-right: 5px"
                      >
                        Update Client
                      </span>
                      <div>
                        <p
                          class="wordBreakAll"
                          *ngIf="
                            proposal!.qsMaster.qs_master_insuredName!.isAnswered()
                          "
                        >
                          {{ proposal!.qsMaster.qs_master_insuredName!.answer }}
                        </p>

                        <span
                          class="unattended"
                          *ngIf="
                            !proposal!.qsMaster.qs_master_insuredName!.isAnswered()
                          "
                          >Unknown</span
                        >
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div class="row" *ngIf="!isIBGProduct">
                <div class="col-md-12">
                  <form role="form ">
                    <div class="form-group">
                      <div
                        id="{{
                          proposal!.qsMaster.qs_master_preferredDeliveryMethod!
                            .key
                        }}"
                        [ngClass]="{
                          'st-danger':
                            proposal!.qsMaster
                              .qs_master_preferredDeliveryMethod!.theme === 1
                        }"
                      >
                        <label
                          for="{{
                            proposal!.qsMaster
                              .qs_master_preferredDeliveryMethod!.key
                          }}"
                          [ngClass]="{
                            required:
                              proposal!.qsMaster
                                .qs_master_preferredDeliveryMethod!.required &&
                              (isChangeOfClientInfo ||
                                (!proposal!.qsMaster
                                  .qs_master_masterPolicyNumber!.readOnly &&
                                  !isEndorsement &&
                                  !isCancel))
                          }"
                          >Preferred Delivery Method:</label
                        >
                        <span> {{ preferredDeliveryText }}</span>
                        <span
                          class="marginLeft10 cursorPointer cursorPointer chubbWhite fontSize12px chubbRed_bg"
                          (click)="updatePreferredDelivery()"
                          *ngIf="
                            !isChecker &&
                            !isMaker &&
                            (isChangeOfClientInfo ||
                              (!proposal!.qsMaster.qs_master_masterPolicyNumber!
                                .readOnly &&
                                !isEndorsement &&
                                !isCancel))
                          "
                          style="padding-left: 5px; padding-right: 5px"
                          >Update</span
                        >
                        <div class="radio clearMarginTopBottom">
                          <div class="row">
                            <div class="col-md-4">
                              <label
                                [ngClass]="{
                                  required:
                                    proposal!.qsMaster
                                      .qs_master_preferredDeliveryMethod!
                                      .answer == 5 &&
                                    (isChangeOfClientInfo ||
                                      (!proposal!.qsMaster
                                        .qs_master_masterPolicyNumber!
                                        .readOnly &&
                                        !isEndorsement &&
                                        !isCancel))
                                }"
                                >Mobile Number</label
                              >
                            </div>
                            <div class="col-md-4">
                              <label
                                [ngClass]="{
                                  required:
                                    proposal!.qsMaster
                                      .qs_master_preferredDeliveryMethod!
                                      .answer == 2 &&
                                    (isChangeOfClientInfo ||
                                      (!proposal!.qsMaster
                                        .qs_master_masterPolicyNumber!
                                        .readOnly &&
                                        !isEndorsement &&
                                        !isCancel))
                                }"
                                >Email</label
                              >
                            </div>
                            <div class="col-md-4">
                              <label
                                [ngClass]="{
                                  required:
                                    proposal!.qsMaster
                                      .qs_master_preferredDeliveryMethod!
                                      .answer == 1 &&
                                    (isChangeOfClientInfo ||
                                      (!proposal!.qsMaster
                                        .qs_master_masterPolicyNumber!
                                        .readOnly &&
                                        !isEndorsement &&
                                        !isCancel))
                                }"
                                >Mailing Address</label
                              >

                              <span
                                class="marginLeft10 cursorPointer cursorPointer chubbWhite fontSize12px chubbRed_bg"
                                (click)="updatePreferredDelivery()"
                                [hidden]="!isChangeOfCorrespondenceAddressType"
                                style="padding-left: 5px; padding-right: 5px"
                                >Update</span
                              >
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="radio clearMarginTopBottom">
                            <div class="row">
                              <div class="col-md-4">
                                <p
                                  *ngIf="
                                    proposal!.qsMaster.qs_master_preferredDeliveryMethod_MobileNo!.isAnswered()
                                  "
                                  class="wordBreak"
                                >
                                  {{
                                    proposal!.qsMaster
                                      .qs_master_preferredDeliveryMethod_MobileNo!
                                      .answer
                                  }}
                                </p>
                              </div>
                              <div class="col-md-4">
                                <p
                                  *ngIf="
                                    proposal!.qsMaster.qs_master_preferredDeliveryMethod_Email!.isAnswered()
                                  "
                                  class="wordBreak"
                                >
                                  {{
                                    proposal!.qsMaster
                                      .qs_master_preferredDeliveryMethod_Email!
                                      .answer
                                  }}
                                </p>
                              </div>
                              <div class="col-md-4">
                                <p
                                  *ngIf="
                                    proposal!.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress!.isAnswered()
                                  "
                                  class="wordBreak"
                                >
                                  {{ contactInformationPreferredDelivery }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3" *ngIf="isIBGProduct">
                  <form role="form ">
                    <div class="form-group">
                      <date-input-question
                        [question]="
                          proposal!.qsMaster
                            .qs_master_dateOfBirthOrIncorporation!
                        "
                        answer="proposal!.qsMaster.qs_master_dateOfBirthOrIncorporation.answer "
                      >
                      </date-input-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-4" *ngIf="isIBGProduct">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="proposal!.qsMaster.qs_master_insuredNo!"
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-4" *ngIf="isIBGProduct">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="proposal!.qsMaster.qs_master_insuredNo2!"
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
              </div>

              <div class="row clearfix" *ngIf="isIBGProduct">
                <div class="col-md-3">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="
                          proposal!.qsMaster.qs_master_namesOtherInsured!
                        "
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
              </div>

              <div class="row clearfix" *ngIf="isIBGProduct">
                <div class="col-md-3">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="
                          proposal!.qsMaster.qs_master_clientContactPerson!
                        "
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-3">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="
                          proposal!.qsMaster
                            .qs_master_clientContactPersonContactNo!
                        "
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-3">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="proposal!.qsMaster.qs_master_phoneNoOffice!"
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
              </div>
              <div class="row clearfix" *ngIf="isIBGProduct">
                <div class="col-md-12">
                  <form role="form ">
                    <div class="form-group">
                      <div
                        id="{{
                          proposal!.qsMaster.qs_master_preferredDeliveryMethod!
                            .key
                        }}"
                        [ngClass]="{
                          'st-danger':
                            proposal!.qsMaster
                              .qs_master_preferredDeliveryMethod!.theme === 1
                        }"
                      >
                        <label
                          for="{{
                            proposal!.qsMaster
                              .qs_master_preferredDeliveryMethod!.key
                          }}"
                          [ngClass]="{
                            required:
                              proposal!.qsMaster
                                .qs_master_preferredDeliveryMethod!.required &&
                              !proposal!.qsMaster
                                .qs_master_preferredDeliveryMethod!.readOnly
                          }"
                          [textContent]="
                            proposal!.qsMaster
                              .qs_master_preferredDeliveryMethod!.displayText
                          "
                          >Preferred Delivery Method:</label
                        >
                        <div class="radio clearMarginTopBottom clearfix">
                          <div class="row">
                            <div class="col-md-4">
                              <label class="radio-inline">
                                <form role="form ">
                                  <div class="form-group">
                                    <input
                                      class="floatLeft"
                                      style="padding-left: 0px"
                                      name="{{
                                        proposal!.qsMaster
                                          .qs_master_preferredDeliveryMethod!
                                          .key
                                      }}"
                                      type="radio"
                                      value="5"
                                      [(ngModel)]="
                                        proposal!.qsMaster
                                          .qs_master_preferredDeliveryMethod!
                                          .answer
                                      "
                                      [disabled]="
                                        toShowQuestion(
                                          proposal!.qsMaster
                                            .qs_master_preferredDeliveryMethod!
                                            .key
                                        )
                                      "
                                    />
                                    <text-box-question
                                      class="floatLeft customMarginRadio"
                                      *ngIf="
                                        toShowQuestion(
                                          proposal!.qsMaster
                                            .qs_master_preferredDeliveryMethod_MobileNo!
                                            .key
                                        )
                                      "
                                      [question]="
                                        proposal!.qsMaster
                                          .qs_master_preferredDeliveryMethod_MobileNo!
                                      "
                                    >
                                    </text-box-question>
                                    <span
                                      *ngIf="
                                        !toShowQuestion(
                                          proposal!.qsMaster
                                            .qs_master_preferredDeliveryMethod_MobileNo!
                                            .key
                                        )
                                      "
                                      >{{
                                        proposal!.qsMaster
                                          .qs_master_preferredDeliveryMethod_MobileNo!
                                          .displayText
                                      }}</span
                                    >
                                  </div>
                                </form>
                              </label>
                            </div>
                            <div class="col-md-4">
                              <label class="radio-inline">
                                <form role="form ">
                                  <div class="form-group">
                                    <input
                                      class="floatLeft"
                                      name="{{
                                        proposal!.qsMaster
                                          .qs_master_preferredDeliveryMethod!
                                          .key
                                      }}"
                                      type="radio"
                                      value="2"
                                      [(ngModel)]="
                                        proposal!.qsMaster
                                          .qs_master_preferredDeliveryMethod!
                                          .answer
                                      "
                                      [disabled]="
                                        toShowQuestion(
                                          proposal!.qsMaster
                                            .qs_master_preferredDeliveryMethod!
                                            .key
                                        )
                                      "
                                    />
                                    <text-box-question
                                      class="floatLeft customMarginRadio"
                                      *ngIf="
                                        toShowQuestion(
                                          proposal!.qsMaster!
                                            .qs_master_preferredDeliveryMethod_Email!
                                            .key
                                        )
                                      "
                                      [question]="
                                        proposal!.qsMaster!
                                          .qs_master_preferredDeliveryMethod_Email!
                                      "
                                    >
                                    </text-box-question>
                                    <span
                                      *ngIf="
                                        !toShowQuestion(
                                          proposal!.qsMaster
                                            .qs_master_preferredDeliveryMethod_Email!
                                            .key
                                        )
                                      "
                                      >{{
                                        proposal!.qsMaster
                                          .qs_master_preferredDeliveryMethod_Email!
                                          .displayText
                                      }}</span
                                    >
                                  </div>
                                </form>
                              </label>
                            </div>

                            <div class="col-md-4">
                              <label class="radio-inline">
                                <form>
                                  <div class="form-group">
                                    <input
                                      class="floatLeft"
                                      name="{{
                                        proposal!.qsMaster
                                          .qs_master_preferredDeliveryMethod!
                                          .key
                                      }}"
                                      type="radio"
                                      value="1"
                                      [(ngModel)]="
                                        proposal!.qsMaster
                                          .qs_master_preferredDeliveryMethod!
                                          .answer
                                      "
                                      [disabled]="
                                        toShowQuestion(
                                          proposal!.qsMaster
                                            .qs_master_preferredDeliveryMethod!
                                            .key
                                        )
                                      "
                                    />
                                    <span
                                      class="floatLeft"
                                      style="margin: -5px 0 0 10px"
                                      [ngClass]="{
                                        required:
                                          proposal!.qsMaster
                                            .qs_master_preferredDeliveryMethod_CorrAddress!
                                            .required &&
                                          !proposal!.qsMaster
                                            .qs_master_preferredDeliveryMethod!
                                            .readOnly
                                      }"
                                      >Mailing Address</span
                                    >
                                  </div>
                                </form>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-md-12" *ngIf="isIBGProduct">
                  <form role="form ">
                    <div class="form-group">
                      <div>
                        <text-box-question
                          [question]="
                            proposal!.qsMaster.qs_master_insuredAddress!
                          "
                        >
                        </text-box-question>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-md-12" *ngIf="isIBGProduct">
                  <form role="form ">
                    <div class="form-group">
                      <label
                        for="{{
                          proposal!.qsMaster.qs_master_insuredName!.key
                        }} "
                        class="textDecoUnderline fontSize14px"
                        >Correspondence Address</label
                      >
                    </div>
                  </form>
                </div>
                <div
                  class="col-md-12"
                  *ngIf="isIBGProduct && isChangeOfCorrespondenceAddressType"
                >
                  <form
                    role="form "
                    *ngIf="
                      !proposal!.qsMaster.qs_master_insuredAddress_lotunit!
                        .readOnly
                    "
                  >
                    <div class="form-group">
                      <label class="floatLeft marginRight10 fontSize14px"
                        >Street Address Search – Please enter only the street
                        address and suburb, do not include building name or
                        suite/lot/unit/shop number or level</label
                      >
                      <div
                        style="font-size: 12px"
                        class="cursorPointer floatLeft chubbWhite"
                      >
                        <span
                          class="chubbGreen_bg"
                          style="
                            padding: 0px 5px 1px 5px;
                            vertical-align: super;
                          "
                          (click)="openAddressHelp($event)"
                          >Help</span
                        >
                      </div>
                      <div>
                        <div>
                          <input
                            *ngIf="
                              !proposal!.qsMaster
                                .qs_master_insuredAddress_lotunit!.readOnly
                            "
                            id="policyInformation_autocomplete"
                            ngx-google-places-autocomplete
                            [options]="ibgoptions"
                            #policyInformation_autocomplete="ngx-places"
                            (onAddressChange)="
                              updateAddressValues(
                                $event,
                                {},
                                'policyInformation_autocomplete'
                              )
                            "
                            placeholder="Enter your address "
                            type="text "
                            class="k-textbox maxWidth"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="col-md-6">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="
                          proposal!.qsMaster.qs_master_insuredAddress_lotunit!
                        "
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-6">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="
                          proposal!.qsMaster.qs_master_insuredAddress_building!
                        "
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-6">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="
                          proposal!.qsMaster.qs_master_insuredAddress1!
                        "
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-6">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="
                          proposal!.qsMaster.qs_master_insuredAddress2!
                        "
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-6">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="
                          proposal!.qsMaster.qs_master_insuredPostalCode!
                        "
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-6">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="proposal!.qsMaster.qs_master_insuredCity!"
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-6">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="proposal!.qsMaster.qs_master_insuredState!"
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div
                  class="col-md-6"
                  *ngIf="proposal!.qsMaster.qs_master_insuredCountry!.isVisible"
                >
                  <form role="form ">
                    <div class="form-group">
                      <drop-down-list-filter-question
                        [question]="
                          proposal!.qsMaster.qs_master_insuredCountry!
                        "
                      >
                      </drop-down-list-filter-question>
                    </div>
                  </form>
                </div>
                <div
                  class="col-md-2 floatLeft"
                  *ngIf="isChangeOfCorrespondenceAddressType"
                >
                  <form
                    role="form "
                    *ngIf="
                      !proposal!.qsMaster.qs_master_insuredAddress_lotunit!
                        .readOnly
                    "
                  >
                    <div class="form-group">
                      <div
                        class="btn-box chubbWhite chubbRed_bg"
                        (click)="
                          resetAddressForm(
                            true,
                            'policyInformation_autocomplete'
                          )
                        "
                      >
                        Reset
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <!-- </div> -->
          </kendo-expansionpanel>

          <hr [hidden]="isIBGProduct" />

          <div class="" [hidden]="isIBGProduct">
            <div class="col-md-12">
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <label
                      class="textDecoUnderline fontSize14px"
                      [textContent]="labelText"
                    ></label>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div
              class="col-md-12"
              [hidden]="!(!proposal!.loanRedeemed && !isIBGProduct)"
            >
              <div class="col-md-3 floatLeft">
                <form role="form ">
                  <div class="form-group">
                    <label class="fontSize14px" [textContent]="'Name:'"></label>
                    <div>
                      <p [textContent]="'DBS Bank Ltd'"></p>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-3 floatLeft">
                <form role="form ">
                  <div class="form-group">
                    <label
                      class="fontSize14px"
                      [textContent]="'Nature Of Interest:'"
                    ></label>
                    <div>
                      <p [textContent]="'Mortgagee'"></p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="" *ngIf="!isIBGProduct">
            <div class="col-md-12">
              <div
                id="qs_fire_interestedParties_table_grid"
                style="margin-bottom: 20px"
              >
                <kendo-grid
                  [data]="gridFireInterestedParties"
                  [pageSize]="10"
                  [pageable]="true"
                  [sortable]="true"
                  (edit)="editHandler($event)"
                  (cancel)="cancelHandler($event)"
                  (save)="saveHandler($event)"
                  (remove)="removeHandler($event)"
                  (add)="addHandler($event)"
                  [navigable]="true"
                  [height]="280"
                >
                  <ng-template
                    kendoGridToolbarTemplate
                    *ngIf="addButtonVisiiblity"
                  >
                    <button kendoGridAddCommand type="button">
                      {{ labelTextInterestedParty }}
                    </button>
                  </ng-template>
                  <kendo-grid-column field="Name" title="Name" [width]="350">
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                      <kendo-textbox
                        [(ngModel)]="dataItem.Name"
                        name="Name"
                        required
                      >
                      </kendo-textbox>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column
                    field="NatureOfInterest"
                    title="Nature Of Interest"
                    [width]="250"
                    [editable]="false"
                  ></kendo-grid-column>
                  <kendo-grid-command-column
                    title="Action"
                    [hidden]="hideInterestedPartiesActionCol"
                    [width]="220"
                  >
                    <ng-template kendoGridCellTemplate let-isNew="isNew">
                      <button
                        kendoGridEditCommand
                        type="button"
                        [primary]="true"
                      >
                        Edit
                      </button>
                      <button kendoGridRemoveCommand type="button">
                        Remove
                      </button>
                      <button
                        kendoGridSaveCommand
                        type="button"
                        class="k-button-icontext"
                      >
                        {{ isNew ? "Add" : "Update" }}
                      </button>
                      <button kendoGridCancelCommand type="button">
                        {{ isNew ? "Discard changes" : "Cancel" }}
                      </button>
                    </ng-template>
                  </kendo-grid-command-column>
                </kendo-grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 card card-mod cardNoBorder ng-scope">
        <kendo-expansionpanel
          id="broker_panel"
          [title]="
            ibgMACDisabled ? 'Fire Risk Details' : 'Machinery Risk Details'
          "
          [expanded]="true"
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <drop-down-list-filter-question
                      [question]="
                        proposal!.qsMaster.qs_master_masterPolicyNumber!
                      "
                    >
                    </drop-down-list-filter-question>
                  </div>
                </form>
              </div>
              <div class="col-md-3" *ngIf="!isNewBusiness && isPlanTypeExists">
                <form role="form ">
                  <div class="form-group">
                    <radio-button-v1-question
                      [question]="proposal!.qsMaster.qs_master_planType!"
                    >
                    </radio-button-v1-question>
                  </div>
                </form>
              </div>

              <div class="col-md-3" *ngIf="isIBGProduct">
                <form role="form ">
                  <div class="form-group">
                    <yes-no-radio-question
                      [question]="
                        proposal!.qsMaster.qs_fire_freeInsuranceFirstYear!
                      "
                    >
                    </yes-no-radio-question>
                  </div>
                </form>
              </div>

              <div
                class="col-md-3"
                *ngIf="
                  proposal!.qsMaster.qs_fire_freeInsuranceNoYear!.isVisible ||
                  !isFreeInsApplicable
                "
              >
                <form role="form ">
                  <div class="form-group">
                    <numeric-box-question
                      [question]="
                        proposal!.qsMaster.qs_fire_freeInsuranceNoYear!
                      "
                    >
                    </numeric-box-question>
                  </div>
                </form>
              </div>
            </div>
            <!--17/10/2018 Chirag : Added Free Insurance Start/End Date -->
            <div
              class="row"
              *ngIf="
                !isIBGProduct &&
                proposal!.qsMaster.qs_master_FreeIns_startDate!.isVisible
              "
            >
              <div class="col-md-3">
                <form role="form ">
                  <div class="form-group">
                    <date-picker-v1-question
                      [question]="
                        proposal!.qsMaster.qs_master_FreeIns_startDate!
                      "
                      answer="proposal!.qsMaster.qs_master_FreeIns_startDate"
                    >
                    </date-picker-v1-question>
                  </div>
                </form>
              </div>
              <div class="col-md-3">
                <form role="form ">
                  <div class="form-group">
                    <date-picker-v1-question
                      [question]="proposal!.qsMaster.qs_master_FreeIns_endDate!"
                      answer="proposal!.qsMaster.qs_master_FreeIns_endDate"
                    >
                    </date-picker-v1-question>
                  </div>
                </form>
              </div>
            </div>

            <!--Chirag: 28-12-2018 Free Insurance Start/End Date For CBG Prodcut-->
            <div
              class="row"
              *ngIf="
                !isFreeInsApplicable &&
                !proposal!.qsMaster.qs_master_FreeIns_startDate!.isVisible
              "
            >
              <div class="col-md-3">
                <form role="form ">
                  <div class="form-group">
                    <label
                      class="fontSize14px"
                      [textContent]="'Free Insurance Start Date'"
                    ></label>
                  </div>
                  <div class="form-group">
                    <label class="fontSize10px" [textContent]="'N/A'"></label>
                  </div>
                </form>
              </div>
              <div class="col-md-3">
                <form role="form ">
                  <div class="form-group">
                    <label
                      class="fontSize14px"
                      [textContent]="'Free Insurance End Date'"
                    ></label>
                  </div>
                  <div class="form-group">
                    <label class="fontSize10px" [textContent]="'N/A'"></label>
                  </div>
                </form>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3">
                <form role="form ">
                  <div class="form-group">
                    <div id="originalInceptionDate">
                      <label
                        for="originalInceptionDate "
                        [textContent]="'Original Inception Date'"
                      ></label>
                      <div>
                        <p>{{ originalInceptionDate }}</p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-3">
                <form role="form ">
                  <div class="form-group">
                    <date-picker-v1-question
                      [question]="proposal!.qsMaster.qs_master_poi_startDate!"
                      answer="proposal!.qsMaster.qs_master_poi_startDate.answer "
                      [minDate]="qs_master_poi_startDate_datePicker_minDate"
                      [maxDate]="qs_master_poi_startDate_datePicker_maxDate"
                      [onClose]="refreshEchoPanel"
                    >
                    </date-picker-v1-question>
                  </div>
                </form>
              </div>
              <div class="col-md-3">
                <form role="form ">
                  <div class="form-group">
                    <date-picker-v1-question
                      [question]="proposal!.qsMaster.qs_master_poi_endDate!"
                      answer="proposal!.qsMaster.qs_master_poi_endDate.answer "
                    >
                    </date-picker-v1-question>
                  </div>
                </form>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <label
                      attr.for="{{
                        proposal!.qsMaster.qs_master_insuredName!.key
                      }} "
                      class="textDecoUnderline fontSize14px"
                      [textContent]="'Risk Address'"
                    ></label>
                  </div>
                </form>
              </div>
            </div>

            <div class="row" *ngIf="isIBGProduct">
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <div>
                      <text-box-question
                        [question]="
                          proposal!.qsMaster.qs_risk_uploadRiskAddress!
                        "
                      >
                      </text-box-question>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12" *ngIf="canEditProposal()">
                <form role="form ">
                  <div class="form-group">
                    <div
                      id="{{ proposal!.qsMaster.qs_risk_sameAsAddress!.key }}"
                      [ngClass]="{
                        'st-danger':
                          proposal!.qsMaster.qs_risk_sameAsAddress!.theme === 1
                      }"
                    >
                      <label
                        for="{{
                          proposal!.qsMaster.qs_risk_sameAsAddress!.key
                        }}"
                        [ngClass]="{
                          required:
                            proposal!.qsMaster.qs_risk_sameAsAddress!
                              .required &&
                            !proposal!.qsMaster.qs_risk_sameAsAddress!.readOnly
                        }"
                        [textContent]="
                          proposal!.qsMaster.qs_risk_sameAsAddress!.displayText
                        "
                      ></label>

                      <div
                        *ngIf="
                          !proposal!.qsMaster.qs_risk_sameAsAddress!.readOnly
                        "
                      >
                        <div class="radio clearMarginTopBottom">
                          <input
                            *ngIf="
                              !proposal!.qsMaster.qs_risk_sameAsAddress!
                                .readOnly
                            "
                            name="{{
                              proposal!.qsMaster.qs_risk_sameAsAddress!.key
                            }} "
                            #qs_risk_sameAsAddress_checkbox
                            [disabled]="isRenewalDraft && isIBGProduct"
                            type="checkbox"
                            id="{{
                              proposal!.qsMaster.qs_risk_sameAsAddress!.key
                            }}_checkbox"
                            (click)="
                              updateModelAnswer(
                                proposal!.qsMaster.qs_risk_sameAsAddress!
                              );
                              copyInsuredAddress()
                            "
                            [checked]="
                              compareQuestionAnswer(
                                proposal!.qsMaster.qs_risk_sameAsAddress!
                              )
                            "
                          />
                        </div>
                      </div>

                      <div
                        *ngIf="
                          proposal!.qsMaster.qs_risk_sameAsAddress!.readOnly
                        "
                      >
                        <div
                          class=""
                          *ngIf="
                            compareQuestionAnswer(
                              proposal!.qsMaster.qs_risk_sameAsAddress!.answer
                            )
                          "
                          style="padding-left: 5px"
                        >
                          Yes
                        </div>
                        <div
                          class=""
                          *ngIf="
                            compareQuestionAnswer(
                              proposal!.qsMaster.qs_risk_sameAsAddress!.answer
                            )
                          "
                          style="padding-left: 5px"
                        >
                          No
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div
                class="col-md-12"
                *ngIf="
                  !proposal!.qsMaster.qs_master_masterPolicyNumber!.readOnly &&
                  !isGeneralEndorsement &&
                  !isSameAsAddressTicked
                "
              >
                <form role="form ">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12">
                        <label
                          class="floatLeft marginRight10 fontSize14px"
                          [textContent]="
                            'Street Address Search – Please enter only the street address and suburb, do not include building name or suite/lot/unit/shop number or level                                      '
                          "
                        ></label>
                        <div
                          style="font-size: 12px"
                          class="cursorPointer floatLeft chubbWhite"
                        >
                          <span
                            class="chubbGreen_bg"
                            style="
                              padding: 0px 5px 1px 5px;
                              vertical-align: super;
                            "
                            (click)="openAddressHelp($event)"
                            >Help</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <input
                          id="riskInformation_autocomplete"
                          ngx-google-places-autocomplete
                          [options]="options"
                          #riskInformation_autocomplete="ngx-places"
                          (onAddressChange)="
                            updateAddressValues(
                              $event,
                              {},
                              'riskInformation_autocomplete'
                            )
                          "
                          [disabled]="isRenewalDraft && isIBGProduct"
                          placeholder="Enter your address "
                          type="text "
                          class="k-textbox maxWidth"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="
                            proposal!.qsMaster.qs_risk_insuredAddress_lotunit!
                          "
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="
                            proposal!.qsMaster.qs_risk_insuredAddress_building!
                          "
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="
                            proposal!.qsMaster.qs_risk_insuredAddress1!
                          "
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="
                            proposal!.qsMaster.qs_risk_insuredAddress2!
                          "
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="
                            proposal!.qsMaster.qs_risk_insuredPostalCode!
                          "
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="proposal!.qsMaster.qs_risk_insuredCity!"
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div
                    class="col-md-6"
                    [hidden]="
                      !proposal!.qsMaster.qs_risk_insuredCountry!.isVisible
                    "
                  >
                    <form role="form ">
                      <div class="form-group">
                        <drop-down-list-filter-question
                          [question]="
                            proposal!.qsMaster.qs_risk_insuredCountry!
                          "
                        >
                        </drop-down-list-filter-question>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div
                class=""
                *ngIf="
                  !proposal!.qsMaster.qs_master_masterPolicyNumber!.readOnly &&
                  !isGeneralEndorsement &&
                  !isSameAsAddressTicked &&
                  !(isRenewalDraft && isIBGProduct)
                "
              >
                <div class="col-md-2 floatLeft">
                  <form role="form ">
                    <div class=" ">
                      <div
                        class="btn-box chubbWhite chubbRed_bg"
                        (click)="
                          resetAddressForm(true, 'riskInformation_autocomplete')
                        "
                        style="margin-bottom: 0px"
                      >
                        Reset
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <hr />

              <div class="col-md-12" *ngIf="isIBGProduct && !ibgMFDisabled">
                <form role="form ">
                  <div class="form-group">
                    <label class="textDecoUnderline fontSize14px"
                      >Mortgage Fire</label
                    >
                  </div>
                </form>
              </div>
              <div class="col-md-12" *ngIf="isIBGProduct && ibgMFDisabled">
                <form role="form ">
                  <div class="form-group">
                    <label
                      class="textDecoUnderline fontSize14px"
                      [textContent]="'Machinery All Risk'"
                    ></label>
                  </div>
                </form>
              </div>

              <div class="col-md-6" *ngIf="isIBGProduct">
                <form role="form ">
                  <div
                    class="form-group"
                    id="qs_master_insuredIndustry"
                    [ngClass]="{
                      'st-danger':
                        proposal!.qsMaster.qs_master_insuredIndustry!.theme ===
                        1
                    }"
                  >
                    <label
                      [ngClass]="{
                        required:
                          proposal!.qsMaster.qs_master_insuredIndustry!
                            .required &&
                          !proposal!.qsMaster.qs_master_insuredIndustry!
                            .readOnly
                      }"
                      [textContent]="
                        proposal!.qsMaster.qs_master_insuredIndustry!
                          .displayText
                      "
                      class="fontSize14px"
                    ></label>
                    <span
                      class="marginLeft10 cursorPointer cursorPointer chubbWhite fontSize12px chubbRed_bg"
                      *ngIf="
                        !proposal!.refreshedProposalEntity!.proposalReadOnly! &&
                        !proposal!.isBroker &&
                        ((!isCancel && !isEndorsement) ||
                          (isEndorsement && isGeneralEndorsement))
                      "
                      style="padding-left: 5px; padding-right: 5px"
                      (click)="qs_master_insuredIndustry_onChange()"
                    >
                      Search
                    </span>
                    <div>
                      <p
                        *ngIf="
                          proposal!.qsMaster.qs_master_insuredIndustry!.isAnswered()
                        "
                      >
                        {{
                          proposal!.qsMaster.qs_master_insuredIndustry_desc!
                            .answer
                        }}
                      </p>
                      <span
                        class="unattended"
                        *ngIf="
                          !proposal!.qsMaster.qs_master_insuredIndustry!.isAnswered() &&
                          proposal!.refreshedProposalEntity!.proposalReadOnly!
                        "
                        >Unknown</span
                      >
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-3" *ngIf="isIBGProduct && !ibgMFDisabled">
                <form role="form ">
                  <div class="form-group">
                    <drop-down-list-filter-question
                      [question]="proposal!.qsMaster.qs_master_occupancyRate!"
                    >
                    </drop-down-list-filter-question>
                  </div>
                </form>
              </div>
              <div class="col-md-3" *ngIf="isIBGProduct && !ibgMFDisabled">
                <form role="form ">
                  <div class="form-group">
                    <drop-down-list-question
                      [question]="proposal!.qsMaster.qs_master_miRate!"
                    >
                    </drop-down-list-question>
                  </div>
                </form>
              </div>
              <div class="col-md-12" *ngIf="!isIBGProduct">
                <div class="row">
                  <div
                    class="col-md-6"
                    id="{{
                      proposal!.qsMaster.qs_master_manualUnderwritting!.key
                    }}"
                  >
                    <form role="form ">
                      <div class="form-group" *ngIf="displayManualUnderwriting">
                        <div>
                          <label
                            for="{{
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .key
                            }}"
                            [textContent]="
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .displayText
                            "
                          ></label>
                          <div
                            *ngIf="
                              !proposal!.qsMaster.qs_master_manualUnderwritting!
                                .readOnly
                            "
                          >
                            <div
                              class="radio clearMarginTopBottom clearfix"
                              *ngIf="isIncomplete || isReferred"
                            >
                              <input
                                *ngIf="
                                  !proposal!.qsMaster
                                    .qs_master_manualUnderwritting!.readOnly
                                "
                                name="{{
                                  proposal!.qsMaster
                                    .qs_master_manualUnderwritting!.key
                                }} "
                                #qs_master_manualUnderwritting_checkbox
                                type="checkbox"
                                value="{{
                                  proposal!.qsMaster
                                    .qs_master_manualUnderwritting!.answer
                                }}"
                                id="{{
                                  proposal!.qsMaster
                                    .qs_master_manualUnderwritting!.key
                                }}_checkbox"
                                [disabled]="
                                  proposal!.qsMaster
                                    .qs_master_manualUnderwritting!.isDisabled!
                                "
                                (click)="
                                  updateModelAnswer(
                                    proposal!.qsMaster
                                      .qs_master_manualUnderwritting!
                                  );
                                  qs_master_manualUnderwritting_onChange(
                                    proposal!.qsMaster
                                      .qs_master_manualUnderwritting!.answer!
                                  )
                                "
                                [checked]="
                                  compareQuestionAnswer(
                                    proposal!.qsMaster
                                      .qs_master_manualUnderwritting!
                                  )
                                "
                              />
                            </div>
                            <div *ngIf="!isIncomplete && !isReferred">
                              <div
                                class=""
                                *ngIf="
                                  proposal!.qsMaster
                                    .qs_master_manualUnderwritting!.answer ===
                                    'True' ||
                                  proposal!.qsMaster
                                    .qs_master_manualUnderwritting!.answer ===
                                    true
                                "
                                style="padding-left: 5px"
                              >
                                Yes
                              </div>
                              <div
                                class=""
                                *ngIf="
                                  proposal!.qsMaster
                                    .qs_master_manualUnderwritting!.answer !==
                                    'True' &&
                                  proposal!.qsMaster
                                    .qs_master_manualUnderwritting!.answer !==
                                    true
                                "
                                style="padding-left: 5px"
                              >
                                No
                              </div>
                            </div>
                          </div>
                          <div
                            *ngIf="
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .readOnly
                            "
                          >
                            <div
                              class=""
                              *ngIf="
                                proposal!.qsMaster
                                  .qs_master_manualUnderwritting!.answer ===
                                  'True' ||
                                proposal!.qsMaster
                                  .qs_master_manualUnderwritting!.answer ===
                                  true
                              "
                              style="padding-left: 5px"
                            >
                              Yes
                            </div>
                            <div
                              class=""
                              *ngIf="
                                proposal!.qsMaster
                                  .qs_master_manualUnderwritting!.answer !==
                                  'True' &&
                                proposal!.qsMaster
                                  .qs_master_manualUnderwritting!.answer !==
                                  true
                              "
                              style="padding-left: 5px"
                            >
                              No
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="form-group"
                        *ngIf="!displayManualUnderwriting"
                      >
                        <div>
                          <label
                            for="{{
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .key
                            }}"
                            [textContent]="
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .displayText
                            "
                          ></label>
                          <div
                            class=""
                            *ngIf="
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .answer === 'True' ||
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .answer === true
                            "
                            style="padding-left: 5px"
                          >
                            Yes
                          </div>
                          <div
                            class=""
                            *ngIf="
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .answer !== 'True' &&
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .answer !== true
                            "
                            style="padding-left: 5px"
                          >
                            No
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group" *ngIf="enableOccupiedAs">
                        <drop-down-list-filter-question
                          [question]="proposal!.qsMaster.qs_fire_occupied!"
                        >
                        </drop-down-list-filter-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group" *ngIf="ibgMACDisabled">
                        <text-area-question
                          [question]="proposal!.qsMaster.qs_fire_notes!"
                        >
                        </text-area-question>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div
                    class="col-md-6"
                    [hidden]="
                      !(
                        proposal.qsMaster.qs_fire_mcst!.isVisible &&
                        ibgMACDisabled
                      )
                    "
                  >
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="proposal!.qsMaster.qs_fire_mcst!"
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>

                  <div
                    class="col-md-3"
                    *ngIf="
                      proposal!.qsMaster.qs_fire_replacement!.isVisible &&
                      ibgMACDisabled
                    "
                  >
                    <form role="form ">
                      <div class="form-group">
                        <currency-box-v1-question
                          [question]="proposal!.qsMaster.qs_fire_replacement!"
                        >
                        </currency-box-v1-question>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-md-12" *ngIf="isIBGProduct && !ibgMFDisabled">
                <div class="row">
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="
                            proposal!.qsMaster.qs_fire_natureBusiness!
                          "
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="proposal!.qsMaster.qs_fire_usagePremises!"
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-md-6" *ngIf="isIBGProduct && ibgMFDisabled">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="proposal!.qsMaster.qs_fire_natureBusiness!"
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6" *ngIf="isIBGProduct && ibgMFDisabled">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="proposal!.qsMaster.qs_fire_nameSolicitors!"
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>

              <div class="col-md-6" *ngIf="isIBGProduct && !ibgMFDisabled">
                <form role="form ">
                  <div class="form-group">
                    <text-area-question
                      [question]="proposal!.qsMaster.qs_fire_typeProperty!"
                    >
                    </text-area-question>
                  </div>
                </form>
              </div>

              <div class="col-md-12">
                <div class="row" *ngIf="!isIBGProduct">
                  <div class="col-md-12">
                    <table
                      class="table table-striped table-bordered table-condensed marginTop10"
                    >
                      <thead>
                        <tr class="chubbGray_bg chubbWhite">
                          <th style="width: 25px">No</th>
                          <th>Item Coverage Description</th>
                          <th class="textAlignCenter" style="width: 250px">
                            Sum Insured
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Building</td>
                          <td
                            class="textAlignCenter"
                            [ngClass]="{
                              chubbGreen_bg: isBuildingSIModified
                            }"
                          >
                            <currency-box-conditional-question
                              [question]="proposal!.qsMaster.qs_fire_building!"
                              [condition]="currency1HDBDisabled"
                            ></currency-box-conditional-question>
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Household contents</td>

                          <td class="textAlignCenter">
                            <div>
                              <span class="textAlignCenter" title="currency "
                                >{{
                                  proposal!.qsMaster.qs_fire_householdContents!
                                    .answer | ausCurrency
                                }}
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-md-6" *ngIf="isIBGProduct && !ibgMFDisabled">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="proposal!.qsMaster.qs_fire_nameSolicitors!"
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>

              <div class="col-md-12" *ngIf="isIBGProduct && ibgMFDisabled">
                <form role="form ">
                  <div class="form-group">
                    <label class="textDecoUnderline fontSize14px"
                      >Equipment</label
                    >
                  </div>
                </form>
              </div>

              <div class="col-md-6" *ngIf="isIBGProduct && ibgMFDisabled">
                <form role="form ">
                  <div class="form-group">
                    <currency-box-v1-question
                      [question]="
                        proposal!.qsMaster.qs_machinery_uploadSumInsured!
                      "
                    >
                    </currency-box-v1-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6" *ngIf="isIBGProduct && ibgMFDisabled">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="
                        proposal!.qsMaster.qs_machinery_uploadTypeOfFinancing!
                      "
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>

              <div class="col-md-6" *ngIf="isIBGProduct && ibgMFDisabled">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="
                        proposal!.qsMaster.qs_machinery_uploadTypeOfEquipment!
                      "
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6" *ngIf="isIBGProduct && ibgMFDisabled">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="
                        proposal!.qsMaster
                          .qs_machinery_uploadEquipmentDescription!
                      "
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>

              <div class="col-md-6" *ngIf="isIBGProduct && ibgMFDisabled">
                <form role="form ">
                  <div
                    class="form-group"
                    id="qs_machinery_equipment"
                    [ngClass]="{
                      'st-danger':
                        proposal!.qsMaster.qs_machinery_equipment!.theme === 1
                    }"
                  >
                    <label
                      class="fontSize14px"
                      for="{{ proposal!.qsMaster.qs_machinery_equipment!.key }}
                        "
                      [ngClass]="{
                        required:
                          proposal!.qsMaster.qs_machinery_equipment!.required &&
                          !proposal!.qsMaster.qs_machinery_equipment!.readOnly
                      }"
                      [textContent]="
                        proposal!.qsMaster.qs_machinery_equipment!.displayText
                      "
                    ></label>
                  </div>
                </form>
              </div>

              <div class="col-md-12" *ngIf="isIBGProduct && ibgMFDisabled">
                <app-mf-sg-equipment-listing
                  [equipmentData]="proposal.qsMaster.qs_machinery_equipment"
                  [proposal]="proposal"
                  [masterPolicyQuestion]="
                    proposal.qsMaster.qs_master_masterPolicyNumber
                  "
                  [equipmentDropdownOptions]="equipmentDropdownOptions"
                  (equipmentDataOnCreate)="equipmentData_OnCreate($event)"
                  (equipmentDataOnUpdate)="equipmentData_OnUpdate($event)"
                  (equipmentDataOnDestroy)="equipmentData_OnDestroy($event)"
                  [clearEquipmentListing]="clearEquipmentListing"
                ></app-mf-sg-equipment-listing>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3" *ngIf="isIBGProduct">
                <form role="form ">
                  <div class="form-group">
                    <yes-no-radio-question
                      [question]="proposal!.qsMaster.qs_master_lst!"
                    >
                    </yes-no-radio-question>
                  </div>
                </form>
              </div>
            </div>
            <!--LST-->
            <div
              class="row"
              *ngIf="
                isIBGProduct &&
                proposal!.qsMaster.qs_master_lst!.answer === '1' &&
                proposal!.isUnderwriter
              "
            >
              <div class="col-md-12">
                <form role="form ">
                  <div class="form-group" id="qs_master_lstDetails">
                    <label
                      class="fontSize14px"
                      for="{{ proposal!.qsMaster.qs_master_lstDetails!.key }}
                                                "
                      [ngClass]="{
                        required:
                          proposal!.qsMaster.qs_master_lstDetails!.required &&
                          !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                      }"
                      [textContent]="
                        proposal!.qsMaster.qs_master_lstDetails!.displayText
                      "
                    ></label>
                  </div>
                </form>
              </div>
              <div class="col-md-12">
                <table class="table table-bordered table-sm" id="sum_LSTtable">
                  <thead>
                    <tr class="chubbLightGray_bg titlerow">
                      <th class="fontBold" style="width: 13%">
                        R/I - CO / Account
                      </th>
                      <th class="fontBold">Reference</th>
                      <!--02/11/2018 Chirag : Modified LST lable details -->
                      <th class="textAlignCenter fontBold" style="width: 13%">
                        Ceded Premium %
                      </th>
                      <th class="textAlignCenter fontBold" style="width: 13%">
                        Ceded Premium Amount
                      </th>
                      <th class="textAlignCenter fontBold" style="width: 13%">
                        Ceding RI Comm. %
                      </th>
                      <th class="textAlignCenter fontBold" style="width: 13%">
                        Ceding RI Comm. Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type="text"
                          class="k-textbox form-control"
                          [(ngModel)]="lstDefaultValue[0]['account']"
                          (blur)="updateLSTDetails()"
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        />
                        <span
                          [textContent]="lstDefaultValue[0]['account']"
                          class="wordBreak"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td>
                        <input
                          type="text"
                          class="k-textbox form-control"
                          [(ngModel)]="lstDefaultValue[0]['reference']"
                          (blur)="updateLSTDetails()"
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        />
                        <span
                          [textContent]="lstDefaultValue[0]['reference']"
                          class="wordBreak"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <kendo-numerictextbox
                            class="textAlignRight rowDataSd textAlignRight"
                            [format]="'n3'"
                            [spinners]="false"
                            [decimals]="3"
                            [min]="0"
                            (blur)="
                              updateLSTDetails(
                                this,
                                '0',
                                'percent1',
                                'lst_0_percent1',
                                3
                              )
                            "
                            [(ngModel)]="lstDefaultValue[0]['percent1']"
                            id="lst_0_percent1"
                            name="lst_0_percent1"
                            #lst_0_percent1
                          >
                          </kendo-numerictextbox>
                        </div>

                        <span
                          [textContent]="
                            lstDefaultValue[0]['percent1'] | percentNoSymbol : 4
                          "
                          class="wordBreak rowDataSd textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <kendo-numerictextbox
                            class="textAlignRight paddingRight5"
                            [spinners]="false"
                            [(ngModel)]="lstDefaultValue[0]['amount1']"
                            (blur)="
                              updateLSTDetails(
                                this,
                                '0',
                                'amount1',
                                'lst_0_amount1',
                                2
                              )
                            "
                            id="lst_0_amount1"
                            name="lst_0_amount1"
                            #lst_0_amount1
                          >
                          </kendo-numerictextbox>
                          <!--k-format="'c2'" k-min="0"-->
                        </div>

                        <span
                          [textContent]="
                            lstDefaultValue[0]['amount1'] | ausCurrency : 2
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <kendo-numerictextbox
                            class="textAlignRight paddingRight5"
                            [spinners]="false"
                            [format]="'n3'"
                            [decimals]="3"
                            [min]="0"
                            (blur)="
                              updateLSTDetails(
                                this,
                                '0',
                                'percent2',
                                'lst_0_percent2',
                                3
                              )
                            "
                            [(ngModel)]="lstDefaultValue[0]['percent2']"
                            id="lst_0_percent2"
                            name="lst_0_percent2"
                            #lst_0_percent2
                          >
                          </kendo-numerictextbox>
                        </div>

                        <span
                          [textContent]="
                            lstDefaultValue[0]['percent2'] | percentNoSymbol : 2
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <kendo-numerictextbox
                            class="textAlignRight paddingRight5"
                            [spinners]="false"
                            [(ngModel)]="lstDefaultValue[0]['amount2']"
                            (blur)="
                              updateLSTDetails(
                                this,
                                '0',
                                'amount2',
                                'lst_0_amount2',
                                2
                              )
                            "
                            id="lst_0_amount2"
                            name="lst_0_amount2"
                            #lst_0_amount2
                          >
                          </kendo-numerictextbox>
                        </div>
                        <!--k-format="'c2'" k-min="0"-->

                        <span
                          [textContent]="
                            lstDefaultValue[0]['amount2'] | ausCurrency : 2
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="text"
                          class="k-textbox form-control"
                          [(ngModel)]="lstDefaultValue[1]['account']"
                          (blur)="updateLSTDetails()"
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        />
                        <span
                          [textContent]="lstDefaultValue[1]['account']"
                          class="wordBreak"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td>
                        <input
                          type="text"
                          class="k-textbox form-control"
                          [(ngModel)]="lstDefaultValue[1]['reference']"
                          (blur)="updateLSTDetails()"
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        />
                        <span
                          [textContent]="lstDefaultValue[1]['reference']"
                          class="wordBreak"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <kendo-numerictextbox
                            class="textAlignRight rowDataSd paddingRight5"
                            [spinners]="false"
                            [format]="'n3'"
                            [decimals]="3"
                            [min]="0"
                            (blur)="
                              updateLSTDetails(
                                this,
                                '1',
                                'percent1',
                                'lst_1_percent1',
                                3
                              )
                            "
                            [(ngModel)]="lstDefaultValue[1]['percent1']"
                            id="lst_1_percent1"
                            name="lst_1_percent1"
                            #lst_1_percent1
                          >
                          </kendo-numerictextbox>
                        </div>

                        <span
                          [textContent]="
                            lstDefaultValue[1]['percent1'] | percentNoSymbol : 4
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <kendo-numerictextbox
                            class="textAlignRight paddingRight5"
                            [spinners]="false"
                            [(ngModel)]="lstDefaultValue[1]['amount1']"
                            (blur)="
                              updateLSTDetails(
                                $event,
                                '1',
                                'amount1',
                                'lst_1_amount1',
                                2
                              )
                            "
                            id="lst_1_amount1"
                            name="lst_1_amount1"
                            #lst_1_amount1
                          >
                          </kendo-numerictextbox>
                        </div>
                        <!--k-format="'c2'" k-min="0"-->

                        <span
                          [textContent]="
                            lstDefaultValue[1]['amount1'] | ausCurrency : 2
                          "
                          class="wordBreak"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <kendo-numerictextbox
                            class="textAlignRight paddingRight5"
                            [spinners]="false"
                            [format]="'n3'"
                            [decimals]="3"
                            [min]="0"
                            (blur)="
                              updateLSTDetails(
                                $event,
                                '1',
                                'percent2',
                                'lst_1_percent2',
                                3
                              )
                            "
                            [(ngModel)]="lstDefaultValue[1]['percent2']"
                            id="lst_1_percent2"
                            name="lst_1_percent2"
                            #lst_1_percent2
                          >
                          </kendo-numerictextbox>
                        </div>

                        <span
                          [textContent]="
                            lstDefaultValue[1]['percent2'] | percentNoSymbol : 2
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <kendo-numerictextbox
                            class="textAlignRight paddingRight5"
                            [spinners]="false"
                            [(ngModel)]="lstDefaultValue[1]['amount2']"
                            (blur)="
                              updateLSTDetails(
                                $event,
                                '1',
                                'amount2',
                                'lst_1_amount2',
                                2
                              )
                            "
                            id="lst_1_amount2"
                            name="lst_1_amount2"
                            #lst_1_amount2
                          >
                          </kendo-numerictextbox>
                        </div>
                        <!--k-format="'c2'" k-min="0"-->
                        <span
                          [textContent]="
                            lstDefaultValue[1]['amount2'] | ausCurrency : 2
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="text"
                          class="k-textbox form-control"
                          [(ngModel)]="lstDefaultValue[2]['account']"
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                          (blur)="updateLSTDetails()"
                        />
                        <span
                          [textContent]="lstDefaultValue[2]['account']"
                          class="wordBreak"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td>
                        <input
                          type="text"
                          class="k-textbox form-control"
                          [(ngModel)]="lstDefaultValue[2]['reference']"
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                          (blur)="updateLSTDetails()"
                        />
                        <span
                          [textContent]="lstDefaultValue[2]['reference']"
                          class="wordBreak"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <kendo-numerictextbox
                            class="textAlignRight rowDataSd paddingRight5"
                            [spinners]="false"
                            [format]="'n3'"
                            [decimals]="3"
                            [min]="0"
                            (blur)="
                              updateLSTDetails(
                                $event,
                                '2',
                                'percent1',
                                'lst_2_percent1',
                                3
                              )
                            "
                            [(ngModel)]="lstDefaultValue[2]['percent1']"
                            id="lst_2_percent1"
                            name="lst_2_percent1"
                            #lst_2_percent1
                          >
                          </kendo-numerictextbox>
                        </div>

                        <span
                          [textContent]="
                            lstDefaultValue[2]['percent1'] | percentNoSymbol : 4
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <kendo-numerictextbox
                            class="textAlignRight paddingRight5"
                            [spinners]="false"
                            [(ngModel)]="lstDefaultValue[2]['amount1']"
                            (blur)="
                              updateLSTDetails(
                                $event,
                                '2',
                                'amount1',
                                'lst_2_amount1',
                                2
                              )
                            "
                            id="lst_2_amount1"
                            name="lst_2_amount1"
                            #lst_2_amount1
                          >
                          </kendo-numerictextbox>
                        </div>
                        <!--k-format="'c2'" k-min="0"-->
                        <span
                          [textContent]="
                            lstDefaultValue[2]['amount1'] | ausCurrency : 2
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <kendo-numerictextbox
                            class="textAlignRight paddingRight5"
                            [spinners]="false"
                            [format]="'n3'"
                            [decimals]="3"
                            [min]="0"
                            (blur)="
                              updateLSTDetails(
                                $event,
                                '2',
                                'percent2',
                                'lst_2_percent2',
                                3
                              )
                            "
                            [(ngModel)]="lstDefaultValue[2]['percent2']"
                            id="lst_2_percent2"
                            name="lst_2_percent2"
                            #lst_2_percent2
                          >
                          </kendo-numerictextbox>
                        </div>

                        <span
                          [textContent]="
                            lstDefaultValue[2]['percent2'] | percentNoSymbol : 2
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <kendo-numerictextbox
                            class="textAlignRight paddingRight5"
                            [spinners]="false"
                            [(ngModel)]="lstDefaultValue[2]['amount2']"
                            (blur)="
                              updateLSTDetails(
                                $event,
                                '2',
                                'amount2',
                                'lst_2_amount2',
                                2
                              )
                            "
                            id="lst_2_amount2"
                            name="lst_2_amount2"
                            #lst_2_amount2
                          >
                          </kendo-numerictextbox>
                        </div>
                        <!--k-format="'c2'" k-min="0"-->
                        <span
                          [textContent]="
                            lstDefaultValue[2]['amount2'] | ausCurrency : 2
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="text"
                          class="k-textbox form-control"
                          [(ngModel)]="lstDefaultValue[3]['account']"
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                          (blur)="updateLSTDetails()"
                        />
                        <span
                          [textContent]="lstDefaultValue[3]['account']"
                          class="wordBreak"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td>
                        <input
                          type="text"
                          class="k-textbox form-control"
                          [(ngModel)]="lstDefaultValue[3]['reference']"
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                          (blur)="updateLSTDetails()"
                        />
                        <span
                          [textContent]="lstDefaultValue[3]['reference']"
                          class="wordBreak"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <kendo-numerictextbox
                            class="textAlignRight rowDataSd paddingRight5"
                            [spinners]="false"
                            [format]="'n3'"
                            [decimals]="3"
                            [min]="0"
                            (blur)="
                              updateLSTDetails(
                                $event,
                                '3',
                                'percent1',
                                'lst_3_percent1',
                                3
                              )
                            "
                            [(ngModel)]="lstDefaultValue[3]['percent1']"
                            id="lst_3_percent1"
                            name="lst_3_percent1"
                            #lst_3_percent1
                          >
                          </kendo-numerictextbox>
                        </div>
                        <span
                          [textContent]="
                            lstDefaultValue[3]['percent1'] | percentNoSymbol : 4
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <kendo-numerictextbox
                            class="textAlignRight paddingRight5"
                            [spinners]="false"
                            [(ngModel)]="lstDefaultValue[3]['amount1']"
                            (blur)="
                              updateLSTDetails(
                                $event,
                                '3',
                                'amount1',
                                'lst_3_amount1',
                                2
                              )
                            "
                            id="lst_3_amount1"
                            name="lst_3_amount1"
                            #lst_3_amount1
                          >
                          </kendo-numerictextbox>
                        </div>
                        <!--k-format="'c2'" k-min="0"-->
                        <span
                          [textContent]="
                            lstDefaultValue[3]['amount1'] | ausCurrency : 2
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <kendo-numerictextbox
                            class="textAlignRight paddingRight5"
                            [spinners]="false"
                            [min]="0"
                            (blur)="
                              updateLSTDetails(
                                $event,
                                '3',
                                'percent2',
                                'lst_3_percent2',
                                3
                              )
                            "
                            [(ngModel)]="lstDefaultValue[3]['percent2']"
                            id="lst_3_percent2"
                            name="lst_3_percent2"
                            #lst_3_percent2
                          >
                          </kendo-numerictextbox>
                        </div>
                        <!--k-format="'n3'" k-decimals="3"-->
                        <span
                          [textContent]="
                            lstDefaultValue[3]['percent2'] | percentNoSymbol : 2
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <kendo-numerictextbox
                            class="textAlignRight paddingRight5"
                            [spinners]="false"
                            [(ngModel)]="lstDefaultValue[3]['amount2']"
                            (blur)="
                              updateLSTDetails(
                                $event,
                                '3',
                                'amount2',
                                'lst_3_amount2',
                                2
                              )
                            "
                            id="lst_3_amount2"
                            name="lst_3_amount2"
                            #lst_3_amount2
                          >
                          </kendo-numerictextbox>
                        </div>
                        <!--k-format="'c2'" k-min="0"-->
                        <span
                          [textContent]="
                            lstDefaultValue[3]['amount2'] | ausCurrency : 2
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                    </tr>
                    <!--<tr  class="totalColumn">
                                                <td class="fontBold">Total</td>
                                                <td class="textAlignCenter fontBold">-</td>
                                                <td class="textAlignCenter fontBold totalCol" />
                                                <td class="textAlignCenter fontBold totalCol" />
                                                <td class="textAlignCenter fontBold">-</td>
                                                <td class="textAlignCenter fontBold totalCol" />
                                            </tr>-->
                  </tbody>
                </table>
              </div>
            </div>
            <!--End LST-->
          </div>
        </kendo-expansionpanel>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 card card-mod cardNoBorder ng-scope">
        <kendo-expansionpanel
          id="broker_panel"
          title="Other Information"
          [expanded]="true"
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <label
                      attr.for="{{
                        proposal!.qsMaster.qs_master_insuredName!.key
                      }} "
                      class="textDecoUnderline fontSize14px"
                      [textContent]="'Other Information'"
                    ></label>
                  </div>
                </form>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="proposal!.qsMaster.qs_fire_excess!"
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="proposal!.qsMaster.qs_fire_generalPage!"
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-area-question
                      [question]="proposal!.qsMaster.qs_fire_businessDesc!"
                    >
                    </text-area-question>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </kendo-expansionpanel>
      </div>
    </div>
  </div>
</div>
