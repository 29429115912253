export class ProductID {
    public static readonly PRODUCT_ID_AUS_BUSINESSTRAVEL = "8391C36C-14C8-40E8-90C0-4F8B0D475247";
    public static readonly PRODUCT_ID_MY_FLEXIGUARD = "1D6AE343-F479-43FE-BC6A-5A22803B3DA7";
    public static readonly PRODUCT_ID_MY_MYHOME = "766EA862-DF3D-4A83-8E6C-3128813956B9";
    public static readonly PRODUCT_ID_MY_GCN = "90498EBA-2CC6-4E6F-A468-2EE2CD46B1A8";
    public static readonly PRODUCT_ID_MY_FOREIGN_MAID = "31B5B1AF-887E-4EE1-8C68-042FF8FF50E5";

    //DBS
    public static readonly PRODUCT_ID_DBS_MORTGAGEINTERESTPLUS = "565E9075-9F73-45C0-BA5C-A78AC05BA837";
    public static readonly PRODUCT_ID_DBS_HOUSEOWNERPLUSPTE = "6FAF6BEE-F5FC-4DC2-9F75-37FE2D61611D";
    public static readonly PRODUCT_ID_DBS_HOUSEOWNERPLUSHDB = "2396885E-49F6-43F6-A314-47D727AB888F";
    public static readonly PRODUCT_ID_DBS_MF_IBG = "06CB09D3-6FE0-4695-92A8-A61DFB627C61";
    public static readonly PRODUCT_ID_DBS_MACH_IBG = "8533FA05-F190-4A29-9613-3DB7F693DD32";
    public static readonly PRODUCT_ID_DBS_HK_CBG = "944439AA-4C28-4A69-999E-0731DE0C795A";
    public static readonly PRODUCT_ID_DBS_HK_IBG = "CC290DC8-A0BE-4818-9557-C2B725A3F4F8";
    public static readonly PRODUCT_ID_CITI_HOUSEOWNERPLUSHDB = "A8CDAC65-3EC4-4138-974A-D18C3EF8536C";
    public static readonly PRODUCT_ID_CITI_MORTGAGEFIREINSURANCEPTE = "1F7EE805-C8CE-416C-AF29-292E920878F8";
    public static readonly PRODUCT_ID_CITI_MORTGAGEFIREINSURANCECONDO = "B8A348CA-491C-48DA-988A-FCC52F7FC5C5";

    //AML
    public static readonly PRODUCT_ID_AUS_MANAGEMENTLIABILITY = "F64A7D46-18F4-421A-931E-43B7FC198CD4";
    public static readonly AUS_MANAGEMENT_LIABILITY_PANEL_ID_EMPLOYMENT_PRACTICES = "2934c8ee-4185-4147-9b0e-a9d54c0a6e68";
    public static readonly AUS_MANAGEMENT_LIABILITY_PANEL_ID_CRIME = "0e7feac7-2598-4eb2-9eab-67c6ba739c07";
    public static readonly AUS_MANAGEMENT_LIABILITY_PANEL_ID_CYBER = "275ab1e7-9706-419e-88bc-d927cfdd04e3";
    public static readonly AUS_MANAGEMENT_LIABILITY_PANEL_ID_STATUTORY = "246c1462-da73-4c4a-a4ed-8ad80f95de36";
    public static readonly AUS_MANAGEMENT_LIABILITY_PANEL_ID_INTERNET = "f092df46-6be6-4883-9b38-589ed119b7ce";
    public static readonly AUS_MANAGEMENT_LIABILITY_PANEL_ID_PROFESSIONAL_INDEMNITY = "0053d614-39bd-46b7-87ee-6f3442e2b998";
    public static readonly AUS_MANAGEMENT_LIABILITY_PANEL_ID_KIDNAP_RANSOM = "b7a2c22b-9451-4e5c-bc8c-17915968d591";
    public static readonly AUS_MANAGEMENT_LIABILITY_PANEL_ID_DIRECTORS_OFFICERS = "98d7da05-1e58-47de-a365-bc3ebc5cce38";
    public static readonly AUS_MANAGEMENT_LIABILITY_PANEL_ID_TRUSTEES = "1a452cf9-d6fe-4406-9a7a-8709aa18d05b";
    public static readonly AUS_MANAGEMENT_LIABILITY_PANEL_ID_MAIN_LOSS_HISTORY = "2dbc6e4b-e349-4959-be23-99cb1456564f";
    public static readonly AUS_MANAGEMENT_LIABILITY_PANEL_ID_MAIN_INSURED_DETAILS = "5c4e6cfa-1ab0-4b42-ab61-ce0eacb0d7d1";
    public static readonly AUS_MANAGEMENT_LIABILITY_PANEL_ID_PREMIUM_SUMMARY = "a1dd5540-a441-4a99-8c19-1b11d433f178";
    public static readonly AUS_MANAGEMENT_LIABILITY_PANEL_ID_QUICK_QUOTE = "cd56c438-2bb7-45fa-ad62-fc07171eae91";
    public static readonly AUS_MANAGEMENT_LIABILITY_PANEL_ID_ENDORSEMENT = "b2cc743f-53e0-4a2a-8851-440a1dd9954b";
    public static readonly AUS_MANAGEMENT_LIABILITY_PANEL_ID_GTC = "94002426-81f7-4ee9-b963-4b4921184b0c";
    public static readonly AUS_MANAGEMENT_LIABILITY_PANEL_ID_LIMITS_DEDUCTIBLES = "1ced21b7-dddc-4d43-bd81-5917f0abf9b9";

    // Australia Biz pack
    public static readonly PRODUCT_ID_AUS_BizPack = "f6c1bcb1-d045-4db3-b947-0e50fe0d49eb";

    public static readonly PROPOSAL_ID_DEFAULT = "00000000-0000-0000-0000-000000000000";
    public static readonly AUS_BIZ_PACK_PANEL_ID_FIRE = "ea88562b-c749-4bdf-b077-0a02505bd87c";
    public static readonly AUS_BIZ_PACK_PANEL_ID_ENVIRONMENTALLIABILITY = "5ccfd6c5-fe27-4df8-908c-e74fc7878e84";
    public static readonly AUS_BIZ_PACK_PANEL_ID_MONEY = "b4a2abdf-555a-48de-8f0a-d68bad994de3";
    public static readonly AUS_BIZ_PACK_TAB_ID_BUSINESSINTERRUPTION = "2d01a81c-b8e1-4057-8b96-3a66254cfa1a";
    public static readonly AUS_BIZ_PACK_TAB_ID_GENERALPROPERTY = "550f2832-fd52-4b05-9343-15d4a6d7e511";
    public static readonly AUS_BIZ_PACK_PANEL_ID_PUBLIC_PRODUCTSLIABILITY = "ddc1b159-7887-43d6-8e2c-8f57df0592a0";
    public static readonly AUS_BIZ_PACK_PANEL_ID_CYBER = "bf70ff16-e74c-4486-bcb8-db5638455270";
    public static readonly AUS_BIZ_PACK_PANEL_ID_GLASS = "dd2ee977-f692-4c83-8508-23f515840cfe";
    public static readonly AUS_BIZ_PACK_TAB_ID_TAX_AUDIT = "8be8ff3a-304b-408c-a072-482707ef4753";
    public static readonly AUS_BIZ_PACK_TAB_ID_ELECTRONIC_EQUIPMENT_BREAKDOWN = "3ce4c32a-f3e3-4d8c-86eb-c276b3530c54";
    public static readonly AUS_BIZ_PACK_TAB_ID_MACHINERY_BREAKDOWN = "bb6b5188-7489-4b76-9521-d2dc3db68a09";
    public static readonly AUS_BIZ_PACK_PANEL_ID_THEFT = "48e1a383-f05c-49e0-9f6c-1d4e9ca273f1";
    public static readonly AUS_BIZ_PACK_TAB_ID_MAIN = "5c4bec68-94b7-4d7f-b5d6-f7003d1748cf";
    public static readonly AUS_BIZ_PACK_TAB_ID_POST_QUOTE = "2a1a23ef-491a-42c4-8ab7-5d9f96954940";
    public static readonly AUS_BIZ_PACK_TAB_ID_ENDORSEMENT = "4afa15a3-3a49-4c34-8cfe-b7b8e1989da1";
    public static readonly AUS_BIZ_PACK_PANEL_ID_LOCATION_DETAILS = "d1e37920-a49c-4a2e-81f8-6f8df228ef17";
    public static readonly AUS_BIZ_PACK_PANEL_ID_PREMIUM_SUMMARY = "f3ec2091-edec-49d1-ba24-69000c6dc116";
    //HongKong EC
    public static readonly PRODUCT_ID_HK_EC = "1ccfa1a3-23bd-4afd-9df5-d88687c3254c";
}

export class UserRoles {
    public static readonly Underwriter_Role: string = "Underwriter_Role";
    public static readonly Dashboard_View: string = "Dashboard_View";

    public static readonly RulesExtension_View: string = "RulesExtension_View";
    public static readonly SalesPlanAcess_View: string = "SalesPlanAcess_View";
    public static readonly Campaigns_View: string = "Campaigns_View";
}

export class EndorsementTypes {
    public static readonly GeneralEndorsement = "6459191a-1f2b-4447-8a44-24b56bfe756c";
    public static readonly ExtensionofPeriod = "8c2694ea-4d73-4b02-8030-daee453fc9ac";
}