/** study materials

https://stackoverflow.com/questions/3635754/sql-select-between-exact-number-of-rows

select * from
    (select Row_Number() over
     (order by userID) as RowIndex, * from users) as Sub
    Where Sub.RowIndex >= 235 and Sub.RowIndex <= 250
 */

export class SearchCriteria {
  constructor() {
    // Backend does not accept 'undefined'.
    // Hence initialize to empty string.
    this.quoteNo = "";
    this.policyNo = "";
    this.masterPolicyId = "";
    this.cin = "";
    this.insuredName = "";
    this.additionalInsuredName = "";
    this.bcrn = "";
    this.riskAddressPostalCode = "";
    this.productIds = new Array<string>();
    this.productId = new Array<string>();
    this.proposalStatus = new Array<number>();
    this.bankReferenceNo = "";
    this.createdByUserId = 0;
    // this.startDateFrom = null;
    // this.startDateTo = null;
    // this.expiryDateFrom = null;
    // this.expiryDateTo = null;
    // this.effectiveDateFrom = null;
    // this.effectiveDateTo = null;
    // this.submissionDateFrom = null;
    // this.submissionDateTo = null;
    // this.approvalDateFrom = null;
    // this.approvalDateTo = null;
    this.segment = new Array<string>();
    this.segmentDetails = "";
    // this.boundDateFrom = null;
    // this.boundDateTo = null;
    this.userTeam = new Array<number>();
    this.transType = 0;
    this.month = 0;
    this.year = 0;
    this.policyStatus = "";
    this.postalCode = "";
    this.MSIGNo = "";
    this.channelTypeID = 0;
    this.dateSearchType = "";
    this.reportType = "";
    this.productRadio = "";
    this.transactionType = new Array<string>();
    //this.ADWeek = null;
    this.productRadio = "upload";
  }

  // Taking Rows 40 - 49
  // From = 4 * 10
  // To = ((4 + 1) * 10) - 1
  // Taking Rows 40 - 49
  // From = 4 * 10
  // To = ((4 + 1) * 10) - 1
  pageNo!: number; // 4
  // 4
  // 4
  pageSize!: number; // 10
  // 10
  // sortField = 'quoteNo'
  // sortField = 'quoteNo'
  sortField!: string;

  // dbo.Proposal.CustomerIdentificationNumber1 (NRIC, Business Registration No)
  // dbo.Proposal.CustomerIdentificationNumber2 (Passport No)

  quoteNo: string;
  policyNo: string;
  cin: string;
  insuredName: string;
  additionalInsuredName: string;
  bcrn: string;
  riskAddressPostalCode: string;
  masterPolicyId: string;
  productId: Array<string>;
  productIds: Array<string>;
  proposalStatus: Array<number>;
  bankReferenceNo: string;
  createdByUserId: number;
  startDateFrom?: Date;
  startDateTo?: Date;
  expiryDateFrom?: Date;
  expiryDateTo?: Date;
  effectiveDateFrom?: Date;
  effectiveDateTo?: Date;
  submissionDateFrom?: Date;
  submissionDateTo?: Date;
  approvalDateFrom?: Date;
  approvalDateTo?: Date;
  segment: Array<string>;
  segmentDetails: string;
  boundDateFrom?: Date;
  boundDateTo?: Date;
  userTeam: Array<number>;
  transType: number;
  month: number;
  year: number;
  policyStatus: string;
  postalCode: string;
  MSIGNo: string;
  channelTypeID: number;
  dateSearchType: string;
  reportType: string;
  productRadio: string;
  ADWeek?: Date;
  transactionType: Array<string>;
}
