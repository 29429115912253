import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "percentage",
})
export class PercentagePipe extends DecimalPipe implements PipeTransform {
  override transform(value: any, decimal?: any, symbol?: any): any {
    /* https://angular.io/api/common/PercentPipe
      
    {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}.

    minIntegerDigits: The minimum number of integer digits before the decimal point. Default is 1.
    minFractionDigits: The minimum number of digits after the decimal point. Default is 0.
    maxFractionDigits: The maximum number of digits after the decimal point. Default is 0.
    Optional. Default is undefined.

    */
      let fractionSize = "0.0-0"; //"1.2-2";
      const percentageValue = value; //number
      const stringValue = percentageValue.toString();
      if (stringValue.includes(".")) {
          let valueAfterDecimal = stringValue.split(".")[1];
          if (parseInt(valueAfterDecimal) !== 0) {
              fractionSize = "1.2-4";
          }
      }
      let formatedByCurrencyPipe = super.transform(value, fractionSize);
      return formatedByCurrencyPipe + " %";
  }
}
