import { Injectable, ViewContainerRef } from "@angular/core";
import { NotificationService } from "@progress/kendo-angular-notification";

type HorizontalPosition = "left" | "center" | "right";
type VerticalPosition = "top" | "bottom";

@Injectable({
  providedIn: "root",
})
export class NotifierService {
  constructor(private notificationService: NotificationService) {}

  public horizontalPos: HorizontalPosition = "center";
  public verticalPos: VerticalPosition = "top";

  success(text: string, appendToVCR: ViewContainerRef) {
    this.notificationService.show({
      appendTo: appendToVCR,
      content: text,
      hideAfter: 1500,
      position: { horizontal: this.horizontalPos, vertical: this.verticalPos },
      animation: { type: "fade", duration: 400 },
      type: { style: "success", icon: true },
    });
  }

  info(text: string, appendToVCR: ViewContainerRef) {
    this.notificationService.show({
      appendTo: appendToVCR,
      content: text,
      hideAfter: 1500,
      position: { horizontal: this.horizontalPos, vertical: this.verticalPos },
      animation: { type: "fade", duration: 2000 },
      type: { style: "info", icon: true },
    });
  }

  error(text: string, appendToVCR: ViewContainerRef) {
    this.notificationService.show({
      appendTo: appendToVCR,
      content: text,
      hideAfter: 1500,
      position: { horizontal: this.horizontalPos, vertical: this.verticalPos },
      animation: { type: "fade", duration: 2000 },
      type: { style: "error", icon: true },
    });
  }
}
