import { Component, OnInit } from "@angular/core";
import {
  FileInfo,
  FileRestrictions,
  RemoveEvent,
  SelectEvent,
} from "@progress/kendo-angular-upload";
import { UploadExcelService } from "../../../services/upload-excel.service";
import { ExcelService } from "../../../services/excel.service";
import { dlgModelArgs } from "../../shared/partials/md-successful-file-upload/md-successful-file-upload.component";
import { Global } from "../../../shared/global";
import { UploadExcel } from "../auto-loan-redemption/auto-loan-redemption.entity";
import { ActivatedRoute, Router } from "@angular/router";
import { ROUTE_HOME } from "../../../app.module";
import { SearchCriteria } from "../../new-product/home/base/search-criteria";
import { PresetExportService } from "src/app/services/preset-export.service";
import { BaseUploadComponent } from "../../accounting/base-upload/base-upload.component";
import { ExcelTemplateService } from "src/app/services/excel-template.service";

@Component({
  selector: "app-cbg-sg-upload",
  templateUrl: "./cbg-sg-upload.component.html",
  styleUrls: ["./cbg-sg-upload.component.scss"],
})
export class CbgSgUploadComponent
  extends BaseUploadComponent
  implements OnInit
{
  public searchCriteria = new SearchCriteria();
  private presetExportService: PresetExportService | undefined;

  constructor(
    public override uploadService: UploadExcelService,
    public override router: Router,
    public override route: ActivatedRoute,
    public override excelTemplate: ExcelTemplateService,
    private excelService: ExcelService
  ) {
    super(uploadService, router, route, excelTemplate);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    //this would most likely override the base's Subscription, which is fine, for now
    this.sub = this.route.data.subscribe(
      (v) => (this.uploadExcel.segmentDetails = v["type"]) //read from route param, as in app.route.js
    );
  }

  getUploadedClaimFilesTotalSize(files: FileInfo[]): number {
    let totalFileSize: number = 0;

    files.forEach((file: FileInfo) => {
      if (file.rawFile) {
        totalFileSize += file.rawFile.size;
      }
    });

    return totalFileSize;
  }

  override add() {
    this.hasError = false;

    if (this.uploadExcel?.files?.length > 0) {
      this.getUploadedExcelFiles(this.uploadExcel);

      const fileName: string = this.uploadExcel.files[0].name;

      if (this.searchCriteria.productRadio === "upload") {
        if (
          fileName
            .toUpperCase()
            .startsWith("CHUBBIN3XXXX.CHUBB.RCLS.INCEPTION_")
        )
          this.addUploadExcel();
        else {
          this.excelFilesErrorMessage =
            "Wrong upload file name. Check for correct file name and upload.";
          this.hasError = true;
        }
      }

      if (this.searchCriteria.productRadio === "Reupload") {
        if (fileName.toUpperCase().startsWith("CLS.CHUBB.RCLS.INCEPTION_"))
          this.addReUploadExcel();
        else {
          this.excelFilesErrorMessage =
            "Wrong Reupload file name. Check for correct file name and upload.";
          this.hasError = true;
        }
      }
    } else {
      this.excelFilesErrorMessage = "At least one file needs to be uploaded.";
      this.hasError = true;
    }
  }

  ddmmyyyy(date: Date) {
    let yyyy = date.getFullYear();
    let mm =
      date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1; // getMonth() is zero-based
    let dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    return "".concat(String(dd)).concat(String(mm)).concat(String(yyyy));
  }

  addUploadExcel(): void {
    this.isProcessing = true;
    const uploadedExcelFormData = this.getUploadedExcelFiles(this.uploadExcel);
    // this.cbgCLSNewBusinessUploadService!.(this.uploadExcel).then(
    this.uploadService.uploadCbgSgUploadExcel(uploadedExcelFormData).subscribe(
      (result: any) => {
        let tempData: any = null;

        if (
          result != undefined &&
          result.body != undefined &&
          result.body != null
        ) {
          if (result.body.Status === false) {
            tempData = {
              message: result.body.Message,
              validationMessage: result.body.ValidationMessage,
              status: false,
            };
          } else {
            //only CBG needs to generate excel report at the moment
            if (this.uploadExcel.segmentDetails.toLowerCase() == "cbg") {
              let escapedJson = this.jsonEscape(result.body.CbgTable);
              tempData = {
                message: result.body.Message,
                status: true,
                CbgTable: JSON.parse(escapedJson),
              };

              let reportTitle: string;
              reportTitle = this.uploadExcel.files[0].name;
              this.excelTemplate.constructWorkbookSuccess(
                escapedJson,
                tempData.CbgTable,
                this.uploadExcel.segmentDetails,
                reportTitle
              );
            } else {
              //ibg
              tempData = {
                message: result.body.Message,
                status: true,
              };
            }
          }

          // Clear uploaded file once, the file is successfully uploaded.
          // this.uploadExcel.files = {};
          this.redirectOpened = true;
          this.redirectData = tempData;
          this.isProcessing = false;
        }
      },
      (error) => {
        // Prompt fail
        this.errorLogs.Logs = new Array<string>(); // Clear error logs
        this.errorLogs.Title = "Error";

        const log: string =
          Global.isDefined(error?.data?.ExceptionMessage) &&
          error.data.ExceptionMessage !== ""
            ? error.data.ExceptionMessage
            : "Failed to upload excel file. Please contact the system administrator.";

        this.errorLogs.Logs.push(log);
        this.isProcessing = false;
      }
    );
  }

  addReUploadExcel(): void {
    this.isProcessing = true;
    const uploadedExcelFormData = this.getUploadedExcelFiles(this.uploadExcel);
    this.uploadService
      .uploadCbgSgReUploadExcel(uploadedExcelFormData)
      .subscribe(
        (result: any) => {
          let tempData: any = null;
          if (
            result != undefined &&
            result.body != undefined &&
            result.body != null
          ) {
            if (result.body.Status === false) {
              tempData = {
                message: result.body.Message,
                validationMessage: result.body.ValidationMessage,
                status: false,
              };
            } else {
              //only CBG needs to generate excel report at the moment
              if (this.uploadExcel.segmentDetails.toLowerCase() == "cbg") {
                let escapedJson = this.jsonEscape(result.body.CbgTable);
                tempData = {
                  message: result.body.Message,
                  status: true,
                  CbgTable: JSON.parse(escapedJson),
                };

                let reportTitle: string;
                reportTitle = this.uploadExcel.files[0].name;
                this.excelTemplate.constructWorkbookSuccess(
                  escapedJson,
                  tempData.CbgTable,
                  this.uploadExcel.segmentDetails,
                  reportTitle
                );
              } else {
                //ibg
                tempData = {
                  message: result.body.Message,
                  status: true,
                };
              }
            }
            // Clear uploaded file once, the file is successfully uploaded.
            // this.uploadExcel.files = {};

            this.redirectOpened = true;
            this.redirectData = tempData;
            this.isProcessing = false;
          }
        },
        (error: any) => {
          // Prompt fail
          this.errorLogs.Logs = new Array<string>(); // Clear error logs
          this.errorLogs.Title = "Error";

          const log: string =
            Global.isDefined(error?.data?.ExceptionMessage) &&
            error.data.ExceptionMessage !== ""
              ? error.data.ExceptionMessage
              : "Failed to upload excel file. Please contact the system administrator.";

          this.errorLogs.Logs.push(log);
          this.isProcessing = false;
        }
      );
  }

  jsonEscape(str: { toString: () => string }) {
    return str
      .toString()
      .replace(/\n/g, "\\\\n")
      .replace(/\r/g, "\\\\r")
      .replace(/\t/g, "\\\\t");
  }

  confirmationModelForSuccessfulExcelUpload = (args: dlgModelArgs): void => {
    this.redirectOpened = false;
    if (args.status === "return") {
      this.router.navigate(["/" + ROUTE_HOME]);
    }
  };
}
