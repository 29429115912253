import * as Const from "./../../../../framework/const.shared";
import * as Enums from "./../../../../framework/enum.shared";

import { ExceptionManager } from "../../../../framework/utils/exception-manager";
import { IIdentity } from "./../../../../framework/interface.shared";
import { Identity } from "../../../../shared/user/identity";
import { ProposalEntity } from "../proposal-entity.model";
import { IdentityRolesService } from "./../../../../services/identity-roles.service";
import { Component, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
/** Service to track user current proposal activity */
export class ProposalEditableHelperService {
  constructor(public identityRolesService: IdentityRolesService) {}

  isReadOnly(proposal: ProposalEntity): boolean {
    /** Current login user is transaction read only user
     * It could be [Claims] or whatever read only user type
     */
    if (
      this.identityRolesService.getIdentity().isProposalTransactionReadOnly ===
      true
    ) {
      return true;
    }

    switch (proposal.proposalStatus) {
      case Enums.ProposalStatus.Renewed:
      case Enums.ProposalStatus.Endorsed:
      case Enums.ProposalStatus.Cancelled:
      case Enums.ProposalStatus.Accepted:
        // always read only
        return true;

      case Enums.ProposalStatus.Referred:
        return this.referred(proposal);

      // draft
      case Enums.ProposalStatus.None:
      case Enums.ProposalStatus.Incomplete:
        return this.incomplete(proposal);
      //DBSEP-2136
      case Enums.ProposalStatus.Declined:
        return this.declined(proposal);
      case Enums.ProposalStatus.PendingChecker:
        // always read only
        return true;

      case Enums.ProposalStatus.Approved:
        //DBSEP-2136
        if (
          proposal.productId?.toString().toUpperCase() ===
            Const.ProductID.PRODUCT_ID_DBS_MF_IBG ||
          proposal.productId?.toString().toUpperCase() ===
            Const.ProductID.PRODUCT_ID_DBS_MACH_IBG
        ) {
          return true;
        }

        ExceptionManager.error("Not to be expected to in [Approved] status");
    }

    // default to true for unhandled case
    return true;
  }

  private declined(proposal: ProposalEntity): boolean {
    switch (this.identityRolesService.getIdentity().currentUser().userType) {
      // only Non-operational Underwriter is able to view the declined button for IBG prodcut.
      case Enums.UserType.Underwriter:
        if (
          this.identityRolesService.getIdentity().role.isOperationUnderwriter &&
          (proposal.productId?.toString().toUpperCase() ===
            Const.ProductID.PRODUCT_ID_DBS_MF_IBG ||
            proposal.productId?.toString().toUpperCase() ===
              Const.ProductID.PRODUCT_ID_DBS_MACH_IBG)
        ) {
          return false;
        } else {
          return true;
        }
      default:
        return true;
    }
  }
  private referred(proposal: ProposalEntity): boolean {
    switch (this.identityRolesService.getIdentity().currentUser().userType) {
      // only Non-operational Underwriter is editable in referred mode
      case Enums.UserType.Underwriter:
        if (
          proposal.productId?.toString().toUpperCase() ===
            Const.ProductID.PRODUCT_ID_DBS_MF_IBG ||
          proposal.productId?.toString().toUpperCase() ===
            Const.ProductID.PRODUCT_ID_DBS_MACH_IBG
        ) {
          return true;
        } else {
          return false;
        }
      default:
        return true;
    }
  }

  private incomplete(proposal: ProposalEntity): boolean {
    // only Maker and Underwriter is editable in draft mode
    switch (this.identityRolesService.getIdentity().currentUser().userType) {
      case Enums.UserType.Underwriter:
        // only Underwriter is editable in referred mode
        return false;

      case Enums.UserType.Broker:
        if (this.identityRolesService.getIdentity().role.isMaker) {
          return false;
        } else {
          return true;
        }

      default:
        return true;
    }
  }
}
