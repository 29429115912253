import { Injectable } from "@angular/core";
import { DatePipe } from "@angular/common";
import {
  Workbook,
  WorkbookSheetColumn,
  WorkbookSheetRow,
  WorkbookSheetRowCell,
  WorkbookSheet,
} from "@progress/kendo-ooxml";
import { saveAs } from "@progress/kendo-file-saver";
import { OpenItemStatus } from "../finance/finance-enums";
import { ReconcilePoliciesDownloadEntity } from "../components/accounting/reconcile/models/reconcile-list.model";
import { OpenItemListingEntity } from "../components/accounting/open-item/OpenItemListingEntity";
import { PolicyWordingEntity } from "../components/product/policyWordingSetUp/policy-wording.entity";
import { reportHelper } from "./../../../src/app/models/new-product/utils/report.helper";

import {
  AutoUpdateEntity,
  BankTransEntity,
  CbgDailyDownloadEntity,
  IBGRenewalEntity,
  LoanRedemptionFailureEntity,
  LoanRedemptionSuccessEntity,
  LSTEntity,
  ManualPaidAutoDebitEntity,
  PrintDocumentEntity,
  RenewalDraftDeletionEntity,
  RenewalEntity,
  SummaryInvoiceAutoDebitEntity,
} from "../components/new-product/home/preset-report/preset-report.model";
import { openItemDisplayStatus } from "../finance/finance-constant";
import * as HomeConstant from "./../components/new-product/home/home-constant";

@Injectable({
  providedIn: "root",
})
export class ExcelTemplateService {
  constructor() {}
  public downloadTemplate(
    columns: string[],
    title: string,
    fileName: string
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns?.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.getFirstRowHeaders(columns),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  public downloadReconcilePolicies(
    columns: string[],
    title: string,
    fileName: string,
    data?: ReconcilePoliciesDownloadEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns?.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructReconcileRows(columns, data),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  public downloadDbsOpenItemPolicies(
    columns: string[],
    title: string,
    fileName: string,
    data?: OpenItemListingEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns?.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructOiDbsRows(columns, data),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  public downloadCitiOpenItemPolicies(
    columns: string[],
    title: string,
    fileName: string,
    data?: OpenItemListingEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns?.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructOiCitiRows(columns, data),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  public downloadPolicies(
    columns: string[],
    title: string,
    fileName: string,
    data?: PolicyWordingEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns?.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructPolicyRows(columns, data),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //download autoDebitExtractionReport
  public downloadautoDebitExtractionReport(
    columns: string[],
    title: string,
    fileName: string,
    data?: SummaryInvoiceAutoDebitEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns?.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructautoDebitExtractionReportRows(columns, data),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //download autoUpdateExtractionReport
  public downloadautoUpdateExtractionReport(
    columns: string[],
    title: string,
    fileName: string,
    data?: AutoUpdateEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns?.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructautoUpdateExtractionReportRows(columns, data),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //download banktrans-dbs report
  public downloadBankTransDBSReport(
    columns: string[],
    title: string,
    fileName: string,
    data?: BankTransEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructBankTransDBSReportRows(columns, data),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //download banktrans-citi report
  public downloadBankTransCITIReport(
    columns: string[],
    title: string,
    fileName: string,
    data?: BankTransEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructBankTransCITIReportRows(columns, data),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //download CitiMonthlyNewBusiness report
  public downloadCitiMonthlyNewBusinessReport(
    columns: string[],
    title: string,
    fileName: string,
    data?: BankTransEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructCitiMonthlyNewBusinessReportRows(columns, data),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //download CitiMonthlyActive report
  public downloadCitiMonthlyActiveReport(
    columns: string[],
    title: string,
    fileName: string,
    data?: BankTransEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructCitiMonthlyActiveReportRows(columns, data),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //download CitiMonthlyCancellation report
  public downloadCitiMonthlyCancellationReport(
    columns: string[],
    title: string,
    fileName: string,
    data?: BankTransEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructCitiMonthlyCancellationReportRows(columns, data),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //download dailycbgdownload report
  public downloadDailyCbgDownloadReport(
    columns: string[],
    title: string,
    fileName: string,
    data?: CbgDailyDownloadEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructDailyCbgDownloadReportRows(columns, data),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //downloadPrintDocumentReport
  public downloadPrintDocumentReport(
    columns: string[],
    title: string,
    fileName: string,
    data?: PrintDocumentEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructPrintDocumentReportRows(columns, data),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //downloadDataPostPrintReport
  public downloadDataPostPrintReport(
    columns: string[],
    title: string,
    fileName: string,
    data?: PrintDocumentEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructDataPostPrintReportRows(columns, data),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //downloadlstReport
  public downloadlstReport(
    columns: string[],
    title: string,
    fileName: string,
    data?: LSTEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructlstReportReportRows(columns, data),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //downloadIBGRenewalReport
  public downloadIBGRenewalReport(
    columns: string[],
    title: string,
    fileName: string,
    data?: IBGRenewalEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructIBGRenewalReportRows(columns, data),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //downloadCitiLoanRedemptionSuccessReport
  public downloadCitiLoanRedemptionSuccessReport(
    columns: string[],
    title: string,
    fileName: string,
    data?: LoanRedemptionSuccessEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructCitiLoanRedemptionSuccessReportRows(
            columns,
            data
          ),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //downloadCitiLoanRedemptionFailureReport
  public downloadCitiLoanRedemptionFailureReport(
    columns: string[],
    title: string,
    fileName: string,
    data?: LoanRedemptionFailureEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructCitiLoanRedemptionFailureReportRows(
            columns,
            data
          ),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //downloadDbsCbgLoanRedemptionSuccessReport
  public downloadDbsCbgLoanRedemptionSuccessReport(
    columns: string[],
    title: string,
    fileName: string,
    data?: LoanRedemptionSuccessEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructDbsCbgLoanRedemptionSuccessReportRows(
            columns,
            data
          ),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //downloadDbsCbgLoanRedemptionFailureReport
  public downloadDbsCbgLoanRedemptionFailureReport(
    columns: string[],
    title: string,
    fileName: string,
    data?: LoanRedemptionFailureEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructDbsCbgLoanRedemptionFailureReportRows(
            columns,
            data
          ),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //downloadDbsIbgLoanRedemptionSuccessReport
  public downloadDbsIbgLoanRedemptionSuccessReport(
    columns: string[],
    title: string,
    fileName: string,
    data?: LoanRedemptionSuccessEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructDbsIbgLoanRedemptionSuccessReportRows(
            columns,
            data
          ),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //downloadDbsIbgLoanRedemptionFailureReport
  public downloadDbsIbgLoanRedemptionFailureReport(
    columns: string[],
    title: string,
    fileName: string,
    data?: LoanRedemptionFailureEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructDbsIbgLoanRedemptionFailureReportRows(
            columns,
            data
          ),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //downloadManualPaidAutoDebitReport
  public downloadManualPaidAutoDebitReport(
    columns: string[],
    title: string,
    fileName: string,
    data?: ManualPaidAutoDebitEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructManualPaidAutoDebitReportRows(columns, data),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //downloadRenewalDraftReport
  public downloadRenewalDraftReport(
    columns: string[],
    title: string,
    fileName: string,
    data?: RenewalDraftDeletionEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructRenewalDraftReportRows(columns, data),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //downloadRenewalReport
  public downloadRenewalReport(
    columns: string[],
    title: string,
    fileName: string,
    data?: RenewalEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructRenewalReportRows(columns, data),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //cbg-cls-failure response
  public downloadFailureResponse(
    columns: string[],
    title: string,
    fileName: string,
    tempData: any,
    data: any,
    segmentType: string
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructFailureResponseRows(
            columns,
            tempData,
            data,
            segmentType
          ),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //cbg-cls-success response
  public downloadSuccessResponse(
    columns: string[],
    title: string,
    fileName: string,
    tempData: any,
    data: any,
    segmentType: string
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructSuccessResponseRows(
            columns,
            tempData,
            data,
            segmentType
          ),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  // creating and returning first row of the sheet
  private getFirstRowHeaders(columns: string[]): WorkbookSheetRow[] {
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    return <WorkbookSheetRow[]>[
      {
        cells: headerRowcell,
      },
    ];
  }
  // Column settings (width)
  private getColSetting(colLenght: number): WorkbookSheetColumn[] {
    let col: WorkbookSheetColumn[] = [];
    for (let i = 0; i < colLenght; i++) col.push({ autoWidth: true });
    return col;
  }
  //reconcile-construct rows
  private constructReconcileRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      rows.push({
        cells: [
          { value: data[i].serialNumber },
          { value: data[i].action },
          { value: data[i].meridianOpenItemSequenceNo },
          { value: data[i].policyCertificateNumber },
          { value: data[i].total },
          { value: data[i].gst },
          { value: OpenItemStatus[data[i].status] },
          { value: data[i].invoiceNo },
          { value: data[i].savedBy },
          { value: data[i].Id },
          { value: data[i].status },
        ],
      });
    }
    return rows;
  }
  //open-item-DBS-construct rows
  private constructOiDbsRows(columns: string[], data: any): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      rows.push({
        cells: [
          { value: data[i].meridianOpenItemSequenceNo },
          { value: data[i].policyCertificateNumber },
          { value: data[i].masterPolicy },
          { value: data[i].policyNo },
          {
            value: new DatePipe("en-US").transform(data[i].dateEntry, "short"),
          },
          { value: data[i].amount },
          { value: data[i].amountGst },
          { value: openItemDisplayStatus[data[i].statusId] },
          { value: data[i].invoiceNo },
          { value: data[i].lastUpdatedByUserName },
          { value: data[i].policyHolder },
          { value: data[i].correspondenceAddress },
          { value: data[i].inactiveAddressChanged },
          { value: data[i].grossPremium },
          { value: data[i].BUPC },
          { value: data[i].bankReference },
          { value: data[i].insuredName },
          { value: data[i].effectiveDate },
          { value: data[i].expiryDate },
          { value: data[i].sumInsured },
          { value: data[i].riskAddress },
          { value: data[i].insuredNRIC },
          { value: data[i].mobileNumber },
          { value: data[i].additionalInsured },
          { value: data[i].AutoDebitMonth },
          { value: data[i].CancellationType },
        ],
      });
    }
    if (data.length > 0) {
      rows.push({
        cells: [{ value: " " }],
      });
      rows.push({
        cells: [
          {
            value: "Summary of Open-Item",
            bold: true,
          },
        ],
      });
      rows.push({
        cells: [
          {
            value: "Total Items: ",
          },
          { value: data[0].totalOpenItemPolicy },
        ],
      });
      rows.push({
        cells: [
          { value: "Total Gross Premium excl. GST:" },
          { value: data[0].totalGrossPremium },
        ],
      });
      rows.push({
        cells: [{ value: "GST on Gross Premium:" }, { value: data[0].GST }],
      });
      rows.push({
        cells: [{ value: "Total Premium:" }, { value: data[0].totalPremium }],
      });
    }
    return rows;
  }

  public downloadBatchInvoices(
    columns: string[],
    title: string,
    fileName: string,
    data?: OpenItemListingEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns?.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructOiBatchInvoiceRows(columns, data),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }

  public downloadAllPolicies(
    columns: string[],
    title: string,
    fileName: string,
    data?: OpenItemListingEntity[]
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns?.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructOiDownloadAllPoliciesRows(columns, data),
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }

  //open-item-citi-construct rows
  private constructOiCitiRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      rows.push({
        cells: [
          { value: data[i].meridianOpenItemSequenceNo },
          { value: data[i].policyCertificateNumber },
          { value: data[i].masterPolicy },
          { value: data[i].policyNo },
          {
            value: new DatePipe("en-US").transform(data[i].dateEntry, "short"),
          },
          { value: data[i].amount },
          { value: data[i].amountGst },
          { value: data[i].premiumToBeCollectedFromCustomer },
          { value: data[i].netCommissionOffsetWithDiscount },
          { value: openItemDisplayStatus[data[i].statusId] },
          { value: data[i].invoiceNo },
          { value: data[i].lastUpdatedByUserName },
          { value: data[i].policyHolder },
          { value: data[i].correspondenceAddress },
          { value: data[i].inactiveAddressChanged },
          { value: data[i].customerType },
          { value: data[i].collateralNumber },
          { value: data[i].insuredName },
          { value: data[i].effectiveDate },
          { value: data[i].expiryDate },
          { value: data[i].sumInsured },
          { value: data[i].riskAddress },
          { value: data[i].insuredNRIC },
          { value: data[i].mobileNumber },
          { value: data[i].additionalInsured },
          { value: data[i].AutoDebitWeek },
          { value: data[i].CancellationType },
        ],
      });
    }

    if (data.length > 0) {
      rows.push({
        cells: [{ value: " " }],
      });
      rows.push({
        cells: [
          {
            value: "Summary of Open-Item",
            bold: true,
          },
        ],
      });
      rows.push({
        cells: [
          {
            value: "Total Items: ",
          },
          { value: data[0].totalOpenItemPolicy },
        ],
      });
      rows.push({
        cells: [
          { value: "Total Gross Premium excl. GST:" },
          { value: data[0].totalGrossPremium },
        ],
      });
      rows.push({
        cells: [{ value: "GST on Gross Premium:" }, { value: data[0].GST }],
      });
      rows.push({
        cells: [{ value: "Total Premium:" }, { value: data[0].totalPremium }],
      });
    }
    return rows;
  }

  private constructOiBatchInvoiceRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({
        value: col,
        bold: true,
        textAlign: "center",
      });
    }
    rows.push({
      cells: headerRowcell,
    });
    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      rows.push({
        cells: [
          {
            value: data[i].batchId,
          },
          {
            value: data[i].financialCode,
          },
          {
            value: data[i].bankReferenceNumber,
          },
          {
            value: data[i].meridianMasterPolicy,
          },
          {
            value: data[i].policyType,
          },
          {
            value: data[i].policyNumber,
          },
          {
            value: data[i].mainInsuredName,
          },
          {
            value: data[i].insuredCorrespondenceAddress,
          },
          {
            value: data[i].riskAddress,
          },
          {
            value: data[i].originalInceptionDate,
          },
          {
            value: data[i].policyInceptionDate,
          },
          {
            value: data[i].policyExpiryDate,
          },
          {
            value: data[i].endorsementEffectiveDate,
          },
          {
            value: data[i].totalSumInsured,
          },
          {
            value: data[i].grossPremiumExclGST,
          },
          {
            value: data[i].gstOnGrossPremium,
          },
          {
            value: data[i].commissionExclGST,
          },
          {
            value: data[i].gstOnCommission,
          },
          {
            value: data[i].premiumNetOffComm,
          },
          {
            value: data[i].gstOnPremiumNetOffComm,
          },
          {
            value: data[i].transactionCode,
          },
          {
            value: data[i].totalPremium,
          },
        ],
      });
    }
    if (data.length > 0) {
      rows.push({
        cells: [
          {
            value: " ",
          },
        ],
      });
      rows.push({
        cells: [
          {
            value: "Summary of Batch-Item",
            bold: true,
          },
        ],
      });
      rows.push({
        cells: [
          {
            value: "Total Items:",
          },
          {
            value: data[0].totalBatchItemPolicy,
          },
        ],
      });
      rows.push({
        cells: [
          {
            value: "Total Gross Premium excl. GST:",
          },
          {
            value: data[0].totalGrossPremium,
          },
        ],
      });
      rows.push({
        cells: [
          {
            value: "GST on Gross Premium:",
          },
          {
            value: data[0].GST,
          },
        ],
      });
      rows.push({
        cells: [
          {
            value: "Total Premium:",
          },
          {
            value: data[0].sumOfTotalPremium,
          },
        ],
      });
    }
    return rows;
  }

  private constructOiDownloadAllPoliciesRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({
        value: col,
        bold: true,
        textAlign: "center",
      });
    }
    rows.push({
      cells: headerRowcell,
    });
    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      rows.push({
        cells: [
          { value: data[i].policyNo },
          { value: data[i].amount },
          { value: data[i].amountGst },
          { value: data[i].batchId },
          { value: openItemDisplayStatus[data[i].statusId] },
          { value: data[i].lastUpdatedByUserName },
        ],
      });
    }
    return rows;
  }

  //policy-wording-construct rows
  private constructPolicyRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      rows.push({
        cells: [
          { value: data[i].PolicyCertificateNumber },
          { value: data[i].ProductType },
          { value: data[i].PolicyType },
          { value: data[i].PolicyNumber },
          { value: data[i].EffectiveDate },
          { value: data[i].PolicyBoundDate },
          { value: data[i].ExpiryDate },
        ],
      });
    }
    return rows;
  }
  //autoDebitExtractionReport-construct rows
  private constructautoDebitExtractionReportRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    var totalItem = 0;
    var totalGrossPremium = 0;
    var totalGST = 0;
    var totalPremium = 0;
    var referenceNumber = "";
    var forAdMonth = "";
    var reportExtractedDate = "";

    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      /// CHNAGE COLUMN

      if (i == 0) {
        referenceNumber = data[i].referenceNumber;
        forAdMonth = data[i].forMonth;
        reportExtractedDate = data[i].reportDate;
      }

      rows.push({
        cells: [
          { value: i + 1 },
          { value: data[i].meridianSequenceNo },
          { value: data[i].policyCertificateNo },
          { value: data[i].masterPolicyNo },
          { value: data[i].policyNo },
          { value: data[i].entryDate },
          { value: data[i].grossPremium },
          { value: data[i].gST },
          { value: data[i].totalPremium },
          { value: data[i].status },
          { value: data[i].invoiceNo },
          { value: data[i].savedBy },
          { value: data[i].bUPCCode },
          { value: data[i].bankRef },
          { value: data[i].insuredName },
          { value: data[i].effectiveDate },
          { value: data[i].expiryDate },
          { value: data[i].sumInsured },
          { value: data[i].riskAddress },
          { value: data[i].insuredNRIC },
          { value: data[i].transactionType },
          { value: data[i].product },
          { value: data[i].contractType },
          { value: data[i].policyStatus },
          { value: data[i].transactionDate },
        ],
      });

      totalItem++;
      totalGrossPremium += parseFloat(data[i].grossPremium);
      totalGST += parseFloat(data[i].gST);
      totalPremium += parseFloat(data[i].totalPremium);
    }

    rows.push({
      cells: [
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: totalGrossPremium, bold: true },
        { value: totalGST, bold: true },
        { value: totalPremium, bold: true },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
      ],
    });
    return rows;
  }
  //autoUpdateExtractionReport-construct rows
  private constructautoUpdateExtractionReportRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    var totalItem = 0;
    var totalGrossPremium = 0;
    var totalGST = 0;
    var totalPremium = 0;
    var referenceNumber = "";
    var forAdMonth = "";
    var reportExtractedDate = "";

    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      /// CHNAGE COLUMN

      if (i == 0) {
        // referenceNumber = data[i].referenceNumber;
        forAdMonth = data[i].forMonth;
        reportExtractedDate = data[i].reportDate;
      }

      rows.push({
        cells: [
          { value: i + 1 },
          { value: data[i].policyNo },
          { value: data[i].batchid },
          { value: data[i].bupcode },
          { value: data[i].bankreferencenumber },
          { value: data[i].meridianmasterpolicyNumber },
          { value: data[i].policytype },
          { value: data[i].maininsuredname },
          { value: data[i].riskaddress },
          { value: data[i].startDate },
          { value: data[i].expiryDate },
          { value: data[i].sumInsured },
          { value: data[i].basePremium },
          { value: data[i].gst },
          { value: data[i].totalPremium },
          { value: data[i].transtype },
        ],
      });
    }
    return rows;
  }
  //constructBankTransDBSReportRows-construct rows
  private constructBankTransDBSReportRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      rows.push({
        cells: [
          { value: data[i].serialNo },
          { value: data[i].financialBranch },
          { value: data[i].masterPolicyNo },
          { value: data[i].agentNo },
          { value: data[i].agentName },
          { value: data[i].contractType },
          { value: data[i].contractNo },
          { value: data[i].referenceNo },
          { value: data[i].originalInceptionDate },
          { value: data[i].contractCommenceDate },
          { value: data[i].renewalDate },
          { value: data[i].clientNo },
          { value: data[i].clientSurname },
          { value: data[i].clientGivenName },
          { value: data[i].clientIndentificationNo },
          { value: data[i].clientAddress },
          { value: data[i].riskNo },
          { value: data[i].locationOfRisk },
          { value: data[i].totalSumInsured },
          /*New- Gross premium*/ { value: data[i].grossPremium },
          /*Ex*/ { value: data[i].gstAmount },
          /*Ex- Total gross premium*/ { value: data[i].totalGrossPremium },
          /*New- Commission Rate*/ { value: data[i].commissiOnRate },
          /*Ex- commissionAmount ex .gst*/ { value: data[i].commissionAmount },
          /*New- GST On Commission*/ { value: data[i].gstOnCommission },
          /*New- Premium Net Off Commission*/ {
            value: data[i].premiumNetOffCommission,
          },
          /*New- GST On Premium Net Off Commission*/ {
            value: data[i].gstOnPremiumNetOffCommission,
          },
          { value: data[i].discountAmount },
          { value: data[i].netPremium },
          { value: data[i].transactionCode },
          { value: data[i].transactionCodeDesc },
          { value: data[i].meridianSequenceNo },
          { value: data[i].policyCertificateNo },
          { value: data[i].quoteNo },
          { value: data[i].MSIGNumber },
          { value: data[i].insuredNRIC },
          { value: data[i].entryDate },
          { value: data[i].endorsementEffectiveDate },
          { value: data[i].status },
          { value: data[i].savedBy },
          { value: data[i].chequeIssuanceBank },
          { value: data[i].chequeNo },
          { value: data[i].paymentMode },
          { value: data[i].freeInsurance },
          { value: data[i].noOfYearsFree },
          { value: data[i].machineryType },
          { value: data[i].propertyType },
          { value: data[i].remarks },
          { value: data[i].occupancy },
          { value: data[i].makersID },
          { value: data[i].makersDateTime },
          { value: data[i].checkersID },
          { value: data[i].checkersDateTime },
        ],
      });
    }
    return rows;
  }
  //constructBankTransCITIReportRows-construct rows
  private constructBankTransCITIReportRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      rows.push({
        cells: [
          { value: data[i].serialNumber },
          { value: data[i].customerType },
          { value: data[i].masterPolicyNumber },
          { value: data[i].contractType },
          { value: data[i].premiumPayment },
          { value: data[i].policyNumber },
          { value: data[i].planType },
          { value: data[i].collateralNumber },
          { value: data[i].clientNumber },
          { value: data[i].clientSurname },
          { value: data[i].clientGivenName },
          { value: data[i].clientIdentificationNumber },
          { value: data[i].clientAddress },
          { value: data[i].mobileNumber },
          { value: data[i].emailAddress },
          { value: data[i].additionalClientName },
          { value: data[i].additionalClientNRIC },
          { value: data[i].preferredDeliveryMode },
          { value: data[i].preferredDeliveryDetails },
          { value: data[i].coverageType },
          { value: data[i].coverageValue },
          { value: data[i].code },
          { value: data[i].originalInceptionDate },
          { value: data[i].effectiveDate },
          { value: data[i].expiryDate },
          { value: data[i].sumInsured },
          { value: data[i].riskAddress },
          { value: data[i].transactionType },
          { value: data[i].propertyType },
          { value: data[i].remarks },
          { value: data[i].dwellingOccupancy },
          { value: data[i].policyStatus },
          { value: data[i].transactionDate },
          { value: data[i].transactedBy },
          { value: data[i].paymentHistoryStatus },
          { value: data[i].aDWeek },
          { value: data[i].openAuditNumber },
          { value: data[i].grossPremium },
          { value: data[i].discountOfferedByCiti },
          { value: data[i].premiumDiscountByCiti },
          { value: data[i].nettPremiumBilledToCustomer },
          { value: data[i].gSTonPremium },
          { value: data[i].premiumToBeCollectedFromCustomer },
          { value: data[i].commissionRate },
          { value: data[i].commissionAmount },
          { value: data[i].nettCommOffsetWithDiscount },
          { value: data[i].gSTonCommission },
          { value: data[i].commissionPayable },
          { value: data[i].amtDuetoChubb },
        ],
      });
    }
    return rows;
  }
  //constructCitiMonthlyNewBusinessReportRows-construct rows
  private constructCitiMonthlyNewBusinessReportRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      rows.push({
        cells: [
          { value: data[i].policyNumber },
          { value: data[i].policyCertificateNumber },
          { value: data[i].productName },
          { value: data[i].clientName },
          { value: data[i].customerType },
          { value: data[i].occupiedAs },
          { value: data[i].collateralNumber },
          { value: data[i].riskAddressBuildingName },
          { value: data[i].riskAddressUnitNumber },
          { value: data[i].riskAddressAddressLine1 },
          { value: data[i].riskAddressAddressLine2 },
          { value: data[i].riskAddressCity },
          { value: data[i].riskAddressCountry },
          { value: data[i].riskAddressPostCode },
          { value: data[i].premiumPayment },
          { value: data[i].coverageValue },
          { value: data[i].masterPolicyNumber },
          { value: data[i].transactedBy },
          { value: data[i].effectiveDate },
          { value: data[i].expiryDate },
          { value: data[i].policyBoundDate },
          { value: data[i].transactionType },
          { value: data[i].planType },
          { value: data[i].grossPremium },
          { value: data[i].gSTonPremium },
          { value: data[i].premiumToBeCollectedFromCustomer },
          { value: data[i].sumInsured },
          { value: data[i].premiumDiscountByCiti },
        ],
      });
    }
    return rows;
  }
  //constructCitiMonthlyActiveReportRows-construct rows
  private constructCitiMonthlyActiveReportRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      rows.push({
        cells: [
          { value: data[i].productName },
          { value: data[i].customerType },
          { value: data[i].clientName },
          { value: data[i].policyNumber },
          { value: data[i].policyCertificateNumber },

          { value: data[i].effectiveDate },
          { value: data[i].expiryDate },
          { value: data[i].policyBoundDate },
          { value: data[i].policyStatus },
          { value: data[i].collateralNumber },

          { value: data[i].riskAddressBuildingName },
          { value: data[i].riskAddressUnitNumber },
          { value: data[i].riskAddressAddressLine1 },
          { value: data[i].riskAddressAddressLine2 },
          { value: data[i].riskAddressCity },
          { value: data[i].riskAddressCountry },
          { value: data[i].riskAddressPostCode },

          { value: data[i].grossPremium },
          { value: data[i].gSTonPremium },
          { value: data[i].premiumToBeCollectedFromCustomer },
          { value: data[i].sumInsured },
          { value: data[i].premiumDiscountByCiti },
        ],
      });
    }
    return rows;
  }
  //constructCitiMonthlyCancellationReportRows-construct rows
  private constructCitiMonthlyCancellationReportRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      rows.push({
        cells: [
          { value: data[i].policyNumber },
          { value: data[i].productName },
          { value: data[i].clientName },
          { value: data[i].customerType },
          { value: data[i].occupiedAs },
          { value: data[i].collateralNumber },
          { value: data[i].riskAddressBuildingName },
          { value: data[i].riskAddressUnitNumber },
          { value: data[i].riskAddressAddressLine1 },
          { value: data[i].riskAddressAddressLine2 },
          { value: data[i].riskAddressCity },
          { value: data[i].riskAddressCountry },
          { value: data[i].riskAddressPostCode },
          { value: data[i].premiumPayment },
          { value: data[i].coverageValue },
          { value: data[i].masterPolicyNumber },
          { value: data[i].transactedBy },
          { value: data[i].cancellationDate },
          { value: data[i].effectiveDate },
          { value: data[i].expiryDate },
          { value: data[i].policyBoundDate },
          { value: data[i].transactionType },
          { value: data[i].planType },
          { value: data[i].grossPremium },
          { value: data[i].gSTonPremium },
          { value: data[i].premiumToBeCollectedFromCustomer },
          { value: data[i].sumInsured },
          { value: data[i].premiumDiscountByCiti },
        ],
      });
    }
    return rows;
  }
  //constructDailyCbgDownloadReportRows
  private constructDailyCbgDownloadReportRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      const statusDisplayText = HomeConstant.proposalStatus[data[i].status];

      // push single row for every record
      rows.push({
        cells: [
          { value: data[i].serialNo },
          { value: data[i].financialBranch },
          { value: data[i].masterPolicyNo },
          { value: data[i].productType },
          { value: data[i].policyNo },
          { value: data[i].contractNo },
          { value: data[i].quoteNo },
          { value: statusDisplayText },
          { value: data[i].insuredName },
          { value: data[i].nric },
          { value: data[i].situation },
          { value: data[i].city },
          { value: data[i].country },
          { value: data[i].postalCode },
          { value: data[i].sumInsured },
          { value: data[i].grossPremium },
          { value: data[i].gst },
          { value: data[i].totalPremium },
          { value: data[i].bankReference },
          { value: data[i].boundDate },
          { value: data[i].effectiveDate },
          { value: data[i].inceptionDate },
          { value: data[i].expiryDate },
          { value: data[i].makerUserName },
          { value: data[i].submittedDateTime },
          { value: data[i].checkerUserName },
          { value: data[i].checkedDateTime },
          { value: data[i].policyStatus },
          { value: data[i].remarks },
          { value: data[i].refundStatus },
          { value: data[i].transCodeDesc },
        ],
      });
    }
    return rows;
  }
  //constructPrintDocumentReportRows
  private constructPrintDocumentReportRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell, height: 50 });
    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      rows.push({
        cells: [
          { value: data[i].product },
          { value: data[i].policyNo },
          { value: data[i].documentType },
          { value: data[i].transactionBoundDate },
          { value: data[i].transUserName },
          { value: data[i].savedByOrg },
          { value: data[i].channelOfDoc, wrap: true },
          { value: data[i].correspondenceAddress },
          { value: data[i].printType },
          { value: data[i].dateToDDS },
          { value: data[i].correspondenceEmail },
          { value: data[i].correspondenceSMS },
          { value: data[i].dDSIsSuccess },
          { value: data[i].dDSInterfaceStatus },
          { value: data[i].dDSIsLive },
        ],
        height: 50,
      });
    }
    return rows;
  }
  //constructDataPostPrintReportRows\
  private constructDataPostPrintReportRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      rows.push({
        cells: [
          { value: data[i].type },
          { value: data[i].nameOfFolder },
          { value: data[i].link },
          { value: data[i].noOfPages },
          { value: data[i].combinePDFName },
          { value: data[i].product },
          { value: data[i].policyNo },
          { value: data[i].documentType },
          { value: data[i].transactionBoundDate },
          { value: data[i].transactedByUserName },
          { value: data[i].savedByOrganisation },
          { value: data[i].channelOfDoc },
          { value: data[i].correspondenceAddress },
        ],
      });
    }

    if (data.length > 0) {
      //Add summary
      rows.push({
        cells: [{ value: " " }],
      });

      rows.push({
        cells: [
          { value: " " },
          { value: " " },
          { value: "Summary", bold: true },
          { value: data[0].sumNoOfPages },
          { value: data[0].uniquePDFCount },
        ],
      });
    }
    return rows;
  }
  //format date
  private formatDecimal(numberToFormat: number): string {
    if (
      numberToFormat != null &&
      numberToFormat.toString().split(".").length === 1
    ) {
      return numberToFormat.toString() + ".00";
    }
    const sign = numberToFormat >= 0 ? 1 : -1;
    return (
      Math.round(numberToFormat * Math.pow(10, 2) + sign * 0.001) /
      Math.pow(10, 2)
    ).toFixed(2);
  }
  //constructlstReportReportRows
  private constructlstReportReportRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      rows.push({
        cells: [
          { value: data[i].PolicyNumber },
          { value: data[i].DeclarationReference },
          { value: data[i].EPLPolicyNumber },
          { value: data[i].Currency },
          { value: data[i].TransCode },
          { value: data[i].EndorsementCount },
          { value: this.formatDecimal(data[i].GrossTSI) },
          { value: this.formatDecimal(data[i].GrossPrem) },
          { value: data[i].RICOAccount },
          { value: data[i].Reference },
          { value: this.formatDecimal(data[i].CededPremiumPercentage) },
          { value: this.formatDecimal(data[i].CededPremiumAmount) },
          { value: this.formatDecimal(data[i].LSTPrem) },
          { value: this.formatDecimal(data[i].PercentageCededToSurplus) },
          { value: data[i].NetTSI.toString() === "0" ? "" : data[i].NetTSI },
          { value: this.formatDecimal(data[i].NetPremium) },
          { value: data[i].TransactionDate },
          { value: data[i].InceptionDate },
          { value: data[i].EffectiveDate },
          { value: data[i].ExpiryDate },
          { value: data[i].NAICSOccupation },
          { value: data[i].SSICOccupation },
          { value: data[i].Occupancy },
          { value: data[i].RiskAddress },
          { value: data[i].InsuredName },
        ],
      });
    }
    return rows;
  }
  //constructIBGRenewalReportRows
  private constructIBGRenewalReportRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      ///CHNAGE COLUMN
      rows.push({
        cells: [
          { value: data[i].SrNo },
          { value: data[i].PolicyNo },
          { value: data[i].MSIGPolicyNo },
          { value: data[i].RMName },
          { value: data[i].BUPCCode },
          { value: data[i].BankReferenceNo },
          { value: data[i].BorrowerCIN },
          { value: data[i].NameOfInsured },
          { value: data[i].AdditionalInsureds },
          { value: data[i].CorrespondenceAddress },
          { value: data[i].RiskAddress },
          { value: data[i].PolicyInceptionDate },
          { value: data[i].PolicyExpiryDate },
          { value: data[i].NoOfFreeYears },
          { value: data[i].SumInsured },
          { value: data[i].GrossPremium },
          { value: data[i].GST },
          { value: data[i].TotalPremium },
          { value: data[i].TransactionType },
          { value: data[i].TransactionDate },
          { value: data[i].RenewableMethod },
          { value: data[i].Occupation },
          { value: data[i].OccupancyRates },
          { value: data[i].OccupiedAs },
          { value: data[i].NatureOfBusiness },
          { value: data[i].UsageOfPremises },
          { value: data[i].TypeOfProperty },
          { value: data[i].TypeOfEquipment },
          { value: data[i].CommissionPerc },
          { value: data[i].Commission },
          { value: data[i].GSTOnCommission },
          { value: data[i].PremiumRates },
          { value: data[i].OpenItemStatus },
          { value: data[i].Remarks },
        ],
      });
    }
    return rows;
  }
  //constructCitiLoanRedemptionSuccessReportRows
  private constructCitiLoanRedemptionSuccessReportRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      rows.push({
        cells: [
          { value: i + 1 },
          { value: data[i].PolicyNo },
          { value: data[i].PolicyStatus },
          { value: data[i].RiskAddress },
          { value: data[i].BankRefNo },
          { value: data[i].InsuredName },
          { value: data[i].MITDDate },
          { value: data[i].RefundPremium },
          { value: data[i].Remarks },
          { value: data[i].TransactionBoundDate },
          { value: data[i].MSBRDateReceived },
        ],
      });
    }

    return rows;
  }
  //constructDbsCbgLoanRedemptionSuccessReportRows
  private constructDbsCbgLoanRedemptionSuccessReportRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      rows.push({
        cells: [
          { value: i + 1 },
          { value: data[i].PolicyNo },
          { value: data[i].PolicyStatus },
          { value: data[i].RiskAddress },
          { value: data[i].InsuredName },
          { value: data[i].MITDDate },
          { value: data[i].RefundPremium },
          { value: data[i].Remarks },
          { value: data[i].TransactionBoundDate },
          { value: data[i].MSBRDateReceived },
        ],
      });
    }

    return rows;
  }
  //constructDbsCbgLoanRedemptionFailureReportRows
  private constructDbsCbgLoanRedemptionFailureReportRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      rows.push({
        cells: [
          { value: i + 1 },
          { value: data[i].PolicyNo },
          { value: data[i].RiskAddress },
          { value: data[i].MITDDate },
          { value: data[i].InsuranceAction },
          { value: data[i].Remarks },
          { value: data[i].MSBRDateReceived },
        ],
      });
    }

    return rows;
  }
  //constructDbsIbgLoanRedemptionSuccessReportRows
  private constructDbsIbgLoanRedemptionSuccessReportRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      rows.push({
        cells: [
          { value: i + 1 },
          { value: data[i].BankRefNo },
          { value: data[i].PolicyNo },
          { value: data[i].PolicyStatus },
          { value: data[i].RiskAddress },
          { value: data[i].InsuredName },
          { value: data[i].MITDDate },
          { value: data[i].RefundPremium },
          { value: data[i].Remarks },
          { value: data[i].TransactionBoundDate },
          { value: data[i].MSBRDateReceived },
        ],
      });
    }

    return rows;
  }
  //constructDbsIbgLoanRedemptionFailureReportRows
  private constructDbsIbgLoanRedemptionFailureReportRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      rows.push({
        cells: [
          { value: i + 1 },
          { value: data[i].BankRefNo },
          { value: data[i].PolicyNo },
          { value: data[i].MITDDate },
          { value: data[i].InsuranceAction },
          { value: data[i].Remarks },
          { value: data[i].MSBRDateReceived },
        ],
      });
    }

    return rows;
  }
  //constructCitiLoanRedemptionFailureReportRows
  private constructCitiLoanRedemptionFailureReportRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      rows.push({
        cells: [
          { value: i + 1 },
          { value: data[i].PolicyNo },
          { value: data[i].BankRefNo },
          { value: data[i].InsuredName },
          { value: data[i].RiskAddress },
          { value: data[i].MITDDate },
          { value: data[i].Remarks },
          { value: data[i].MSBRDateReceived },
        ],
      });
    }
    return rows;
  }
  //constructManualPaidAutoDebitReportRows
  private constructManualPaidAutoDebitReportRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data?.length; i++) {
      // push single row for every record
      rows.push({
        cells: [
          { value: data[i].serialNo },
          { value: data[i].policyNo },
          { value: data[i].policyCertificateNo },
          { value: data[i].chequeIssuanceBank },
          { value: data[i].chequeNo },
          { value: data[i].payerName },
          { value: data[i].amount },
          { value: data[i].gstAmount },
        ],
      });
    }
    return rows;
  }
  //constructRenewalDraftReportRows
  private constructRenewalDraftReportRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      rows.push({
        cells: [
          { value: data[i].policyCertificateNo },
          { value: data[i].masterPolicyNo },
          { value: data[i].policyNo },
          { value: data[i].effectiveDate },
          { value: data[i].expiryDate },
          { value: data[i].grossRenewalPremium },
          { value: data[i].gstAmount },
          { value: data[i].totalRenewalPremium },
          { value: data[i].freeOrPaidRenewal },
          { value: data[i].draftCreationDate },
          { value: data[i].status },
          { value: data[i].draftDeletionDate },
          { value: data[i].transactionType },
          { value: data[i].insuredName },
          { value: data[i].draftSumInsured },
          { value: data[i].currentSumInsured },
          { value: data[i].riskAddress },
          { value: data[i].bankReference },
        ],
      });
    }

    return rows;
  }
  //constructRenewalReportRows
  private constructRenewalReportRows(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      /// CHNAGE COLUMN
      rows.push({
        cells: [
          { value: data[i].meridianSequenceNo },
          { value: data[i].policyCertificateNo },
          { value: data[i].masterPolicyNo },
          { value: data[i].policyNo },
          { value: data[i].effectiveDate },
          { value: data[i].expiryDate },
          { value: data[i].grossRenewalPremium },
          { value: data[i].gST },
          { value: data[i].totalRenewalPremium },
          { value: data[i].commissionPerc },
          { value: data[i].commission },
          { value: data[i].gSTOnCommission },
          { value: data[i].noOfFreeYears },
          { value: data[i].freeOrPaidRenewal },
          { value: data[i].renewalBoundDate },
          { value: data[i].renewalDocsGeneratedDate },
          { value: data[i].aDFileMonth },
          { value: data[i].status },
          { value: data[i].savedBy },
          { value: data[i].rMName },
          { value: data[i].bUPCCode },
          { value: data[i].bankRef },
          { value: data[i].insuredName },
          { value: data[i].additionalInsureds },
          { value: data[i].sumInsured },
          { value: data[i].riskAddress },
          { value: data[i].correspondenceAddress },
          { value: data[i].insuredNRIC },
          { value: data[i].borrowerCIN },
          { value: data[i].occupation },
          { value: data[i].occupationRates },
          { value: data[i].occupiedAs },
          { value: data[i].natureOfBusiness },
          { value: data[i].usageOfPremises },
          { value: data[i].typeOfProperty },
          { value: data[i].typeOfEquipment },
        ],
      });
    }

    if (data.length > 0) {
      //Add summary
      rows.push({
        cells: [{ value: " " }],
      });

      rows.push({
        cells: [
          {
            value: "Summary of Renewal",
            bold: true,
          },
          { value: "Count", bold: true },
          { value: "Premium with GST", bold: true },
        ],
      });

      rows.push({
        cells: [
          {
            value: "Total Due Renewal Sent:",
          },
          { value: data[0].totalRenewalPolicy },
          { value: data[0].totalRenewalPolicyPremium },
        ],
      });

      rows.push({
        cells: [
          { value: "Total Pending Renewal in EPL:" },
          { value: data[0].totalPendingRenewalPolicy },
          { value: data[0].totalPendingRenewalPremium },
        ],
      });

      rows.push({
        cells: [
          { value: "Total Auto/Manual Renewal Bound in EPL:" },
          { value: data[0].totalRenewalBoundPolicy },
          { value: data[0].totalRenewalBoundPremium },
        ],
      });
    }
    return rows;
  }
  //downloadsummaryInvoiceAutoDebitReport
  public downloadsummaryInvoiceAutoDebitReport(
    columns: string[],
    title: string,
    fileName: string,
    data?: SummaryInvoiceAutoDebitEntity[],
    segmentDetails?: string
  ): void {
    const workbook = new Workbook({
      sheets: <WorkbookSheet[]>[
        {
          // Column settings (width)
          columns: this.getColSetting(columns.length),
          // Title of the sheet
          name: title,
          // Rows of the sheet
          rows: this.constructsummaryInvoiceAutoDebitReportRows1(columns, data),
        },
        {
          // Column settings (width)
          columns: [
            { autoWidth: true },
            { autoWidth: true },
            { autoWidth: true },
          ],
          // Title of the sheet
          name: "Summary by BUPC",
          // Rows of the sheet
          rows: this.constructsummaryInvoiceAutoDebitReportRows2(data),
        },
        {
          // Title of the sheet
          name: "Summary by Tax Invoice",
          // mergedCells: ["A9:B9","A21:F21", "A32:C32", "B34:C34", "B36:C36", "A44:B44", "E11:F11", "E12:F12"],
          // Rows of the sheet
          rows: reportHelper.getTaxInvoiceRow(
            this.taxInvoiceInput.referenceNumber,
            this.taxInvoiceInput.forAdMonth,
            this.taxInvoiceInput.reportExtractedDate,
            this.taxInvoiceInput.totalGrossPremium,
            this.taxInvoiceInput.totalGST,
            this.taxInvoiceInput.totalPremium,
            "AD",
            segmentDetails ? segmentDetails : ""
          ),
          // Column settings (width)
          columns: [
            { autoWidth: false, width: 200 },
            { autoWidth: false, width: 300 },
            { autoWidth: false, width: 275 },
            { autoWidth: false, width: 200 },
            { autoWidth: false, width: 25 },
            { autoWidth: false, width: 100 },
          ],
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, fileName + ".xlsx");
    });
  }
  //constructsummaryInvoiceAutoDebitReportRows
  private constructsummaryInvoiceAutoDebitReportRows1(
    columns: string[],
    data: any
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    var totalItem = 0;
    var totalGrossPremium = 0;
    var totalGST = 0;
    var totalPremium = 0;
    var referenceNumber = "";
    var forAdMonth = "";
    var reportExtractedDate = "";

    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      /// CHNAGE COLUMN

      if (i == 0) {
        referenceNumber = data[i].referenceNumber;
        forAdMonth = data[i].forMonth;
        reportExtractedDate = data[i].reportDate;
      }

      rows.push({
        cells: [
          { value: i + 1 },
          { value: data[i].meridianSequenceNo },
          { value: data[i].policyCertificateNo },
          { value: data[i].masterPolicyNo },
          { value: data[i].policyNo },
          { value: data[i].entryDate },
          { value: data[i].grossPremium },
          { value: data[i].gST },
          { value: data[i].totalPremium },
          { value: data[i].status },
          { value: data[i].invoiceNo },
          { value: data[i].savedBy },
          { value: data[i].bUPCCode },
          { value: data[i].bankRef },
          { value: data[i].insuredName },
          { value: data[i].effectiveDate },
          { value: data[i].expiryDate },
          { value: data[i].sumInsured },
          { value: data[i].riskAddress },
          { value: data[i].insuredNRIC },
          { value: data[i].transactionType },
          { value: data[i].product },
          { value: data[i].contractType },
          { value: data[i].policyStatus },
          { value: data[i].transactionDate },
        ],
      });

      totalItem++;
      totalGrossPremium += parseFloat(data[i].grossPremium);
      totalGST += parseFloat(data[i].gST);
      totalPremium += parseFloat(data[i].totalPremium);
    }

    rows.push({
      cells: [
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: totalGrossPremium, bold: true },
        { value: totalGST, bold: true },
        { value: totalPremium, bold: true },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
      ],
    });
    this.taxInvoiceInput.referenceNumber = referenceNumber;
    this.taxInvoiceInput.forAdMonth = forAdMonth;
    this.taxInvoiceInput.reportExtractedDate = reportExtractedDate;
    this.taxInvoiceInput.totalGrossPremium = totalGrossPremium;
    this.taxInvoiceInput.totalGST = totalGST;

    return rows;
  }
  //constructsummaryInvoiceAutoDebitReportRows2
  private constructsummaryInvoiceAutoDebitReportRows2(
    data?: SummaryInvoiceAutoDebitEntity[]
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    rows.push({
      cells: [
        { value: "SI No", bold: true, textAlign: "center" },
        { value: "BUPC Code", bold: true, textAlign: "center" },
        { value: "Total Premium", bold: true, textAlign: "center" },
      ],
    });
    var data2 = null;
    var result: any[] = [];
    data2 = data?.reduce(function (res: any, value) {
      if (!res[value.bUPCCode]) {
        res[value.bUPCCode] = { id: value.bUPCCode, totalPremium: 0 };
        result.push(res[value.bUPCCode]);
      }
      res[value.bUPCCode].totalPremium += value.totalPremium;
      return res;
    }, {});

    var totalPremium = 0;

    for (let i = 0; i < result.length; i++) {
      rows.push({
        cells: [
          { value: i + 1 },
          { value: result[i].id },
          { value: result[i].totalPremium },
        ],
      });

      totalPremium += result[i].totalPremium;
    }

    rows.push({
      cells: [
        { value: "" },
        { value: "Total" },
        { value: totalPremium, bold: true },
      ],
    });
    this.taxInvoiceInput.totalPremium = totalPremium;

    return rows;
  }
  taxInvoiceInput: any = {};
  //cls failure response
  private constructFailureResponseRows(
    columns: string[],
    tempData: any,
    data: any,
    SegmentType: string
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      rows.push({
        cells: [
          { value: i + 1 },
          { value: data[i].PolicyNo },
          { value: data[i].RiskAddress },
          { value: data[i].InsuredName },
          { value: data[i].MITDDate },
          { value: data[i].PolicyExpiryDate },
          { value: data[i].InsuranceAction },
          { value: data[i].Reason },
          { value: data[i].MSBRDateReceived },
          { value: data[i].Remarks },
          { value: data[i].DateOfReject },
        ],
      });
    }
    for (let i = 0; i < 5; i++) {
      rows.push({
        cells: [{ value: "" }],
      });
    }
    var total = 0;
    var successTotal = 0;

    total = tempData.cBGExceptionTable.length + tempData.cBGSuccessTable.length;
    successTotal = tempData.cBGSuccessTable.length;

    rows.push({
      cells: [
        { value: "SUMMARY", bold: true, textAlign: "center" },
        { value: "NO. OF CASES", bold: true, textAlign: "center" },
      ],
    });

    if (SegmentType === "CBG") {
      rows.push({
        cells: [
          {
            value: "TOTAL NUMBER OF RECORDS IN DSG FILE PROVIDED BY DBS:",
            bold: true,
            textAlign: "center",
          },
          { value: total },
        ],
      });

      rows.push({
        cells: [
          { value: "SUCCESSFULLY PROCESSED:", bold: true, textAlign: "center" },
          { value: successTotal },
        ],
      });

      var exceptionCount = tempData.cBGExceptionTable.length;
      var exceptionCountCBG = tempData.cBGExceptionTable.length;
      rows.push({
        cells: [
          { value: "EXCEPTIONS:", bold: true, textAlign: "center" },
          { value: exceptionCount },
        ],
      });
      var exceptionType = "CBG" + " EXCEPTIONS:";
      rows.push({
        cells: [
          { value: exceptionType, bold: true, textAlign: "center" },
          { value: exceptionCountCBG },
        ],
      });
    }
    return rows;
  }

  //cls success response
  private constructSuccessResponseRows(
    columns: string[],
    tempData: any,
    data: any,
    SegmentType: string
  ): WorkbookSheetRow[] {
    var rows: WorkbookSheetRow[] = [];
    let headerRowcell: WorkbookSheetRowCell[] = [];
    for (let col of columns) {
      headerRowcell.push({ value: col, bold: true, textAlign: "center" });
    }
    rows.push({ cells: headerRowcell });
    for (let i = 0; i < data.length; i++) {
      // push single row for every record
      rows.push({
        cells: [
          { value: i + 1 },
          { value: data[i].PolicyNo },
          { value: data[i].PolicyStatus },
          { value: data[i].RiskAddress },
          { value: data[i].InsuredName },
          { value: data[i].MITDDate },
          { value: data[i].RefundPremium },
          { value: data[i].InsuranceAction },
          { value: data[i].Reason },
          { value: data[i].Remarks },
          { value: data[i].MSBRDate },
          { value: data[i].MSBRDateReceived },
        ],
      });
    }
    for (let i = 0; i < 5; i++) {
      rows.push({
        cells: [{ value: "" }],
      });
    }
    var total = 0;
    var successTotal = 0;

    total = tempData.cBGExceptionTable.length + tempData.cBGSuccessTable.length;
    successTotal = tempData.cBGSuccessTable.length;

    rows.push({
      cells: [
        { value: "SUMMARY", bold: true, textAlign: "center" },
        { value: "NO. OF CASES", bold: true, textAlign: "center" },
      ],
    });

    if (SegmentType === "CBG") {
      rows.push({
        cells: [
          {
            value: "TOTAL NUMBER OF RECORDS IN DSG FILE PROVIDED BY DBS:",
            bold: true,
            textAlign: "center",
          },
          { value: total },
        ],
      });

      rows.push({
        cells: [
          { value: "SUCCESSFULLY PROCESSED:", bold: true, textAlign: "center" },
          { value: successTotal },
        ],
      });

      var exceptionCount = tempData.cBGExceptionTable.length;
      var exceptionCountCBG = tempData.cBGExceptionTable.length;
      rows.push({
        cells: [
          { value: "EXCEPTIONS:", bold: true, textAlign: "center" },
          { value: exceptionCount },
        ],
      });
      var exceptionType = "CBG" + " EXCEPTIONS:";
      rows.push({
        cells: [
          { value: exceptionType, bold: true, textAlign: "center" },
          { value: exceptionCountCBG },
        ],
      });
    }
    return rows;
  }
  //cbg-cls new business
  public constructWorkbookSuccess(
    escapedJson: any,
    data: any,
    SegmentType: string,
    reportTitle: string
  ): void {
    var arrAutoWidth: any = [];
    const rows: WorkbookSheetRow[] = [];
    if (SegmentType.toLowerCase() === "cbg") {
      var cells: WorkbookSheetRowCell[] = []; //has to be called 'cells' exactly to be worked

      var keyvalues = JSON.parse(escapedJson);
      if (keyvalues.length > 0) {
        //table header
        var headers = this.splitArray(keyvalues[0]);

        headers.forEach(function (key: any) {
          cells.push({ value: key, bold: true, textAlign: "center" });
        });

        rows.push({ cells });
      }

      for (var counter = 0; counter < data.length; counter++) {
        cells = []; //reset

        Object.keys(data[counter]).forEach(function (key) {
          cells.push({ value: data[counter][key] });
        });

        rows.push({ cells });
      }

      Object.keys(data[0]).forEach(function (key) {
        arrAutoWidth.push({ autoWidth: true });
      });
    }

    const workbook = new Workbook({
      sheets: [
        {
          // Title of the sheet
          name: "SGCHUBBINS004",
          // Rows of the sheet
          rows: rows,
          // Column settings (width)
          columns: arrAutoWidth,
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, reportTitle);
    });
  }
  private splitArray(value: any) {
    var candid = JSON.parse(
      JSON.stringify(value)
        .replace(/{/g, "[")
        .replace(/}/g, "]")
        .replace(/"\:/g, '",')
    );
    var oddOnes: any = [],
      evenOnes: any = [];
    for (var i = 0; i < candid.length; i++)
      (i % 2 == 0 ? evenOnes : oddOnes).push(candid[i]);
    return evenOnes; //[evenOnes, oddOnes];
  }
}
