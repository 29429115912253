export enum ProposalPageMode {
  Create,
  Edit,
  View,
}

export enum ComponentMode {
  Add,
  Edit,
}

export enum QuestionTheme {
  Normal,
  Danger,
  Warning,
}

export enum ErrorExceptionType {
  UnknownErrorType = 0,
  ApplicationError = 1,
  FrameworkError = 2,
  ArgumentException = 3,
  InternalServerError = 500,
  Unauthorized = 401,
  NotFound = 404,
}

export enum AnswerDataType {
  String = 1,
  Boolean = 2,
  Numeric = 3,
  Date = 4,
  Object = 5,
}

export enum UserType {
  Undefined = 0,
  Admin = 1,
  Underwriter = 3,
  Marketer = 6,
  Broker = 2,
  Agent = 5,
  Client = 4,
}

export enum ApplicationMode {
  browser,
  local,
}

export enum ProductType {
  MY_Flexiguard = 1,
  MY_MyHome = 2,
  MY_GCN = 3,
  MY_ForeignMaid = 4,
  MY_ForeingWorker = 5,
  MY_PremiumDriveCare = 6,
  MY_MyPremiumDriveCare = 7,
  MY_PAPassenger1 = 8, // to be rename
  MY_PAPassenger2 = 9, // to be rename
  MY_PAPassenger3 = 10, // to be rename
  AUS_BusinessTravel = 11,
  AUS_BizPack = 12,
}

export enum ProductListingType {
  Submission = 1,
  Quotation = 2,
  Policy = 3,
  Cancellation = 4,
  Endorsement = 5,
  Renewal = 6,
  RenewalManager = 7,
}

export enum ConfirmationBoxType {
  productListingAction = 1,
}

export enum ProposalCommandSection {
  CreateOrEdit = 1,
  Endorsement = 33,
  Cancellation = 36,
}

export enum ProposalCommandAction {
  /** Create new or edit existing proposal */
  CreateOrEdit = 1,
  /** Save Proposal and recalculate premium */
  Save = 2,
  /** Submit Proposal */
  Submit = 3,
  /** Save Proposal and recalculate premium */
  Recalculate = 4,
  /** Exit to product listing page */
  Exit = 5,
  /** Underwriter Approve referred case */
  Approve = 6,
  /** Accept approved Quotation */
  Accept = 7,
  /** Open proposal */
  Open = 8,
  /** Underwriter Decline referred case */
  Decline = 9,
  /** Underwriter soft delete referred case */
  MakeRedundant = 10,
  /** ??? */
  SendEmail = 11,
  /** Producer/Underwriter Unsuccessful approved Quotation */
  Unsuccessful = 12,
  /** Producer/Underwriter download various document */
  Download = 13,
  /** Applicable to Malaysia Flexiguard product only. Clear all answer value of a particular proposal */
  AnswerReset = 14,
  /** Applicable to Malaysia Flexiguard product only.  Clear all answer value of all proposals that link to a particular location */
  AnswerResetAll = 15,
  /** Producer/Underwriter to raise endorsement of a particular Policy */
  Endorse = 16,
  /** Producer/Underwriter to raise cancellation of a particular Policy */
  Cancel = 17,
  /** Producer to copy a new Incomplete Proposal from an exisitng record */
  Copy = 18,
  /** Producer to edit approved Quotation */
  EditQuotation = 19,
  /** Producer/Underwriter to raise renewal of a particular Policy */
  Renew = 20,
  /** Underwriter to grant an exisitng Policy from renewal */
  AllowRenewal = 21,
  /** Underwriter to block an exisitng Policy from renewal */
  DeclineRenewal = 22,
  /** Producer/Underwriter to get a quick calculation and underwriter referral message without actual saving the record */
  PremiumCalculationAndReferralQuickProcess = 23,
  /** Producer/Underwriter to change the existing endorsement type */
  EndorsementTypeChange = 24,
  /** Applicable to Malaysia EBiz GCN product only. To change Polisy Asia submission status */
  SubmissionStatusChange = 25,
  /** Applicable to all Malaysia EBiz product only. To change Polisy Asia submission status to cancel with reason provided */
  UnsuccessfulWithReason = 26,
  /** Skip quick quote for internal checking at the front-end */
  SkipQuickQuote = 27,
  /** Underwriter to set force refferal for renewal */
  ForceReferral = 28,
  /** Proceed quick quote for internal checking at the front-end */
  ProceedFormalQuote = 29,
  /** Download quick quote for internal checking at the front-end */
  DownloadQuickQuote = 30,

  /** download  quick quote proposal for internal checking at the front-end */
  DownloadQuickQuoteProposal = 31,
  /** Update ExportStatusID to 6(PendingResend) */
  InterfaceResend = 40,
  /** Producer to edit declined proposal */
  EditDeclinedProposal = 41,
  /** Generate Referral Letter for AML */
  GenerateReferralLetter = 42,
  /** Resend Proposal Data to Codex Interface */
  CodexInterfaceResend = 43,
  /** Send draft to checker */
  SendToChecker = 44,
  /** Return to maker for revise */
  ReturnForRevise = 45,
  /** Refund minimum premium after performed cancellation*/
  CancellationAdjustment = 46,
  ChangeOfCorrespondenceAddress = 47,
}

export enum ProposalStatus {
  /** No status has been assigned */
  None = 0,

  /** Proposal has been approved */
  Approved = 1,

  /** Proposal has been bound */
  Accepted = 2,

  /** Proposal has been referred to an underwriter */
  Referred = 3,

  /** Proposal has been declined */
  Declined = 4,

  /** Proposal has been made redundant */
  Replaced = 5,

  /** Draft, Proposal is incomplete */
  Incomplete = 6,

  /** Proposal has given a quick quote only */
  QuickQuoteApprovedOnly = 7,

  /** Applicable to Taiwan Broker Pro product only. Proposal has been referred from a client to a broker */
  ReferredToBroker = 8,

  /** Quotation has lapsed */
  Lapsed = 9,

  /** Policy has been endorsed */
  Endorsed = 10,

  /** Policy has been renewed */
  Renewed = 11,

  /** Policy has been cancelled */
  Cancelled = 12,

  /** Quotation has not been taken out (unsuccessful) */
  Unsuccessful = 13,

  /** Broker submitted proposal for review */
  Submitted = 14,

  /** Pending for underwriter to determine the referral case */
  PendingUnderwriter = 15,

  /** Pending for broker to submit supporting document */
  PendingBroker = 16,

  /** Complete in the sense of filling up all the mandatory field and insured to at least one coverage.
   * Currently only used for Sunrise integration purposes. */
  Complete = 17,

  /** Draft submitted by maker and now pending for checker to verify and submit */
  PendingChecker = 18,
}

export enum ProposalType {
  /** No proposal type has been assigned */
  None = 0,

  /** It is a referred of incomplete proposal */
  Proposal = 1,

  /** A quotation has been issued */
  Quotation = 2,

  /** The policy has been bound */
  Policy = 3,

  /** The proposal is a pending cancellation */
  Cancellation = 4,

  /** The proposal is a pending endorsement */
  Endorsement = 5,

  /** The proposal is a pending renewal */
  Renewal = 6,
}

export enum ProposalActionResultStatus {
  Undefined = 0,
  Success = 1,
  Failed = 2,
  NotAllowedToLeavePage = 3,
  Mandatory = 4,
  Referred = 5,
  BackDatedAcknowledgementFailed = 6,
  MannualOverwritten = 7,
}

export enum After_ProposalAction {
  DoNothing = 0,
  NavigateToProposalListing = 1,
  NavigateToUrl = 2,
}

/** Location coverage type. Applicable to package product only */
export enum CoverageType {
  Undefined = 0,
  Individual = 1,
  Floating = 2,
}

export enum TrueFalseQuestionAnswer {
  Unanswered = 0,
  Yes = 1,
  No = 2,
}

export enum UiControlType {
  Undefined = 0,

  /** Label web control */
  LabelQuestion = 1,

  /** Yes/No question web control */
  TrueFalseQuestion = 6,

  /** Text question web control */
  ValueQuestion = 7,

  /** Date question web control */
  DateTimeQuestion = 9,

  /** Drop down list question web control */
  DropDownListQuestion = 13,

  /** Date range question web control */
  DateRangeQuestion = 14,

  /** Checkbox array question web control */
  CheckboxQuestion = 16,

  /** Upload file question web control */
  UploadQuestion = 17,

  /** Single checkbox question web control */
  SingleCheckboxQuestion = 19,

  /** Currency Value question web control */
  CurrencyValueQuestion = 33,

  /** Numeric Value question web control */
  NumericAndLabelQuestion = 34,

  /** Email question web control */
  EmailQuestion = 35,

  /** Currency Premium Value question web control */
  CurrencyPremiumValueQuestion = 36,

  /** Single Premium Check Box question web control */
  SinglePremiumCheckBoxQuestion = 37,

  /** Numeric Premium Text question web control */
  NumericPremiumValueQuestion = 38,

  /** Numeric Premium drop down list question web control */
  ComboBoxPremiumValueQuestion = 39,

  /** Text area question web control */
  TextAreaValueQuestion = 40,

  /** Numeric Premium drop down list question web control */
  ComboBoxInputQuestion = 41,
}

export enum CommonForeignType {
  /** For dbo.[Address]. Used by dbo.Orgnisation
   * Currently there is a dbo.Organisation.AddressID column,
   * Every time create/update an organization address, it will create a new entry in dbo.Address with ForeignType = 0,
   * and then update the new AddressID into dbo.Organisation.AddressID table.
   * */
  Undefined = 0,

  /** For dbo.[Address]. Used by dbo.Person. */
  Person = 1,

  /** For dbo.[Address]. Used by dbo.Company (Corporate type client) */
  InsuredCompany = 2,
}

export enum TransType {
  NONE = 0,
  NEW = 1,
  END = 2,
  CAN = 3,
  REN = 4,
}

export enum DocumentType {
  Undefined = 0,
  DebitNote = 1,
  CreditNote = 2,
  CreditAdvice = 3,
  DebitAdvice = 4,
  TaxInvoice = 5,
  Quotation = 6,
  PolicySchedule = 7,
  Endorsement = 8,
  Cancellation = 9,
  Renewal = 10,
  RenewalNotice = 11,
  PolicyWording = 12,
  CreditAdvice_AgentCommission = 13,
  DebitAdvice_AgentCommission = 14,
  PaymentReceipt = 15,
  CoverNote_Draft = 17,
  CoverNote = 18,
  CertificateOfCurrency = 19,
  PolicyScheduleAsAgreed = 20,
  QuotationAsAgreed = 21,
  CancellationAsAgreed = 22,
  CertificateOfCurrencyAsAgreed = 23,
  ProposalForm = 24,
  BinderLetter = 25,
  Quote = 26,
  EndorsementAsAgreed = 27,
  RenewalQuote = 28,
  QuickIndication = 32,
  CancellationBinder = 33,
  MultiQuote = 34,
  RedemptionLetter = 35,
  ChangeInBuildingSumInsured = 36,
  ChangeInCorrespondanceAddress = 37,
  ChangeOfAdditionalInsuredName = 38,
  ChangeInPOI = 39,
  ChangeOfClientInfo = 40,
  CancellationAdjustment = 42,
  RedeemedExpired = 43,
  RedeemedCancelled = 44,
  ChangeOfPlanType = 45,
  RenewalMultiYear = 46,

  GstAdjustment = 47,
}

export enum ErrorType {
  Undefined = 0,
  Unanswered = 1,
  Alert = 2,
  Note = 3,
  Referral = 4,
}

export enum ProposalBusinessRuleType {
  LessThan = 1,
  GreaterThan = 2,
  TrueFalse = 3,
  EqualTo = 4,
  Mandatory = 5,
  ABN = 6,
  Email = 7,
  Date = 8,
  AustralianAddress = 9,
  BooleanRule = 10,
  Undefined = 11,
  HasValue = 12,
  LessThanCompareByFuncRule = 13,
  GreaterThanCompareByFuncRule = 14,
  SumInsuredGreaterThan = 15,
}
export enum fileType {
  Undefined = 0,
  PropsalAttachment = 1,
  Bulletin = 2,
  MessageBoard = 3,
  VisitAppointment = 4,
}

export enum EndorsementType {
  Undefined = 0,
  GeneralEndorsement = 1,
  LoanRedemption = 16,
  ChangeOfPOI = 17,
  ChangeOfSumInsured = 8,
  ChangeOfCorrespondenceAddress = 3,
  ChangeOfAdditionalInsured = 18,
  ChangeOfClientInfo = 19,
  CancellationAdjustment = 20,
  MakeRedundant = 21,
  ChangeOfPlanType = 22,

  GstAdjustment = 23,
}

export enum ManualPaymentType {
  Undefined = 0,
  Cash = 1,
  Cheque = 2,
}

export enum RefundPaymentType {
  Undefined = 0,
  Cheque = 1,
  OnlineTransfer = 2,
  Paynow = 3,
  EFT = 4,
}

export enum RefundPaymentPayNowType {
  UEN = 1,
  NRIC = 2,
  MobileNo = 3,
}

export enum RefundStatus {
  PendingRefundToIndividualInReview = "Pending Refund To Individual In Review",
  PendingRefundToIndividualInReviewAdHoc = "Pending Refund To Individual In Review - Ad Hoc",
  PendingRefundToIndividualInReviewNA = "Pending Refund To Individual In Review - NA",
}

export enum UserOrganisation {
  DBS = "DBS",
  Citi = "Citi",
}

export enum AuthTypes {
  Normal = 0,
  ADB2C = 2,
}
export enum Gender {
  Undefined = 0,
  Male = 1,
  Female = 2,
}
export enum MaritalStatus {
  Undefined = 0,
  Single = 1,
  Married = 2,
  Widowed = 3,
  Divorced = 4,
  Separated = 5,
}
