import { Identity } from "./identity";
import { UserType } from "./../../framework/enum.shared";

export class IdentityRole {
  public isUnderwriter: boolean = false;
  public isBroker: boolean = false;
  public isAdmin: boolean = false;
  public hasProductReadWriteRoles: boolean = false;
  public hasProductTransactRoles: boolean = false;
  public hasFinanceAccountingRoles: boolean = false;

  public isMaker: boolean = false;
  public isChecker: boolean = false;
  public isClaimant: boolean = false;
  public isInternalUser: boolean = false;
  public isPrimaryInternalUser: boolean = false;
  public isFinanceAccounting: boolean = false;
  public isProductReadOnly: boolean = false;
  public isReporter: boolean = false;
  public isExportReporter: boolean = false;
  public isOperationUnderwriter: boolean = false;
  public IsCbsOps: boolean = false;
  public IsCSCBGSG: boolean = false;

  private userType?: UserType;

  constructor(private identity: Identity) {
    this.init();
  }

  init() {
    this.userType = this.identity.currentUser().userType;
    switch (this.userType) {
      case UserType.Underwriter:
        this.isUnderwriter = true;
        break;

      case UserType.Broker:
        this.isBroker = true;
        break;

      case UserType.Admin:
        this.isAdmin = true;
        break;

      default:
        // do nothing
        break;
    }

    if (this.identity.isInUserRole("PolicySubmissionMaker")) {
      this.isMaker = true;
    }

    if (this.identity.isInUserRole("PolicySubmissionChecker")) {
      this.isChecker = true;
    }

    if (this.identity.isInUserRole("PolicyClaims")) {
      this.isClaimant = true;
    }

    if (this.identity.isInUserRole("InternalUser")) {
      this.isInternalUser = true;
    }

    if (this.identity.isInUserRole("PrimaryInternalUser")) {
      this.isPrimaryInternalUser = true;
    }

    if (this.identity.isInUserRole("FinanceAccounting")) {
      this.isFinanceAccounting = true;
    }

    if (this.identity.isInUserRole("Reports")) {
      this.isReporter = true;
    }

    if (this.identity.isInUserRole("ExportReports")) {
      this.isExportReporter = true;
    }

    if (this.identity.isInUserRole("ProductReadOnly")) {
      this.isProductReadOnly = true;
    }

    if (this.identity.isInUserRole("CustomerService_Operations")) {
      this.isProductReadOnly = true;
      this.IsCbsOps = true;
    }

    if (this.identity.isInUserRole("OperationUnderwriter")) {
      this.isOperationUnderwriter = true;
    }

    if (this.identity.isInUserRole("CustomerService_CBS_SG")) {
      this.isProductReadOnly = true;
      this.IsCSCBGSG = true;
    }

    /*check is underwriter*/
    if (
      this.isChecker ||
      this.isMaker ||
      this.isUnderwriter ||
      this.isClaimant ||
      this.IsCbsOps ||
      this.isProductReadOnly ||
      this.IsCSCBGSG
    ) {
      this.hasProductReadWriteRoles = true;
    }

    if (this.isMaker || this.isUnderwriter) {
      this.hasProductTransactRoles = true;
    }
  }
}
