import { Component } from "@angular/core";
import { UploadExcelService } from "src/app/services/upload-excel.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ExcelTemplateService } from "src/app/services/excel-template.service";
import { BaseUploadComponent } from "../base-upload/base-upload.component";
import { Global } from "src/app/shared/global";

@Component({
  selector: "app-refund-upload",
  templateUrl: "./refund-upload.component.html",
  styleUrls: ["./refund-upload.component.scss"],
})
export class RefundUploadComponent extends BaseUploadComponent {
  refundPaymentType: string = "cheque";

  constructor(
    public override uploadService: UploadExcelService,
    public override router: Router,
    public override route: ActivatedRoute,
    public override excelTemplate: ExcelTemplateService
  ) {
    super(uploadService, router, route, excelTemplate);
  }

  public onChange_refundPaymentRadio(): void {}

  downloadTemplate(): void {
    this.excelTemplate.downloadTemplate(
      columns,
      "Refund",
      "RefundUploadTemplate"
    );
  }

  downloadPayNowTemplate(): void {
    this.excelTemplate.downloadTemplate(
      columnsPayNow,
      "Refund",
      "RefundUploadTemplatePayNow"
    );
  }

  downloadEFTTemplate(): void {
    this.excelTemplate.downloadTemplate(
      columnsEFT,
      "Refund",
      "RefundUploadTemplateEFT"
    );
  }

  override addExcel() {
    this.isProcessing = true;
    this.errorLogs.Logs = []; //reset errorLogs

    const uploadedExcelFormData = this.getUploadedExcelFiles(this.uploadExcel);

    this.uploadExcel.segmentDetails = this.refundPaymentType;
    uploadedExcelFormData.append(
      "refundPaymentType",
      JSON.parse(JSON.stringify(this.uploadExcel.segmentDetails))
    );

    this.uploadService.uploadRefund(uploadedExcelFormData).subscribe(
      (result: any) => {
        let tempData: any = null;
        //no longer result.data as in angularJs but result.body
        if (
          result != undefined &&
          result.body != undefined &&
          result.body != null
        ) {
          if (result.body.Status === false) {
            tempData = {
              message: result.body.Message,
              validationMessage: result.body.ValidationMessage,
              status: false,
            };
          } else {
            tempData = {
              message: result.body.Message,
              status: true,
            };
          }

          this.redirectOpened = true;
          this.redirectData = tempData;
          this.isProcessing = false;
        }
      },
      (error: any) => {
        console.log(error);
        this.errorLogs.Logs = new Array<string>();
        this.errorLogs.Title = "Error";

        const log: string =
          Global.isDefined(error?.data?.ExceptionMessage) &&
          error.data.ExceptionMessage !== ""
            ? error.data.ExceptionMessage
            : "Failed to upload excel file. Please contact the system administrator.";

        this.errorLogs.Logs.push(log);
        this.isProcessing = false;
      }
    );
  }
}

const columns: string[] = [
  "Policy Certificate Number",
  "Payee Name",
  "Cheque Issuing Bank",
  "Cheque Number",
  "Remarks",
];

const columnsPayNow: string[] = [
  "Policy Certificate Number",
  "Payer Name",
  "PayNow ID Type (UEN/NRIC/Mobile No)",
  "PayNow ID (4 characters)",
  "PayNow Date",
  "PayNow Issuing Bank",
  "Remarks",
];

const columnsEFT: string[] = [
  "Policy Certificate Number",
  "Payer Name",
  "Bank Account Number",
  "Bank Name",
  "EFT Date",
  "EFT Issuing Bank",
  "Remarks",
];
