import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  EventEmitter,
} from "@angular/core";
import {
  RefundPaymentEntity,
  OpenItemEntity,
  RefundPaynowEntity,
} from "../models/open-item-list.model";
import {
  dlgModelArgs,
  MdConfirmationMessageV1Component,
  v1DlgInputArgs,
} from "../../../shared/partials/md-confirmation-message-v1/md-confirmation-message-v1.component";
import { RefundPaymentType } from "src/app/framework/enum.shared";
import { OpenItemStatus } from "src/app/finance/finance-enums";
import { proposalCommon } from "src/app/components/new-product/base/utils/proposal-common";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { common } from "src/app/framework/utils/common";
import { OpenItemListService } from "src/app/services/open-item-list.service";
import { PaynowEditorComponent } from "./paynow-editor/paynow-editor.component";

@Component({
  selector: "app-do-refund-pay-form",
  templateUrl: "./do-refund.component.html",
  styleUrls: ["./do-refund.component.scss"],
})
export class DoRefundComponent implements OnInit {
  public refundPayment = new RefundPaymentEntity();
  public openItemId!: string;
  public openItemEntity = new OpenItemEntity();
  public totalPremium: any;
  public active = false;
  public opened: boolean = false;
  public isHideRefundPaymentTypeDDL: boolean = true;
  public isHideOtherBanktext: boolean = false;

  productRadio: string | undefined;
  refundType!: RefundPaymentType;
  refundTypeDisplayText!: string;

  BankName!: string;
  refundType_PendingRefundToIndividualInReview!: string;
  EFTdate!: string;
  refundTypeOptions: Array<{ text: string; value: string }> = [
    //{ text: "All", value: "" },
    { text: "Cheque", value: RefundPaymentType.Cheque.toString() },
    //{ text: "PayNow", value: "3" }, //only for dbs, so add after init
  ];

  public defaultMigratedItem: { text: string; value: string } = {
    text: "Select Refund Payment Type",
    value: "",
  };

  ChangetoStatusOptions: Array<{ text: string; value: string }> = [
    { text: "Refunded", value: "1" },
    { text: "Rejected", value: "2" },
  ];

  public defaultChangetoStatusItem: { text: string; value: string } = {
    text: "-- Select status --",
    value: "",
  };

  bankNameOptions: Array<{ text: string; value: string }> = [
    { text: "UOB", value: "1" },
    { text: "DBS", value: "2" },
    { text: "POSBank", value: "3" },
    { text: "OCBC", value: "4" },
    { text: "HSBC Corporate", value: "5" },
    { text: "HSBC Personal", value: "6" },
    { text: "SBI", value: "7" },
    { text: "Standard Chartered", value: "8" },
    { text: "Citibank", value: "9" },

    { text: "Other", value: "10" },
  ];

  public defaultbankNameItem: { text: string; value: string } = {
    text: "Select bank",
    value: "",
  };

  branchOptions: Array<{ text: string; value: string }> = [
    { text: "UOB Main", value: "001" },
    { text: "UOB City Square Mall", value: "002" },
    { text: "UOB PLQ", value: "003" },
    { text: "UOB Tai Seng", value: "004" },
  ];

  public defaultbranchItem: { text: string; value: string } = {
    text: "Select branch",
    value: "",
  };

  public refundPaynow = new RefundPaynowEntity();
  @ViewChild("childPaynowComponent", { static: false })
  childPaynowComponent!: PaynowEditorComponent;

  @Input() public set model(openItemEntity: any) {
    this.refundPayment.openItemId = openItemEntity.openItemId;
    this.productRadio = openItemEntity.productRadio;
  }
  @Output() cancel: EventEmitter<undefined> = new EventEmitter();
  @Output() doRefund: EventEmitter<RefundPaymentEntity> = new EventEmitter();
  //validation
  invalidRefundType_PendingRefundToIndividualInReview = false;
  invalidRequiredField = false;
  invalidPayerName = false;
  invalidCIB = false;
  invalidCN = false;
  invalidRemark = false;
  invalidChangetoStatus = false;
  invalidbankAccountNumber = false;
  invalidbankName = false;
  invalidbranch = false;
  invalidotherBankName = false;
  invalidEFTdate = false;
  constructor(
    private cdkDialogService: CdkDialogService,
    private openItemListService: OpenItemListService
  ) {}

  ngOnInit(): void {
    this.getOpenItemDetails();
  }
  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
  }
  public on_ChangetoStatus_change() {
    this.invalidRequiredField = false;
    this.isHideRefundPaymentTypeDDL = this.refundPayment.changestatus == "1";
  }
  public on_ChangeBankCode_change() {
    this.invalidRequiredField = false;
    this.isHideOtherBanktext = this.refundPayment.bankcode == 10;
  }
  private getOpenItemDetails() {
    this.openItemListService
      .getOpenItem(this.refundPayment.openItemId)
      .subscribe((response: OpenItemEntity) => {
        const openItem: OpenItemEntity = response;
        this.active = openItem !== undefined;
        this.openItemEntity = openItem;

        this.refundType =
          openItem.statusId === OpenItemStatus.PendingRefundToBankInReview
            ? RefundPaymentType.OnlineTransfer
            : openItem.statusId ===
                OpenItemStatus.PendingRefundToIndividualInReview ||
              openItem.statusId ===
                OpenItemStatus.PendingRefundToIndividualInReviewAdHoc ||
              openItem.statusId ===
                OpenItemStatus.PendingRefundToIndividualInReviewNA
            ? RefundPaymentType.Cheque
            : RefundPaymentType.Undefined;

        const refundTypeDisplayText: string =
          proposalCommon.translateRefundPaymentType(this.refundType);
        this.refundTypeDisplayText = refundTypeDisplayText;

        this.refundPayment.refundPaymentType = this.refundType; //will be updated to PayNow if chosen

        this.addPayNowOption();
      });
  }

  private addPayNowOption() {
    if (this.productRadio && this.productRadio === "dbs") {
      this.refundTypeOptions.push(
        {
          text: "PayNow",
          value: RefundPaymentType.Paynow.toString(),
        },
        {
          text: "EFT",
          value: RefundPaymentType.EFT.toString(),
        }
      );
    }
  }

  public closeForm(): void {
    this.active = false;
    this.cancel.emit();
  }

  ConfirmationPopup(msg: string, data: any) {
    let v1InputData: v1DlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: data,
      message: msg,
    };

    const v1DialogRef = this.cdkDialogService.open(
      MdConfirmationMessageV1Component,
      {
        data: v1InputData,
      }
    );

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationModalCloseForActions(result);
      }
    });
  }

  public onSave(e: PointerEvent): void {
    this.invalidRequiredField = false;

    let isValid: boolean = true;
    if (this.refundType === 1) {
      isValid = this.validateGeneralMandatoryFields();

      if (isValid && this.refundPayment.changestatus != "2") {
        isValid =
          this.refundType_PendingRefundToIndividualInReview === "1"
            ? this.validateChequeMandatoryFields()
            : this.refundType_PendingRefundToIndividualInReview === "4"
            ? this.validateEFTMandatoryFields()
            : this.childPaynowComponent.validatePaynowMandatoryFields();

        if (
          isValid &&
          this.refundType_PendingRefundToIndividualInReview === "3"
        ) {
          this.mapPaynowToRefundPaymentEntity();
        } else if (
          isValid &&
          this.refundType_PendingRefundToIndividualInReview === "4"
        ) {
          this.mapEFTToRefundPaymentEntity();
        } else if (
          isValid &&
          this.refundType_PendingRefundToIndividualInReview === "1"
        ) {
          this.mapChequeToRefundPaymentEntity();
        }
      }
    }

    if (isValid) {
      this.ConfirmationPopup(
        "Are you sure you want to refund this payment?",
        ""
      );
    } else {
      this.invalidRequiredField = true;
      e.preventDefault();
    }
  }

  private mapEFTToRefundPaymentEntity() {
    this.refundPayment.refundPaymentType = RefundPaymentType.EFT;

    if (this.refundPayment.bankcode?.toString() != "10") {
      this.refundPayment.bankName = this.bankNameOptions.filter(
        (x) => x.value == this.refundPayment.bankcode?.toString()
      )[0].text;

      const eftdate = new Date(this.refundPayment.eFTDate);
      this.refundPayment.eFTDate = new Date(
        eftdate.getTime() - eftdate.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];
    }
  }
  private mapChequeToRefundPaymentEntity() {
    this.refundPayment.refundPaymentType = RefundPaymentType.Cheque;
  }
  private mapPaynowToRefundPaymentEntity() {
    this.refundPayment.refundPaymentType = RefundPaymentType.Paynow;

    this.refundPayment.payerName = this.refundPaynow.payerName;
    this.refundPayment.paynowType = this.refundPaynow.paynowType;
    this.refundPayment.paynowLast4Chars = this.refundPaynow.paynowLast4Chars;

    const date = new Date(this.refundPaynow.paynowDate);
    this.refundPayment.paynowDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0]; //use simplest date form

    this.refundPayment.paynowIssuanceBank = this.refundPaynow.issuanceBank;
  }

  private validateGeneralMandatoryFields(): boolean {
    let hasError: boolean = false;
    // validate mandatory fields
    if (this.refundPayment.changestatus != "2") {
      if (
        common.isStringNullOrEmpty(
          this.refundType_PendingRefundToIndividualInReview
        )
      ) {
        hasError = true;
        this.invalidRefundType_PendingRefundToIndividualInReview = true;
      } else {
        this.invalidRefundType_PendingRefundToIndividualInReview = false;
      }

      if (this.refundPayment.changestatus == undefined) {
        hasError = true;
        this.invalidChangetoStatus = true;
      } else {
        this.invalidChangetoStatus = false;
      }
    }

    return !hasError;
  }

  private validateEFTMandatoryFields(): boolean {
    let hasError: boolean = false;
    // validate mandatory fields

    if (this.refundPayment.payerName == undefined) {
      hasError = true;
      this.invalidPayerName = true;
    } else if (this.refundPayment.payerName.toString() == "") {
      hasError = true;
      this.invalidPayerName = true;
    } else {
      this.invalidPayerName = false;
    }
    if (this.refundPayment.bankAccountNumber == undefined) {
      hasError = true;
      this.invalidbankAccountNumber = true;
    } else {
      this.invalidbankAccountNumber = false;
    }
    if (this.refundPayment.bankcode == undefined) {
      hasError = true;
      this.invalidbankName = true;
    } else if (this.refundPayment.bankcode.toString() == "") {
      hasError = true;
      this.invalidbankName = true;
    } else {
      this.invalidbankName = false;
    }

    if (this.refundPayment.bankcode != undefined) {
      if (this.refundPayment.bankcode?.toString() == "10") {
        if (this.refundPayment.bankName == undefined) {
          hasError = true;
          this.invalidotherBankName = true;
        } else {
          this.invalidotherBankName = false;
        }
      }
    }

    if (this.refundPayment.eFTDate == undefined) {
      hasError = true;
      this.invalidEFTdate = true;
    } else {
      this.invalidEFTdate = false;
    }

    return !hasError;
  }
  private validateChequeMandatoryFields(): boolean {
    let hasError: boolean = false;
    // validate mandatory fields
    if (common.isStringNullOrEmpty(this.refundPayment.payerName)) {
      hasError = true;
      this.invalidPayerName = true;
    } else {
      this.invalidPayerName = false;
    }
    if (common.isStringNullOrEmpty(this.refundPayment.chequeIssuanceBank)) {
      hasError = true;
      this.invalidCIB = true;
    } else {
      this.invalidCIB = false;
    }
    if (common.isStringNullOrEmpty(this.refundPayment.chequeNo)) {
      hasError = true;
      this.invalidCN = true;
    } else {
      this.invalidCN = false;
    }
    if (this.refundType === 2) {
      if (common.isStringNullOrEmpty(this.refundPayment.remarks)) {
        hasError = true;
        this.invalidRemark = true;
      } else {
        this.invalidRemark = false;
      }
    }

    return !hasError;
  }
  async confirmationModalCloseForActions(args: dlgModelArgs) {
    if (args.status === "yes") {
      this.doRefund.emit(this.refundPayment);
    }
  }
}
