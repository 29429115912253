import * as EnumSG from "./../../new-product/sme/mortgage-fire/singapore/base/mf-sg-enums";

import { ClientAddressEntity } from "./client-address-entity.model";

export class ClientEntity {
  clientId!: number;
  clientCode?: string;
  clientType!: EnumSG.ClientType;
  productBase!: EnumSG.ProductBase;
  nric!: string;
  passportNo!: string;

  businessRegistrationNumber!: string;
  dbsClientReferenceNumber!: string;
  incorporatedDate!: string;
  personInCharge!: string;
  firstName!: string;
  lastName!: string;
  clientName!: string;

  salutation!: string;
  gender!: string;
  birthDate: any;
  occupation!: string;
  nationality!: string | undefined;

  race!: string;
  maritalStatus!: string;
  phoneNoHome!: string;
  phoneNoOffice!: string;
  mobileNumber!: string;

  pagerNo!: string;
  faxNumber!: string;
  emailAddress!: string;
  address!: ClientAddressEntity[];
  docType!: string;
  cinNo!: string;
}

export class PreferredDeliveryEntity {
  emailAddress!: string;
  mobileNumber!: string;
  address!: ClientAddressEntity[];
}
