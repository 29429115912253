import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment"; //use the new one in src folder, old one mainly for guid.ts only

@Injectable({
  providedIn: "root",
})
export class UploadExcelService {
  SERVER_URL_BOUNCEDMAILPOLICY: string =
    "api/product/home/bouncedMailPolicy/uploadExcel";
  SERVER_URL_LOANREDEMPTION: string =
    "api/product/home/LoanRedemption/uploadExcel";
  SERVER_URL_IBGPROPOSALUPLOAD: string = "api/IBGProposal/uploadExcel";
  SERVER_URL_REVIEWREFUNDUPLOAD: string =    "api/reviewrefundupload/uploadReviewRefundItems";
  SERVER_URL_REVIEWUPLOAD: string = "api/refundupload/uploadRefundItems";
  SERVER_URL_GSTADJUSTMENTUPLOAD: string =
    "api/gstadjustment/uploadGSTAdjustment";
  SERVER_URL_UPLOADRECONCILE: string = "api/reconcile/uploadAndReconcile";
  SERVER_URL_UPLOAD_MULTI_YEAR_AUTO_DEBIT: string =
    "api/product/home/MultiyearAutoDebit/uploadExcel";
  SERVER_URL_UPLOADPOLICYWORDING: string =
    "api/policyWordingSetUp/uploadPolicyWording";
  SERVER_URL_CBG_SG_UPLOAD: string = "api/CBGProposal/uploadExcel";
  SERVER_URL_CBG_SG_REUPLOAD: string = "api/CBGProposal/reuploadExcel";
  SERVER_URL_CBG_CLS_LOAN_REDEMPTION_UPLOAD: string =
    "api/product/home/LoanRedemption/uploadCLSExcel";
    SERVER_URL_REJECTUPLOAD: string =    "api/reviewrefundupload/uploadRejectItems";

  constructor(protected httpClient: HttpClient) {}

  protected upload(formData: any, url: string) {
    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      //Authorization: "Bearer " + this.BEARER, //authData.token,
      //https://stackoverflow.com/questions/48749008/request-content-ismimemultipartcontent-fails
      //'Content-Type':  'multipart/form-data',
      //Session: "27b87e1c-0478-4637-9ced-d531b8191092", //see request() in: ~\app\library\module\interceptor\http-interceptor.ts
    });
    return this.httpClient.post<any>(url, formData, {
      headers: headers,
      reportProgress: true,
      observe: "events",
    });
  }

  protected uploadAsResponse(formData: any, url: string) {
    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      //Authorization: "Bearer " + this.BEARER, //authData.token,
      //https://stackoverflow.com/questions/48749008/request-content-ismimemultipartcontent-fails
      //'Content-Type':  'multipart/form-data',
      //Session: "27b87e1c-0478-4637-9ced-d531b8191092", //see request() in: ~\app\library\module\interceptor\http-interceptor.ts
    });
    return this.httpClient.post<any>(url, formData, {
      headers: headers,
      reportProgress: true,
      //observe: "events",
      observe: "response",
    });
  }

  public uploadBouncedMailPolicy(formData: any) {
    return this.upload(
      formData,
      environment.serviceBase + this.SERVER_URL_BOUNCEDMAILPOLICY
    );
  }

  public uploadAutoLoanRedemption(formData: any) {
    return this.upload(
      formData,
      environment.serviceBase + this.SERVER_URL_LOANREDEMPTION
    );
  }

  public uploadIbgProposal(formData: any) {
    return this.upload(
      formData,
      environment.serviceBase + this.SERVER_URL_IBGPROPOSALUPLOAD
    );
  }

  public uploadReviewRefund(formData: any) {
    return this.upload(
      formData,
      environment.serviceBase + this.SERVER_URL_REVIEWREFUNDUPLOAD
    );
  }

  public uploadRefund(formData: any) {
    return this.upload(
      formData,
      environment.serviceBase + this.SERVER_URL_REVIEWUPLOAD
    );
  }

  public uploadReject(formData: any) {
    return this.upload(
      formData,
      environment.serviceBase + this.SERVER_URL_REJECTUPLOAD
    );
  }

  public uploadGSTAdjustment(formData: any) {
    return this.upload(
      formData,
      environment.serviceBase + this.SERVER_URL_GSTADJUSTMENTUPLOAD
    );
  }

  generateGSTAdjustmentReport(reportType: string) {
    var url = "api/gstadjustment/generateGSTAdjustmentReport";
    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      "Content-Type": "application/x-www-form-urlencoded",
    });
    const params = {
      reportType: reportType,
    };

    return this.httpClient.get(environment.serviceBase + url, {
      headers: headers,
      params: params,
      responseType: "arraybuffer",
      observe: "response",
    });
  }

  public generateAnchorAndTriggerDownload(data: any, fileName: any): void {
    const file = new Blob([data], { type: "application/octet-stream" });

    const nav = window.navigator as any;
    if (nav && nav.msSaveOrOpenBlob) {
      nav.msSaveOrOpenBlob(file, fileName);
    } else {
      const url = window.URL.createObjectURL(file);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display: none");
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  }

  public uploadReconcile(formData: any) {
    return this.upload(
      formData,
      environment.serviceBase + this.SERVER_URL_UPLOADRECONCILE
    );
  }
  public uploadMultiYearAutoDebit(formData: any) {
    return this.upload(
      formData,
      environment.serviceBase + this.SERVER_URL_UPLOAD_MULTI_YEAR_AUTO_DEBIT
    );
  }
  public uploadPolicyWording(formData: any) {
    return this.upload(
      formData,
      environment.serviceBase + this.SERVER_URL_UPLOADPOLICYWORDING
    );
  }

  public async doBulkAction(bulkActionModel: any): Promise<BulkActionResponse> {
    const putData = JSON.stringify(bulkActionModel);

    const headers = new HttpHeaders({
      Accept: "application/octet-stream;",
      //Authorization: "Bearer " + this.BEARER, //authData.token,
      //https://stackoverflow.com/questions/23118249/whats-the-difference-between-request-payload-vs-form-data-as-seen-in-chrome
      //In Chrome, request with 'Content-Type:application/json' shows as Request PayedLoad and sends data as json object.
      //But request with 'Content-Type:application/x-www-form-urlencoded' shows Form Data and sends data as Key:Value Pair
      "Content-Type": "application/json", //"application/x-www-form-urlencoded",
      //Session: this.SESSION_TOKEN, //see request() in: ~\app\library\module\interceptor\http-interceptor.ts
    });

    // User AuthData required in HTTP POST
    //const authData: Interface.IAuthenticationData = this.localStorageService.get(Identity.AUTH_STORAGE_NAME);

    var url = "api/proposalBulkAction/BulkAction";

    const response = await this.httpClient
      .put<BulkActionResponse>(environment.serviceBase + url, putData, {
        headers: headers,
      })
      .toPromise();
    return response;
  }
  public uploadCbgSgUploadExcel(formData: any) {
    return this.uploadAsResponse(
      formData,
      environment.serviceBase + this.SERVER_URL_CBG_SG_UPLOAD
    );
  }
  public uploadCbgSgReUploadExcel(formData: any) {
    return this.upload(
      formData,
      environment.serviceBase + this.SERVER_URL_CBG_SG_REUPLOAD
    );
  }

  public uploadCbgCLSLoanRedemptionUploadExcel(formData: any) {
    return this.uploadAsResponse(
      formData,
      environment.serviceBase + this.SERVER_URL_CBG_CLS_LOAN_REDEMPTION_UPLOAD
    );
  }
}

interface BulkActionResponse {
  Status: boolean;
  ValidationMessage: string;
}

export class IBGLoanRedemptionFailureEntity {
  rowIndex!: string;
  BankRefNo!: string;
  PolicyNo!: string;
  RiskAddress!: string;
  MITDDate!: string;
  InsuranceAction!: string;
  Remarks!: string;
  MSBRDateReceived!: string;
  InsuredName!: string; //citi
  //citi
}
export class IBGLoanRedemptionSuccessEntity {
  rowIndex!: string;
  BankRefNo!: string;
  PolicyNo!: string;
  RiskAddress!: string;
  PolicyStatus!: string;
  InsuredName!: string;
  MITDDate!: string;
  RefundPremium!: string;
  MSBRDateReceived!: string;
  Remarks!: string;
  MSBRDate!: string;
}
