import { Injectable } from "@angular/core";
import { common } from "src/app/framework/utils/common";
import { Identity } from "src/app/shared/user/identity";
import { IdentityRole } from "src/app/shared/user/identity-role";
import * as Enum from "src/app/framework/enum.shared";
import { IIdentity } from "../framework/interface.shared";
import { MsalService } from "@azure/msal-angular";
import { SessionStorageService } from "./storages/session-storage.service";
import { BroadcastService } from "./broadcast.service";

@Injectable({
  providedIn: "root",
})
export class IdentityRolesService {
  public isSuperAdmin: boolean = false;
  public identityRole!: IdentityRole;
  public isUnderwriter: boolean = false;
  public isOperationUnderwriter: boolean = false;
  public isMarketer: boolean = false;
  public isChecker: boolean = false;
  public isMaker: boolean = false;

  public isBroker: boolean = false;
  public isAdmin: boolean = false;
  public hasProductReadWriteRoles: boolean = false;
  public hasProductTransactRoles: boolean = false;
  public hasFinanceAccountingRoles: boolean = false;

  public isClaimant: boolean = false;
  public isInternalUser: boolean = false;
  public isPrimaryInternalUser: boolean = false;
  public isFinanceAccounting: boolean = false;
  public isProductReadOnly: boolean = false;
  public isReporter: boolean = false;
  public isExportReporter: boolean = false;
  public IsCbsOps: boolean = false;
  public IsCSCBGSG: boolean = false;

  public showIntermedairy: boolean = false;
  public showUserProfile: boolean = false;
  public showDashboard: boolean = false;
  public showSOA: boolean = false;
  public showMyAMS: boolean = false;
  public showCampaigns: boolean = false;
  public showFlexiguard: boolean = false;
  public showSalesMgmt: boolean = false;
  public showRulesExtension: boolean = false;
  public showRenewalListing: boolean = false;
  public showSPE: boolean = false;
  public showReports: boolean = false;
  public showRecruitment: boolean = false;
  public showVisitation: boolean = false;
  public showInterfaceMaintenance: boolean = false;
  public visitURL: string = "";
  public showSingleSignOn: boolean = false;
  public showACRS: boolean = false;
  public showIPAFrontEnd: boolean = false;
  public isThailandUser: boolean = false;
  // set url for visitation
  public showPaymentGatewayTransaction: boolean = false;
  public showCCPaymentDetails: boolean = false;
  public showAdminTab: boolean = false;
  public showMiscTab: boolean = false;
  public isManager: boolean = false;
  public showTraining: boolean = false;
  public showPlanningTab: boolean = false;
  public showReportTab: boolean = false;
  public showStaticDocTab: boolean = false;
  public isIBGProductTab: boolean = false;
  public isCitiProductTab: boolean = false;
  public productListing: any;

  constructor(
    private Identity: Identity,
    private msalService: MsalService,
    private sessionStorageService: SessionStorageService,
    private broadcastService: BroadcastService
  ) {
    //when log-in, the sessionStorageService might not yet init-ed, hence needs to sub to a broadcast
    this.broadcastService.onAutheticated.subscribe((msg: any) => {
      // reset flag for all user type
      this.resetValues();
      this.SetRoles();
    });
  }

  private SetRoles() {
    if (!common.isUndefinedOrNull(this.Identity)) {
      if (this.Identity.isInUserRole("SuperAdmin_Role")) {
        this.isSuperAdmin = true;
      }
      //from old angularjs: ~\library\module\application\core\components\menu\horizontal-menu\horizontal-menu-component.ts
      //and : ~\library\module\shared\user\identity-role.ts
      /*check is underwriter*/
      let userType = this.Identity.currentUser().userType;

      switch (userType) {
        case Enum.UserType.Underwriter:
          this.isUnderwriter = true;
          break;
        case Enum.UserType.Broker:
          this.isBroker = true;
          break;

        case Enum.UserType.Admin:
          this.isAdmin = true;
          if (this.Identity.currentUser().roles["SuperAdmin_Role"]) {
            this.isSuperAdmin = true;
          }
          break;

        default:
          // do nothing
          break;
      }
      /*check is marketer*/
      this.isMarketer =
        this.Identity.currentUser().userType === Enum.UserType.Marketer;
      /*check is checker*/
      if (this.Identity.isInUserRole("PolicySubmissionChecker")) {
        this.isChecker = true;
      }
      /*check is maker*/
      if (this.Identity.isInUserRole("PolicySubmissionMaker")) {
        this.isMaker = true;
      }

      /*chec is operationUnderwtiter*/
      if (this.Identity.isInUserRole("OperationUnderwriter")) {
        this.isOperationUnderwriter = true;
      }
      this.identityRole = this.Identity.role;

      if (this.Identity.isInUserRole("PolicyClaims")) {
        this.isClaimant = true;
      }

      if (this.Identity.isInUserRole("InternalUser")) {
        this.isInternalUser = true;
      }

      if (this.Identity.isInUserRole("PrimaryInternalUser")) {
        this.isPrimaryInternalUser = true;
      }

      if (this.Identity.isInUserRole("FinanceAccounting")) {
        this.isFinanceAccounting = true;
      }

      if (this.Identity.isInUserRole("Reports")) {
        this.isReporter = true;
      }

      if (this.Identity.isInUserRole("ExportReports")) {
        this.isExportReporter = true;
      }

      if (this.Identity.isInUserRole("ProductReadOnly")) {
        this.isProductReadOnly = true;
      }

      if (this.Identity.isInUserRole("CustomerService_Operations")) {
        this.isProductReadOnly = true;
        this.IsCbsOps = true;
      }

      if (this.Identity.isInUserRole("OperationUnderwriter")) {
        this.isOperationUnderwriter = true;
      }

      if (this.Identity.isInUserRole("CustomerService_CBS_SG")) {
        this.isProductReadOnly = true;
        this.IsCSCBGSG = true;
      }

      /*check is underwriter*/
      if (
        this.isChecker ||
        this.isMaker ||
        this.isUnderwriter ||
        this.isClaimant ||
        this.IsCbsOps ||
        this.isProductReadOnly ||
        this.IsCSCBGSG
      ) {
        this.hasProductReadWriteRoles = true;
      }

      if (this.isMaker || this.isUnderwriter) {
        this.hasProductTransactRoles = true;
      }

      // Access Control Specifications
      this.showIntermedairy = this.Identity.isInRole("Intermediary_View");
      this.showUserProfile = this.Identity.isInRole("UserProfile_View");
      this.showDashboard = this.Identity.isInRole("Dashboard_View");
      this.showSOA = this.Identity.isInRole("SOA_View");
      this.showMyAMS = this.Identity.isInRole("MyAMS_View");
      this.showCampaigns = this.Identity.isInRole("Campaigns_View");
      this.showFlexiguard = this.Identity.isInRole("FlexiGuard_View");
      this.showSalesMgmt = this.Identity.isInRole("SalesManagement_View");
      this.showRulesExtension = this.Identity.isInRole("RulesExtension_View");
      this.showRenewalListing = this.Identity.isInRole("Underwriter_Role");
      this.showSPE = this.Identity.isInRole("SalesPlanAcess_View");
      this.showReports = this.Identity.isInRole("Underwriter_Role");
      this.showRecruitment = this.Identity.isInRole("Recruitment_View");
      this.showVisitation = this.Identity.isInRole("Visitation_View");
      this.visitURL = "agencyvisitation.management";
      this.showSingleSignOn = this.Identity.isInRole("SingleSignOn_View");
      this.showACRS = this.Identity.isInRole("ACRS_View");
      this.showIPAFrontEnd = this.Identity.isInRole("IPAFrontEnd_View");
      this.isThailandUser = false;
      if (this.Identity.currentUser().CountryISO === "TH") {
        this.isThailandUser = true;
      }
      // set url for visitation
      this.showPaymentGatewayTransaction = true;
      this.showCCPaymentDetails = true;

      this.showInterfaceMaintenance = false;
      this.showAdminTab = this.Identity.isInRole("AdminTab_View");
      this.showTraining = this.Identity.isInRole("TrainingTab_View");
      this.showReportTab = this.Identity.isInRole("ReportsTab_View");
      this.showPlanningTab = this.Identity.isInRole("PlanningTab_View");
      this.showStaticDocTab = this.Identity.isInRole("StaticDocTab_View");

      if (this.Identity.currentUser().id === 1) {
        this.showInterfaceMaintenance = true;
      }

      if (
        this.Identity.currentUser().underwriterControlGroupId === 7 ||
        this.Identity.currentUser().underwriterControlGroupId === 8 ||
        this.Identity.currentUser().underwriterControlGroupId === 9
      ) {
        this.isManager = true;
        this.showDashboard = false;
        //this.NavLeftMenu.dashboard = false;
        this.showTraining = true;
        this.showSOA = true;
        this.showPlanningTab = true;
        this.showMiscTab = true;
        this.showReportTab = false;
        this.showStaticDocTab = false;
      } else {
        this.isManager = false;
      }

      // product tab checking
      this.productListing =
        this.Identity.currentUser().products !== undefined
          ? this.Identity.currentUser().products
          : "";

      if (common.isDefined(this.productListing)) {
        this.productListing.forEach((element: { pdid: string }) => {
          if (
            element.pdid.toLowerCase() ===
              "06cb09d3-6fe0-4695-92a8-a61dfb627c61" ||
            element.pdid.toLowerCase() ===
              "8533fa05-f190-4a29-9613-3db7f693dd32"
          ) {
            this.isIBGProductTab = true;
          }

          if (
            element.pdid.toUpperCase() ===
              "A8CDAC65-3EC4-4138-974A-D18C3EF8536C" ||
            element.pdid.toUpperCase() ===
              "1F7EE805-C8CE-416C-AF29-292E920878F8" ||
            element.pdid.toUpperCase() ===
              "B8A348CA-491C-48DA-988A-FCC52F7FC5C5"
          ) {
            this.isCitiProductTab = true;
          }
        });
      }
    }
  }

  refreshCurrentUser() {
    //this is when browser reload
    this.Identity.populateCurrentUser(true);
  }

  isLoggedIn(): boolean {
    return this.msalService.instance.getActiveAccount() != null;
  }

  logOut() {
    if (this.isLoggedIn()) {
      this.sessionStorageService.clear(); //clear sessionStorage
      this.clearAuthData(); //clear Identity
      this.msalService.instance.logout();
    }
  }

  public clearAuthData() {
    this.Identity.clearAuthData();
  }

  getIdentity(): IIdentity {
    return this.Identity;
  }

  resetValues() {
    this.isSuperAdmin = false;
    this.isUnderwriter = false;
    this.isOperationUnderwriter = false;
    this.isMarketer = false;
    this.isChecker = false;
    this.isMaker = false;

    this.isBroker = false;
    this.isAdmin = false;
    this.hasProductReadWriteRoles = false;
    this.hasProductTransactRoles = false;
    this.hasFinanceAccountingRoles = false;

    this.isClaimant = false;
    this.isInternalUser = false;
    this.isPrimaryInternalUser = false;
    this.isFinanceAccounting = false;
    this.isProductReadOnly = false;
    this.isReporter = false;
    this.isExportReporter = false;
    this.IsCbsOps = false;
    this.IsCSCBGSG = false;

    this.showIntermedairy = false;
    this.showUserProfile = false;
    this.showDashboard = false;
    this.showSOA = false;
    this.showMyAMS = false;
    this.showCampaigns = false;
    this.showFlexiguard = false;
    this.showSalesMgmt = false;
    this.showRulesExtension = false;
    this.showRenewalListing = false;
    this.showSPE = false;
    this.showReports = false;
    this.showRecruitment = false;
    this.showVisitation = false;
    this.showInterfaceMaintenance = false;
    this.showSingleSignOn = false;
    this.showACRS = false;
    this.showIPAFrontEnd = false;
    this.isThailandUser = false;
    // set url for visitation
    this.showPaymentGatewayTransaction = false;
    this.showCCPaymentDetails = false;
    this.showAdminTab = false;
    this.showMiscTab = false;
    this.isManager = false;
    this.showTraining = false;
    this.showPlanningTab = false;
    this.showReportTab = false;
    this.showStaticDocTab = false;
    this.isIBGProductTab = false;
    this.isCitiProductTab = false;
  }
}
