import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { ClientAddressEntity } from "src/app/models/customer/base/client-address-entity.model";
import { MfSgProposalService } from "src/app/services/new-product/sme/mortgage-fire/singapore/mf-sg-proposal.service";
import { MfSgBaseComponent } from "../../mf-sg-base.component";
import * as Enum from "src/app/framework/enum.shared";
import { MasterDataService } from "src/app/services/master-data.service";
import { common } from "src/app/framework/utils/common";
import { Culture } from "src/app/shared/date-formatter";
import { ClientService } from "src/app/services/customer/client.service";
import { dialogActionNotifier } from "src/app/models/new-product/notifier/dialog-action-notifier";

@Component({
  selector: "mf-sg-client-view",
  templateUrl: "./mf-sg-client-view.component.html",
  styleUrls: ["./mf-sg-client-view.component.scss"],
})
export class MfSgClientViewComponent
  extends MfSgBaseComponent
  implements OnInit
{
  id!: string;
  clientViewData: any;
  address!: ClientAddressEntity;
  noAnswerDefaultText: string = "Unknown";
  countryList!: any[];
  nationalityList!: any[];
  contactInformation: string = "";
  isStateVisible: boolean = false;
  countryExcludeState: any = ["SG", "HK"];
  clientSalutationDictionary: { [key: number]: string } = {
    0: "Unknown",
    1: "Capt",
    2: "Dr.",
    3: "Madam",
    4: "Miss",
    5: "Mr",
    6: "Mrs",
    7: "Ms",
    8: "Prof",
    9: "Sir",
    10: "Tan Sir",
    13: "Others",
  };

  clientRaceDictionary: { [key: number]: string } = {
    0: "Unknown",
    1: "Chinese",
    2: "Indian",
    3: "Malay",
    4: "Others",
  };

  clientGenderDictionary: { [key: number]: string } = {
    0: "Unknown",
    1: "Male",
    2: "Female",
  };

  clientMaritalStatusDictionary: { [key: number]: string } = {
    0: "Unknown",
    1: "Single",
    2: "Married",
    3: "Widowed",
    4: "Divorced",
    5: "Separated",
  };

  clientDocumentTypePrefixDictionary: { [key: number]: string } = {
    0: "Unknown",
    1: "Singapore NRIC",
    2: "Singapore PR",
    3: "Malaysia IC",
    4: "Foreign Passport",
  };

  private dialogOkNotifierSubscription!: Subscription;
  isEditable: boolean = false;
  isProcessing = false;

  constructor(
    private proposalService: MfSgProposalService,
    private clientService: ClientService,
    private masterDataService: MasterDataService,
    private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    if (
      this.proposalService.proposal.proposalStatus ===
        Enum.ProposalStatus.Incomplete &&
      this.proposalService.proposal.transType === Enum.TransType.NEW
    ) {
      this.isEditable = true;
    }

    super.subscribeTabLoadRenderer();
    this.initialize();
    this.dialogOkNotifierSubscription =
      dialogActionNotifier.ok_notifier$.subscribe((event) => {});
  }

  ngOnDestroy() {
    this.unsubscribeRxjs();
    this.dialogOkNotifierSubscription.unsubscribe();
  }

  verifyStateCountry(answer: string) {
    const elementPos = this.countryExcludeState
      .map(function (x: any) {
        return x;
      })
      .indexOf(answer);
    if (elementPos > -1) {
      this.isStateVisible = false;
    } else {
      this.isStateVisible = true;
    }
  }

  //think no use
  // confirmationModalCloseForActions = (data: any): void => {
  //     if (data.confirmation) {
  //         // map to customer mapping id
  //         this.proposalService.proposal.customerMappingId = data.clientId;
  //         this.proposalService.proposal.customerMappingAddressID = data.clientAddressId;
  //         // this.ClientService.search();
  //         if (common.isDefined(data.clientId)) {
  //             this.clientService.get(data.clientId, data.clientCode).then((response) => {
  //                 if (response['clientType'] === 1) {
  //                     this.proposalService.proposal.qsMaster.qs_master_insuredName.answer =
  //                         response['lastName'] + ' ' + response['firstName'];
  //                 } else {
  //                     this.proposalService.proposal.qsMaster.qs_master_insuredName.answer = response['clientName'];
  //                 }

  //             this.proposalService.proposal.qsMaster.qs_master_insuredNo.answer = response['clientCode'];
  //             this.proposalService.proposal.qsMaster.qs_master_mobileNumber.answer = response['mobileNumber'];
  //             this.proposalService.proposal.qsMaster.qs_master_emailID.answer = response['emailAddress'];
  //             let contactInfo = '';
  //             if (response['address'][0]['countryISO2'] === 'SG') {
  //                 contactInfo = response['address'][0]['addressLine1'] + ' ' +
  //                     this.getAddressFormat(response['address'][0]['addressLine2']) +
  //                     this.getAddressFormat(response['address'][0]['unitNo']) +
  //                     this.getAddressFormat(response['address'][0]['buildingName']) +
  //                     this.getDisplayTextIfUnknown(response['address'][0]['countryISO2'], 'country') + ' ' +
  //                     this.getAddressFormat(response['address'][0]['postCode']);
  //             } else {
  //                 contactInfo =
  //                     this.getAddressFormat(response['address'][0]['unitNo']) +
  //                     this.getAddressFormat(response['address'][0]['buildingName']) +
  //                     this.getAddressFormat(response['address'][0]['addressLine1']) +
  //                     this.getAddressFormat(response['address'][0]['addressLine2']) +
  //                     this.getAddressFormat(response['address'][0]['postCode']) +
  //                     this.getAddressFormat(response['address'][0]['city']) +
  //                     this.getAddressFormat(response['address'][0]['stateName']) +
  //                     this.getDisplayTextIfUnknown(response['address'][0]['countryISO2'], 'country');

  //                 }

  //                 this.contactInformation = contactInfo;
  //                 angular.element('#clientContactInfo p').text('');
  //                 angular.element('#clientContactInfo p').text(contactInfo);

  //             });
  //         }
  //     }
  // }

  getAddressFormat = (input: any) => {
    if (!common.isUndefinedOrNull(input)) {
      return input + " ";
    } else {
      return "";
    }
  };

  //think no use
  // getCityState = (answer: string) => {

  //     if (answer['countryISO2'] === 'SG') {
  //         return ' ';
  //     } else {
  //         if (answer['countryISO2'] === 'HK') {
  //             return ' ' + answer['city'] + ' ';
  //         } else {
  //             return ' ' + answer['city'] + ' ' + answer['stateName'] + ' ';
  //         }
  //     }

  // }

  getDisplayTextIfUnknown = (inputId: string, question: any) => {
    let displayText: string = "Unknown";
    let array: any = [];
    if (inputId.length > 0) {
      if (question === "country") {
        if (common.isDefined(this.countryList)) {
          array = this.countryList;
        }
      } else {
        if (common.isDefined(this.nationalityList)) {
          array = this.nationalityList;
        }
      }
      if (array.length > 0) {
        const elementPos = array
          .map(function (x: { value: any }) {
            return x.value;
          })
          .indexOf(inputId);
        const objectFound = array[elementPos];
        displayText = objectFound.key;
      }
    }

    return displayText;
  };

  getDateFormat = (date: any, culture: Culture) => {
    const mm = date.substring(5, 7);
    const dd = date.substring(8, 10);
    const yyyy = date.substring(0, 4);

    switch (culture) {
      case Culture.enSg:
        return dd + "/" + mm + "/" + yyyy;

      default:
        throw Error("date culture unhandled");
    }
  };

  private initialize() {
    this.masterDataService
      .getCountryList()
      .subscribe((responseCountry: any[]) => {
        this.countryList = responseCountry;
        this.masterDataService
          .getAllNationalityList()
          .subscribe((responseNationality: any[]) => {
            this.nationalityList = responseNationality;
            this.proposalLoaded();
          });
      });
  }

  //https://stackoverflow.com/questions/34547127/angular2-equivalent-of-document-ready
  ngAfterViewInit() {
    //todo: check if this works
    this.proposalLoaded();
    //https://stackoverflow.com/questions/53767289/angular-error-error-expressionchangedafterithasbeencheckederror-expression-ha
    this.cdRef.detectChanges();
  }

  private proposalLoaded = () => {
    //todo
    // $(document).ready(() => {
    setTimeout(() => {
      this.getClientDetailsLoaded();
    }, 60);
    // });
  };

  private getClientDetailsLoaded = () => {
    this.clientService
      .get(
        this.proposalService.proposal.customerMappingId!,
        this.proposalService.proposal.qsMaster.qs_master_insuredNo!.answer
      )
      .subscribe((response: any) => {
        this.clientViewData = response;
        if (response.clientType === 1) {
          this.clientViewData.salutationText =
            this.clientSalutationDictionary[response.salutation];
          //dob changes begin
          let editDate: any = this.clientViewData.birthDate;
          if (!common.isUndefinedOrNull(this.clientViewData.birthDate)) {
            editDate = new Date(this.clientViewData.birthDate);

            const year = editDate.getFullYear();
            const month = editDate.getMonth() + 1;
            const day = editDate.getDate();
            var dateofbirth = `${day}/${month}/${year}`;
            this.clientViewData.birthDateFormat = dateofbirth;
          } else {
            this.clientViewData.birthDateFormat = "";
          }
          //dob changes end
          //this.clientViewData.birthDateFormat = Global.isUndefined(this.clientViewData.birthDate) ?
          //  '' : this.getDateFormat(this.clientViewData.birthDate, Culture.enSg);
          this.clientViewData.genderText =
            this.clientGenderDictionary[response.gender];
          this.clientViewData.maritalStatusText =
            this.clientMaritalStatusDictionary[response.maritalStatus];
          this.clientViewData.raceText =
            this.clientRaceDictionary[response.race];
          this.clientViewData.nationalityText = this.getDisplayTextIfUnknown(
            [response.nationality][0],
            "nationality"
          );
          this.clientViewData.documentTypeText =
            this.clientDocumentTypePrefixDictionary[response.docType];
        }
        this.clientViewData.countryISO2Text = this.getDisplayTextIfUnknown(
          [response.address[0].countryISO2][0],
          "country"
        );
        this.verifyStateCountry([response.address[0].countryISO2][0]);
      });
  };

  formatDate = (date: string) => {
    const dateSplit = date.split("/");
    const newDate = dateSplit[1] + "/" + dateSplit[0] + "/" + dateSplit[2];
    return newDate.replace(/-/g, "/").replace(/T.+/, "");
  };
}
