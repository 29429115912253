import { Component, OnInit } from "@angular/core";
import { PolicyCustomerLoginService } from "../../../services/policy-customer-login.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-customer-accept-offer",
  templateUrl: "./customer-accept-offer.component.html",
  styleUrls: ["./customer-accept-offer.component.scss"],
})
export class CustomerAcceptOfferComponent implements OnInit {
  public hasError: boolean = false;
  public msg: string = "";
  IsDisabled: boolean = false;

  enc: string = "";
  productList: any;
  proposalIdList: any;
  policyList: any;
  expiryDateList: any;
  productPolicyList: any;
  premium1Year: number | undefined;
  premium3Year: number | undefined;
  premium3YearDecimalUpto2: string | undefined;
  vouchercode: string | undefined;
  showDropdown: boolean = false;
  showDetails: boolean = false;
  policyNumberDescription: string = "";
  productDescription: string | undefined;
  userChoice: { name: string } = {
    name: "No",
  };
  proposalIdProductPolicy: any;
  hasSuccess: boolean = false;
  productPolicySelected: any;
  footerTitle: string = "";

  constructor(
    private policyCustomerLoginService: PolicyCustomerLoginService,
    private route: ActivatedRoute
  ) {
    const getYear = new Date().getFullYear();
    this.footerTitle = "© " + getYear + " - " + "Chubb E-placement℠ System";
  }

  onSubmit = () => {
    let proposalId = "";
    if (this.proposalIdList.length > 1) {
      let idxProposal = this.proposalIdList.findIndex(
        (x: any) => x === this.productPolicySelected
      );
      this.policyNumberDescription = this.policyList[idxProposal];
      proposalId = this.productPolicySelected;
    } else {
      this.policyNumberDescription = this.policyList[0];
      proposalId = this.proposalIdList[0];
    }

    this.policyCustomerLoginService
      .acceptReject(
        this.enc,
        proposalId,
        this.policyNumberDescription,
        this.userChoice.name
      )
      .then((response) => {
        if (response.status === 1) {
          this.showDetails = true;
          if (this.proposalIdList.length === 1) {
            this.hasError = false;
            this.hasSuccess = true;
            this.msg =
              "Thank you! Your response has been submitted for this policy";
          } else {
            this.hasError = false;
            this.hasSuccess = true;
            this.msg =
              "Thank you! Your response has been submitted for this policy. Please select another policy to renew and acknowledge your acceptance for this exclusive promotion";
          }
        } else if (response.status === 7) {
          this.showDetails = false;
          if (this.proposalIdList.length === 1) {
            this.hasError = true;
            this.hasSuccess = false;
            this.msg =
              "Thank you, your response has been submitted for this policy";
          } else {
            this.hasError = true;
            this.hasSuccess = false;
            this.msg =
              "Thank you! Your response has been submitted for this policy.  Please select another policy to renew and acknowledge your acceptance for this exclusive promotion";
          }
        } else {
          this.hasError = true;
          this.hasSuccess = false;
          this.msg = "An error has occurred";
        }
      });
  };

  onProductPolicyChange = () => {
    this.hasError = false;
    this.hasSuccess = false;
    if (
      this.productPolicySelected !== undefined &&
      this.productPolicySelected !== null
    ) {
      this.showDetails = false;

      let idxPolicy: string = this.proposalIdProductPolicy.findIndex(
        (x: any) => x.proposalId === this.productPolicySelected
      );
      let encExpiryDate = this.expiryDateList[idxPolicy];

      this.policyCustomerLoginService
        .calculatePremium(this.enc, this.productPolicySelected, encExpiryDate)
        .then((data) => {
          if (data.status === 1) {
            this.premium1Year = data.premium1Year;
            this.premium3Year = data.premium3Year;
            this.premium3YearDecimalUpto2 = "$" + data.premium3Year.toFixed(2);
            this.vouchercode = data.vouchercode;
            this.policyNumberDescription = this.policyList[idxPolicy];
            this.productDescription = this.productList[idxPolicy];

            this.showDetails = true;
          } else if (data.status === 7) {
            this.showDetails = false;
            if (this.proposalIdList.length === 1) {
              this.hasError = true;
              this.hasSuccess = false;
              this.msg =
                "Thank you, your response has been submitted for this policy";
            } else {
              this.hasError = true;
              this.hasSuccess = false;
              this.msg =
                "Thank you! Your response has been submitted for this policy.  Please select another policy to renew and acknowledge your acceptance for this exclusive promotion";
            }
          } else {
            this.hasError = true;
            this.hasSuccess = false;
            this.msg = "An error has occurred";
          }
        });
    }
  };

  downloadFaqs = (e: any) => {
    this.policyCustomerLoginService.downloadFaqs();
    e.preventDefault();
  };

  ngOnInit(): void {
    this.route.firstChild?.params.subscribe((param: any) => {
      this.enc = param.enc;
      this.proposalIdList = param.proposalIdList;
      this.productList = param.productList;
      this.policyList = param.policyList;
      this.expiryDateList = param.expiryDateList;
    });

    this.premium1Year = 0;
    this.premium3Year = 0;
    this.showDropdown = false;
    this.showDetails = false;
    this.proposalIdList =
      this.proposalIdList !== undefined
        ? this.proposalIdList.split("|").map((i: number) => (isNaN(i) ? i : +i))
        : [];
    this.productList =
      this.productList !== undefined
        ? this.productList.split("|").map((i: number) => (isNaN(i) ? i : +i))
        : [];
    this.policyList =
      this.policyList !== undefined
        ? this.policyList.split("|").map((i: number) => (isNaN(i) ? i : +i))
        : [];
    this.expiryDateList =
      this.expiryDateList !== undefined
        ? this.expiryDateList.split("|").map((i: number) => (isNaN(i) ? i : +i))
        : [];
    this.policyNumberDescription = "";
    this.productDescription = "";
    this.proposalIdProductPolicy = [];
    this.productPolicyList = [];

    this.IsDisabled = false;

    this.productList.forEach((ele: any, index: any) => {
      this.productPolicyList.push(
        this.productList[index] + " " + this.policyList[index]
      );
    });

    this.proposalIdList.forEach((ele: any, index: any) => {
      this.proposalIdProductPolicy.push({
        proposalId: this.proposalIdList[index],
        productPolicy: this.productPolicyList[index],
      });
    });
    if (this.proposalIdList.length > 1) {
      this.showDropdown = true;
    } else {
      this.hasError = false;
      this.hasSuccess = false;
      this.showDetails = true; //temp
      this.policyCustomerLoginService
        .calculatePremium(
          this.enc,
          this.proposalIdList[0],
          this.expiryDateList[0]
        )
        .then((response) => {
          if (response.status === 1) {
            this.premium1Year = response.premium1Year;
            this.premium3Year = response.premium3Year;
            this.premium3YearDecimalUpto2 =
              "$" + response.premium3Year.toFixed(2);
            this.vouchercode = response.vouchercode;
            this.policyNumberDescription = this.policyList[0];
            this.productDescription = this.productList[0];

            this.showDetails = true;
          } else if (response.status === 7) {
            this.showDetails = false;
            if (this.proposalIdList.length === 1) {
              this.hasError = true;
              this.hasSuccess = false;
              this.msg =
                "Thank you, your response has been submitted for this policy";
            } else {
              this.hasError = true;
              this.hasSuccess = false;
              this.msg =
                "Thank you! Your response has been submitted for this policy.  Please select another policy to renew and acknowledge your acceptance for this exclusive promotion";
            }
          } else {
            this.hasError = true;
            this.hasSuccess = false;
            this.msg = "An error has occurred";
          }
        });
    }
  }
}
