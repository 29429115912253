import * as Enums from "./../../../../framework/enum.shared";
import * as lodash from "lodash";

import { Echo } from "./../echo.model";
import { Echos } from "./../echos.model";
import { ExceptionManager } from "./../../../../framework/utils/exception-manager";
import { QuestionEntity } from "./../question-entity.model";
import { SectionTab } from "./../section-tab.model";
import { common } from "./../../../../framework/utils/common";
import { proposalHelper } from "./../../utils/proposal.helper";

export abstract class QuestionEntityValidator {
  constructor(
    private questions: QuestionEntity[],
    private tabSchemas: SectionTab[]
  ) {}

  validate(callback?: (echosToAdd: Echos<QuestionEntity>) => void) {
    const mandatoryResult = this.checkMandatory(true);
    const maxLengthResult = this.checkMaxLength(false);
    const minMaxValueResult = this.checkMinMaxValue(false);

    let results = lodash.concat(mandatoryResult, maxLengthResult);
    results = lodash.concat(results, minMaxValueResult);

    lodash.sortBy(results, ["key"]);

    if (results.length > 0) {
      return this.convertToEchos(results, callback);
    } else {
      return [];
    }
  }

  protected convertToEchos(
    input: Echo<QuestionEntity>[],
    callback?: (echosToAdd: Echos<QuestionEntity>) => void
  ): Echos<QuestionEntity>[] {
    const results: Echos<QuestionEntity>[] = [];
    const resultEchos = proposalHelper.convertToEchos(input);
    resultEchos.forEach((e) => {
      const echosToAdd = proposalHelper.convertTitleToTabIntoEchos(
        e,
        this.tabSchemas
      );
      if (common.isDefined(callback) && callback) {
        callback(echosToAdd);
      }
      results.push(echosToAdd);
    });

    return results;
  }

  protected checkMandatory(themReset: boolean = true): Echo<QuestionEntity>[] {
    const echos: Echo<QuestionEntity>[] = [];

    this.questions.forEach((question) => {
      if (themReset) {
        question.theme = Enums.QuestionTheme.Normal;
      }
      if (question.required) {
        if (!question.isAnswered() && question.displayText) {
          question.theme = Enums.QuestionTheme.Danger;
          echos.push(
            proposalHelper.buildQuestionEntityEcho(
              question.displayText,
              question
            )
          );
        }
      }
    });

    return echos;
  }

  protected checkMaxLength(themReset: boolean = true): Echo<QuestionEntity>[] {
    const echos: Echo<QuestionEntity>[] = [];

    this.questions.forEach((question) => {
      if (themReset) {
        question.theme = Enums.QuestionTheme.Normal;
      }

      switch (question.answerDataType) {
        case Enums.AnswerDataType.String:
          if (
            common.isDefined(question.maxLength) &&
            question.maxLength !== undefined &&
            question.maxLength > 0
          ) {
            const answer = question.readAnswerAsString();
            if (
              common.isDefined(answer) &&
              answer?.length !== undefined &&
              answer.length > question.maxLength
            ) {
              question.theme = Enums.QuestionTheme.Danger;
              const message = `Answer exceed maximum character count of ${question.maxLength}:- ${question.displayText}`;
              echos.push(
                proposalHelper.buildQuestionEntityEcho(message, question)
              );
            }
          }
          break;
      }
    });

    return echos;
  }

  protected checkMinMaxValue(
    themReset: boolean = true
  ): Echo<QuestionEntity>[] {
    const echos: Echo<QuestionEntity>[] = [];

    this.questions.forEach((question) => {
      if (themReset) {
        question.theme = Enums.QuestionTheme.Normal;
      }

      switch (question.answerDataType) {
        case Enums.AnswerDataType.Date:
          if (common.isDefined(question.maxValue)) {
            if (!common.isDate(question.maxValue)) {
              ExceptionManager.error(`${question.key} .maxValue is not Date`);
            }

            const answer: Date = question.answer as Date;
            const max: Date = question.maxValue as Date;

            if (answer > max) {
              question.theme = Enums.QuestionTheme.Danger;
              const dateText = max.toISOString().slice(0, 10); // 2107-09-20
              const message = `Answer exceed maximum date of [${dateText}]:- ${question.displayText}`;
              echos.push(
                proposalHelper.buildQuestionEntityEcho(message, question)
              );
            }
          }

          if (common.isDefined(question.minValue)) {
            if (!common.isDate(question.minValue)) {
              ExceptionManager.error(`${question.key} .minValue is not Date`);
            }

            const answer: Date = question.answer as Date;
            const min: Date = question.minValue as Date;

            if (answer < min) {
              question.theme = Enums.QuestionTheme.Danger;
              const dateText = min.toISOString().slice(0, 10); // 2107-09-20
              const message = `Answer below minimum date of [${dateText}]:- ${question.displayText}`;
              echos.push(
                proposalHelper.buildQuestionEntityEcho(message, question)
              );
            }
          }
          break;

        case Enums.AnswerDataType.Numeric:
          if (common.isDefined(question.maxValue)) {
            if (!common.isNumber(question.maxValue)) {
              ExceptionManager.error(`${question.key} .maxValue is not Number`);
            }

            const answer: number = question.answer as number;
            const max: number = question.maxValue as number;

            if (answer > max) {
              question.theme = Enums.QuestionTheme.Danger;
              const message = `Answer exceed maximum value of [${max}]:- ${question.displayText}`;
              echos.push(
                proposalHelper.buildQuestionEntityEcho(message, question)
              );
            }
          }

          if (common.isDefined(question.minValue)) {
            if (!common.isNumber(question.minValue)) {
              ExceptionManager.error(`${question.key} .minValue is not Number`);
            }

            const answer: number = question.answer as number;
            const min: number = question.minValue as number;

            /**Code block to fix validate $ symbol pasting into numeric field starts  */
            if (isNaN(answer)) {
              question.theme = Enums.QuestionTheme.Danger;
              const message = `Answer below minimum value of [${min}]:- ${question.displayText}`;
              echos.push(
                proposalHelper.buildQuestionEntityEcho(message, question)
              );
            }
            /**Code block to fix validate $ symbol pasting into numeric field starts  */

            if (answer < min) {
              question.theme = Enums.QuestionTheme.Danger;
              const message = `Answer below minimum value of [${min}]:- ${question.displayText}`;
              echos.push(
                proposalHelper.buildQuestionEntityEcho(message, question)
              );
            }
          }
          break;
      }
    });

    return echos;
  }
}
