export enum OpenItemStatus {
  Undefined = 0,
  PendingAutoDebit = 1,
  PendingInvoiceToBank = 2,
  PendingToInvoiceToIndividual = 3,
  PendingRefundToBank = 4,
  AutoDebited = 5,
  ManualPaid = 6,
  InvoicedToBank = 7,
  InvoicedToIndividual = 8,
  Refunded = 9,
  Reconciled = 10,
  PendingManualPayByCheque = 11,
  ManualPaidNonAutoDebitFileSent = 12,
  PendingRefundToIndividual = 13,
  UnpaidChequeToBank = 14,
  UnpaidChequeToIndividual = 15,
  NoRefund = 16,
  RefundedCheque = 17,
  MeridianAutoOffset = 18,
  ChequeAutoUpdated = 19,
  FreeInsuranceAutoUpdated = 20,
  PendingRefundToIndividualInReview = 21,
  PendingRefundToBankInReview = 22,
  RefundedViaPayNow = 23,
  Rejected = 24,
  PendingRefundToIndividualInReviewAdHoc = 25,
  PendingRefundToIndividualInReviewNA = 26,
  RefundedViaEFT = 27,
  // PendingOffset = 23,
  // PendingRefundOffset = 24,
  // Offset = 25
}
