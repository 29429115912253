import { Component, Input, OnInit } from "@angular/core";
import { QuestionEntity } from "src/app/models/new-product/base/question-entity.model";
import { ProposalConst } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal-constant";

@Component({
  selector: "radio-button-v1-question",
  templateUrl: "./radio-button-v1-question.component.html",
  styleUrls: ["./radio-button-v1-question.component.scss"],
})
export class RadioButtonV1QuestionComponent implements OnInit {
  noAnswerDefaultText = ProposalConst.noAnswerDefaultText;
  @Input() public question!: QuestionEntity;
  constructor() {}

  ngOnInit(): void {}
  answerDisplayText(question: QuestionEntity): string {
    let answerDisplayText: string = "";
    /*this.answer = "1";*/
    question.optionsValue.forEach((option) => {
      if (option.value === question.answer) {
        answerDisplayText = option.key;
      }
    });
    return answerDisplayText;
  }
}
