import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { DialogRef } from "../dialog/dialog-ref";
import { DIALOG_DATA } from "../dialog/dialog-tokens";
import { clientViewDlgModelArgs } from "../mdClient/client-view/client-view.component";
import { dialogActionNotifier } from "src/app/models/new-product/notifier/dialog-action-notifier";
import { Subscription } from "rxjs";

@Component({
  selector: "md-preferred-delivery-method",
  templateUrl: "./md-preferred-delivery-method.component.html",
  styleUrls: ["./md-preferred-delivery-method.component.scss"],
})
export class MdPreferredDeliveryMethodComponent implements OnInit {
  isNewClient = false;
  isProcessing = false;
  model = {};
  showCreateButton = true;
  labelText = "Update";
  h3LabelText = "Change of Preferred Delivery";
  public dlgWidth: number = window.innerWidth * 0.6;
  public dlgMaxHeight: number = window.innerHeight * 0.9;

  dialogActionSubscription: Subscription;

  //#region old ui code
  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public inputData: any
  ) {
    this.dialogActionSubscription =
      dialogActionNotifier.cancel_notifier$.subscribe(this.dialogCancelled);
  }

  private dialogCancelled = (event: any) => {
    this.showCreateButton = event.value === "failed" ? true : false;
    if (
      event.value["status"] !== "failed" &&
      event.key == "preferred-delivery-close"
    ) {
      this.close("yes", event);
    } else {
      this.showCreateButton = true;
    }
  };

  ngOnInit(): void {
    if (this.inputData) {
      this.isNewClient = this.inputData.isNewClient;
    }
  }

  create() {
    this.showCreateButton = false;
    dialogActionNotifier.ok_notifier$.next({
      key: "client-create-save",
      value: {
        isNewClient: this.isNewClient,
      },
    });
  }

  public destroy() {
    this.dialogActionSubscription?.unsubscribe();
  }

  public close(status: any, event?: any) {
    if (status === "yes") {
      let outputData: preferredDeliveryMethodDlgInputArgs = {
        mobileNumber: event.value["data"].mobileNumber,
        emailAddress: event.value["data"].emailAddress,
        address: event.value["data"].address,
        preferredDeliveryMethod: event.value["preferredDeliveryMethod"],
      };

      let savParamters: clientViewDlgModelArgs = {
        status: status,
        data: outputData,
      };
      this.dialogRef?.close(savParamters);
    } else {
      let savParamters: clientViewDlgModelArgs = {
        status: status,
        data: null,
      };
      this.dialogRef?.close(savParamters);
    }
  }
  //#endregion
}

export interface preferredDeliveryMethodDlgInputArgs {
  mobileNumber: string;
  emailAddress: string;
  address: string;
  preferredDeliveryMethod: string;
}
