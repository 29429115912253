<kendo-dialog
  *ngIf="active"
  [width]="600"
  [height]="450"
  (close)="closeForm()"
  style="z-index: 1000"
>
  <kendo-dialog-titlebar class="modal-title-custom chubbLightOrange_bg">
    Refund Payment
  </kendo-dialog-titlebar>

  <div class="container-fluid" style="padding-top: 15px">
    <div *ngIf="invalidRequiredField" id="alertMessage" class="row">
      <div class="col-md-12">
        <div class="alert alert-danger displayBlock">
          <label class="margin0">
            <span>
              <i
                class="fa fa-exclamation-circle marginRight5"
                aria-hidden="true"
              ></i>
              Please fill in the required fields.
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-4">
        <label class="marginTop5" for="policyNo">Policy Number</label>
      </div>
      <div class="col-md-8">
        <p
          class="form-control-static"
          id="policyNo"
          [textContent]="openItemEntity.policyNo"
        ></p>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-4">
        <label class="marginTop5" for="amount">Total Premium</label>
      </div>
      <div class="col-md-8">
        <p
          class="form-control-static"
          id="amount"
          [textContent]="openItemEntity.amount"
        ></p>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-4">
        <label class="marginTop5" for="amountGst">GST</label>
      </div>
      <div class="col-md-8">
        <p
          class="form-control-static"
          id="amountGst"
          [textContent]="openItemEntity.amountGst"
        ></p>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-4" [ngClass]="{ 'st-danger': invalidChangetoStatus }">
        <label class="marginTop5" for="ChangetoStatus">Change to Status</label>
      </div>
      <div class="col-md-8" [ngClass]="{ 'st-danger': invalidChangetoStatus }">
        <kendo-dropdownlist
          id="ChangetoStatusId"
          required="true"
          [defaultItem]="defaultChangetoStatusItem"
          [kendoDropDownFilter]="{
            operator: 'contains'
          }"
          [data]="ChangetoStatusOptions"
          textField="text"
          valueField="value"
          [valuePrimitive]="true"
          [(ngModel)]="refundPayment.changestatus"
          (valueChange)="on_ChangetoStatus_change()"
          name="name_ChangetoStatusType"
        ></kendo-dropdownlist>
      </div>
    </div>

    <!-- Refund by Cheque fields    refundType === 1 && refundType_ChangetoStatus === 1 -->
    <div *ngIf="isHideRefundPaymentTypeDDL">
      <div class="form-group row">
        <div
          class="col-md-4"
          [ngClass]="{
            'st-danger': invalidRefundType_PendingRefundToIndividualInReview
          }"
        >
          <label class="marginTop5" for="refundType">Refund Payment Type</label>
        </div>
        <div class="col-md-8">
          <kendo-dropdownlist
            id="refundTypeId"
            [defaultItem]="defaultMigratedItem"
            [kendoDropDownFilter]="{
              operator: 'contains'
            }"
            [data]="refundTypeOptions"
            textField="text"
            valueField="value"
            [valuePrimitive]="true"
            [(ngModel)]="refundType_PendingRefundToIndividualInReview"
            name="name_refundType"
          ></kendo-dropdownlist>
        </div>
      </div>
      <paynow-editor
        #childPaynowComponent
        *ngIf="refundType_PendingRefundToIndividualInReview === '3'"
        [parentRefundPaynowEntity]="refundPaynow"
      ></paynow-editor>
      <!-- EFT container-->
      <div
        *ngIf="refundType_PendingRefundToIndividualInReview === '4'"
        class="form-group row"
      >
        <div class="col-md-4" [ngClass]="{ 'st-danger': invalidPayerName }">
          <label class="marginTop5 required" for="payerName">Payer Name</label>
        </div>
        <div class="col-md-8" [ngClass]="{ 'st-danger': invalidPayerName }">
          <input
            type="text"
            class="form-control"
            id="payerName"
            placeholder="Payer Name"
            [(ngModel)]="refundPayment.payerName"
          />
        </div>
      </div>
      <div
        *ngIf="refundType_PendingRefundToIndividualInReview === '4'"
        class="form-group row"
      >
        <div
          class="col-md-4"
          [ngClass]="{ 'st-danger': invalidbankAccountNumber }"
        >
          <label class="marginTop5 required" for="bankAccountNumber"
            >Bank Account Number
          </label>
        </div>
        <div
          class="col-md-8"
          [ngClass]="{ 'st-danger': invalidbankAccountNumber }"
        >
          <input
            type="text"
            class="form-control"
            id="bankAccountNumber"
            placeholder="Bank Account Number"
            [(ngModel)]="refundPayment.bankAccountNumber"
          />
        </div>
      </div>

      <div
        *ngIf="refundType_PendingRefundToIndividualInReview === '4'"
        class="form-group row"
      >
        <div class="col-md-4" [ngClass]="{ 'st-danger': invalidbankName }">
          <label class="marginTop5 required" for="bankName">Bank Name </label>
        </div>
        <div class="col-md-8" [ngClass]="{ 'st-danger': invalidbankName }">
          <kendo-dropdownlist
            id="bankNameId"
            [defaultItem]="defaultbankNameItem"
            [kendoDropDownFilter]="{
              operator: 'contains'
            }"
            [data]="bankNameOptions"
            textField="text"
            valueField="value"
            [valuePrimitive]="true"
            [(ngModel)]="refundPayment.bankcode"
            (valueChange)="on_ChangeBankCode_change()"
            name="name_bankName"
          ></kendo-dropdownlist>
        </div>
      </div>

      <div *ngIf="isHideOtherBanktext" class="form-group row">
        <div class="col-md-4" [ngClass]="{ 'st-danger': invalidotherBankName }">
          <label class="marginTop5" for="otherBankName"> </label>
        </div>
        <div class="col-md-8" [ngClass]="{ 'st-danger': invalidotherBankName }">
          <input
            type="text"
            class="form-control"
            id="otherBankName"
            placeholder="Other Bank Name"
            [(ngModel)]="refundPayment.bankName"
          />
        </div>
      </div>

      <div
        *ngIf="refundType_PendingRefundToIndividualInReview === '4'"
        class="form-group row"
      >
        <div class="col-md-4" [ngClass]="{ 'st-danger': invalidEFTdate }">
          <label class="marginTop5 required" for="eFTDate">EFT Date</label>
        </div>
        <div class="col-md-8" [ngClass]="{ 'st-danger': invalidEFTdate }">
          <kendo-datepicker
            class="maxWidth"
            id="EFTdate"
            format="dd/MM/yyyy"
            placeholder=""
            [(ngModel)]="refundPayment.eFTDate"
            name="name_EFTdate"
            #name="ngModel"
          ></kendo-datepicker>
        </div>
      </div>
      <!-- EFT container-->
      <div
        *ngIf="refundType_PendingRefundToIndividualInReview === '1'"
        class="form-group row"
      >
        <div class="col-md-4" [ngClass]="{ 'st-danger': invalidPayerName }">
          <label class="marginTop5 required" for="payerName">Payer Name</label>
        </div>
        <div class="col-md-8" [ngClass]="{ 'st-danger': invalidPayerName }">
          <input
            type="text"
            class="form-control"
            id="payerName"
            placeholder="Payer Name"
            [(ngModel)]="refundPayment.payerName"
          />
        </div>
      </div>
      <div
        *ngIf="refundType_PendingRefundToIndividualInReview === '1'"
        class="form-group row"
      >
        <div class="col-md-4" [ngClass]="{ 'st-danger': invalidCIB }">
          <label class="marginTop5 required" for="chequeIssuanceBank"
            >Cheque Issuing Bank</label
          >
        </div>
        <div class="col-md-8" [ngClass]="{ 'st-danger': invalidCIB }">
          <input
            type="text"
            class="form-control"
            id="chequeIssuanceBank"
            placeholder="Cheque Issuing Bank"
            [(ngModel)]="refundPayment.chequeIssuanceBank"
          />
        </div>
      </div>
      <div
        *ngIf="refundType_PendingRefundToIndividualInReview === '1'"
        class="form-group row"
      >
        <div class="col-md-4" [ngClass]="{ 'st-danger': invalidCN }">
          <label class="marginTop5 required" for="chequeNo"
            >Cheque Number</label
          >
        </div>
        <div class="col-md-8" [ngClass]="{ 'st-danger': invalidCN }">
          <input
            type="text"
            class="form-control"
            id="chequeNo"
            placeholder="Cheque Number"
            [(ngModel)]="refundPayment.chequeNo"
          />
        </div>
      </div>
    </div>
    <!-- Common fields -->
    <div class="form-group row">
      <div
        class="col-md-4"
        [ngClass]="{ 'st-danger': refundType === 2 && invalidRemark }"
      >
        <!-- Is required for OnlineTransfer refundType -->
        <label
          class="marginTop5"
          [ngClass]="refundType === 2 ? 'required' : ''"
          for="remarks"
          >Remarks</label
        >
      </div>
      <div
        class="col-md-8"
        [ngClass]="{ 'st-danger': refundType === 2 && invalidRemark }"
      >
        <textarea
          class="form-control"
          rows="3"
          id="remarks"
          [(ngModel)]="refundPayment.remarks"
        ></textarea>
      </div>
    </div>
  </div>
  <kendo-dialog-actions>
    <button
      kendoButton
      class="btn chubbWhite chubbLightBlue_bg_btn"
      themeColor="primary"
      (click)="onSave($event)"
    >
      Make Refund
    </button>
    <button
      kendoButton
      class="btn chubbWhite chubbRed_bg_btn"
      (click)="onCancel($event)"
    >
      Cancel
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
