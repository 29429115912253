import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotifierService } from "src/app/services/notifier.service";
import { PolicyWordingSetupService } from "src/app/services/policy-wording-setup.service";
import { common } from "../../../framework/utils/common";

@Component({
  selector: "app-policy-wording",
  templateUrl: "./policy-wording.component.html",
  styleUrls: ["./policy-wording.component.scss"],
})
export class PolicyWordingComponent implements OnInit {
  public appendTo!: ViewContainerRef;
  footerTitle: string;
  constructor(
    private policyWordingSetUpService: PolicyWordingSetupService,
    private route: ActivatedRoute,
    private notifier: NotifierService
  ) {
    const getYear = new Date().getFullYear();
    this.footerTitle = "© " + getYear + " - " + "Chubb E-placement℠ System";
    const urlParam: any = this.constructURLParam(window.location.pathname);
    if (!common.isStringNullOrEmpty(urlParam[2])) {
      this.downloadDocument(urlParam[2]);
    }
  }

  constructURLParam = (url: any) => {
    if (url === "/") {
      // if the url containing "#"
      let hash: string = window.location.hash.replace("#", "");
      return hash.split("/");
    } else {
      return window.location.pathname.split("/");
    }
  };

  downloadDocument = (id: string) => {
    try {
      this.policyWordingSetUpService
        .noAuthDownloadDocument(id)
        .subscribe((response: any) => {
          if (response.status != null && response.status == 500) {
            alert("System Error");
          } else {
            var file = new Blob([response.body], { type: "application/pdf" });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL, "_parent");
          }
        });
    } catch (err) {
      this.notifier.error("Download failed", this.appendTo);
    }
  };

  ngOnInit(): void {}
}
