import { Component, Inject, OnInit } from "@angular/core";
import { MortgageFireSingaporeProposal } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal";
import * as Enum from "src/app/framework/enum.shared";
import * as EnumSG from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-enums";
import { ApiService } from "src/app/services/dal/api.service";
import { dialogActionNotifier } from "src/app/models/new-product/notifier/dialog-action-notifier";
import { ClientService } from "src/app/services/customer/client.service";
import { MasterDataService } from "src/app/services/master-data.service";
import { MortgageFireSGTabSchema } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/schemas/mf-sg-tab-schema";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { Global } from "src/app/shared/global";
import { httpNotifier } from "src/app/interceptor/http-notifier";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { MfSgBaseComponent } from "src/app/components/new-product/sme/mortgage-fire/singapore/components/mf-sg-base.component";
import { common } from "src/app/framework/utils/common";
import { DialogRef } from "../../dialog/dialog-ref";
import { DIALOG_DATA } from "../../dialog/dialog-tokens";
import { clientViewDlgModelArgs } from "../client-view/client-view.component";
import {
  CellClickEvent,
  GridDataResult,
  PageChangeEvent,
  PageSizeItem,
  SelectionEvent,
} from "@progress/kendo-angular-grid";

@Component({
  selector: "app-client-search",
  templateUrl: "./client-search.component.html",
  styleUrls: ["./client-search.component.scss"],
})
export class ClientSearchComponent extends MfSgBaseComponent implements OnInit {
  isEditable = false;
  isDisabled = false;

  proposal!: MortgageFireSingaporeProposal;

  currentUserType!: Enum.UserType;
  isUnderwriter!: boolean;
  isMarketer!: boolean;
  isChecker: boolean = false;
  isMaker: boolean = false;

  showErrorMessage!: boolean;
  clientId: any;
  clientName: string = "";
  custIdentificationNo: string = "";
  clientAddress: string = "";
  clientCode: string = "";
  clientNric: string = "";
  clientPassport: string = "";
  clientMobileNumber: string = "";
  clientEmailAddress: string = "";
  searchData!: any[];
  countryList!: any[];
  productBaseType: any;

  public gridView!: GridDataResult;
  public pageSize = 10;
  public skip = 0;
  public take = 10;
  public pageSizes: (PageSizeItem | number)[] = [10, 50, 100];
  private listing: any[] = [];
  private totalListing: number = 0;
  submitted = false;

  public selectedRowIndexes: number[] = [];
  public loading!: boolean;

  outputData!: any;
  public gridHeight = 100;

  constructor(
    //private proposalService: MortgageFireSingaporeProposal,
    private api: ApiService,
    private clientService: ClientService,
    private masterDataService: MasterDataService,
    public identityRolesService: IdentityRolesService,
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public inputData: clientSearchDlgInputArgs
  ) {
    super();
    // this.$state = Application.angular_$state();
    // this.timeOut = Application.angular_$timeout();
    this.tabId = MortgageFireSGTabSchema.premiumSummary.index.toString();

    if (this.inputData) {
      this.proposal = this.inputData.proposal; //temp pass from outside
      this.isEditable = this.inputData.isEditable;
    }
  }

  //#region old ui code
  ngOnInit(): void {
    // /*check is underwriter*/
    //this.currentUserType = this.identityRolesService.getIdentity().currentUser().userType;

    /*check is underwriter*/
    this.isUnderwriter = this.identityRolesService.isUnderwriter;
    /*check is marketer*/
    this.isMarketer = this.identityRolesService.isMarketer;
    /*check is checker*/
    this.isChecker = this.identityRolesService.isChecker;
    /*check is maker*/
    this.isMaker = this.identityRolesService.isMaker;

    this.initialize();

    //todo
    // $(document).ready(() => {
    //     // Trigger search on 'Enter' key pressed
    //     $('#clientName_textBox, #custIdentificationNo_textBox, #clientAddress_textBox, #clientCode_textBox, #nric_textBox, #passport_textBox,#clientMobile_textBox,#clientEmail_textBox').keypress((event) => {

    //         // 'Enter'
    //         if (event.keyCode === 13) {
    //             this.search(event);
    //         }
    //     });
    // });
  }

  ngOnDestroy() {
    this.unsubscribeAllRxjs();
  }

  search = (e: any) => {
    // TODO: validations
    if (
      (Global.isUndefined(this.clientName) || this.clientName === "") &&
      (Global.isUndefined(this.custIdentificationNo) ||
        this.custIdentificationNo === "") &&
      (Global.isUndefined(this.clientAddress) || this.clientAddress === "") &&
      (Global.isUndefined(this.clientCode) || this.clientCode === "") &&
      (Global.isUndefined(this.clientNric) || this.clientNric === "") &&
      (Global.isUndefined(this.clientPassport) || this.clientPassport === "") &&
      (Global.isUndefined(this.clientMobileNumber) ||
        this.clientMobileNumber === "") &&
      (Global.isUndefined(this.clientEmailAddress) ||
        this.clientEmailAddress === "")
    ) {
      this.showErrorMessage = true;
      httpNotifier.response_notifier$.next(new KeyValue("", e));

      //reset grid
      this.listing = [];
      this.totalListing = 0;
    } else {
      this.showErrorMessage = false;
      // refresh grid
      this.masterDataService.getCountryList().subscribe((responseCountry) => {
        this.countryList = responseCountry;

        this.refresh();
      });
    }
  };

  private initialize() {
    //this.proposal = this.proposalService.proposal;//temp pass from outside
    super.subscribeTabLoadRenderer();
    this.productBaseType = this.getCurrentProductBase();
  }

  public getCurrentProductBase(): EnumSG.ProductBase {
    // this.proposal = this.proposalService.proposal;//temp pass from outside
    return this.proposal.productType === EnumSG.MortgageFireSgProduct.CTHDB ||
      this.proposal.productType === EnumSG.MortgageFireSgProduct.CTMFPTE ||
      this.proposal.productType === EnumSG.MortgageFireSgProduct.CTMFCONDO
      ? EnumSG.ProductBase.Citi
      : EnumSG.ProductBase.DBS;
  }

  //todo
  // private on_RequestStart(e: kendo.data.DataSourceRequestStartEvent) {
  //     httpNotifier.request_notifier$.next(new KeyValue('', e));
  // }

  // private grid_dataBound = (e: kendo.ui.GridDataBoundEvent) => {

  //     const dataItems = e.sender.dataSource.view();

  //     // k-hierarchy-cell CSS stylings
  //     const gridRowsToProcess = e.sender.tbody.find('tr');

  //     for (let i = 0; i < dataItems.length; i++) {
  //         const $gridRowsToProcess = $(gridRowsToProcess[i]);

  //         this.proposal = this.proposalService.proposal;
  //         const productType = (this.proposal.productType === EnumSG.MortgageFireSgProduct.CTHDB || this.proposal.productType === EnumSG.MortgageFireSgProduct.CTMFPTE
  //             || this.proposal.productType === EnumSG.MortgageFireSgProduct.CTMFCONDO)
  //             ? EnumSG.ProductBase.Citi : EnumSG.ProductBase.DBS;

  //         if (((dataItems[i].get('productBase')).toUpperCase()) === productType.toUpperCase()) {
  //             // hover cell - blue background & cursor pointer
  //             $gridRowsToProcess.addClass('hoverPowderBlue cursorPointer');
  //         } else {
  //             // white background & cursor default
  //             $gridRowsToProcess.addClass('ACElightgrey cursorDefault');
  //         }
  //     }
  // }

  public rowClass = (args: any) => ({
    "k-disabled": !(
      args.dataItem.productBase.toUpperCase() ===
      this.productBaseType.toString().toUpperCase()
    ),
  });

  private on_RequestEnd = (e: any) => {
    if (e !== undefined) {
      e.forEach(
        (c: {
          address: any[];
          addressDisplayText: string;
          clientType: any;
          badgeClass: string;
          clientTypeDisplayText: string;
        }) => {
          const clientAddress = c.address[0];

          // kendo grid schema model field: addressDisplayText
          if (clientAddress.countryISO2 === "SG") {
            c.addressDisplayText = this.getAddressDisplayText(clientAddress);
          } else {
            c.addressDisplayText =
              this.getNonSGAddressDisplayText(clientAddress);
          }

          let badgeClass = "";
          let clientTypeDisplayText = "";
          switch (c.clientType) {
            case 1:
              // Individual
              clientTypeDisplayText = "Ind.";
              badgeClass = "primary";
              break;
            case 2:
              // Organization
              clientTypeDisplayText = "Org.";
              badgeClass = "success";
              break;
          }

          c.badgeClass = badgeClass;
          c.clientTypeDisplayText = clientTypeDisplayText;
        }
      );
    }

    httpNotifier.response_notifier$.next(new KeyValue("", e));
  };

  // private on_Change = (e: kendo.ui.GridChangeEvent) => {
  //     // Selected row
  //     this.clientService.clientDictionary = {};
  //     const selectedRow = this.clientSearchGrid.select();
  //     const dataItem: any = this.clientSearchGrid.dataItem(selectedRow);

  //     if ((dataItem.productBase).toUpperCase() === this.getCurrentProductBase().toUpperCase()) {
  //         // Parameters
  //         this.clientId = dataItem.clientId;
  //         this.clientCode = dataItem.clientCode;
  //         this.PolicyConfirmationModalCloseForActions({ confirmation: true });
  //     } else {
  //         selectedRow.removeClass('k-state-selected');
  //         selectedRow.addClass('ACElightgrey cursorDefault');
  //     }
  // }

  // DBSEP-2063: It will check the confirmation from user and redirect to client edit or create page.

  PolicyConfirmationModalCloseForActions = (data: any): void => {
    if (data.confirmation) {
      // Return data to master component
      dialogActionNotifier.cancel_notifier$.next({
        key: "client-search-close",
        value: {
          status: "success",
          clientId: this.clientId,
          clientCode: this.clientCode,
        },
      });
    }
  };

  getDisplayTextIfUnknown = (inputId: string) => {
    let displayText: string = "Unknown";
    let array: any = [];
    if (inputId.length > 0) {
      if (common.isDefined(this.countryList)) {
        array = this.countryList;
      }

      if (array.length > 0) {
        const elementPos = array
          .map(function (x: { value: any }) {
            return x.value;
          })
          .indexOf(inputId);
        const objectFound = array[elementPos];
        displayText = objectFound.key;
      }
    }

    return displayText;
  };

  private getAddressDisplayText(addressEntity: any): string {
    let addressDisplayText = "";

    if (!common.isStringNullOrEmpty(addressEntity.addressLine1)) {
      addressDisplayText += addressEntity.addressLine1;
    }

    if (!common.isStringNullOrEmpty(addressEntity.addressLine2)) {
      if (!common.isStringNullOrEmpty(addressDisplayText)) {
        addressDisplayText += " ";
      }
      addressDisplayText += addressEntity.addressLine2;
    }

    if (!common.isStringNullOrEmpty(addressEntity.unitNo)) {
      if (!common.isStringNullOrEmpty(addressDisplayText)) {
        addressDisplayText += " ";
      }
      addressDisplayText += addressEntity.unitNo;
    }

    if (!common.isStringNullOrEmpty(addressEntity.buildingName)) {
      if (!common.isStringNullOrEmpty(addressDisplayText)) {
        addressDisplayText += " ";
      }
      addressDisplayText += addressEntity.buildingName;
    }

    if (!common.isStringNullOrEmpty(addressEntity.city)) {
      if (addressEntity.countryISO2 !== "SG") {
        if (!common.isStringNullOrEmpty(addressDisplayText)) {
          addressDisplayText += " ";
        }
        addressDisplayText += addressEntity.city;
      }
    }
    if (!common.isStringNullOrEmpty(addressEntity.state)) {
      if (
        addressEntity.countryISO2 !== "SG" ||
        addressEntity.countryISO2 !== "HK"
      ) {
        if (!common.isStringNullOrEmpty(addressDisplayText)) {
          addressDisplayText += " ";
        }
        addressDisplayText += addressEntity.state;
      }
    }
    if (!common.isStringNullOrEmpty(addressEntity.countryISO2)) {
      if (!common.isStringNullOrEmpty(addressDisplayText)) {
        addressDisplayText += " ";
      }

      addressDisplayText += this.getDisplayTextIfUnknown(
        addressEntity.countryISO2
      );
    }
    if (!common.isStringNullOrEmpty(addressEntity.postCode)) {
      if (!common.isStringNullOrEmpty(addressDisplayText)) {
        addressDisplayText += " ";
      }
      addressDisplayText += addressEntity.postCode;
    }
    return addressDisplayText;
  }
  private getNonSGAddressDisplayText(addressEntity: any): string {
    let addressDisplayText = "";
    if (!common.isStringNullOrEmpty(addressEntity.unitNo)) {
      if (!common.isStringNullOrEmpty(addressDisplayText)) {
        addressDisplayText += " ";
      }
      addressDisplayText += addressEntity.unitNo;
    }
    if (!common.isStringNullOrEmpty(addressEntity.buildingName)) {
      if (!common.isStringNullOrEmpty(addressDisplayText)) {
        addressDisplayText += " ";
      }
      addressDisplayText += addressEntity.buildingName;
    }

    if (!common.isStringNullOrEmpty(addressEntity.addressLine1)) {
      addressDisplayText += addressEntity.addressLine1;
    }

    if (!common.isStringNullOrEmpty(addressEntity.addressLine2)) {
      if (!common.isStringNullOrEmpty(addressDisplayText)) {
        addressDisplayText += " ";
      }
      addressDisplayText += addressEntity.addressLine2;
    }
    if (!common.isStringNullOrEmpty(addressEntity.postCode)) {
      if (!common.isStringNullOrEmpty(addressDisplayText)) {
        addressDisplayText += " ";
      }
      addressDisplayText += addressEntity.postCode;
    }
    if (!common.isStringNullOrEmpty(addressEntity.city)) {
      if (!common.isStringNullOrEmpty(addressDisplayText)) {
        addressDisplayText += " ";
      }
      addressDisplayText += addressEntity.city;
    }
    if (!common.isStringNullOrEmpty(addressEntity.state)) {
      if (!common.isStringNullOrEmpty(addressDisplayText)) {
        addressDisplayText += " ";
      }
      addressDisplayText += addressEntity.state;
    }
    if (!common.isStringNullOrEmpty(addressEntity.countryISO2)) {
      if (!common.isStringNullOrEmpty(addressDisplayText)) {
        addressDisplayText += " ";
      }

      addressDisplayText += this.getDisplayTextIfUnknown(
        addressEntity.countryISO2
      );
    }

    return addressDisplayText;
  }
  private getSearchFilters() {
    return [
      { field: "clientName", operator: "eq", value: this.clientName },
      {
        field: "customerIdentificationNo",
        operator: "eq",
        value: this.custIdentificationNo,
      },
      { field: "address", operator: "eq", value: this.clientAddress },
      { field: "clientCode", operator: "eq", value: this.clientCode },
      { field: "nric", operator: "eq", value: this.clientNric },
      { field: "passportNo", operator: "eq", value: this.clientPassport },
      { field: "mobileNumber", operator: "eq", value: this.clientMobileNumber },
      { field: "emailAddress", operator: "eq", value: this.clientEmailAddress },
    ];
  }

  unsubscribeAllRxjs() {
    super.unsubscribeRxjs();
  }

  public close(status: any) {
    let savParamters: clientViewDlgModelArgs = {
      status: status,
      data: this.outputData,
    };
    this.dialogRef?.close(savParamters);
  }
  //#endregion

  //#region generic Grid event
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.take = event.take;
    this.pageSize = event.take; //this is the one that update the <kendo-pager-info>
    this.refresh();
  }

  private clearSelection() {
    // Optionally, clear the selection when paging
    this.selectedRowIndexes = [];
  }

  public gridSelectionChange(selection: SelectionEvent) {}

  public cellClickHandler({
    sender,
    rowIndex,
    columnIndex,
    dataItem,
    isEdited,
  }: CellClickEvent): void {
    const di = dataItem;
    //const proposalId = dataItem.proposalId;
    this.outputData = di;
    this.close("yes");
  }

  private refresh(): void {
    this.clearSelection();
    this.loading = true;

    const formData: FormData = new FormData();

    formData.append("take", this.take.toString());
    formData.append("skip", this.skip.toString());
    formData.append("page", "1");
    formData.append("pageSize", this.pageSize.toString());
    formData.append("sort[0][field]", "clientName");
    formData.append("sort[0][dir]", "asc");

    let array = [
      ["clientName", "eq", this.clientName],
      ["customerIdentificationNo", "eq", this.custIdentificationNo],
      ["address", "eq", this.clientAddress],
      ["clientCode", "eq", this.clientCode],
      ["nric", "eq", this.clientNric],
      ["passportNo", "eq", this.clientPassport],
      ["mobileNumber", "eq", this.clientMobileNumber],
      ["emailAddress", "eq", this.clientEmailAddress],
    ];

    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < array[i].length; j++) {
        if (j == 0) {
          formData.append(
            `filter[filters][${i}][field]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else if (j == 1) {
          formData.append(
            `filter[filters][${i}][operator]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else {
          formData.append(
            `filter[filters][${i}][value]`,
            JSON.parse(JSON.stringify(array[i][j] || ""))
          );
        }
      }
    }

    //https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
    //IT HAS TO BE IN SINGLE CHUNK OF STRING
    var querystring: string = "";
    for (var pair of formData.entries()) {
      querystring +=
        encodeURIComponent(pair[0]) +
        "=" +
        encodeURIComponent(pair[1] as string) +
        "&";
    }

    this.clientService.search(querystring).subscribe((result: any) => {
      if (
        result != undefined &&
        result.body != undefined &&
        result.body != null
      ) {
        let e = result.body.d?.results;
        this.on_RequestEnd(e);
        this.listing = e;
        this.totalListing = result.body.d?.__count;
        this.loadItems();
        this.loading = false;

        if (this.totalListing > 0) {
          this.gridHeight = 400;
        } else {
          this.gridHeight = 100;
        }
      }
    });
  }

  private loadItems(): void {
    this.gridView = {
      data: this.listing, //.slice(this.skip, this.skip + this.pageSize),
      total: this.totalListing,
    };
  }
  //#endregion
}

export interface clientSearchDlgInputArgs {
  isEditable: boolean;
  proposal: MortgageFireSingaporeProposal;
}
