import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { HomeComponent } from "./components/new-product/home/home.component";
import { MfSgMainComponent } from "./components/new-product/sme/mortgage-fire/singapore/components/main/mf-sg-main.component";
import { IbgSgUploadComponent } from "./components/product/ibg-sg-upload/ibg-sg-upload.component";
import { RmScreenComponent } from "./components/product/rm-screen/rm-screen.component";
import { AutoLoanRedemptionComponent } from "./components/product/auto-loan-redemption/auto-loan-redemption.component";
import { BouncedMailPolicyComponent } from "./components/product/bounced-mail-policy/bounced-mail-policy.component";
import { ReviewRefundUploadComponent } from "./components/accounting/review-refund-upload/review-refund-upload.component";
import { RefundUploadComponent } from "./components/accounting/refund-upload/refund-upload.component";
import { ReconcileListComponent } from "./components/accounting/reconcile/reconcile-list.component";
import { OpenItemListComponent } from "./components/accounting/open-item/open-item-list.component";
import { Adb2cLoginRedirectComponent } from "./components/login/adb2c-login-redirect/adb2c-login-redirect.component";
import { Adb2cLoginComponent } from "./components/login/adb2c-login/adb2c-login.component";
import { AuthGuard } from "./components/security/auth.guard";
import { MultiYearAutoDebitUploadComponent } from "./components/product/multi-year-auto-debit-upload/multi-year-auto-debit-upload.component";
import { PolicyWordigSetupComponent } from "./components/product/policyWordingSetUp/policy-wording-setup.component";
import { LstRateComponent } from "./components/product/lst-rate/lst-rate.component";
import { BatchInvoiceListComponent } from "./components/accounting/batch-invoice/batch-invoice-list.component";
import { UserProfileComponent } from "./components/admin/user-profile/user-profile.component";
import { CustomerLoginComponent } from "./components/login/customer-login/customer-login.component";
import { CustomerEnterOtpComponent } from "./components/login/customer-login/customer-enter-otp.component";
import { CustomerAcceptOfferComponent } from "./components/login/customer-login/customer-accept-offer.component";
import { Adb2cUserTypeListingComponent } from "./components/login/adb2c-user-type-listing/adb2c-user-type-listing.component";
import { GstAdjustmentComponent } from "./components/accounting/gst-adjustment/gst-adjustment.component";
import { UserRoleSelectionComponent } from "./components/login/user-role-selection/user-role-selection.component";
import { PolicyWordingComponent } from "./components/product/policyWordingSetUp/policy-wording.component";
import { CbgSgUploadComponent } from "./components/product/cbg-sg-upload/cbg-sg-upload.component";
import { CbgCLSLoanRedemptionUploadComponent } from "./components/product/cbg-cls-loan-redemption-upload/cbg-cls-loan-redemption-upload.component";
import { RejectUploadComponent } from "./components/accounting/reject-upload/reject-upload.component";

export const ROUTE_LOGIN = ""; //todo, unify with "src/app/app.module"
export const ROUTE_HOME = "product/home"; //todo, unify with "src/app/app.module"
export const ROUTE_PROPOSAL = "proposal"; //todo, unify with "src/app/app.module"
export const ROUTE_CUSTOMER_LOGIN = "customerLogin";
export const ROUTE_POLICY_WORDING = "policyWording";
export const ROUTE_USER_TYPE_LISTING = "adb2cUserTypeListing";
export const ROUTE_USER_ROLE_SELECTION = "userroleselection";

const routes = [
  {
    path: ROUTE_LOGIN,
    component: Adb2cLoginComponent, //HomeComponent,
    text: "",
  },
  {
    path: ROUTE_USER_ROLE_SELECTION,
    component: UserRoleSelectionComponent,
    text: "user role selection",
    canActivate: [AuthGuard],
    // data: {
    //   role: "hasProductReadWriteRoles",
    // },
  },
  {
    path: ROUTE_HOME, //follow old angular
    component: HomeComponent,
    text: "Home",
    canActivate: [AuthGuard],
    data: {
      role: "hasProductReadWriteRoles",
    },
  },
  {
    path: ROUTE_PROPOSAL,
    component: MfSgMainComponent,
    text: "Proposal",
    canActivate: [AuthGuard],
    data: {
      role: "hasProductTransactRoles",
    },
  },
  {
    path: ROUTE_POLICY_WORDING,
    component: PolicyWordingComponent,
    text: "Policy Wording",
  },
  {
    path: ROUTE_CUSTOMER_LOGIN,
    component: CustomerLoginComponent,
    text: "Customer Login",
  },
  {
    path: "customerEnterOtp/:enc/:email/:mobile/:dob/:last4UniqueId",
    component: CustomerEnterOtpComponent,
    text: "",
  },
  {
    path: "customerAcceptOffer/:enc/:proposalIdList/:productList/:policyList/:expiryDateList",
    component: CustomerAcceptOfferComponent,
    text: "",
  },
  {
    path: "", //follow old angular
    text: "Accounting",
    children: [
      {
        path: "openItem",
        component: OpenItemListComponent,
        text: "Open Item",
        canActivate: [AuthGuard],
        data: {
          role: "isFinanceAccounting",
        },
      },
      {
        path: "batchInvoice",
        component: BatchInvoiceListComponent,
        text: "Batch Invoice",
        canActivate: [AuthGuard],
        data: {
          role: "isFinanceAccounting",
        },
      },
      {
        path: "reconcile",
        component: ReconcileListComponent,
        text: "Reconcile",
        canActivate: [AuthGuard],
        data: {
          role: "isFinanceAccounting",
        },
      },
      {
        path: "reviewRefundUpload",
        component: ReviewRefundUploadComponent,
        text: "Bulk Refund in Review Upload",
        canActivate: [AuthGuard],
        data: {
          role: "isFinanceAccounting",
        },
      },
      {
        path: "refundUpload",
        component: RefundUploadComponent,
        text: "Bulk Refund Upload",
        canActivate: [AuthGuard],
        data: {
          role: "isFinanceAccounting",
        },
      },
      {
        path: "rejectUpload",
        component: RejectUploadComponent,
        text: "Bulk Reject Upload",
        canActivate: [AuthGuard],
        data: {
          role: "isFinanceAccounting",
        },
      },
      {
        path: "dbsAccountingGSTAdjustmentUpload",
        component: GstAdjustmentComponent,
        text: "GST Adjustment Upload",
        canActivate: [AuthGuard],
        data: {
          role: "isFinanceAccounting",
        },
      },
    ],
  },
  //todo: new angular has to use extra queryParams to differentiate?
  {
    path: "", //follow old angular
    text: "Product",
    children: [
      {
        path: "customerLogin",
        component: CustomerLoginComponent,
        text: "CustomerLogin",
        canActivate: [AuthGuard],
        data: {
          role: "isUnderwriter|isIBGProductTab",
        },
      },
      {
        path: "uploadExcelCreate",
        component: IbgSgUploadComponent,
        text: "IBG (SG) - Upload",
        canActivate: [AuthGuard],
        data: {
          role: "isUnderwriter|isIBGProductTab",
        },
      },
      {
        path: "uploadExcelCreateonCBGNB",
        component: CbgSgUploadComponent,
        text: "CBG (SG) - Upload",
        canActivate: [AuthGuard],
        data: {
          type: "CBG",
          role: "isUnderwriter|isIBGProductTab",
        },
      },
      {
        path: "rmscreen",
        component: RmScreenComponent,
        text: "RM Screen",
        canActivate: [AuthGuard],
        data: {
          role: "isUnderwriter|isIBGProductTab",
        },
      },
      {
        path: "lstRate",
        component: LstRateComponent,
        text: "LSTRates",
        canActivate: [AuthGuard],
        data: {
          role: "isUnderwriter|isIBGProductTab",
        },
      },
      {
        path: "autoLoanRedemption?type=CBG",
        component: AutoLoanRedemptionComponent,
        text: "CBG Loan Redemption - Upload",
        data: {
          type: "CBG",
          role: "isUnderwriter|isIBGProductTab",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "uploadExcelAutoLoanRedemptionCBGCLSLR",
        component: CbgCLSLoanRedemptionUploadComponent,
        text: "CBG CLS Loan Redemption & Cancellation - Upload",
        canActivate: [AuthGuard],
        data: {
          type: "CBG",
          role: "isUnderwriter|isIBGProductTab",
        },
      },
      {
        path: "autoLoanRedemption?type=IBG",
        component: AutoLoanRedemptionComponent,
        text: "IBG Loan Redemption - Upload",
        data: {
          type: "IBG",
          role: "isUnderwriter|isIBGProductTab",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "autoLoanRedemption?type=Citi",
        component: AutoLoanRedemptionComponent,
        text: "Citi Loan Redemption - Upload",
        data: {
          type: "Citi",
          role: "isUnderwriter|isIBGProductTab",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "bouncedMailPolicy",
        component: BouncedMailPolicyComponent,
        text: "Bounced Mail Policy",
        data: {
          role: "isUnderwriter|isIBGProductTab|isOperationUnderwriter",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "policyWordingSetUp",
        component: PolicyWordigSetupComponent,
        text: "Policy Wording SetUp",
        data: {
          role: "isUnderwriter|isIBGProductTab|isOperationUnderwriter",
        },
        canActivate: [AuthGuard],
      },
      {
        path: "multiYearAutoDebit",
        component: MultiYearAutoDebitUploadComponent,
        text: "Multiyear AutoDebit - Upload",
        data: {
          role: "isUnderwriter|isIBGProductTab",
          type: "CBG",
        },
        canActivate: [AuthGuard],
      },
    ],
  },
  //admin
  {
    path: "", //follow old angular
    text: "Admin",
    children: [
      {
        path: "userProfileList",
        component: UserProfileComponent,
        text: "User Profile",
        canActivate: [AuthGuard],
        data: {
          role: "showAdminTab",
        },
      },
    ],
  },
  { path: ROUTE_USER_TYPE_LISTING, component: Adb2cUserTypeListingComponent },
  {
    path: "adb2csso",
    component: Adb2cLoginRedirectComponent,
  },
  {
    path: "**",
    component: Adb2cLoginComponent,
  }, //todo: wildcard { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
