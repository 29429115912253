<!--height is controlled by css: .modal-body-auto -->
<kendo-dialog
  title="Message Forum"
  class="modal-title-light-orange"
  (close)="close('cancel')"
  [width]="850"
>
  <!--modal-body still needed for padding: 15px ; just overide other props with modal-body-auto-->
  <div class="modal-body modal-body-auto">
    <div class="" *ngIf="showReprintFunction">
      <div class="form-group">
        <div class="col-md-12">
          <label ng-model="response" class="ChubbBlack" style="padding-left: 0"
            >Print Options</label
          >
          <label ng-model="response" class="chubbRed" style="padding-left: 0">{{
            data.confimationMsg
          }}</label>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-4">
            <kendo-dropdownlist
              id="selectReprintType"
              [data]="options"
              textField="key"
              valueField="value"
              [valuePrimitive]="true"
              [(ngModel)]="selectedPrintType"
              (valueChange)="optionChange($event)"
              name="selectReprintType"
            ></kendo-dropdownlist>
          </div>
          <div class="col-md-4" style="padding-left: 0px">
            <input
              id="clientemail"
              type="text "
              (blur)="validateEmail(data.emailId)"
              *ngIf="+model.selectedPrintType.value == 2"
              value=""
              maxlength="100"
              class="k-textbox form-control"
              [(ngModel)]="data.emailId"
            />

            <input
              id="clientmobileNo"
              type="text "
              (blur)="validateMobile(data.mobileNo)"
              *ngIf="+model.selectedPrintType.value == 5"
              value=""
              class="k-textbox form-control"
              [(ngModel)]="data.mobileNo"
            />
          </div>
          <div class="col-md-4" style="padding-left: 0px">
            <div
              class="btn chubbWhite chubbLightBlue_bg_btn floatRight"
              *ngIf="showSummaryDocumentButton"
              type="button"
              (click)="printSummary()"
              [textContent]="'Generate Latest Summary'"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix" *ngIf="showReprintFunction && response">
      <label
        ng-model="response"
        class="col-md-12 chubbRed"
        style="padding-left: 0"
        >{{ response }}</label
      >
    </div>
    <div>
      <table
        id="tblMessageForumNoRecords"
        class="table table-bordered"
        [hidden]="hasMbRecords()"
      >
        <tbody>
          <tr>
            <td>
              <b>No record found. Please proceed to add a new record.</b>
            </td>
          </tr>
        </tbody>
      </table>
      <table
        id="tblMessageForum"
        class="table table-bordered"
        *ngIf="hasMbRecords()"
      >
        <tbody *ngFor="let message of model.messageBoard">
          <tr class="chubbGray_bg chubbWhite" style="text-decoration: none">
            <td style="width: 20px" *ngIf="showReprintFunction"></td>
            <td style="width: 20%" [textContent]="message.userType"></td>
            <td
              [textContent]="
                (message.logDateTime | date : 'MM-dd-yyyy HH:mm:ss') +
                ' (GMT+8)'
              "
            ></td>
            <td style="width: 5%">Post</td>
          </tr>
          <tr>
            <td *ngIf="showReprintFunction">
              <input
                type="checkbox"
                [(ngModel)]="message.selected"
                *ngIf="
                  message.files != undefined &&
                  message.files.length > 0 &&
                  message.files[0].fileName.length > 0
                "
              />
            </td>
            <td style="width: 20%" class="" [textContent]="message.name"></td>
            <td>
              <table>
                <tr class="fontBold" [textContent]="message.messageTitle"></tr>
                <tr [innerHTML]="message.messageContent | newLines"></tr>
                <tr [textContent]="message.folderTypeId"></tr>
                <tr>
                  <td style="border: none; padding-left: 0px">
                    <ul
                      *ngFor="let file of message.files"
                      style="padding-left: 16px; margin: 0"
                    >
                      <i
                        class="fa fa-paperclip chubbDarkBlue cursorPointer"
                        *ngIf="file.fileName"
                      >
                        <button
                          (click)="getFile(file.fileId)"
                          type="button"
                          [textContent]="file.fileName"
                          class="btn-link"
                          style="border: none; background-color: white"
                        ></button>
                      </i>
                      <div
                        *ngIf="file.description"
                        class="floatRight fontSize13px"
                        [textContent]="'( ' + file.description + ' )'"
                      ></div>
                    </ul>
                  </td>
                </tr>
                <tr
                  *ngIf="message.PolicySummaryVersion != ''"
                  [textContent]="message.PolicySummaryVersion"
                ></tr>
              </table>
            </td>
            <td
              style="width: 5%"
              class="textAlignCenter"
              [textContent]="message.rowIndex"
            ></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer-border-top">
    <kendo-dialog-actions>
      <button
        class="btn chubbWhite chubbLightBlue_bg_btn confirmation_ok"
        kendoButton
        (click)="openMessageBoard($event)"
        themeColor="primary"
      >
        Add
      </button>
      <button
        class="btn chubbWhite chubbLightBlue_bg_btn confirmation_ok"
        kendoButton
        *ngIf="showReprintFunction"
        (click)="reprint()"
      >
        Reprint
      </button>
      <button
        class="btn chubbWhite chubbRed_bg_btn confirmation_cancel"
        kendoButton
        (click)="close('no')"
      >
        Return
      </button>
      <button
        class="btn chubbWhite chubbLightBlue_bg_btn confirmation_ok"
        kendoButton
        *ngIf="showCustomerTimeline"
        (click)="openClientTimeline()"
      >
        Update Multiyear Choice
      </button>
    </kendo-dialog-actions>
  </div>
</kendo-dialog>
