import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Inject,
} from "@angular/core";
import { common } from "src/app/framework/utils/common";
import { ClientAddressEntity } from "src/app/models/customer/base/client-address-entity.model";
import { ClientEntity } from "src/app/models/customer/base/client-entity.model";
import { MortgageFireSingaporeProposal } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal";
import { MfSgProposalService } from "src/app/services/new-product/sme/mortgage-fire/singapore/mf-sg-proposal.service";
import * as EnumSG from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-enums";
import { ClientService } from "src/app/services/customer/client.service";
import { MfSgBaseComponent } from "src/app/components/new-product/sme/mortgage-fire/singapore/components/mf-sg-base.component";
import { dialogActionNotifier } from "src/app/models/new-product/notifier/dialog-action-notifier";
import { Subscription } from "rxjs";
import { MasterDataService } from "src/app/services/master-data.service";
import * as Enums from "src/app/framework/enum.shared";
//import { notifier } from "src/app/framework/utils/notifier";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { AddressHelpFormComponent } from "../../../products/address-help-form/address-help-form.component";
import { SafeHtml } from "@angular/platform-browser";
import { DIALOG_DATA } from "../../dialog/dialog-tokens";
import { DialogRef } from "../../dialog/dialog-ref";
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Address } from "ngx-google-places-autocomplete/objects/address";
import { Options } from "ngx-google-places-autocomplete/objects/options/options";
import { MdInformationMessageComponent } from "src/app/components/shared/partials/md-information-message/md-information-message.component";
import { Global } from "src/app/shared/global";
import { NotifierService } from "src/app/services/notifier.service";
import { formatDate } from "@angular/common";

@Component({
  selector: "app-client-create",
  templateUrl: "./client-create.component.html",
  styleUrls: ["./client-create.component.scss"],
})
export class ClientCreateComponent extends MfSgBaseComponent implements OnInit {
  postBackData!: any[];
  proposal!: MortgageFireSingaporeProposal;
  //googleSearchAutoCompleteClientInformation!: GoogleAddressAutoCompleteManager;
  ibgDisabled: boolean = true;
  isProcessing!: boolean;
  // mandatoryField: any[];
  isPersonal: boolean = true;
  isOrganization: boolean = false;
  changedVal: string = "1";
  //timeout = Application.angular_$timeout();
  public dlgWidth: number = window.innerWidth * 0.6;
  public dlgMaxHeight: number = window.innerHeight * 0.9;

  clientData = new ClientEntity();
  address = new ClientAddressEntity();
  showErrorMessage!: boolean;
  emailPattern: string = "";
  readOnlyFields!: any[];
  addAddress1RO: boolean = true;
  addAddress2RO: boolean = true;
  addCityRO: boolean = true;
  addCountryRO: boolean = true;
  addPostcodeRO: boolean = true;
  addStateRO: boolean = true;
  nationalityList!: any[];
  countryList!: any[];
  firstRun: boolean = true;
  phoneNumberEntered: boolean = false;
  isEditable: boolean = false;
  productBase!: EnumSG.ProductBase;

  id: any;
  isCreate!: boolean;
  isStateVisible: boolean = false;
  countryExcludeState: any = ["SG", "HK"];
  newDOB: any;
  private dialogOkNotifierSubscription!: Subscription;
  errorMsgInHtml!: SafeHtml;
  CINText = "";

  @ViewChild("insuredAddress_lotunit", { static: false })
  public insuredAddress_lotunit: any;

  @ViewChild("insuredAddress_building", { static: false })
  public insuredAddress_building: any;

  @ViewChild("insuredAddress1", { static: false })
  public insuredAddress1: any;

  @ViewChild("insuredAddress2", { static: false })
  public insuredAddress2: any;

  @ViewChild("insuredCity", { static: false })
  public insuredCity: any;

  @ViewChild("insuredPostalCode", { static: false })
  public insuredPostalCode: any;

  @ViewChild("insuredState", { static: false })
  public insuredState: any;

  @ViewChild("clientInformation_autocomplete", { static: false })
  public clientInformation_autocomplete!: GooglePlaceDirective;

  @ViewChild("modal_body")
  modal_body!: ElementRef;

  options = {
    types: ["address"],
    componentRestrictions: { country: [] },
  } as unknown as Options;

  public salutationList!: Array<{ text: string; value: number }>;
  public docTypeList!: Array<{ text: string; value: string }>;
  public raceList!: Array<{ text: string; value: number }>;
  public genderList = new Array<{ text: string; value: number }>();
  public maritalStatus = new Array<{ text: string; value: number }>();
  labelText = "Create";
  h3LabelText = "Client - Create";
  //validations
  emptyFieldValidation = "";
  constructor(
    private proposalService: MfSgProposalService,
    private clientService: ClientService,
    private masterDataService: MasterDataService,
    private cdkDialogService: CdkDialogService,
    @Inject(DIALOG_DATA) public inputData: clientCreateDlgInputArgs,
    private dialogRef: DialogRef,
    private notifier: NotifierService
  ) {
    super();
    if (this.inputData) {
      this.proposal = this.inputData.proposal; //temp pass from outside
      this.isCreate = this.inputData.isNewClient;
      this.labelText = "Create";
      this.h3LabelText = "Client - Create";
      if (!this.isCreate) {
        this.labelText = "Update";
        this.isCreate = false;
        this.h3LabelText = "Change of Client Info";
      }
    }
  }

  ngOnInit(): void {
    if (!this.isCreate) {
      this.clientService
        .get(
          this.proposalService.proposal.customerMappingId!,
          this.proposalService!.proposal.qsMaster.qs_master_insuredNo!.answer
        )
        .subscribe((response: ClientEntity) => {
          this.clientData = response;
          this.setDate(this.clientData.birthDate);
          // check for docType, assign to empty if is 0
          if (common.isDefined(this.clientData.docType)) {
            this.clientData.docType =
              parseInt(this.clientData.docType) === 0
                ? ""
                : this.clientData.docType.toString();
          }

          this.proposalService.proposal.customerMappingAddressID = Number(
            response.address[0].clientAddressID
          );
          this.address = response.address[0];
          if (this.clientData.clientType === EnumSG.ClientType.Person) {
            this.isPersonal = true;
            this.isOrganization = false;
          } else {
            this.isPersonal = false;
            this.isOrganization = true;
          }

          this.getIsStateVisible(this.address.countryISO2);
        });

      // this.updateClientCIN();
    }
    this.dialogOkNotifierSubscription =
      dialogActionNotifier.ok_notifier$.subscribe((event) => {
        this.createClient();
      });

    this.initialize();
    //  this.checkIsNewClient();
  }
  private setDate(birthDate: Date) {
    if (birthDate) {
      let date = formatDate(birthDate, "dd/MM/yyyy", "en-US");
      let year = date.split("/")[2];
      let month = date.split("/")[1];
      let day = date.split("/")[0];
      this.clientData.birthDate = new Date(
        parseInt(year),
        parseInt(month) - 1,
        parseInt(day)
      );
    }
  }
  ngOnDestroy(): void {
    this.unsubscribeAllRxjs();
  }

  private initialize() {
    this.postBackData = [];
    this.proposal = this.proposalService.proposal;
    this.nationalityList = new Array<any>();
    this.countryList = new Array<any>();
    super.subscribeTabLoadRenderer();
    super.subscribeTabLoadRenderer();
    if (this.isCreate) this.initializeQuestionSchema();
    // $(document).ready(() => {
    this.componentsInitialize();
    // });
  }
  unsubscribeAllRxjs() {
    super.unsubscribeRxjs();
    this.dialogOkNotifierSubscription.unsubscribe();
  }

  public close(status: any) {
    let savParamters: clientCreateDlgModelArgs = {
      status: status,
      data: this.clientData,
    };
    this.dialogRef?.close(savParamters);
  }

  emailValidation = (e: any, inputId: any): void => {
    const re = /\S+@\S+\.\S+/;
    if (!re.test(e.currentTarget.value)) {
      this.showErrorMessage = true;
      this.clientData.emailAddress = "";
      this.errorMsgInHtml =
        '<div class="col-md-12"><i class="fa fa-exclamation-circle fa-2 chubbRed"' +
        ' aria-hidden="true"></i> Please enter a valid <b> email address </b></div';
      this.modal_body.nativeElement.scrollIntoView({ behavior: "smooth" });
    } else {
      this.showErrorMessage = false;
      this.errorMsgInHtml = "";
      // angular
      //   .element("#" + inputId)
      //   .closest(".form-group")
      //   .removeClass("has-error");
    }
  };
  addressCountryIfUnknown = (inputId: any) => {
    let displayText: string = "Unknown";
    if (inputId?.length > 0) {
      this.countryList.forEach((i) => {
        if (i.value === inputId) {
          displayText = i.key;
        }
      });
    }

    return displayText;
  };

  addressDataIfUnknown = (inputId: any): string => {
    if (inputId?.length > 0) {
      return inputId;
    }
    if (Number(inputId)) {
      return inputId;
    }
    return "Unknown";
  };

  phoneValidation = (inputId: any): void => {
    const inputValue = this.clientData.mobileNumber.trim();
    let errorText: string = "";

    var phoneno = /^\+?[0-9]+$/;
    if (inputValue.length > 0) {
      if (!inputValue.match(phoneno)) {
        errorText = this.getPhoneValidationText(inputId);
        this.showErrorMessage = true;
        this.errorMsgInHtml = "";
        this.errorMsgInHtml =
          '<div class="col-md-6"><i class="fa fa-exclamation-circle fa-2  chubbRed"' +
          ' aria-hidden="true"></i> Please enter a valid <b> ' +
          errorText +
          "</b></div>";
        // angular.element('.modal-body').animate({ scrollTop: 0 }, 200);
      } else if (inputValue == "0" && inputValue.length == 1) {
        // this.checkIfPhoneEntered();
        this.showErrorMessage = false;
        this.errorMsgInHtml = "";
        // angular
        //   .element("#" + inputId)
        //   .closest(".form-group")
        //   .removeClass("has-error");
      } else if (inputValue.length != 8 && inputValue != "0") {
        errorText = this.getPhoneValidationText(inputId);
        this.showErrorMessage = true;
        // this.htmlErrorMessage = htmlErrorMessage.replace('@i.label@', i.label);
        // if (inputId === 'brokerMobileNo' || inputId === 'brokerMobileNo' || inputId === 'brokerMobileNo') {
        //     this.checkIfPhoneEntered();
        // }
        this.errorMsgInHtml = "";
        this.errorMsgInHtml =
          '<div class="col-md-6"><i class="fa fa-exclamation-circle fa-2  chubbRed"' +
          ' aria-hidden="true"></i> Please enter a valid <b> ' +
          errorText +
          "</b></div>";
      } else if (
        inputValue.length == 8 &&
        inputValue.toString()[0] != "9" &&
        inputValue.toString()[0] != "8"
      ) {
        errorText = this.getPhoneValidationText(inputId);
        this.showErrorMessage = true;
        // this.htmlErrorMessage = htmlErrorMessage.replace('@i.label@', i.label);
        // if (inputId === 'brokerMobileNo' || inputId === 'brokerMobileNo' || inputId === 'brokerMobileNo') {
        //     this.checkIfPhoneEntered();
        // }
        this.errorMsgInHtml = "";
        this.errorMsgInHtml =
          '<div class="col-md-6"><i class="fa fa-exclamation-circle fa-2  chubbRed"' +
          ' aria-hidden="true"></i> Please enter a valid <b> ' +
          errorText +
          "</b></div>";
        // angular.element('.modal-body').animate({ scrollTop: 0 }, 200);
      } else {
        this.showErrorMessage = false;
        this.errorMsgInHtml = "";
        //   angular
        //     .element("#" + inputId)
        //     .closest(".form-group")
        //     .removeClass("has-error");
      }
    }
  };
  nricPassportValidation = (e: any, inputId: any): void => {
    const inputValue = e.currentTarget.value.trim();

    if (inputValue.length > 0) {
      if (inputId === "clientNRICNo") {
        if (this.clientData.passportNo.length > 0) {
          //angular.element("#passportNo").find("label").removeClass("required");
        }
        //angular.element("#nric").find("label").addClass("required");
      } else {
        if (this.clientData.nric.length > 0) {
          //angular.element("#nric").find("label").addClass("required");
          //angular.element("#passportNo").find("label").removeClass("required");
        } else {
          //angular.element("#passportNo").find("label").addClass("required");
          //angular.element("#nric").find("label").removeClass("required");
        }
      }
    }
    this.updateClientCIN();
  };
  businessRegistrationNumberToCIN = (e: any): void => {
    this.clientData.cinNo = this.clientData.businessRegistrationNumber;
  };
  getPhoneValidationText = (inputId: any): string => {
    let errorText: string = "";
    switch (inputId) {
      case "brokerMobileNo":
        errorText = "Mobile Number";
        this.clientData.mobileNumber = "";
        break;
      case "brokerPhoneNo":
        errorText = "Phone Number (Home)";
        this.clientData.phoneNoHome = "";
        break;
      case "brokerOfficeNo":
        errorText = "Phone Number (Office)";
        this.clientData.phoneNoOffice = "";
        break;
      case "brokerFaxNo":
        errorText = "Fax Number";
        this.clientData.faxNumber = "";
        break;
      default:
        break;
    }
    return errorText;
  };

  checkIFContainsRequired = (
    elementId1: any,
    elementId2: any,
    requiredElement: any
  ) => {
    let containsRequiredExists: boolean = false;
    // if (
    //   angular.element("#mobilePhoneForm").find("label").hasClass("required") ||
    //   angular.element("#housePhoneForm").find("label").hasClass("required") ||
    //   angular.element("#officePhoneForm").find("label").hasClass("required")
    // ) {
    //   containsRequiredExists = true;
    // }

    // if (containsRequiredExists) {
    //   angular
    //     .element("#" + elementId1)
    //     .find("label")
    //     .removeClass("required");
    //   angular
    //     .element("#" + elementId2)
    //     .find("label")
    //     .removeClass("required");
    // } else {
    //   angular
    //     .element("#" + requiredElement)
    //     .find("label")
    //     .addClass("required");
    // }
  };

  checkIfPhoneEntered = () => {
    this.phoneNumberEntered = false;
    if (
      this.clientData.mobileNumber !== "" ||
      this.clientData.phoneNoHome !== "" ||
      this.clientData.phoneNoOffice !== ""
    ) {
      this.phoneNumberEntered = true;
      // angular.element("#mobilePhoneForm").find("label").removeClass("required");
      // angular.element("#housePhoneForm").find("label").removeClass("required");
      // angular.element("#officePhoneForm").find("label").removeClass("required");
      if (this.clientData.phoneNoHome !== "") {
        this.checkIFContainsRequired(
          "mobilePhoneForm",
          "officePhoneForm",
          "housePhoneForm"
        );
      } else if (this.clientData.mobileNumber !== "") {
        this.checkIFContainsRequired(
          "housePhoneForm",
          "officePhoneForm",
          "mobilePhoneForm"
        );
      } else if (this.clientData.phoneNoOffice !== "") {
        this.checkIFContainsRequired(
          "mobilePhoneForm",
          "housePhoneForm",
          "officePhoneForm"
        );
      }
    }
  };

  initializeQuestionSchema = () => {
    this.clientData = new ClientEntity();
    this.clientData.address = [];
    this.address = new ClientAddressEntity();

    this.clientData.clientId = 0;
    this.clientData.clientCode = "";
    this.clientData.clientType = 1;
    this.clientData.nric = "";
    this.clientData.passportNo = "";

    this.clientData.businessRegistrationNumber = "";
    this.clientData.dbsClientReferenceNumber = "";
    this.clientData.clientName = "";
    this.clientData.incorporatedDate = "";
    this.clientData.firstName = "";
    this.clientData.lastName = "";
    this.clientData.personInCharge = "";

    this.clientData.salutation = "";
    this.clientData.docType = "";
    this.clientData.gender = "";
    this.clientData.birthDate = "";
    this.clientData.occupation = "";
    this.clientData.nationality = "154";

    this.clientData.race = "";
    this.clientData.maritalStatus = "";
    this.clientData.phoneNoHome = "";
    this.clientData.phoneNoOffice = "";
    this.clientData.mobileNumber = "";

    this.clientData.pagerNo = "";
    this.clientData.faxNumber = "";
    this.clientData.emailAddress = "";
    this.clientData.mobileNumber = "";

    this.address.clientAddressID = "";
    this.address.clientID = "";
    this.address.buildingName = "";
    this.address.unitNo = "";
    this.address.addressLine1 = "";

    this.address.addressLine2 = "";
    this.address.addressLine3 = "";
    this.address.addressLine4 = "";
    this.address.addressLine5 = "";
    this.address.city = "";

    this.address.postCode = "";
    this.address.stateName = "";
    this.address.countryISO2 = "";
    this.address.contact1 = "";
    this.address.contact2 = "";

    this.clientData.address.push(this.address);
  };

  componentsInitialize = () => {
    this.salutationList = [
      { text: "Capt", value: 1 },
      { text: "Dr.", value: 2 },
      { text: "Madam", value: 3 },
      { text: "Miss", value: 4 },
      { text: "Mr", value: 5 },
      { text: "Mrs", value: 6 },
      { text: "Ms", value: 7 },
      { text: "Prof", value: 8 },
      { text: "Sir", value: 9 },
      { text: "Tan Sir", value: 10 },
      { text: "Others", value: 13 },
    ];

    this.docTypeList = [
      { text: "Singapore NRIC", value: "1" },
      { text: "Singapore PR", value: "2" },
      { text: "Malaysia IC", value: "3" },
      { text: "Foreign Passport", value: "4" },
    ];

    this.raceList = [
      { text: "Chinese", value: 1 },
      { text: "Indian", value: 2 },
      { text: "Malay", value: 3 },
      { text: "Others", value: 4 },
    ];
    this.genderList = [
      { text: "Male", value: 1 },
      { text: "Female", value: 2 },
    ];
    this.maritalStatus = [
      { text: "Single", value: 1 },
      { text: "Married", value: 2 },
      { text: "Widowed", value: 3 },
      { text: "Divorced", value: 4 },
      { text: "Separated", value: 5 },
    ];
    setTimeout(() => {
      if (this.clientData.clientType === EnumSG.ClientType.Person) {
        this.masterDataService.getNationalityList().subscribe((response) => {
          this.nationalityList = response;
          setTimeout(() => {
            let defaultNationality: any = "";
            if (
              !this.isCreate &&
              this.clientData.clientType === EnumSG.ClientType.Person
            ) {
              defaultNationality = this.clientData.nationality;
            }
            const docType: string = this.clientData.docType;
            this.documentTypeChange(docType);
          }, 10);
        });
      }
      this.masterDataService.getCountryList().subscribe((response) => {
        this.countryList = response;
      });
      this.CINText =
        this.proposal.productType === EnumSG.MortgageFireSgProduct.CTHDB ||
        this.proposal.productType === EnumSG.MortgageFireSgProduct.CTMFPTE ||
        this.proposal.productType === EnumSG.MortgageFireSgProduct.CTMFCONDO
          ? "Citibank CIN"
          : "DBS CIN";
    }, 500);
  };

  selectClientType = (e: any): void => {
    this.emptyFieldValidation = "";
    this.showErrorMessage = false;
    if (this.isCreate) {
      this.clientData.cinNo = "";
    }

    this.errorMsgInHtml = "";
    this.clientData.clientType = Number(e.currentTarget.value);
    if (e.currentTarget.value === "1") {
      this.isOrganization = false;
      this.isPersonal = true;
      this.clientData.businessRegistrationNumber = "";
      this.clientData.clientName = "";
      this.clientData.personInCharge = "";
    }
    if (e.currentTarget.value === "2") {
      this.isOrganization = true;
      this.isPersonal = false;
      this.clientData.salutation = "";
      this.clientData.firstName = "";
      this.clientData.lastName = "";
      this.clientData.birthDate = "";
      this.clientData.gender = "";
      this.clientData.maritalStatus = "";
      this.clientData.nric = "";
      this.clientData.passportNo = "";
      this.clientData.occupation = "";
      this.clientData.race = "";
      this.clientData.docType = "";
    }

    this.componentsInitialize();
  };

  documentTypeChange = (docType: string) => {
    if (!this.firstRun) {
      this.clientData.nric = "";
      this.clientData.passportNo = "";
      this.clientData.nationality = undefined;
    }
    switch (docType) {
      case "1": // singaporean
        this.clientData.nationality = "154";
        break;
      case "2": // singapore PR
        if (!this.firstRun) {
          this.clientData.nric = "";
          this.clientData.passportNo = "";
          this.clientData.nationality = undefined;
        }
        break;
      case "3": // malaysian
        this.clientData.nationality = "112";
        if (!this.firstRun) {
          this.clientData.passportNo = "";
        }
        break;
      case "4": // foreigner
        if (this.isCreate) {
          //this.clientData.nationality = undefined;
        } else {
          //this.clientData.nationality = undefined;
        }
        break;
      default:
        // do nothing
        //this.clientData.nationality = "";
        break;
    }
    this.updateClientCIN();
    this.firstRun = false;
  };

  updateClientCIN = () => {
    let docType;
    docType = this.clientData.docType.toString();
    if (this.isCreate) {
      this.clientData.cinNo = "";
    }
    if (docType !== "" && docType !== null) {
      switch (docType) {
        case "1": // singaporean
        //case 1: // singaporean
        case "2": // singapore PR
          //case 2: // singapore PR
          this.clientData.cinNo = this.clientData.nric;
          break;
        case "3": // malaysian
          //case 3: // malaysian
          if (this.clientData.nric !== "" && this.clientData.nric != null) {
            this.clientData.cinNo = "M" + this.clientData.nric;
          }
          break;
        case "4": // foreigner
          //case 4: // foreigner
          if (
            this.clientData.passportNo !== "" &&
            this.clientData.nric != null
          ) {
            this.clientData.cinNo = "P" + this.clientData.passportNo;
          }
          break;
        default:
          break;
      }
    }
  };
  updateAddressValues = (
    place: { address_components: string | any[] },
    componentForm: { [x: string]: string | number },
    divId: string
  ): void => {
    this.resetClientAddressForm();
    if (Global.isDefined(place.address_components)) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        //if (componentForm[addressType]) {
        //const val = place.address_components[i][componentForm[addressType]];
        switch (addressType) {
          case "street_number":
            let val1 = place.address_components[i].long_name;
            this.address.addressLine1 = val1;
            this.validateReadOnlyAddress(val1, "addAddress1RO");
            break;
          case "route":
            let val2 = place.address_components[i].long_name;
            this.address.addressLine2 = val2;
            this.validateReadOnlyAddress(val2, "addAddress2RO");
            break;
          case "locality":
            let val3 = place.address_components[i].long_name;
            this.address.city = val3;
            this.validateReadOnlyAddress(val3, "addCityRO");
            break;
          case "postal_code":
            let val4 = place.address_components[i].long_name;
            this.address.postCode = val4;
            this.validateReadOnlyAddress(val4, "addPostcodeRO");
            break;
          case "administrative_area_level_1":
            let val5 = place.address_components[i].short_name;
            this.address.stateName = val5;
            this.validateReadOnlyAddress(val5, "addStateRO");
            break;
          case "country":
            let val6 = place.address_components[i].short_name;
            this.address.countryISO2 = val6.trim();
            this.validateReadOnlyAddress(val6, "addCountryRO");
            this.getIsStateVisible(val6);
            break;
          default:
            break;
        }
        // clearing the search input
        (<HTMLInputElement>(
          document.getElementById("clientInformation_autocomplete")
        )).value = "";
        this.inputReadOnlyAddress();
      }
    }
    //this.this.$apply();//todo
  };
  getIsStateVisible = (value: any) => {
    const elementPos = this.countryExcludeState
      .map(function (x: any) {
        return x;
      })
      .indexOf(value);
    if (elementPos > -1) {
      this.isStateVisible = false;
    } else {
      this.isStateVisible = true;
    }
  };
  validateReadOnlyAddress = (value: any, inputId: any) => {
    if (value !== "") {
      const index = this.readOnlyFields.indexOf(inputId);
      switch (inputId) {
        case "addAddress1RO":
          this.addAddress1RO = false;
          break;
        case "addAddress2RO":
          this.addAddress2RO = false;
          break;
        case "addCityRO":
          this.addCityRO = false;
          break;
        case "addPostcodeRO":
          this.addPostcodeRO = false;
          break;
        case "addStateRO":
          this.addStateRO = false;
          break;
        case "addCountryRO":
          this.addCountryRO = false;
          break;
        default:
          break;
      }
      if (index !== -1) {
        this.readOnlyFields.splice(index, 1);
      }
    }
  };

  inputReadOnlyAddress = () => {
    for (let i = 0; i < this.readOnlyFields.length; i++) {
      const inputFieldId = this.readOnlyFields[i];
      switch (inputFieldId) {
        case "addAddress1RO":
          this.addAddress1RO = false;
          break;
        case "addAddress2RO":
          this.addAddress2RO = false;
          break;
        case "addCityRO":
          this.addCityRO = false;
          break;
        case "addStateRO":
          this.addStateRO = false;
          break;
        case "addPostcodeRO":
          this.addPostcodeRO = false;
          break;
        case "addCountryRO":
          this.addCountryRO = false;
          break;
        default:
          break;
      }
    }
  };

  resetClientAddressForm = () => {
    this.readOnlyFields = [
      "addAddress1RO",
      "addAddress2RO",
      "addCityRO",
      "addPostcodeRO",
      "addStateRO",
    ];
    this.address.buildingName = "";
    this.address.unitNo = "";
    this.address.addressLine1 = "";
    this.address.addressLine2 = "";
    this.address.city = "";
    this.address.postCode = "";
    this.address.stateName = "";

    this.insuredAddress_lotunit.nativeElement.value = "";
    this.insuredAddress_building.nativeElement.value = "";
    this.updateNativeElement(this.insuredAddress1, "");
    this.updateNativeElement(this.insuredAddress2, "");
    this.updateNativeElement(this.insuredCity, "");
    this.updateNativeElement(this.insuredPostalCode, "");
    this.updateNativeElement(this.insuredState, "");
    (<HTMLInputElement>(
      document.getElementById("clientInformation_autocomplete")
    )).value = "";
  };
  updateNativeElement(ele: any, value: string) {
    if (ele !== undefined) {
      ele.nativeElement.value = value;
    }
  }

  customerTypeCheck = () => {
    return this.clientData.clientType !== undefined ? true : false;
  };

  createClient = () => {
    this.showErrorMessage = false;

    //const deferred = this.$q.defer();

    if (this.customerTypeCheck()) {
      let personalMandatory: any[];
      let orgMandatory: any[];
      let contactMandatory: any[];
      let allMandatoryField: any[];

      personalMandatory = [
        {
          id: "clientSalutation",
          label: "Salutation",
          qid: this.clientData.salutation,
        },
        {
          id: "clientDocType",
          label: "Document Type",
          qid: this.clientData.docType === "0" ? "" : this.clientData.docType,
        },
        {
          id: "clientSurname",
          label: "Surname",
          qid: this.clientData.lastName,
        },
        {
          id: "clientGivenName",
          label: "Given Name",
          qid: this.clientData.firstName,
        },
        {
          id: "clientDOB",
          label: "Date of Birth",
          qid: this.clientData.birthDate,
        },
        {
          id: "clientNationality",
          label: "Nationality",
          qid: this.clientData.nationality,
        },
      ];

      if (
        this.clientData.docType === "1" ||
        this.clientData.docType === "2" ||
        this.clientData.docType === "3"
      ) {
        personalMandatory.push({
          id: "clientNRICNo",
          label: "NRIC",
          qid: this.clientData.nric,
        });
      } else if (this.clientData.docType === "4") {
        personalMandatory.push({
          id: "clientPassportNo",
          label: "Passport Number",
          qid: this.clientData.passportNo,
        });
      }

      contactMandatory = [
        {
          id: "insuredAddress1",
          label: "Address Line 1",
          qid: this.address.addressLine1,
        },
        {
          id: "insuredAddress2",
          label: "Address Line 2",
          qid: this.address.addressLine2,
        },
        {
          id: "insuredPostalCode",
          label: "PostCode",
          qid: this.address.postCode,
        },
        { id: "insuredCity", label: "City/Suburb", qid: this.address.city },
        {
          id: "insuredCountry",
          label: "Country",
          qid: this.address.countryISO2,
        },
      ];

      orgMandatory = [
        {
          id: "clientBRNo",
          label: "Business Registration Number",
          qid: this.clientData.businessRegistrationNumber,
        },
        {
          id: "clientName",
          label: "Business Name",
          qid: this.clientData.clientName,
        },
      ];
      if (!this.ibgDisabled) {
        orgMandatory.push({
          id: "clientIncorporatedDate",
          label: "Incorporated Date",
          qid: this.clientData.incorporatedDate,
        });
      }

      if (
        this.proposal.productType === EnumSG.MortgageFireSgProduct.CTHDB ||
        this.proposal.productType === EnumSG.MortgageFireSgProduct.CTMFPTE ||
        this.proposal.productType === EnumSG.MortgageFireSgProduct.CTMFCONDO
      ) {
        contactMandatory = [
          {
            id: "insuredAddress1",
            label: "Address Line 1",
            qid: this.address.addressLine1,
          },
          {
            id: "insuredCountry",
            label: "Country",
            qid: this.address.countryISO2,
          },
        ];
      } else {
        contactMandatory = [
          {
            id: "insuredAddress1",
            label: "Address Line 1",
            qid: this.address.addressLine1,
          },
          {
            id: "insuredCountry",
            label: "Country",
            qid: this.address.countryISO2,
          },
          {
            id: "brokerMobileNo",
            label: "Mobile Number",
            qid: this.clientData.mobileNumber,
          },
        ];
      }

      if (this.clientData.clientType === EnumSG.ClientType.Person) {
        allMandatoryField = personalMandatory.concat(contactMandatory);
      } else {
        allMandatoryField = orgMandatory.concat(contactMandatory);
      }

      this.postBackData = [Array];
      let errorMessageIndicator: number = 0;
      const htmlErrorMessage: string =
        '<div class="col-md-6"><i class="fa fa-exclamation-circle fa-2 chubbRed" aria-hidden="true">' +
        "</i> <b> @i.label@ </b> is required. <br></div>";

      const clientAddressData: ClientAddressEntity = new ClientAddressEntity();

      clientAddressData.clientAddressID = this.isCreate
        ? ""
        : String(this.proposalService.proposal.customerMappingAddressID);
      clientAddressData.clientID = this.isCreate
        ? ""
        : String(this.proposalService.proposal.customerMappingId);
        clientAddressData.buildingName = this.address.buildingName.trim();
        clientAddressData.unitNo = this.address.unitNo.trim();
        clientAddressData.addressLine1 = this.address.addressLine1.trim();



        clientAddressData.addressLine2 = this.address.addressLine2.trim();
        clientAddressData.city = this.address.city.trim();
        clientAddressData.postCode = this.address.postCode.trim();
        clientAddressData.stateName = this.address.stateName.trim();

        clientAddressData.countryISO2 = this.address.countryISO2.trim();
      clientAddressData.contact1 = "";
      clientAddressData.contact2 = "";

      const clientData: ClientEntity = new ClientEntity();
      clientData.address = [];

      clientData.clientId = this.isCreate
        ? 0
        : this.proposalService.proposal.customerMappingId!;
      clientData.clientCode = this.isCreate ? "0" : this.clientData.clientCode;
      clientData.clientType = this.clientData.clientType;
        clientData.nric = this.clientData.nric.trim();
        clientData.passportNo = this.clientData.passportNo.trim();

      clientData.businessRegistrationNumber =
          this.clientData.businessRegistrationNumber.trim();
      clientData.incorporatedDate = this.clientData.incorporatedDate;
      // clientData.businessName = this.clientData.businessName;
        clientData.personInCharge = this.clientData.personInCharge.trim();
        clientData.firstName = this.clientData.firstName.trim();
        clientData.lastName = this.clientData.lastName.trim();
        clientData.clientName = this.clientData.clientName.trim();
      clientData.salutation = this.clientData.salutation;
      clientData.docType = this.clientData.docType;

      clientData.gender = this.clientData.gender;
      clientData.birthDate = this.clientData.birthDate;
      if (this.clientData.clientType === EnumSG.ClientType.Person) {
        if (
          common.isDefined(this.clientData.birthDate) &&
          this.clientData.birthDate !== ""
        ) {
          const answer = new Date(this.clientData.birthDate);
          const year = answer.getFullYear();
          const month = answer.getMonth() + 1;
          const day = answer.getDate();
          clientData.birthDate = `${year}-${month}-${day} 12:00:00 AM`;
        }
      }

        clientData.occupation = this.clientData.occupation.trim();
      clientData.race = this.clientData.race;
      clientData.maritalStatus = this.clientData.maritalStatus;

        clientData.phoneNoHome = this.clientData.phoneNoHome.trim();
        clientData.phoneNoOffice = this.clientData.phoneNoOffice.trim();
        clientData.mobileNumber = this.clientData.mobileNumber.trim();
        clientData.pagerNo = this.clientData.pagerNo.trim();
        clientData.faxNumber = this.clientData.faxNumber.trim();
        clientData.cinNo = this.clientData.cinNo.trim();
        clientData.emailAddress = this.clientData.emailAddress.trim();
      clientData.nationality =
        this.clientData.clientType === EnumSG.ClientType.Person
          ? this.clientData.nationality
          : "";
      clientData.address.push(clientAddressData);
      clientData.productBase =
        this.proposal.productType === EnumSG.MortgageFireSgProduct.CTHDB ||
        this.proposal.productType === EnumSG.MortgageFireSgProduct.CTMFPTE ||
        this.proposal.productType === EnumSG.MortgageFireSgProduct.CTMFCONDO
          ? EnumSG.ProductBase.Citi
          : EnumSG.ProductBase.DBS;

      this.showErrorMessage = true;
      if (allMandatoryField.length > 0) {
        this.errorMsgInHtml = "";
        this.emptyFieldValidation = "";

        for (const i of allMandatoryField) {
          if (common.isUndefinedOrNull(i.qid) || i.qid.length === 0) {
            this.errorMsgInHtml += htmlErrorMessage.replace(
              "@i.label@",
              i.label
            );
            this.emptyFieldValidation += " " + i.label;
            errorMessageIndicator++;
          } else {
            this.emptyFieldValidation.replace(i.label, "");
          }
        }
      }
      if (errorMessageIndicator === 0) {
        this.showErrorMessage = false;
        this.isProcessing = true;

        let clientActionData: any;

        if (
          this.proposalService.proposal.endorsementType ===
            Enums.EndorsementType.ChangeOfClientInfo &&
          this.proposalService.proposal.transType === Enums.TransType.END &&
          !this.isCreate
        ) {
          clientActionData = {
            clientModel: clientData,
            action: "validate",
          };
        } else {
          clientActionData = {
            clientModel: clientData,
            action: "save",
          };
        }

        this.ClientSave(clientActionData);
      } else {
        //angular.element(".modal-body").animate({ scrollTop: 0 }, 200);
        dialogActionNotifier.cancel_notifier$.next({
          key: "client-save-close",
          value: { status: "failed" },
        });
      }
    } else {
      this.showErrorMessage = true;
      this.errorMsgInHtml = "";
      this.errorMsgInHtml;
      '<div class="col-md-6"><i class="fa fa-exclamation-circle fa-2 chubbRed"' +
        ' aria-hidden="true"></i> Please select the <b> client type</b> to proceed.</div>';
      dialogActionNotifier.cancel_notifier$.next({
        key: "client-save-close",
        value: { status: "failed" },
      });
    }
    //return deferred.promise;
  };

  ClientSave(clientData: any) {
    //const deferred = this.$q.defer();
    this.clientService.create(clientData).subscribe(
      (result: any) => {
        let response = result?.body;
        let responseClientData: ClientEntity;
        // success
        if (response["IsSuccess"]) {
          responseClientData = response["ClientData"];

          if (this.isCreate) {
            this.notifier.info(
              "New client is created.",
              this.baseNotifierAppendTo
            );
            const outputData = {
              status: "success",
              clientId: responseClientData["clientId"],
              clientAddressID:
                responseClientData["address"][0]["clientAddressID"],
              clientCode: responseClientData["clientCode"],
            };
            let savParamters: clientCreateDlgModelArgs = {
              status: "yes",
              data: outputData,
            };

            this.dialogRef?.close(savParamters);
          } else {
            this.notifier.info(
              "Client details is updated.",
              this.baseNotifierAppendTo
            );
            const outputData = {
              status: "success",
              clientId: responseClientData["clientId"],
              clientAddressID:
                responseClientData["address"][0]["clientAddressID"],
              clientData: clientData.clientModel,
              clientAddressData: clientData.clientModel["address"][0],
              clientCode: responseClientData["clientCode"],
            };
            let savParamters: clientCreateDlgModelArgs = {
              status: "yes",
              data: outputData,
            };
            this.dialogRef?.close(savParamters);
          }
          this.proposalService.proposal.customerMappingId =
            responseClientData["clientId"];
          this.isProcessing = false;
        } else {
          // failed
          this.showClientValidationMessage(response["ClientErrorType"]);
          this.showErrorMessage = true;
          this.isProcessing = false;
          dialogActionNotifier.cancel_notifier$.next({
            key: "client-save-close",
            value: {
              status: "failed",
            },
          });
        }

        //deferred.resolve(response);
      },
      (rejection) => {
        this.isProcessing = false;
        this.notifier.info("Failed to save.", this.baseNotifierAppendTo);
        dialogActionNotifier.cancel_notifier$.next({
          key: "client-save-close",
          value: { status: "failed" },
        });
        //deferred.reject(rejection);
      }
    );
  }

  formatDate = (date: any) => {
    const dateSplit = date.split("/");

    const newDate = dateSplit[1] + "/" + dateSplit[0] + "/" + dateSplit[2];

    return newDate.replace(/-/g, "/").replace(/T.+/, "");
  };

  showClientValidationMessage = (errorType: number) => {
    //todo
    this.errorMsgInHtml = "";
    // angular.element("#createNewClient").removeClass("has-error");

    switch (errorType) {
      case EnumSG.ClientErrorType.NRICError:
        this.errorMsgInHtml =
          '<div class="col-md-6"><i class="fa fa-exclamation-circle fa-2  chubbRed"' +
          ' aria-hidden="true"></i> <b>NRIC</b> is already exists.</div>';

        break;
      case EnumSG.ClientErrorType.PassportNumberError:
        this.errorMsgInHtml =
          '<div class="col-md-6"><i class="fa fa-exclamation-circle fa-2 chubbRed"' +
          ' aria-hidden="true"></i> <b>Passport Number</b> is already exists.</div>';

        break;
      case EnumSG.ClientErrorType.BusinessRegistrationNumberError:
        this.errorMsgInHtml =
          '<div class="col-md-6"><i class="fa fa-exclamation-circle fa-2 chubbRed"' +
          ' aria-hidden="true"></i> <b>Business Registration Number</b> is already exists.</div>';

        break;
      case EnumSG.ClientErrorType.MandatoryError:
        this.errorMsgInHtml =
          '<div class="col-md-6"><i class="fa fa-exclamation-circle fa-2  chubbRed"' +
          ' aria-hidden="true"></i>Please enter all <b>Mandatory fields</b>.</div>';

        break;
      case EnumSG.ClientErrorType.EmailAddressError:
        this.errorMsgInHtml =
          '<div class="col-md-6"><i class="fa fa-exclamation-circle fa-2 chubbRed"' +
          ' aria-hidden="true"></i> Please enter a valid <b> email address </b></div>';

        break;
      case EnumSG.ClientErrorType.PhoneNumberError:
        this.errorMsgInHtml =
          '<div class="col-md-6"><i class="fa fa-exclamation-circle fa-2 chubbRed"' +
          ' aria-hidden="true"></i> <b>Phone Number</b> is invalid.</div>';

        break;
      case EnumSG.ClientErrorType.NameAndNricError:
        this.errorMsgInHtml =
          '<div class="col-md-6"><i class="fa fa-exclamation-circle fa-2 chubbRed"' +
          ' aria-hidden="true"></i>Client is already exists with same given name ,surname,nric and date of birth.</div>';

        break;
      default:
        break;
    }

    this.componentsInitialize();
  };

  openAddressHelp(event: { preventDefault: () => void }): void {
    event.preventDefault();
    const data = {
      // 'proposalModel': this.proposal.proposalModel,
      showGeoCode: false, // determine to hide/show geocode
    };

    const clientCreateDialogRef = this.cdkDialogService.open(
      AddressHelpFormComponent,
      {
        data: data,
      }
    );

    clientCreateDialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
      }
    });
  }
  keyDown = function (evt: any) {
    /* to disbale the input for date input*/
    evt.preventDefault();
  };

  formatDateToString = (date: any) => {
    // const dateSplit = date.split('-');
    // const newDate = dateSplit[1] + '-' + dateSplit[0] + '-' + dateSplit[2];
    const newDate = date;
    // 01, 02, 03, ... 29, 30, 31
    const dd =
      (new Date(newDate).getDate() < 10 ? "0" : "") +
      new Date(newDate).getDate();
    // 01, 02, 03, ... 10, 11, 12
    const MM =
      (new Date(newDate).getMonth() + 1 < 10 ? "0" : "") +
      (new Date(newDate).getMonth() + 1);
    // 1970, 1971, ... 2015, 2016, ...
    const yyyy = new Date(newDate).getFullYear();

    // create the format you want
    return MM + "/" + dd + "/" + yyyy;
  };
  isEmpty(lblValue: string): boolean {
    return this.emptyFieldValidation.includes(lblValue);
  }
}
export interface clientCreateDlgInputArgs {
  isNewClient: boolean;
  proposal: MortgageFireSingaporeProposal;
}
export interface clientCreateDlgModelArgs {
  status: string;
  data: any; //ProposalEntity[]
}
