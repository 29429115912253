import { Component, Input, OnInit } from "@angular/core";
import { RefundPaynowEntity } from "../../models/open-item-list.model";
import { common } from "src/app/framework/utils/common";

@Component({
  selector: "paynow-editor",
  templateUrl: "./paynow-editor.component.html",
  styleUrls: ["./paynow-editor.component.scss"],
})
export class PaynowEditorComponent implements OnInit {
  @Input()
  parentRefundPaynowEntity!: RefundPaynowEntity;

  paynowTypeOptions: Array<{ text: string; value: string }> = [
    { text: "UEN", value: "1" },
    { text: "NRIC", value: "2" },
    { text: "Mobile No", value: "3" },
  ];

  public defaultPaynowTypeItem: { text: string; value: string } = {
    text: "Select PayNow Type",
    value: "",
  };

  invalidPayerName = false;
  invalidIB = false;
  invalidPaynowType = false;
  invalidPaynowLast4Chars = false;
  invalidPaynowDate = false;

  constructor() {}

  ngOnInit(): void {}

  public validatePaynowMandatoryFields(): boolean {
    let hasError: boolean = false;
    // validate mandatory fields
    if (common.isStringNullOrEmpty(this.parentRefundPaynowEntity.payerName)) {
      hasError = true;
      this.invalidPayerName = true;
    } else {
      this.invalidPayerName = false;
    }

    if (common.isUndefinedOrNull(this.parentRefundPaynowEntity.paynowType)) {
      hasError = true;
      this.invalidPaynowType = true;
    } else {
      this.invalidPaynowType = false;
    }

    if (
      common.isStringNullOrEmpty(this.parentRefundPaynowEntity.paynowLast4Chars)
    ) {
      hasError = true;
      this.invalidPaynowLast4Chars = true;
    } else if (this.parentRefundPaynowEntity.paynowLast4Chars.length < 4) {
      this.invalidPaynowLast4Chars = true;
    } else {
      this.invalidPaynowLast4Chars = false;
    }

    if (common.isStringNullOrEmpty(this.parentRefundPaynowEntity.paynowDate)) {
      hasError = true;
      this.invalidPaynowDate = true;
    } else {
      this.invalidPaynowDate = false;
    }

    return !hasError;
  }
}
