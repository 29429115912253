<kendo-dialog
  *ngIf="opened"
  title="Refund Payment"
  class="modal-title-light-orange"
  (close)="close('cancel')"
  [width]="600"
  [maxHeight]="650"
>
  <div class="container-fluid-custom">
    <div class="modal-body">
      <div class="container" style="padding-top: 15px">
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="policyNo">Policy Number</label>
          </div>
          <div class="col-md-8">
            <p
              class="form-control-static"
              id="policyNo"
              [textContent]="openItemEntity.policyNo"
            ></p>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="amount">Total Premium</label>
          </div>
          <div class="col-md-8">
            <p
              class="form-control-static"
              id="amount"
              [textContent]="openItemEntity.amount"
            ></p>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="amountGst">GST</label>
          </div>
          <div class="col-md-8">
            <p
              class="form-control-static"
              id="amountGst"
              [textContent]="openItemEntity.amountGst"
            ></p>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="refundPaymentType"
              >Refund Payment Type</label
            >
          </div>

          <div class="col-md-8">
            <p
              class="form-control-static"
              id="refundPaymentType"
              [textContent]="'EFT'"
            ></p>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="payerName">Payer Name</label>
          </div>
          <div class="col-md-8">
            <p
              class="form-control-static"
              id="payerName"
              [textContent]="openItemEntity.payerName"
            ></p>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="BankAccountNumber"
              >Bank Account Number</label
            >
          </div>
          <div class="col-md-8">
            <p
              class="form-control-static"
              id="bankAccountNumber"
              [textContent]="openItemEntity.BankAccountNumber"
            ></p>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="paynowType">Bank Name</label>
          </div>
          <div class="col-md-8">
            <p
              class="form-control-static"
              id="bankcode"
              [textContent]="openItemEntity.BankName"
            ></p>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="EFTDate">EFT Date</label>
          </div>
          <div class="col-md-8">
            <p
              class="form-control-static"
              id="EFTDate"
              [textContent]="displayHaseDateFormat(openItemEntity.EFTdate)"
            ></p>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4">
            <label class="marginTop5" for="remarks">Remarks</label>
          </div>
          <div class="col-md-8">
            <p
              class="form-control-static"
              id="remarks"
              [textContent]="openItemEntity.refundPaymentRemarks"
            ></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <kendo-dialog-actions>
    <button class="btn chubbRed_bg_btn" kendoButton (click)="close('no')">
      Return
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
