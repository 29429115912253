<div ng-if="tabRequested" id="masterTab">
  <div *ngIf="tabShow">
    <div class="container-fluid-customized">
      <div class="row" *ngIf="isEndorsement || isCancel">
        <div class="col-md-12 card card-mod cardNoBorder ng-scope">
          <div class="card-head chubbLightGray_bg">
            <header
              class="fontBold chubbWhite"
              id="client_panel_end"
              [textContent]="'Endorsement'"
              *ngIf="isEndorsement"
            ></header>
            <header
              class="fontBold chubbWhite"
              id="client_panel_can"
              [textContent]="'Cancellation'"
              *ngIf="isCancel"
            ></header>
          </div>
          <div class="card-body card-body-mod cardBodyBorder cardNoBorder">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6">
                  <form role="form ">
                    <div class="form-group">
                      <date-picker-v1-question
                        [question]="proposal!.qsMaster.qs_master_effectiveDate!"
                        answer="proposal!.qsMaster.qs_master_effectiveDate!.answer"
                        [minDate]="qs_master_effectiveDate_datePicker_minDate"
                        [maxDate]="qs_master_effectiveDate_datePicker_maxDate"
                      >
                      </date-picker-v1-question>
                    </div>
                    <div class="form-group">
                      <div
                        id="{{ proposal!.qsMaster.qs_master_refund!.key }}"
                        [ngClass]="{
                          'st-danger':
                            proposal!.qsMaster.qs_master_refund!.theme === 1
                        }"
                      >
                        <label
                          for="{{ proposal!.qsMaster.qs_master_refund!.key }}"
                          [textContent]="
                            proposal!.qsMaster.qs_master_refund!.displayText
                          "
                          [ngClass]="{
                            required:
                              proposal!.qsMaster.qs_master_refund!.required &&
                              !proposal!.qsMaster.qs_master_refund!.readOnly
                          }"
                        ></label>
                        <div
                          *ngIf="!proposal!.qsMaster.qs_master_refund!.readOnly"
                        >
                          <label class="radio-inline">
                            <input
                              name="{{
                                proposal!.qsMaster.qs_master_refund!.key
                              }}"
                              type="radio"
                              value="1"
                              [(ngModel)]="
                                proposal!.qsMaster.qs_master_refund!.answer
                              "
                              [disabled]="
                                proposal!.qsMaster.qs_master_refund!.isDisabled!
                              "
                            />
                            <span>Default</span>
                          </label>
                          <label class="radio-inline">
                            <input
                              name="{{
                                proposal!.qsMaster.qs_master_refund!.key
                              }}"
                              type="radio"
                              value="2"
                              [(ngModel)]="
                                proposal!.qsMaster.qs_master_refund!.answer
                              "
                              [disabled]="
                                proposal!.qsMaster.qs_master_refund!.isDisabled!
                              "
                            />
                            <span>Bank</span>
                          </label>
                          <label class="radio-inline">
                            <input
                              name="{{
                                proposal!.qsMaster.qs_master_refund!.key
                              }}"
                              type="radio"
                              value="3"
                              [(ngModel)]="
                                proposal!.qsMaster.qs_master_refund!.answer
                              "
                              [disabled]="
                                proposal!.qsMaster.qs_master_refund!.isDisabled!
                              "
                            />
                            <span>Policy Holder</span>
                          </label>
                          <label class="radio-inline">
                            <input
                              name="{{
                                proposal!.qsMaster.qs_master_refund!.key
                              }}"
                              type="radio"
                              value="4"
                              [(ngModel)]="
                                proposal!.qsMaster.qs_master_refund!.answer
                              "
                              [disabled]="
                                proposal!.qsMaster.qs_master_refund!.isDisabled!
                              "
                            />
                            <span>No Refund</span>
                          </label>
                        </div>
                        <div
                          *ngIf="proposal!.qsMaster.qs_master_refund!.readOnly"
                        >
                          <p
                            [textContent]="refundDisplayText"
                            *ngIf="
                              proposal!.qsMaster.qs_master_refund!.isAnswered()
                            "
                          >
                            {{ refundDisplayText }}
                          </p>
                          <span
                            class="unattended"
                            *ngIf="
                              !proposal!.qsMaster.qs_master_refund!.isAnswered()
                            "
                            [textContent]="noAnswerDefaultText"
                            >{{ noAnswerDefaultText }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-md-6">
                  <form role="form ">
                    <div class="form-group">
                      <text-area-question
                        [question]="
                          proposal!.qsMaster
                            .qs_master_endorse_endorsementReason!
                        "
                      >
                      </text-area-question>
                    </div>
                    <div class="form-group" *ngIf="editMSIGPolicy">
                      <text-box-question
                        [question]="
                          proposal!.qsMaster.qs_master_replacePolicyNo!
                        "
                      >
                      </text-box-question>
                    </div>
                    <div class="row">
                      <div class="col-md-5">
                        <form role="form "></form>
                      </div>
                    </div>
                  </form>
                  <form role="form " class="form-inline">
                    <div
                      class="form-group"
                      *ngIf="
                        proposal!.qsMaster.qs_master_waiveMinimumPremium!
                          .isVisible
                      "
                    >
                      <div class="checkbox form-inline">
                        <label>
                          <input
                            #qs_master_waiveMinimumPremium_checkbox
                            name="{{
                              proposal!.qsMaster.qs_master_waiveMinimumPremium!
                                .key
                            }}"
                            type="checkbox"
                            id="{{
                              proposal!.qsMaster.qs_master_waiveMinimumPremium!
                                .key
                            }}_checkbox"
                            (click)="
                              updateModelAnswer(
                                proposal!.qsMaster
                                  .qs_master_waiveMinimumPremium!
                              )
                            "
                            [checked]="
                              compareQuestionAnswer(
                                proposal!.qsMaster
                                  .qs_master_waiveMinimumPremium!
                              )
                            "
                            [disabled]="
                              proposal!.qsMaster.qs_master_waiveMinimumPremium!
                                .isDisabled!
                            "
                          />
                          {{
                            proposal!.qsMaster.qs_master_waiveMinimumPremium!
                              .displayText
                          }}
                        </label>
                      </div>
                    </div>
                  </form>
                  <form role="form">
                    <div class="form-group">
                      <div
                        *ngIf="
                          isIBGProduct &&
                          proposal!.IsCancellationAdjustment &&
                          proposalService.proposal!.auxiliary[
                            'isPremiumManualOverridden'
                          ]
                        "
                      >
                        <label
                          [textContent]="
                            proposal!.qsMaster.qs_master_manualUnderwritting!
                              .displayText
                          "
                        ></label>
                        <div>
                          <div
                            class=""
                            *ngIf="
                              proposalService.proposal!.auxiliary[
                                'isPremiumManualOverridden'
                              ]
                            "
                            style="padding-left: 5px"
                          >
                            Yes
                          </div>
                          <div
                            class=""
                            *ngIf="
                              !proposalService.proposal!.auxiliary[
                                'isPremiumManualOverridden'
                              ]
                            "
                            style="padding-left: 5px"
                          >
                            No
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div class="row">
                <div class="clearfix">
                  <div class="">
                    <form role="form " class="">
                      <div
                        class="form-group col-md-2"
                        *ngIf="
                          proposal!.qsMaster
                            .qs_master_cancellation_adjustmentAmount!.isVisible
                        "
                      >
                        <div class=" ">
                          <label
                            class=""
                            for="cancellation_adjustmentAmount_{{
                              proposal!.qsMaster
                                .qs_master_cancellation_adjustmentAmount!.key
                            }}"
                            [ngClass]="{
                              required:
                                proposal!.qsMaster
                                  .qs_master_cancellation_adjustmentAmount!
                                  .required &&
                                !proposal!.qsMaster
                                  .qs_master_cancellation_adjustmentAmount!
                                  .readOnly
                            }"
                            [textContent]="
                              proposal!.qsMaster
                                .qs_master_cancellation_adjustmentAmount!
                                .displayText
                            "
                          ></label>
                          <div
                            class=""
                            *ngIf="
                              !proposal!.qsMaster
                                .qs_master_cancellation_adjustmentAmount!
                                .readOnly
                            "
                          >
                            <kendo-numerictextbox
                              [(ngModel)]="
                                proposal!.qsMaster
                                  .qs_master_cancellation_adjustmentAmount!
                                  .answer
                              "
                              class="textAlignLeft form-inline"
                              style="width: 100px"
                              *ngIf="
                                !proposal!.qsMaster
                                  .qs_master_cancellation_adjustmentAmount!
                                  .readOnly
                              "
                              [format]="'c2'"
                              [spinners]="false"
                              id="cancellation_adjustmentAmount_{{
                                proposal!.qsMaster
                                  .qs_master_cancellation_adjustmentAmount!.key
                              }}"
                              name="cancellation_adjustmentAmount_{{
                                proposal!.qsMaster
                                  .qs_master_cancellation_adjustmentAmount!.key
                              }}"
                              [decimals]="2"
                            >
                            </kendo-numerictextbox>
                          </div>
                          <div>
                            <span
                              [textContent]="
                                proposal!.qsMaster
                                  .qs_master_cancellation_adjustmentAmount!
                                  .answer | ausCurrency
                              "
                              *ngIf="
                                proposal!.qsMaster
                                  .qs_master_cancellation_adjustmentAmount!
                                  .readOnly &&
                                proposal!.qsMaster.qs_master_cancellation_adjustmentAmount!.isAnswered()
                              "
                            >
                            </span>
                            <span
                              class="unattended"
                              *ngIf="
                                proposal!.qsMaster
                                  .qs_master_cancellation_adjustmentAmount!
                                  .readOnly &&
                                !proposal!.qsMaster.qs_master_cancellation_adjustmentAmount!.isAnswered()
                              "
                              >{{ noAnswerDefaultText }}</span
                            >
                          </div>
                        </div>
                      </div>

                      <div
                        class="form-group col-md-6"
                        *ngIf="
                          proposal!.qsMaster
                            .qs_master_cancellation_maximumAdjustmentAmount!
                            .isVisible
                        "
                      >
                        <div class="">
                          <label
                            for="{{
                              proposal!.qsMaster
                                .qs_master_cancellation_maximumAdjustmentAmount!
                                .key
                            }}"
                            [textContent]="
                              proposal!.qsMaster
                                .qs_master_cancellation_maximumAdjustmentAmount!
                                .displayText
                            "
                          >
                          </label>
                          <div>
                            <span
                              attr.for="{{
                                proposal!.qsMaster
                                  .qs_master_cancellation_maximumAdjustmentAmount!
                                  .key
                              }}"
                              >{{
                                proposal!.qsMaster
                                  .qs_master_cancellation_maximumAdjustmentAmount!
                                  .answer | ausCurrency
                              }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!isChecker && !isMaker">
        <div class="col-md-12 card card-mod cardNoBorder ng-scope">
          <kendo-expansionpanel
            id="broker_panel"
            title="Borrower Information"
            [expanded]="true"
          >
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6">
                  <form role="form">
                    <div class="form-group">
                      <text-box-question
                        [question]="proposal!.qsMaster.qs_master_rmName!"
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </kendo-expansionpanel>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 card card-mod cardNoBorder ng-scope">
          <kendo-expansionpanel
            id="broker_panel"
            title="Client Information"
            [expanded]="true"
          >
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 card card-mod cardNoBorder ng-scope">
                  <div class="card-head chubbLightGray_bg">
                    <header class="fontBold chubbWhite" id="client_panel">
                      Bank Reference
                    </header>
                  </div>
                  <div class="container-fluid cardBodyBorder">
                    <div class="row">
                      <div *ngIf="isIBGProduct" class="col-md-6">
                        <form role="form ">
                          <div class="form-group">
                            <text-box-question
                              [question]="
                                proposal!.qsMaster.qs_master_uploadBuPcCode!
                              "
                            >
                            </text-box-question>
                          </div>
                        </form>
                      </div>
                      <div class="col-md-6">
                        <form role="form ">
                          <div class="form-group">
                            <drop-down-list-filter-question
                              [question]="
                                proposal!.qsMaster.qs_master_customerType!
                              "
                            >
                            </drop-down-list-filter-question>
                          </div>
                        </form>
                      </div>
                      <div class="col-md-6">
                        <form role="form ">
                          <div class="form-group">
                            <text-box-question
                              [question]="
                                proposal!.qsMaster.qs_master_collateralNumber!
                              "
                            >
                            </text-box-question>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <form role="form ">
                          <div class="form-group">
                            <text-box-question
                              [question]="
                                proposal!.qsMaster.qs_master_assetClassCode!
                              "
                            >
                            </text-box-question>
                          </div>
                        </form>
                      </div>
                      <div class="col-md-6">
                        <form role="form ">
                          <div class="form-group">
                            <text-box-question
                              [question]="
                                proposal!.qsMaster.qs_master_assetSerialNumber!
                              "
                            >
                            </text-box-question>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="row"
                *ngIf="
                  !proposal!.qsMaster.qs_master_masterPolicyNumber!.readOnly &&
                  !isIBGProduct &&
                  !isEndorsement &&
                  !isCancel
                "
              >
                <div class="col-md-3">
                  <form role="form">
                    <div
                      class="btn-box chubbWhite chubbLightOrange_bg"
                      (click)="clientSearch()"
                    >
                      Client Search
                    </div>
                  </form>
                </div>
                <div class="col-md-3">
                  <form role="form">
                    <div
                      class="btn-box chubbWhite chubbLightOrange_bg"
                      (click)="clientCreate()"
                    >
                      Create New Client
                    </div>
                  </form>
                </div>
              </div>

              <div class="row">
                <div class="col-md-3" *ngIf="!isIBGProduct">
                  <form role="form">
                    <div class="form-group">
                      <text-box-question
                        [question]="proposal!.qsMaster.qs_master_insuredNo!"
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-3" *ngIf="isIBGProduct">
                  <form role="form">
                    <div class="form-group">
                      <text-box-question
                        [question]="proposal!.qsMaster.qs_master_borrowerCIN!"
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div
                  [ngClass]="{
                    'col-md-4': isIBGProduct,
                    'col-md-3': !isIBGProduct
                  }"
                >
                  <form role="form ">
                    <div class="form-group" *ngIf="isIBGProduct">
                      <text-box-question
                        [question]="proposal!.qsMaster.qs_master_insuredName!"
                      >
                      </text-box-question>
                    </div>
                    <div
                      class="form-group"
                      id="qs_master_insuredName"
                      [ngClass]="{
                        'st-danger':
                          proposal!.qsMaster.qs_master_insuredName!.theme === 1
                      }"
                      *ngIf="!isIBGProduct"
                    >
                      <label
                        [ngClass]="{
                          required:
                            proposal!.qsMaster.qs_master_insuredName!
                              .required &&
                            !proposal!.qsMaster.qs_master_insuredName!.readOnly
                        }"
                        [textContent]="
                          proposal!.qsMaster.qs_master_insuredName!.displayText
                        "
                        class="fontSize14px"
                      ></label>
                      <span
                        class="marginLeft10 cursorPointer cursorPointer chubbWhite fontSize12px chubbRed_bg"
                        (click)="viewClientDetails()"
                        *ngIf="
                          proposal!.qsMaster.qs_master_insuredName!.isAnswered() &&
                          !isIBGProduct
                        "
                        style="padding-left: 5px; padding-right: 5px"
                        >View Details</span
                      >
                      <span
                        class="marginLeft10 cursorPointer cursorPointer chubbWhite fontSize12px chubbRed_bg"
                        (click)="GotoUpdateClientInfo()"
                        *ngIf="!isIBGProduct && isChangeOfClientInfo"
                        style="
                          padding-left: 5px;
                          padding-right: 5px;
                          display: inline-flex;
                        "
                      >
                        Update Client
                      </span>
                      <div>
                        <p
                          [textContent]="
                            proposal!.qsMaster.qs_master_insuredName!.answer
                          "
                          class="wordBreakAll"
                          *ngIf="
                            proposal!.qsMaster.qs_master_insuredName!.isAnswered()
                          "
                        ></p>

                        <span
                          class="unattended"
                          *ngIf="
                            !proposal!.qsMaster.qs_master_insuredName!.isAnswered()
                          "
                          [textContent]="'Unknown'"
                        ></span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div class="row" *ngIf="!isIBGProduct">
                <div class="col-md-12">
                  <form role="form ">
                    <div class="form-group">
                      <div
                        id="{{
                          proposal!.qsMaster.qs_master_preferredDeliveryMethod!
                            .key
                        }}"
                        [ngClass]="{
                          'st-danger':
                            proposal!.qsMaster
                              .qs_master_preferredDeliveryMethod!.theme === 1
                        }"
                      >
                        <label
                          for="{{
                            proposal!.qsMaster
                              .qs_master_preferredDeliveryMethod!.key
                          }}"
                          [ngClass]="{
                            required:
                              proposal!.qsMaster
                                .qs_master_preferredDeliveryMethod!.required &&
                              (isChangeOfClientInfo ||
                                (!proposal!.qsMaster
                                  .qs_master_masterPolicyNumber!.readOnly &&
                                  !isEndorsement &&
                                  !isCancel))
                          }"
                          >Preferred Delivery Method:</label
                        >
                        <span> {{ preferredDeliveryText }}</span>
                        <span
                          class="marginLeft10 cursorPointer cursorPointer chubbWhite fontSize12px chubbRed_bg"
                          (click)="updatePreferredDelivery()"
                          *ngIf="
                            !isChecker &&
                            !isMaker &&
                            (isChangeOfClientInfo ||
                              (!proposal!.qsMaster.qs_master_masterPolicyNumber!
                                .readOnly &&
                                !isEndorsement &&
                                !isCancel))
                          "
                          style="padding-left: 5px; padding-right: 5px"
                          >Update</span
                        >
                        <div class="radio clearMarginTopBottom clearfix">
                          <div style="display: flex">
                            <div class="col-md-4">
                              <label
                                [ngClass]="{
                                  required:
                                    proposal!.qsMaster
                                      .qs_master_preferredDeliveryMethod!
                                      .answer == 5 &&
                                    (isChangeOfClientInfo ||
                                      (!proposal!.qsMaster
                                        .qs_master_masterPolicyNumber!
                                        .readOnly &&
                                        !isEndorsement &&
                                        !isCancel))
                                }"
                                >Mobile Number</label
                              >
                            </div>
                            <div class="col-md-4">
                              <label
                                [ngClass]="{
                                  required:
                                    proposal!.qsMaster
                                      .qs_master_preferredDeliveryMethod!
                                      .answer == 2 &&
                                    (isChangeOfClientInfo ||
                                      (!proposal!.qsMaster
                                        .qs_master_masterPolicyNumber!
                                        .readOnly &&
                                        !isEndorsement &&
                                        !isCancel))
                                }"
                                >Email</label
                              >
                            </div>
                            <div class="col-md-4">
                              <label
                                [ngClass]="{
                                  required:
                                    proposal!.qsMaster
                                      .qs_master_preferredDeliveryMethod!
                                      .answer == 1 &&
                                    (isChangeOfClientInfo ||
                                      (!proposal!.qsMaster
                                        .qs_master_masterPolicyNumber!
                                        .readOnly &&
                                        !isEndorsement &&
                                        !isCancel))
                                }"
                                >Mailing Address</label
                              >

                              <span
                                class="marginLeft10 cursorPointer cursorPointer chubbWhite fontSize12px chubbRed_bg"
                                (click)="updatePreferredDelivery()"
                                *ngIf="isChangeOfCorrespondenceAddressType"
                                style="padding-left: 5px; padding-right: 5px"
                                >Update</span
                              >
                            </div>
                          </div>
                        </div>
                        <div
                          class="radio clearMarginTopBottom clearfix"
                          style="display: flex"
                        >
                          <div class="col-md-4">
                            <p
                              [textContent]="
                                proposal!.qsMaster
                                  .qs_master_preferredDeliveryMethod_MobileNo!
                                  .answer
                              "
                              *ngIf="
                                proposal!.qsMaster.qs_master_preferredDeliveryMethod_MobileNo!.isAnswered()
                              "
                              class="wordBreak"
                            ></p>
                          </div>
                          <div class="col-md-4">
                            <p
                              [textContent]="
                                proposal!.qsMaster
                                  .qs_master_preferredDeliveryMethod_Email!
                                  .answer
                              "
                              *ngIf="
                                proposal!.qsMaster.qs_master_preferredDeliveryMethod_Email!.isAnswered()
                              "
                              class="wordBreak"
                            ></p>
                          </div>
                          <div class="col-md-4">
                            <p
                              [textContent]="
                                contactInformationPreferredDelivery
                              "
                              *ngIf="
                                proposal!.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress!.isAnswered()
                              "
                              class="wordBreak"
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div class="row">
                <div *ngIf="isIBGProduct" class="col-md-3">
                  <form role="form ">
                    <div class="form-group">
                      <date-input-question
                        [question]="
                          proposal!.qsMaster
                            .qs_master_dateOfBirthOrIncorporation!
                        "
                        answer="proposal!.qsMaster.qs_master_dateOfBirthOrIncorporation!.answer "
                      >
                      </date-input-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-4" *ngIf="isIBGProduct">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="proposal!.qsMaster.qs_master_insuredNo!"
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-4" *ngIf="isIBGProduct">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="proposal!.qsMaster.qs_master_insuredNo2!"
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
              </div>

              <div class="row" *ngIf="isIBGProduct">
                <div class="col-md-3">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="
                          proposal!.qsMaster.qs_master_namesOtherInsured!
                        "
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
              </div>

              <div class="row" *ngIf="isIBGProduct">
                <div class="col-md-3">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="
                          proposal!.qsMaster.qs_master_clientContactPerson!
                        "
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-3">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="
                          proposal!.qsMaster
                            .qs_master_clientContactPersonContactNo!
                        "
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-3">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="proposal!.qsMaster.qs_master_phoneNoOffice!"
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
              </div>
              <div class="row" *ngIf="isIBGProduct">
                <div class="col-md-12">
                  <form role="form ">
                    <div class="form-group">
                      <div
                        id="{{
                          proposal!.qsMaster.qs_master_preferredDeliveryMethod!
                            .key
                        }}"
                        [ngClass]="{
                          'st-danger':
                            proposal!.qsMaster
                              .qs_master_preferredDeliveryMethod!.theme === 1
                        }"
                      >
                        <label
                          for="{{
                            proposal!.qsMaster
                              .qs_master_preferredDeliveryMethod!.key
                          }}"
                          [ngClass]="{
                            required:
                              proposal!.qsMaster
                                .qs_master_preferredDeliveryMethod!.required &&
                              !proposal!.qsMaster
                                .qs_master_preferredDeliveryMethod!.readOnly
                          }"
                          [textContent]="
                            proposal!.qsMaster
                              .qs_master_preferredDeliveryMethod!.displayText
                          "
                          >Preferred Delivery Method:</label
                        >
                        <div class="radio clearMarginTopBottom clearfix">
                          <div>
                            <div class="col-md-4">
                              <label class="radio-inline">
                                <form role="form ">
                                  <div class="form-group">
                                    <input
                                      style="padding-left: 0px"
                                      name="{{
                                        proposal!.qsMaster
                                          .qs_master_preferredDeliveryMethod!
                                          .key
                                      }}"
                                      type="radio"
                                      value="5"
                                      [(ngModel)]="
                                        proposal!.qsMaster
                                          .qs_master_preferredDeliveryMethod!
                                          .answer
                                      "
                                      [disabled]="
                                        toShowQuestion(
                                          proposal!.qsMaster
                                            .qs_master_preferredDeliveryMethod!
                                            .key
                                        )
                                      "
                                    />
                                    <text-box-question
                                      *ngIf="
                                        toShowQuestion(
                                          proposal!.qsMaster
                                            .qs_master_preferredDeliveryMethod_MobileNo!
                                            .key
                                        )
                                      "
                                      [question]="
                                        proposal!.qsMaster
                                          .qs_master_preferredDeliveryMethod_MobileNo!
                                      "
                                    >
                                    </text-box-question>
                                    <span
                                      *ngIf="
                                        !toShowQuestion(
                                          proposal!.qsMaster
                                            .qs_master_preferredDeliveryMethod_MobileNo!
                                            .key
                                        )
                                      "
                                      >{{
                                        proposal!.qsMaster
                                          .qs_master_preferredDeliveryMethod_MobileNo!
                                          .displayText
                                      }}</span
                                    >
                                  </div>
                                </form>
                              </label>
                            </div>
                            <div class="col-md-4">
                              <label class="radio-inline">
                                <form role="form ">
                                  <div class="form-group">
                                    <input
                                      name="{{
                                        proposal!.qsMaster
                                          .qs_master_preferredDeliveryMethod!
                                          .key
                                      }}"
                                      type="radio"
                                      value="2"
                                      [(ngModel)]="
                                        proposal!.qsMaster
                                          .qs_master_preferredDeliveryMethod!
                                          .answer
                                      "
                                      [disabled]="
                                        toShowQuestion(
                                          proposal!.qsMaster
                                            .qs_master_preferredDeliveryMethod!
                                            .key
                                        )
                                      "
                                    />
                                    <text-box-question
                                      *ngIf="
                                        toShowQuestion(
                                          proposal!.qsMaster
                                            .qs_master_preferredDeliveryMethod_Email!
                                            .key
                                        )
                                      "
                                      [question]="
                                        proposal!.qsMaster
                                          .qs_master_preferredDeliveryMethod_Email!
                                      "
                                    >
                                    </text-box-question>
                                    <span
                                      *ngIf="
                                        !toShowQuestion(
                                          proposal!.qsMaster
                                            .qs_master_preferredDeliveryMethod_Email!
                                            .key
                                        )
                                      "
                                      >{{
                                        proposal!.qsMaster
                                          .qs_master_preferredDeliveryMethod_Email!
                                          .displayText
                                      }}</span
                                    >
                                  </div>
                                </form>
                              </label>
                            </div>

                            <div class="col-md-4">
                              <label class="radio-inline">
                                <input
                                  name="{{
                                    proposal!.qsMaster
                                      .qs_master_preferredDeliveryMethod!.key
                                  }}"
                                  type="radio"
                                  value="1"
                                  [(ngModel)]="
                                    proposal!.qsMaster
                                      .qs_master_preferredDeliveryMethod!.answer
                                  "
                                  [disabled]="
                                    toShowQuestion(
                                      proposal!.qsMaster
                                        .qs_master_preferredDeliveryMethod!.key
                                    )
                                  "
                                />
                                <span
                                  [ngClass]="{
                                    required:
                                      proposal!.qsMaster
                                        .qs_master_preferredDeliveryMethod_CorrAddress!
                                        .required &&
                                      !proposal!.qsMaster
                                        .qs_master_preferredDeliveryMethod!
                                        .readOnly
                                  }"
                                  >Mailing Address</span
                                >
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div class="row" *ngIf="isIBGProduct">
                <div class="col-md-6">
                  <form role="form ">
                    <div class="form-group">
                      <div>
                        <text-box-question
                          [question]="
                            proposal!.qsMaster.qs_master_insuredAddress!
                          "
                        >
                        </text-box-question>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="row" *ngIf="isIBGProduct">
                <div class="col-md-6">
                  <form role="form ">
                    <div class="form-group">
                      <label
                        class="textDecoUnderline fontSize14px"
                        [textContent]="'Correspondence Address'"
                      ></label>
                    </div>
                  </form>
                </div>
              </div>
              <div *ngIf="isIBGProduct">
                <div class="row" *ngIf="isChangeOfCorrespondenceAddressType">
                  <div class="col-md-12">
                    <form role="form ">
                      <div class="form-group">
                        <label
                          class="floatLeft marginRight10 fontSize14px"
                          innerHTML="
                            'Street Address Search – Please enter only the street address and suburb, do not include building name or suite/lot/unit/shop number or level'
                          "
                        ></label>
                        <div
                          style="font-size: 12px"
                          class="cursorPointer floatLeft chubbWhite"
                        >
                          <span
                            class="chubbGreen_bg"
                            style="
                              padding: 0px 5px 1px 5px;
                              vertical-align: super;
                            "
                            (click)="openAddressHelp($event)"
                            >Help</span
                          >
                        </div>
                        <div>
                          <div>
                            <input
                              id="policyInformation_autocomplete"
                              placeholder="Enter your address "
                              type="text "
                              class="k-textbox maxWidth"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <!-- <hr *ngIf="!proposal!.refreshedProposalEntity.proposalReadOnly "> -->
                <div class="row">
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="
                            proposal!.qsMaster.qs_master_insuredAddress_lotunit!
                          "
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="
                            proposal!.qsMaster
                              .qs_master_insuredAddress_building!
                          "
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="
                            proposal!.qsMaster.qs_master_insuredAddress1!
                          "
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="
                            proposal!.qsMaster.qs_master_insuredAddress2!
                          "
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="
                            proposal!.qsMaster.qs_master_insuredPostalCode!
                          "
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="proposal!.qsMaster.qs_master_insuredCity!"
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-6">
                    <form role="form ">
                      <div class="form-group">
                        <text-box-question
                          [question]="
                            proposal!.qsMaster.qs_master_insuredState!
                          "
                        >
                        </text-box-question>
                      </div>
                    </form>
                  </div>
                  <div
                    class="col-md-12"
                    *ngIf="
                      proposal!.qsMaster.qs_master_insuredCountry!.isVisible
                    "
                  >
                    <form role="form ">
                      <div class="form-group">
                        <drop-down-list-filter-question
                          [question]="
                            proposal!.qsMaster.qs_master_insuredCountry!
                          "
                        >
                        </drop-down-list-filter-question>
                      </div>
                    </form>
                  </div>
                  <div *ngIf="isChangeOfCorrespondenceAddressType">
                    <div class="col-md-2 floatLeft">
                      <form role="form ">
                        <div class="form-group">
                          <div
                            class="btn-box chubbWhite chubbRed_bg"
                            (click)="
                              resetAddressForm(
                                true,
                                'policyInformation_autocomplete'
                              )
                            "
                          >
                            Reset
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </kendo-expansionpanel>
        </div>
      </div>
    </div>
    <div class="clearfix">
      <div class="col-md-6">
        <form role="form ">
          <div class="form-group">
            <label
              class="textDecoUnderline fontSize14px"
              [textContent]="labelText"
              >{{ labelText }}</label
            >
          </div>
        </form>
      </div>
    </div>

    <div
      style="display: flex"
      class="clearfix"
      *ngIf="!proposal.loanRedeemed && !isIBGProduct"
    >
      <div class="col-md-3">
        <form role="form ">
          <div class="form-group">
            <label class="fontSize14px" [textContent]="'Name:'"></label>
            <div>
              <p [textContent]="'Citibank Singapore Ltd'"></p>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-3">
        <form role="form ">
          <div class="form-group">
            <label
              class="fontSize14px"
              [textContent]="'Nature Of Interest:'"
            ></label>
            <div>
              <p [textContent]="'Mortgagee'"></p>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="clearfix">
      <div class="col-md-12">
        <div
          id="qs_ct_additional_insured_table_grid"
          style="margin-bottom: 20px"
        >
          <form novalidate #myForm="ngForm">
            <kendo-grid
              [data]="gridFireInterestedParties"
              [pageSize]="10"
              [pageable]="true"
              [sortable]="true"
              (edit)="editHandler($event)"
              (cancel)="cancelHandler($event)"
              (save)="saveHandler($event)"
              (remove)="removeHandler($event)"
              (add)="addHandler($event)"
              [navigable]="true"
              [height]="280"
            >
              <ng-template kendoGridToolbarTemplate *ngIf="addButtonVisiiblity">
                <button kendoGridAddCommand type="button">
                  {{ labelTextInterestedParty }}
                </button>
              </ng-template>
              <kendo-grid-column field="Name" title="Name" [width]="350">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                  <kendo-textbox
                    [(ngModel)]="dataItem.Name"
                    name="Name"
                    required
                  >
                  </kendo-textbox>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column
                field="NatureOfInterest"
                title="Nature Of Interest"
                [width]="250"
                [editable]="false"
                ><ng-template kendoGridEditTemplate let-dataItem="dataItem">
                  <label [textContent]="dataItem.NatureOfInterest"> </label>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="NRIC" title="NRIC" [width]="350">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                  <kendo-textbox [(ngModel)]="dataItem.NRIC" name="NRIC">
                  </kendo-textbox>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-command-column
                title="Action"
                [hidden]="hideInterestedPartiesActionCol"
                [width]="220"
              >
                <ng-template kendoGridCellTemplate let-isNew="isNew">
                  <button kendoGridEditCommand type="button" [primary]="true">
                    Edit
                  </button>
                  <button kendoGridRemoveCommand type="button">Remove</button>
                  <button
                    kendoGridSaveCommand
                    type="button"
                    [disabled]="myForm.invalid!"
                  >
                    {{ isNew ? "Add" : "Update" }}
                  </button>
                  <button kendoGridCancelCommand type="button">
                    {{ isNew ? "Discard changes" : "Cancel" }}
                  </button>
                </ng-template>
              </kendo-grid-command-column>
            </kendo-grid>
          </form>
        </div>
      </div>
    </div>
    <hr *ngIf="!isIBGProduct" />
    <div class="row">
      <div class="col-md-12 card card-mod cardNoBorder ng-scope">
        <kendo-expansionpanel
          id="broker_panel"
          [title]="
            ibgMACDisabled ? 'Fire Risk Details' : 'Machinery Risk Details'
          "
          [expanded]="true"
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-4">
                <form role="form ">
                  <div class="form-group">
                    <drop-down-list-filter-question
                      [question]="
                        proposal!.qsMaster.qs_master_masterPolicyNumber!
                      "
                    >
                    </drop-down-list-filter-question>
                  </div>
                </form>
              </div>
              <div class="col-md-3">
                <form role="form ">
                  <div class="form-group">
                    <radio-button-v1-question
                      [question]="proposal!.qsMaster.qs_master_planType!"
                    >
                    </radio-button-v1-question>
                  </div>
                </form>
              </div>

              <div class="col-md-3" *ngIf="isIBGProduct">
                <form role="form ">
                  <div class="form-group">
                    <yes-no-radio-question
                      [question]="
                        proposal!.qsMaster.qs_fire_freeInsuranceFirstYear!
                      "
                    >
                    </yes-no-radio-question>
                  </div>
                </form>
              </div>

              <!--Chirag: 28-12-2018 Free Year Change For CBG Prodcut-->
              <!-- isIBGProduct && -->

              <div
                class="col-md-3"
                *ngIf="
                  proposal!.qsMaster.qs_fire_freeInsuranceNoYear!.isVisible ||
                  !isFreeInsApplicable
                "
              >
                <form role="form ">
                  <div class="form-group">
                    <numeric-box-question
                      [question]="
                        proposal!.qsMaster.qs_fire_freeInsuranceNoYear!
                      "
                    >
                    </numeric-box-question>
                  </div>
                </form>
              </div>
              <div class="col-md-2" *ngIf="!hideDiscountCode">
                <form role="form">
                  <div class="form-group">
                    <drop-down-list-filter-question
                      [question]="proposal!.qsMaster.qs_master_discountCode!"
                    >
                    </drop-down-list-filter-question>
                  </div>
                </form>
              </div>
              <div class="col-md-2" *ngIf="isCustomDiscount">
                <form role="form">
                  <div class="form-group">
                    <percentage-box-v1-question
                      [question]="
                        proposal!.qsMaster.qs_master_customDiscountCode!
                      "
                    >
                    </percentage-box-v1-question>
                  </div>
                </form>
              </div>
            </div>
            <!--17/10/2018 Chirag : Added Free Insurance Start/End Date -->
            <div
              class="row"
              *ngIf="
                !isIBGProduct &&
                proposal!.qsMaster.qs_master_FreeIns_startDate!.isVisible
              "
            >
              <div class="col-md-3">
                <form role="form ">
                  <div class="form-group">
                    <date-picker-v1-question
                      [question]="
                        proposal!.qsMaster.qs_master_FreeIns_startDate!
                      "
                      answer="proposal!.qsMaster.qs_master_FreeIns_startDate"
                    >
                    </date-picker-v1-question>
                  </div>
                </form>
              </div>
              <div class="col-md-3">
                <form role="form ">
                  <div class="form-group">
                    <date-picker-v1-question
                      [question]="proposal!.qsMaster.qs_master_FreeIns_endDate!"
                      answer="proposal!.qsMaster.qs_master_FreeIns_endDate"
                    >
                    </date-picker-v1-question>
                  </div>
                </form>
              </div>
            </div>

            <!--Chirag: 28-12-2018 Free Insurance Start/End Date For CBG Prodcut-->
            <div
              class="row"
              *ngIf="
                !isFreeInsApplicable &&
                !proposal!.qsMaster.qs_master_FreeIns_startDate!.isVisible
              "
            >
              <div class="col-md-3">
                <form role="form ">
                  <div class="form-group">
                    <label
                      class="fontSize14px"
                      [textContent]="'Free Insurance Start Date'"
                    ></label>
                  </div>
                  <div class="form-group">
                    <label class="fontSize10px" [textContent]="'N/A'"></label>
                  </div>
                </form>
              </div>
              <div class="col-md-3">
                <form role="form ">
                  <div class="form-group">
                    <label
                      class="fontSize14px"
                      [textContent]="'Free Insurance End Date'"
                    ></label>
                  </div>
                  <div class="form-group">
                    <label class="fontSize10px" [textContent]="'N/A'"></label>
                  </div>
                </form>
              </div>
            </div>

            <!-- End Free Insurance Start Date -->

            <div class="row">
              <div class="col-md-3">
                <form role="form ">
                  <div class="form-group">
                    <div id="originalInceptionDate">
                      <label
                        for="originalInceptionDate "
                        [textContent]="'Original Inception Date'"
                      ></label>
                      <div>
                        <p [textContent]="originalInceptionDate"></p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-2">
                <form role="form ">
                  <div class="form-group">
                    <date-picker-v1-question
                      [question]="proposal!.qsMaster.qs_master_poi_startDate!"
                      answer="proposal!.qsMaster.qs_master_poi_startDate!.answer"
                      [minDate]="qs_master_poi_startDate_datePicker_minDate"
                      [maxDate]="qs_master_poi_startDate_datePicker_maxDate"
                    >
                    </date-picker-v1-question>
                  </div>
                </form>
              </div>
              <div class="col-md-2">
                <form role="form ">
                  <div class="form-group">
                    <date-picker-v1-question
                      [question]="proposal!.qsMaster.qs_master_poi_endDate!"
                      answer="proposal!.qsMaster.qs_master_poi_endDate!.answer"
                    >
                    </date-picker-v1-question>
                  </div>
                </form>
              </div>
              <div class="col-md-2">
                <form role="form">
                  <div class="form-group">
                    <drop-down-list-filter-question
                      [question]="proposal!.qsMaster.qs_master_premiumPayment!"
                    >
                    </drop-down-list-filter-question>
                  </div>
                </form>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <label
                      attr.for="{{
                        proposal!.qsMaster.qs_master_insuredName!.key
                      }} "
                      class="textDecoUnderline fontSize14px"
                      [textContent]="'Risk Address'"
                    ></label>
                  </div>
                </form>
              </div>
            </div>

            <div class="row" *ngIf="isIBGProduct">
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <div>
                      <text-box-question
                        [question]="
                          proposal!.qsMaster.qs_risk_uploadRiskAddress!
                        "
                      >
                      </text-box-question>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="row">
              <div
                *ngIf="
                  !proposal!.refreshedProposalEntity?.proposalReadOnly &&
                  !isEndorsement &&
                  !isCancel
                "
                class="col-md-12"
              >
                <form role="form ">
                  <div class="form-group">
                    <div
                      id="{{ proposal!.qsMaster.qs_risk_sameAsAddress!.key }}"
                      [ngClass]="{
                        'st-danger':
                          proposal!.qsMaster.qs_risk_sameAsAddress!.theme === 1
                      }"
                    >
                      <label
                        for="{{ proposal!.qsMaster.qs_risk_sameAsAddress!.key }}
                                          "
                        [ngClass]="{
                          required:
                            proposal!.qsMaster.qs_risk_sameAsAddress!
                              .required &&
                            !proposal!.qsMaster.qs_risk_sameAsAddress!.readOnly
                        }"
                        [textContent]="
                          proposal!.qsMaster.qs_risk_sameAsAddress!.displayText
                        "
                      ></label>
                      <div
                        *ngIf="
                          !proposal!.qsMaster.qs_risk_sameAsAddress!.readOnly
                        "
                      >
                        <div class="radio clearMarginTopBottom">
                          <input
                            *ngIf="
                              !proposal!.qsMaster.qs_risk_sameAsAddress!
                                .readOnly
                            "
                            name="{{
                              proposal!.qsMaster.qs_risk_sameAsAddress!.key
                            }} "
                            #qs_risk_sameAsAddress_checkbox
                            [disabled]="isRenewalDraft"
                            type="checkbox"
                            id="{{
                              proposal!.qsMaster.qs_risk_sameAsAddress!.key
                            }}_checkbox"
                            (click)="
                              updateModelAnswer(
                                proposal!.qsMaster.qs_risk_sameAsAddress!
                              );
                              copyInsuredAddress()
                            "
                            [checked]="
                              compareQuestionAnswer(
                                proposal!.qsMaster.qs_risk_sameAsAddress!
                              )
                            "
                          />
                        </div>
                      </div>

                      <div
                        *ngIf="
                          proposal!.qsMaster.qs_risk_sameAsAddress!.readOnly
                        "
                      >
                        <div
                          class=""
                          *ngIf="
                            compareQuestionAnswer(
                              proposal!.qsMaster.qs_risk_sameAsAddress!.answer
                            )
                          "
                          style="padding-left: 5px"
                        >
                          Yes
                        </div>
                        <div
                          class=""
                          *ngIf="
                            compareQuestionAnswer(
                              proposal!.qsMaster.qs_risk_sameAsAddress!.answer
                            )
                          "
                          style="padding-left: 5px"
                        >
                          No
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div
                *ngIf="
                  !proposal!.qsMaster.qs_master_masterPolicyNumber!.readOnly &&
                  !isGeneralEndorsement &&
                  !isSameAsAddressTicked
                "
                class="col-md-12"
              >
                <form role="form ">
                  <div class="form-group">
                    <label
                      class="floatLeft marginRight10 fontSize14px"
                      [textContent]="
                        'Street Address Search – Please enter only the street address and suburb, do not include building name or suite/lot/unit/shop number or level'
                      "
                    ></label>
                    <div
                      style="font-size: 12px"
                      class="cursorPointer floatLeft chubbWhite"
                    >
                      <span
                        class="chubbGreen_bg"
                        style="padding: 0px 5px 1px 5px; vertical-align: super"
                        (click)="openAddressHelp($event)"
                        >Help</span
                      >
                    </div>
                    <div>
                      <div>
                        <input
                          id="riskInformation_autocomplete"
                          ngx-google-places-autocomplete
                          [options]="options"
                          #riskInformation_autocomplete="ngx-places"
                          (onAddressChange)="
                            updateAddressValues(
                              $event,
                              {},
                              'riskInformation_autocomplete'
                            )
                          "
                          [disabled]="isRenewalDraft && isIBGProduct"
                          placeholder="Enter your address "
                          type="text "
                          class="k-textbox maxWidth"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="
                        proposal!.qsMaster.qs_risk_insuredAddress_lotunit!
                      "
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="
                        proposal!.qsMaster.qs_risk_insuredAddress_building!
                      "
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="proposal!.qsMaster.qs_risk_insuredAddress1!"
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="proposal!.qsMaster.qs_risk_insuredAddress2!"
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="proposal!.qsMaster.qs_risk_insuredPostalCode!"
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="proposal!.qsMaster.qs_risk_insuredCity!"
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
              <div
                class="col-md-12"
                *ngIf="proposal!.qsMaster.qs_risk_insuredCountry!.isVisible"
              >
                <form role="form ">
                  <div class="form-group">
                    <drop-down-list-filter-question
                      [question]="proposal!.qsMaster.qs_risk_insuredCountry!"
                    >
                    </drop-down-list-filter-question>
                  </div>
                </form>
              </div>
              <div
                *ngIf="
                  !proposal!.qsMaster.qs_master_masterPolicyNumber!.readOnly &&
                  !isGeneralEndorsement &&
                  !isSameAsAddressTicked &&
                  !(isRenewalDraft && isIBGProduct)
                "
                class="col-md-2 floatLeft"
              >
                <form role="form ">
                  <div class=" ">
                    <div
                      class="btn-box chubbWhite chubbRed_bg"
                      (click)="
                        resetAddressForm(true, 'riskInformation_autocomplete')
                      "
                      style="margin-bottom: 0px"
                    >
                      Reset
                    </div>
                  </div>
                </form>
              </div>

              <hr />

              <div *ngIf="isIBGProduct && !ibgMFDisabled" class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <label
                      class="textDecoUnderline fontSize14px"
                      [textContent]="'Mortgage Fire'"
                    ></label>
                  </div>
                </form>
              </div>
              <div *ngIf="isIBGProduct && ibgMFDisabled" class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <label
                      class="textDecoUnderline fontSize14px"
                      [textContent]="'Machinery All Risk'"
                    ></label>
                  </div>
                </form>
              </div>

              <div class="col-md-6" *ngIf="isIBGProduct">
                <form role="form ">
                  <div
                    class="form-group"
                    id="qs_master_insuredIndustry"
                    [ngClass]="{
                      'st-danger':
                        proposal!.qsMaster.qs_master_insuredIndustry!.theme ===
                        1
                    }"
                  >
                    <label
                      [ngClass]="{
                        required:
                          proposal!.qsMaster.qs_master_insuredIndustry!
                            .required &&
                          !proposal!.qsMaster.qs_master_insuredIndustry!
                            .readOnly
                      }"
                      [textContent]="
                        proposal!.qsMaster.qs_master_insuredIndustry!
                          .displayText
                      "
                      class="fontSize14px"
                    ></label>
                    <ng-container
                      *ngIf="
                        !proposal!.refreshedProposalEntity.proposalReadOnly &&
                        !proposal!.isBroker
                      "
                    >
                      <span
                        class="marginLeft10 cursorPointer cursorPointer chubbWhite fontSize12px chubbRed_bg"
                        style="padding-left: 5px; padding-right: 5px"
                        (click)="qs_master_insuredIndustry_onChange()"
                        *ngIf="
                          (!isCancel && !isEndorsement) ||
                          (isEndorsement && isGeneralEndorsement)
                        "
                      >
                        Search
                      </span>
                    </ng-container>
                    <div>
                      <p
                        [textContent]="
                          proposal!.qsMaster.qs_master_insuredIndustry_desc!
                            .answer
                        "
                        *ngIf="
                          proposal!.qsMaster.qs_master_insuredIndustry!.isAnswered()
                        "
                      ></p>
                      <span
                        class="unattended"
                        *ngIf="
                          !proposal!.qsMaster.qs_master_insuredIndustry!.isAnswered() &&
                          proposal!.refreshedProposalEntity.proposalReadOnly
                        "
                        [textContent]="'Unknown'"
                      ></span>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-3" *ngIf="isIBGProduct && !ibgMFDisabled">
                <form role="form ">
                  <div class="form-group">
                    <drop-down-list-filter-question
                      [question]="proposal!.qsMaster.qs_master_occupancyRate!"
                    >
                    </drop-down-list-filter-question>
                  </div>
                </form>
              </div>
              <div class="col-md-3" *ngIf="isIBGProduct && !ibgMFDisabled">
                <form role="form ">
                  <div class="form-group">
                    <drop-down-list-question
                      [question]="proposal!.qsMaster.qs_master_miRate!"
                    >
                    </drop-down-list-question>
                  </div>
                </form>
              </div>
              <div
                *ngIf="!isIBGProduct"
                class="col-md-12"
                id="{{ proposal!.qsMaster.qs_master_manualUnderwritting!.key }}"
              >
                <form role="form ">
                  <div class="form-group" *ngIf="displayManualUnderwriting">
                    <div>
                      <label
                        for="{{
                          proposal!.qsMaster.qs_master_manualUnderwritting!.key
                        }}"
                        [textContent]="
                          proposal!.qsMaster.qs_master_manualUnderwritting!
                            .displayText
                        "
                      ></label>
                      <div
                        *ngIf="
                          !proposal!.qsMaster.qs_master_manualUnderwritting!
                            .readOnly
                        "
                      >
                        <div
                          class="radio clearMarginTopBottom clearfix"
                          *ngIf="isIncomplete || isReferred"
                        >
                          <input
                            *ngIf="
                              !proposal!.qsMaster.qs_master_manualUnderwritting!
                                .readOnly
                            "
                            name="{{
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .key
                            }} "
                            #qs_master_manualUnderwritting_checkbox
                            type="checkbox"
                            value="{{
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .answer
                            }}"
                            id="{{
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .key
                            }}_checkbox"
                            [disabled]="
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .isDisabled!
                            "
                            (click)="
                              updateModelAnswer(
                                proposal!.qsMaster
                                  .qs_master_manualUnderwritting!
                              );
                              qs_master_manualUnderwritting_onChange(
                                proposal!.qsMaster
                                  .qs_master_manualUnderwritting!.answer!
                              )
                            "
                            [checked]="
                              compareQuestionAnswer(
                                proposal!.qsMaster
                                  .qs_master_manualUnderwritting!
                              )
                            "
                          />
                        </div>
                        <div *ngIf="!isIncomplete && !isReferred">
                          <div
                            class=""
                            *ngIf="
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .answer === 'True' ||
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .answer === true
                            "
                            style="padding-left: 5px"
                          >
                            Yes
                          </div>
                          <div
                            class=""
                            *ngIf="
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .answer !== 'True' &&
                              proposal!.qsMaster.qs_master_manualUnderwritting!
                                .answer !== true
                            "
                            style="padding-left: 5px"
                          >
                            No
                          </div>
                        </div>
                      </div>
                      <div
                        *ngIf="
                          proposal!.qsMaster.qs_master_manualUnderwritting!
                            .readOnly
                        "
                      >
                        <div
                          class=""
                          *ngIf="
                            proposal!.qsMaster.qs_master_manualUnderwritting!
                              .answer === 'True' ||
                            proposal!.qsMaster.qs_master_manualUnderwritting!
                              .answer === true
                          "
                          style="padding-left: 5px"
                        >
                          Yes
                        </div>
                        <div
                          class=""
                          *ngIf="
                            proposal!.qsMaster.qs_master_manualUnderwritting!
                              .answer !== 'True' &&
                            proposal!.qsMaster.qs_master_manualUnderwritting!
                              .answer !== true
                          "
                          style="padding-left: 5px"
                        >
                          No
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="!displayManualUnderwriting">
                    <div>
                      <label
                        for="{{
                          proposal!.qsMaster.qs_master_manualUnderwritting!.key
                        }}"
                        [textContent]="
                          proposal!.qsMaster.qs_master_manualUnderwritting!
                            .displayText
                        "
                      ></label>
                      <div
                        class=""
                        *ngIf="
                          proposal!.qsMaster.qs_master_manualUnderwritting!
                            .answer === 'True' ||
                          proposal!.qsMaster.qs_master_manualUnderwritting!
                            .answer === true
                        "
                        style="padding-left: 5px"
                      >
                        Yes
                      </div>
                      <div
                        class=""
                        *ngIf="
                          proposal!.qsMaster.qs_master_manualUnderwritting!
                            .answer !== 'True' &&
                          proposal!.qsMaster.qs_master_manualUnderwritting!
                            .answer !== true
                        "
                        style="padding-left: 5px"
                      >
                        No
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group" *ngIf="enableOccupiedAs">
                    <drop-down-list-filter-question
                      [question]="proposal!.qsMaster.qs_fire_occupied!"
                    ></drop-down-list-filter-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group" *ngIf="ibgMACDisabled">
                    <text-area-question
                      [question]="proposal!.qsMaster.qs_fire_notes!"
                    >
                    </text-area-question>
                  </div>
                </form>
              </div>
              <div
                class="col-md-12"
                *ngIf="proposal!.qsMaster.qs_fire_mcst!.isVisible"
              >
                <div class="col-md-6" style="padding-inline-start: unset">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="proposal!.qsMaster.qs_fire_mcst!"
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
              </div>
              <div
                class="col-md-6"
                *ngIf="
                  proposal!.qsMaster.qs_fire_replacement!.isVisible &&
                  ibgMACDisabled
                "
              >
                <form role="form ">
                  <div class="form-group">
                    <div class="">
                      <radio-button-v1-question
                        [question]="
                          proposal!.qsMaster.qs_fire_coverageValueType!
                        "
                        (click)="qs_fire_coverageValueType_OnChange()"
                      >
                      </radio-button-v1-question>
                    </div>
                    <currency-box-v1-question
                      [question]="proposal!.qsMaster.qs_fire_replacement!"
                    >
                    </currency-box-v1-question>
                  </div>
                </form>
              </div>
              <div *ngIf="isIBGProduct && !ibgMFDisabled" class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="proposal!.qsMaster.qs_fire_natureBusiness!"
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
              <div *ngIf="isIBGProduct && !ibgMFDisabled" class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="proposal!.qsMaster.qs_fire_usagePremises!"
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>

              <div *ngIf="isIBGProduct && ibgMFDisabled">
                <div class="col-md-6">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="proposal!.qsMaster.qs_fire_natureBusiness!"
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-6">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="proposal!.qsMaster.qs_fire_nameSolicitors!"
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
              </div>

              <div *ngIf="isIBGProduct && !ibgMFDisabled">
                <div class="col-md-6">
                  <form role="form ">
                    <div class="form-group">
                      <text-area-question
                        [question]="proposal!.qsMaster.qs_fire_typeProperty!"
                      >
                      </text-area-question>
                    </div>
                  </form>
                </div>
                <!-- <div class="col-md-6 ">
                                          <form role="form ">
                                              <div class="form-group ">
                                                  <currency-box-v1-question [question]="proposal!.qsMaster.qs_fire_sumOfInsured "></currency-box-v1-question>
                                              </div>
  
                                          </form>
                                      </div> -->
              </div>

              <div *ngIf="!isIBGProduct" class="col-md-12">
                <table
                  class="table table-striped table-bordered table-condensed marginTop10"
                >
                  <thead>
                    <tr class="chubbGray_bg chubbWhite">
                      <th style="width: 25px">No</th>
                      <th>Item Coverage Description</th>
                      <th class="textAlignCenter" style="width: 250px">
                        Sum Insured
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Building</td>
                      <td
                        class="textAlignCenter"
                        [ngClass]="{
                          chubbGreen_bg: isBuildingSIModified
                        }"
                      >
                        <currency-box-conditional-question
                          [question]="proposal!.qsMaster.qs_fire_building!"
                          [condition]="currency1HDBDisabled"
                        ></currency-box-conditional-question>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Household contents</td>

                      <td class="textAlignCenter">
                        <div>
                          <span class="textAlignCenter" title="currency "
                            >{{
                              proposal!.qsMaster.qs_fire_householdContents!
                                .answer | ausCurrency
                            }}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="isIBGProduct && !ibgMFDisabled" class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="proposal!.qsMaster.qs_fire_nameSolicitors!"
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>

              <div *ngIf="isIBGProduct && ibgMFDisabled" class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <label
                      class="textDecoUnderline fontSize14px"
                      [textContent]="'Equipment'"
                    ></label>
                  </div>
                </form>
              </div>

              <div *ngIf="isIBGProduct && ibgMFDisabled" class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <currency-box-v1-question
                      [question]="
                        proposal!.qsMaster.qs_machinery_uploadSumInsured!
                      "
                    >
                    </currency-box-v1-question>
                  </div>
                </form>
              </div>
              <div *ngIf="isIBGProduct && ibgMFDisabled" class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="
                        proposal!.qsMaster.qs_machinery_uploadTypeOfFinancing!
                      "
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>

              <div *ngIf="isIBGProduct && ibgMFDisabled">
                <div class="col-md-6">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="
                          proposal!.qsMaster.qs_machinery_uploadTypeOfEquipment!
                        "
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
                <div class="col-md-6">
                  <form role="form ">
                    <div class="form-group">
                      <text-box-question
                        [question]="
                          proposal!.qsMaster
                            .qs_machinery_uploadEquipmentDescription!
                        "
                      >
                      </text-box-question>
                    </div>
                  </form>
                </div>
              </div>

              <div *ngIf="isIBGProduct && ibgMFDisabled">
                <div class="col-md-6">
                  <form role="form ">
                    <div
                      class="form-group"
                      id="qs_machinery_equipment"
                      [ngClass]="{
                        'st-danger':
                          proposal!.qsMaster.qs_machinery_equipment!.theme === 1
                      }"
                    >
                      <label
                        class="fontSize14px"
                        for="{{
                          proposal!.qsMaster.qs_machinery_equipment!.key
                        }}
                                          "
                        [ngClass]="{
                          required:
                            proposal!.qsMaster.qs_machinery_equipment!
                              .required &&
                            !proposal!.qsMaster.qs_machinery_equipment!.readOnly
                        }"
                        [textContent]="
                          proposal!.qsMaster.qs_machinery_equipment!.displayText
                        "
                      ></label>
                    </div>
                  </form>
                </div>
              </div>

              <div *ngIf="isIBGProduct && ibgMFDisabled">
                <div class="col-md-12">
                  <div id="qs_machinery_equipment_table_grid"></div>
                  <div id="equipmentDetails"></div>
                </div>
              </div>
            </div>

            <div class="clearfix marginTop10" *ngIf="isIBGProduct">
              <div class="col-md-3">
                <form role="form ">
                  <div class="form-group">
                    <yes-no-radio-question
                      [question]="proposal!.qsMaster.qs_master_lst!"
                    >
                    </yes-no-radio-question>
                  </div>
                </form>
              </div>
            </div>
            <!--LST-->
            <div
              *ngIf="
                isIBGProduct &&
                proposal!.qsMaster.qs_master_lst!.answer === '1' &&
                proposal!.isUnderwriter
              "
            >
              <div class="col-md-12">
                <form role="form ">
                  <div class="form-group" id="qs_master_lstDetails">
                    <label
                      class="fontSize14px"
                      for="{{ proposal!.qsMaster.qs_master_lstDetails!.key }}
                                                  "
                      [ngClass]="{
                        required:
                          proposal!.qsMaster.qs_master_lstDetails!.required &&
                          !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                      }"
                      [textContent]="
                        proposal!.qsMaster.qs_master_lstDetails!.displayText
                      "
                    ></label>
                  </div>
                </form>
              </div>
              <div class="col-md-12">
                <table class="table table-bordered table-sm" id="sum_LSTtable">
                  <thead>
                    <tr class="chubbLightGray_bg titlerow">
                      <th class="fontBold" style="width: 13%">
                        R/I - CO / Account
                      </th>
                      <th class="fontBold">Reference</th>
                      <!--02/11/2018 Chirag : Modified LST lable details -->
                      <th class="textAlignCenter fontBold" style="width: 13%">
                        Ceded Premium %
                      </th>
                      <th class="textAlignCenter fontBold" style="width: 13%">
                        Ceded Premium Amount
                      </th>
                      <th class="textAlignCenter fontBold" style="width: 13%">
                        Ceding RI Comm. %
                      </th>
                      <th class="textAlignCenter fontBold" style="width: 13%">
                        Ceding RI Comm. Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type="text"
                          class="k-textbox form-control"
                          [(ngModel)]="lstDefaultValue[0]['account']"
                          ng-blur="updateLSTDetails()"
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        />
                        <span
                          [textContent]="lstDefaultValue[0]['account']"
                          class="wordBreak"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td>
                        <input
                          type="text"
                          class="k-textbox form-control"
                          [(ngModel)]="lstDefaultValue[0]['reference']"
                          ng-blur="updateLSTDetails()"
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        />
                        <span
                          [textContent]="lstDefaultValue[0]['reference']"
                          class="wordBreak"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <input
                            kendo-numeric-text-box
                            type="text"
                            class="textAlignRight rowDataSd textAlignRight"
                            k-spinners="false"
                            k-format="'n3'"
                            k-decimals="3"
                            k-min="0"
                            ng-blur="updateLSTDetails(this, '0', 'percent1', 'lst_0_percent1', 3)"
                            [(ngModel)]="lstDefaultValue[0]['percent1']"
                            id="lst_0_percent1"
                          />
                        </div>

                        <span
                          [textContent]="
                            lstDefaultValue[0]['percent1'] | percentNoSymbol : 4
                          "
                          class="wordBreak rowDataSd textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <input
                            kendo-numeric-text-box
                            type="text"
                            class="textAlignRight paddingRight5"
                            k-spinners="false"
                            [(ngModel)]="lstDefaultValue[0]['amount1']"
                            ng-blur="updateLSTDetails(this, '0', 'amount1', 'lst_0_amount1', 2)"
                            id="lst_0_amount1"
                          />
                          <!--k-format="'c2'" k-min="0"-->
                        </div>

                        <span
                          [textContent]="
                            lstDefaultValue[0]['amount1'] | ausCurrency : 2
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <input
                            kendo-numeric-text-box
                            type="text"
                            class="textAlignRight paddingRight5"
                            k-spinners="false"
                            k-format="'n3'"
                            k-decimals="3"
                            k-min="0"
                            ng-blur="updateLSTDetails(this, '0', 'percent2', 'lst_0_percent2', 3)"
                            [(ngModel)]="lstDefaultValue[0]['percent2']"
                            id="lst_0_percent2"
                          />
                        </div>

                        <span
                          [textContent]="
                            lstDefaultValue[0]['percent2'] | percentNoSymbol : 2
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <input
                            kendo-numeric-text-box
                            type="text"
                            class="textAlignRight paddingRight5"
                            k-spinners="false"
                            [(ngModel)]="lstDefaultValue[0]['amount2']"
                            ng-blur="updateLSTDetails(this, '0', 'amount2', 'lst_0_amount2', 2)"
                            id="lst_0_amount2"
                          />
                        </div>
                        <!--k-format="'c2'" k-min="0"-->

                        <span
                          [textContent]="
                            lstDefaultValue[0]['amount2'] | ausCurrency : 2
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="text"
                          class="k-textbox form-control"
                          [(ngModel)]="lstDefaultValue[1]['account']"
                          ng-blur="updateLSTDetails()"
                          ng-blur="updateLSTDetails()"
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        />
                        <span
                          [textContent]="lstDefaultValue[1]['account']"
                          class="wordBreak"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td>
                        <input
                          type="text"
                          class="k-textbox form-control"
                          [(ngModel)]="lstDefaultValue[1]['reference']"
                          ng-blur="updateLSTDetails()"
                          ng-blur="updateLSTDetails()"
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        />
                        <span
                          [textContent]="lstDefaultValue[1]['reference']"
                          class="wordBreak"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <input
                            kendo-numeric-text-box
                            type="text"
                            class="textAlignRight rowDataSd paddingRight5"
                            k-spinners="false"
                            k-format="'n3'"
                            k-decimals="3"
                            k-min="0"
                            ng-blur="updateLSTDetails(this, '1', 'percent1', 'lst_1_percent1', 3)"
                            [(ngModel)]="lstDefaultValue[1]['percent1']"
                          />
                        </div>

                        <span
                          [textContent]="
                            lstDefaultValue[1]['percent1'] | percentNoSymbol : 4
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <input
                            kendo-numeric-text-box
                            type="text"
                            class="textAlignRight paddingRight5"
                            k-spinners="false"
                            [(ngModel)]="lstDefaultValue[1]['amount1']"
                            ng-blur="updateLSTDetails(this, '1', 'amount1', 'lst_1_amount1', 2)"
                          />
                        </div>
                        <!--k-format="'c2'" k-min="0"-->

                        <span
                          [textContent]="
                            lstDefaultValue[1]['amount1'] | ausCurrency : 2
                          "
                          class="wordBreak"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <input
                            kendo-numeric-text-box
                            type="text"
                            class="textAlignRight paddingRight5"
                            k-spinners="false"
                            k-format="'n3'"
                            k-decimals="3"
                            k-min="0"
                            ng-blur="updateLSTDetails(this, '1', 'percent2', 'lst_1_percent2', 3)"
                            [(ngModel)]="lstDefaultValue[1]['percent2']"
                          />
                        </div>

                        <span
                          [textContent]="
                            lstDefaultValue[1]['percent2'] | percentNoSymbol : 2
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <input
                            kendo-numeric-text-box
                            type="text"
                            class="textAlignRight paddingRight5"
                            k-spinners="false"
                            [(ngModel)]="lstDefaultValue[1]['amount2']"
                            ng-blur="updateLSTDetails(this, '1', 'amount2', 'lst_1_amount2', 2)"
                          />
                        </div>
                        <!--k-format="'c2'" k-min="0"-->
                        <span
                          [textContent]="
                            lstDefaultValue[1]['amount2'] | ausCurrency : 2
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="text"
                          class="k-textbox form-control"
                          [(ngModel)]="lstDefaultValue[2]['account']"
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                          ng-blur="updateLSTDetails()"
                        />
                        <span
                          [textContent]="lstDefaultValue[2]['account']"
                          class="wordBreak"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td>
                        <input
                          type="text"
                          class="k-textbox form-control"
                          [(ngModel)]="lstDefaultValue[2]['reference']"
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                          ng-blur="updateLSTDetails()"
                        />
                        <span
                          [textContent]="lstDefaultValue[2]['reference']"
                          class="wordBreak"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <input
                            kendo-numeric-text-box
                            type="text"
                            class="textAlignRight rowDataSd paddingRight5"
                            k-spinners="false"
                            k-format="'n3'"
                            k-decimals="3"
                            k-min="0"
                            ng-blur="updateLSTDetails(this, '2', 'percent1', 'lst_2_percent1', 3)"
                            [(ngModel)]="lstDefaultValue[2]['percent1']"
                          />
                        </div>

                        <span
                          [textContent]="
                            lstDefaultValue[2]['percent1'] | percentNoSymbol : 4
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <input
                            kendo-numeric-text-box
                            type="text"
                            class="textAlignRight paddingRight5"
                            k-spinners="false"
                            [(ngModel)]="lstDefaultValue[2]['amount1']"
                            ng-blur="updateLSTDetails(this, '2', 'amount1', 'lst_2_amount1', 2)"
                          />
                        </div>
                        <!--k-format="'c2'" k-min="0"-->
                        <span
                          [textContent]="
                            lstDefaultValue[2]['amount1'] | ausCurrency : 2
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <input
                            kendo-numeric-text-box
                            type="text"
                            class="textAlignRight paddingRight5"
                            k-spinners="false"
                            k-format="'n3'"
                            k-decimals="3"
                            k-min="0"
                            ng-blur="updateLSTDetails(this, '2', 'percent2', 'lst_2_percent2', 3)"
                            [(ngModel)]="lstDefaultValue[2]['percent2']"
                          />
                        </div>

                        <span
                          [textContent]="
                            lstDefaultValue[2]['percent2'] | percentNoSymbol : 2
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <input
                            kendo-numeric-text-box
                            type="text"
                            class="textAlignRight paddingRight5"
                            k-spinners="false"
                            [(ngModel)]="lstDefaultValue[2]['amount2']"
                            ng-blur="updateLSTDetails(this, '2', 'amount2', 'lst_2_amount2', 2)"
                          />
                        </div>
                        <!--k-format="'c2'" k-min="0"-->
                        <span
                          [textContent]="
                            lstDefaultValue[2]['amount2'] | ausCurrency : 2
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="text"
                          class="k-textbox form-control"
                          [(ngModel)]="lstDefaultValue[3]['account']"
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                          ng-blur="updateLSTDetails()"
                        />
                        <span
                          [textContent]="lstDefaultValue[3]['account']"
                          class="wordBreak"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td>
                        <input
                          type="text"
                          class="k-textbox form-control"
                          [(ngModel)]="lstDefaultValue[3]['reference']"
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                          ng-blur="updateLSTDetails()"
                        />
                        <span
                          [textContent]="lstDefaultValue[3]['reference']"
                          class="wordBreak"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <input
                            kendo-numeric-text-box
                            type="text"
                            class="textAlignRight rowDataSd paddingRight5"
                            k-spinners="false"
                            k-format="'n3'"
                            k-decimals="3"
                            k-min="0"
                            ng-blur="updateLSTDetails(this, '3', 'percent1', 'lst_3_percent1', 3)"
                            [(ngModel)]="lstDefaultValue[3]['percent1']"
                          />
                        </div>
                        <span
                          [textContent]="
                            lstDefaultValue[3]['percent1'] | percentNoSymbol : 4
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <input
                            kendo-numeric-text-box
                            type="text"
                            class="textAlignRight paddingRight5"
                            k-spinners="false"
                            [(ngModel)]="lstDefaultValue[3]['amount1']"
                            ng-blur="updateLSTDetails(this, '3', 'amount1', 'lst_3_amount1', 2)"
                          />
                        </div>
                        <!--k-format="'c2'" k-min="0"-->
                        <span
                          [textContent]="
                            lstDefaultValue[3]['amount1'] | ausCurrency : 2
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <input
                            kendo-numeric-text-box
                            type="text"
                            class="textAlignRight paddingRight5"
                            k-spinners="false"
                            k-min="0"
                            ng-blur="updateLSTDetails(this, '3', 'percent2', 'lst_3_percent2', 3)"
                            [(ngModel)]="lstDefaultValue[3]['percent2']"
                          />
                        </div>
                        <!--k-format="'n3'" k-decimals="3"-->
                        <span
                          [textContent]="
                            lstDefaultValue[3]['percent2'] | percentNoSymbol : 2
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                      <td class="textAlignRight">
                        <div
                          *ngIf="
                            !proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        >
                          <input
                            kendo-numeric-text-box
                            type="text"
                            class="textAlignRight paddingRight5"
                            k-spinners="false"
                            [(ngModel)]="lstDefaultValue[3]['amount2']"
                            ng-blur="updateLSTDetails(this, '3', 'amount2', 'lst_3_amount2', 2)"
                          />
                        </div>
                        <!--k-format="'c2'" k-min="0"-->
                        <span
                          [textContent]="
                            lstDefaultValue[3]['amount2'] | ausCurrency : 2
                          "
                          class="wordBreak textAlignRight"
                          *ngIf="
                            proposal!.qsMaster.qs_master_lstDetails!.readOnly
                          "
                        ></span>
                      </td>
                    </tr>
                    <!--<tr  class="totalColumn">
                                                  <td class="fontBold">Total</td>
                                                  <td class="textAlignCenter fontBold">-</td>
                                                  <td class="textAlignCenter fontBold totalCol" />
                                                  <td class="textAlignCenter fontBold totalCol" />
                                                  <td class="textAlignCenter fontBold">-</td>
                                                  <td class="textAlignCenter fontBold totalCol" />
                                              </tr>-->
                  </tbody>
                </table>
              </div>
            </div>
            <!--End LST-->
          </div>
        </kendo-expansionpanel>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 card card-mod cardNoBorder ng-scope">
        <kendo-expansionpanel
          id="broker_panel"
          title="Other Information"
          [expanded]="true"
        >
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <form role="form ">
                  <div class="form-group">
                    <label
                      attr.for="{{
                        proposal!.qsMaster.qs_master_insuredName!.key
                      }} "
                      class="textDecoUnderline fontSize14px"
                      [textContent]="'Other Information'"
                      >Other Information</label
                    >
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="proposal!.qsMaster.qs_fire_excess!"
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-box-question
                      [question]="proposal!.qsMaster.qs_fire_generalPage!"
                    >
                    </text-box-question>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form role="form ">
                  <div class="form-group">
                    <text-area-question
                      [question]="proposal!.qsMaster.qs_fire_businessDesc!"
                    >
                    </text-area-question>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </kendo-expansionpanel>
      </div>
    </div>
  </div>
</div>
