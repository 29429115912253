import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { DatePickerComponent } from "@progress/kendo-angular-dateinputs";
import {
  CellClickEvent,
  GridDataResult,
  PageChangeEvent,
  PageSizeItem,
  SelectionEvent,
} from "@progress/kendo-angular-grid";
import { ROUTE_PROPOSAL } from "src/app/app.module";
import { IProduct } from "src/app/framework/interface.product";
import { MasterDataService } from "src/app/services/master-data.service";
import {
  MasterPolicyEntity,
  MasterPolicyService,
} from "src/app/services/master-policy.service";
import { UploadExcelService } from "src/app/services/upload-excel.service";
import { dlgModelArgs } from "../../../shared/partials/md-successful-file-upload/md-successful-file-upload.component";
import { SearchCriteria } from "../base/search-criteria";
import { warningZoneArgs } from "../warning-zone/warning-zone.component";
import { TodoPolicyListingEntity } from "./TodoPolicyListingEntity";
import * as Enum from "./../../../../framework/enum.shared";
import * as AppEnums from "src/app/framework/app.enums";
import * as Const from "./../../../../framework/const.shared";
import { BroadcastService } from "src/app/services/broadcast.service";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { IUser } from "src/app/framework/interface.shared";
import {
  v6DlgInputArgs,
  MdConfirmationMessageV6Component,
} from "src/app/components/shared/partials/md-confirmation-message-v6/md-confirmation-message-v6.component";
import { CdkDialogService } from "./../../../../services/dialog/cdk-dialog.service";
import {
  MdConfirmationMessageV1Component,
  v1DlgInputArgs,
} from "src/app/components/shared/partials/md-confirmation-message-v1/md-confirmation-message-v1.component";
import {
  MdConfirmationMessageV2Component,
  v2DlgInputArgs,
} from "src/app/components/shared/partials/md-confirmation-message-v2/md-confirmation-message-v2.component";
import { common } from "src/app/framework/utils/common";
import { BaseListingComponent } from "../../base/base-listing/base-listing.component";
import * as HomeConstant from "./../../../new-product/home/home-constant";
@Component({
  selector: "app-todo-list",
  templateUrl: "./todo-list.component.html",
  styleUrls: ["./todo-list.component.scss"],
})
export class TodoListComponent extends BaseListingComponent implements OnInit {
  @ViewChild("heroForm", { static: false })
  private form!: UntypedFormGroup;

  @ViewChild("toDoStartDateTo", { static: false })
  public toDoStartDateTo!: DatePickerComponent;

  public toDoStartDateTo_min!: Date;
  public toDoStartDateTo_max!: Date;

  @ViewChild("toDoExpiryDateTo", { static: false })
  public toDoExpiryDateTo!: DatePickerComponent;

  public toDoExpiryDateTo_min!: Date;
  public toDoExpiryDateTo_max!: Date;

  @ViewChild("toDoEffectiveDateTo", { static: false })
  public toDoEffectiveDateTo!: DatePickerComponent;

  public toDoEffectiveDateTo_min!: Date;
  public toDoEffectiveDateTo_max!: Date;

  public gridView!: GridDataResult;
  public pageSize = 10;
  public skip = 0;
  public take = 10;
  public pageSizes: (PageSizeItem | number)[] = [10, 50, 100];
  private listing: TodoPolicyListingEntity[] = [];
  private totalListing: number = 0;
  submitted = false;

  currentUser: IUser | undefined;
  model = new SearchCriteria();
  public userProductList!: IProduct[];
  private masterPolicyList!: MasterPolicyEntity[];
  public masterPolicyOptions!: Array<{ text: string; value: string }>;
  public proposalStatusOptions!: Array<{ text: string; value: number }>;
  public userTeamOptions!: Array<{ text: string; value: number }>;
  public isCitiUser: boolean = false;
  public isDBSUser: boolean = false;
  public isChubbUser: boolean = false;
  public defaultUserListItem: { text: string; value: string } = {
    text: "All",
    value: "",
  };
  public defaultMasterPolicyOptionsItem: { text: string; value: string } = {
    text: "Select master policy no...",
    value: "",
  };

  public userList: Array<{ text: string; value: string }> = new Array<{
    text: string;
    value: string;
  }>();
  public checkableRows: number[] = [];
  public selectedRowIndexes: number[] = [];
  public loading!: boolean;
  msg_maxWarning!: string;
  confirmationMsg!: string;

  @Output() warningZoneEvent = new EventEmitter<any>();

  constructor(
    public override router: Router,
    public override broadcastService: BroadcastService,
    public override identityRolesService: IdentityRolesService,
    public masterPolicyService: MasterPolicyService,
    public masterDataService: MasterDataService,
    public uploadService: UploadExcelService,
    private cdkDialogService: CdkDialogService
  ) {
    super(router, broadcastService, identityRolesService);
  }

  //#region migrate from old angularjs
  override ngOnInit(): void {
    super.ngOnInit();

    //when refresh
    if (common.isUndefinedOrNull(this.currentUser)) {
      this.identityRolesService.refreshCurrentUser();
    } else {
      this.fetchUserList();
      this.fetchMasterPolicyList();
      this.defaultSearchCriteria();
      this.getUserOrganisation();
      this.refresh();
    }

    this.broadcastService.onAutheticated.subscribe((msg: any) => {
      this.currentUser = this.currentIdentity.currentUser(); //init
      this.fetchUserList();
      this.fetchMasterPolicyList();
      this.defaultSearchCriteria();
      this.getUserOrganisation();
      this.refresh();
    });
  }

  private getUserOrganisation() {
    //Hide/Show the appropriate search name for Bank Reference No/Collateral Reference No
    switch (this.currentUser?.organisation?.description) {
      case Enum.UserOrganisation.DBS:
        this.isCitiUser = false;
        this.isChubbUser = false;
        this.isDBSUser = true;
        break;
      case Enum.UserOrganisation.Citi:
        this.isCitiUser = true;
        this.isChubbUser = false;
        this.isDBSUser = false;
        break;
      default:
        this.isCitiUser = false;
        this.isChubbUser = true;
        this.isDBSUser = false;
        break;
    }
  }

  public getBcrnByUserOrg(): string {
    var currentUserOrg = this.currentUser?.organisation;
    if (currentUserOrg?.description == Enum.UserOrganisation.DBS) {
      return "Bank Reference No";
    } else if (
      this.currentUser?.organisation?.description == Enum.UserOrganisation.Citi
    ) {
      return "Collateral Reference No";
    } else {
      return "Bank Ref No/Coll Ref No";
    }
  }

  /** Default search criteria while page load */
  private defaultSearchCriteria() {
    let products = this.currentUser?.products;
    if (products != undefined) {
      this.userProductList = products;
    }

    // Status dropdownlist options
    const proposalStatusOptions = [
      { text: "Draft", value: 6 },
      { text: "Pending Checker", value: 18 },
      { text: "Referred", value: 3 },
      { text: "Approved by Underwriter", value: 1 },
      { text: "Declined by Underwriter", value: 4 },
    ];
    this.proposalStatusOptions = proposalStatusOptions;

    // user team dropdownlist options
    const userTeamOptions = [
      { text: "CBG-Customer Solutions", value: 4 },
      { text: "Loan Document", value: 1 },
      { text: "Loan Records", value: 2 },
      { text: "Loan Servicing", value: 3 },
      { text: "Maker & Checker", value: 6 },
      { text: "NA", value: 5 },
    ];
    this.userTeamOptions = userTeamOptions;

    // effectiveDateFrom (14 days earlier from today)
    const effectiveDateFrom: Date = new Date(new Date().getFullYear(), 0, 1);
    this.model.effectiveDateFrom = effectiveDateFrom;

    // effectiveDateFrom ( 6 months after effectiveDateFrom)
    const effectiveDateTo: Date = new Date(
      effectiveDateFrom.getFullYear(),
      11,
      31
    );
    this.model.effectiveDateTo = effectiveDateTo;

    if (
      this.identityRolesService.isChecker &&
      !this.identityRolesService.isMaker
    ) {
      this.model.proposalStatus.push(18);
    }
  }

  private fetchUserList = () => {
    if (this.identityRolesService.isUnderwriter) {
      this.masterDataService
        .getUnderwriterMakerUserList()
        .subscribe((response) => {
          // update list
          var keyValueUserList = response;
          keyValueUserList.forEach((mp) => {
            this.userList.push({ text: mp.value, value: mp.key });
          });
        });
    } else {
      this.masterDataService.getMakerUserList().subscribe((response) => {
        // update list
        var keyValueUserList = response;
        keyValueUserList.forEach((mp) => {
          this.userList.push({ text: mp.value, value: mp.key });
        });
      });
    }
  };

  private fetchMasterPolicyList = async () => {
    this.masterPolicyOptions = new Array<{
      text: string;
      value: string;
    }>();

    this.masterPolicyService.getList(this.currentUser!).subscribe(
      (result: any) => {
        this.masterPolicyList = result;

        // update list
        this.masterPolicyList.forEach((mp) => {
          const displayText =
            mp.policyType +
            " - " +
            mp.description +
            " - " +
            mp.meridianMasterPolicyNumber +
            " (MSIG: " +
            mp.policyNumber +
            ")";
          this.masterPolicyOptions.push({ text: displayText, value: mp.id });
        });
      },
      (err: any) => {
        console.log(err);
      }
    );
  };
  //#endregion

  //#region generic Grid event
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.take = event.take;
    this.pageSize = event.take; //this is the one that update the <kendo-pager-info>
    this.refresh();
  }

  private clearSelection() {
    // Optionally, clear the selection when paging
    this.checkableRows = [];
    this.selectedRowIndexes = [];
  }

  public gridSelectionChange(selection: SelectionEvent) {
    // console.log("gridSelectionChanged");
    const selectedData = selection?.selectedRows;
    if (selectedData != undefined && selectedData.length > 0) {
      var entity = selectedData[0].dataItem;
    }

    const deselectedData = selection?.deselectedRows;
    if (deselectedData != undefined && deselectedData.length > 0) {
      var entity = deselectedData[0].dataItem;
      //auto, from two-way binding of [(selectedKeys)]
      // this.selectedRowIndexes.forEach((item, index) => {
      //   if (item === deselectedData[0].index)
      //     this.selectedRowIndexes.splice(index, 1);
      // });
    }
  }

  public cellClickHandler({
    sender,
    rowIndex,
    columnIndex,
    dataItem,
    isEdited,
  }: CellClickEvent): void {
    //https://www.telerik.com/kendo-angular-ui/components/grid/api/CellClickEvent/
    const productId = dataItem.productId;
    const proposalId = dataItem.proposalId;

    const route = AppEnums.productsRoute[productId + "|1"];
    const commandAction = AppEnums.proposalCommandAction["Open"];
    if (route !== undefined) {
      this.router.navigate(["/" + ROUTE_PROPOSAL], {
        queryParams: {
          pdid: productId,
          pid: proposalId,
          action: commandAction,
        },
      });
    }
  }

  public approvedIBGForUW(dataItem: any, idx: number): boolean {
    const isOperationUnderwriter =
      this.identityRolesService.isOperationUnderwriter;

    const isIBGProduct =
      dataItem.productId.toUpperCase() ===
        Const.ProductID.PRODUCT_ID_DBS_MACH_IBG ||
      dataItem.productId.toUpperCase() ===
        Const.ProductID.PRODUCT_ID_DBS_MF_IBG;

    const isReferredOrApproved =
      dataItem.proposalStatusId === Enum.ProposalStatus.Referred ||
      dataItem.proposalStatusId === Enum.ProposalStatus.Approved;

    if (
      (isIBGProduct &&
        isOperationUnderwriter &&
        dataItem.proposalStatusId === Enum.ProposalStatus.Approved) ||
      (isIBGProduct && !isOperationUnderwriter && isReferredOrApproved)
    ) {
      if (this.checkableRows.indexOf(idx) == -1) {
        this.checkableRows.push(idx);
      }
      return true; //"<input che id='chk" + dataItem.proposalId + "' type='checkbox' class='check-box-inner'>";
    } else {
      return false; //'';
    }
  }

  private refresh(): void {
    this.clearSelection();
    this.loading = true;
    //todo: future enhancement
    //var other_data = $('form').serialize(); //page_id=&category_id=15&method=upload&required%5Bcategory_id%5D=Category+ID
    const formData: FormData = new FormData();
    formData.append("take", this.take.toString());
    formData.append("skip", this.skip.toString());
    formData.append("page", "1");
    formData.append("pageSize", this.pageSize.toString());
    formData.append("sort[0][field]", "createdDate");
    formData.append("sort[0][dir]", "desc");

    let array = [
      ["startDateFrom", "gte", this.model.startDateFrom],
      ["startDateTo", "gte", this.model.startDateTo],
      ["expiryDateFrom", "gte", this.model.expiryDateFrom],
      ["expiryDateTo", "gte", this.model.expiryDateTo],
      ["effectiveDateFrom", "gte", this.model.effectiveDateFrom],
      ["effectiveDateTo", "lte", this.model.effectiveDateTo],
      ["quoteNo", "eq", this.model.quoteNo],
      ["policyNo", "eq", this.model.policyNo],
      ["masterPolicyId", "eq", this.model.masterPolicyId],
      ["cin", "eq", this.model.cin],
      ["insuredName", "eq", this.model.insuredName],
      ["productId", "eq", this.model.productId],
      ["proposalStatus", "eq", this.model.proposalStatus],
      ["bankReferenceNo", "eq", this.model.bankReferenceNo],
      ["createdByUserId", "eq", this.model.createdByUserId],
      ["userTeam", "eq", this.model.userTeam],
      ["AdditionalInsuredName", "eq", this.model.additionalInsuredName],
      ["bcrn", "eq", this.model.bcrn],
      ["RiskAddressPostalCode", "eq", this.model.riskAddressPostalCode],
    ];

    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < array[i].length; j++) {
        if (j == 0) {
          formData.append(
            `filter[filters][${i}][field]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else if (j == 1) {
          formData.append(
            `filter[filters][${i}][operator]`,
            JSON.parse(JSON.stringify(array[i][j]))
          );
        } else {
          formData.append(
            `filter[filters][${i}][value]`,
            JSON.parse(JSON.stringify(array[i][j] || ""))
          );
        }
      }
    }

    //https://stackoverflow.com/questions/35325370/how-do-i-post-a-x-www-form-urlencoded-request-using-fetch
    //IT HAS TO BE IN SINGLE CHUNK OF STRING
    var querystring: string = "";
    for (var pair of formData.entries()) {
      querystring +=
        encodeURIComponent(pair[0]) +
        "=" +
        encodeURIComponent(pair[1] as string) +
        "&";
    }

    this.masterPolicyService.searchTodoPolicyListing(querystring).subscribe(
      (result: any) => {
        if (
          result != undefined &&
          result.body != undefined &&
          result.body != null
        ) {
          this.listing = result.body.d?.results;
          this.totalListing = result.body.d?.__count;
          this.loadItems();
          this.loading = false;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  private loadItems(): void {
    this.listing.forEach((entity, index) => {
      entity.canApproveIBGForUW = this.approvedIBGForUW(entity, index);
      entity.checkboxChecked = false; //false by default

      // Proposal Status
      entity.proposalStatusDisplayText =
        HomeConstant.proposalStatus[parseInt(entity.proposalStatusId)];

      // Transaction Type
      entity.proposalTypeDisplayText =
        HomeConstant.transactionType[parseInt(entity.proposalTransTypeId)];

      // CIN
      if (common.isStringNullOrEmpty(entity.cin)) {
        entity.cin = "-";
      }

      // Policy No
      if (common.isStringNullOrEmpty(entity.policyNo)) {
        entity.policyNo = "-";
      }

      //MSIG Policy No
      entity.msIGPolicyNo =
        common.isStringNullOrEmpty(entity.msIGPolicyNo) ||
        entity.policyNo == entity.msIGPolicyNo
          ? "-"
          : entity.msIGPolicyNo;
    });

    this.gridView = {
      data: this.listing, //.slice(this.skip, this.skip + this.pageSize),
      total: this.totalListing,
    };
  }

  public on_searchStartDateFrom_change(value: Date): void {
    if (this.model.startDateFrom === null) {
      this.model.startDateTo = undefined;
      this.toDoStartDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoStartDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
      return;
    }
    if (
      this.model.startDateTo &&
      this.model.startDateFrom &&
      this.model.startDateTo < this.model.startDateFrom
    ) {
      this.model.startDateTo = undefined;
      this.toDoStartDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoStartDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
    }
    //  set range 6 months
    const maxDate = new Date(value);
    maxDate.setMonth(maxDate.getMonth() + 6);
    this.toDoStartDateTo_min = value;
    this.toDoStartDateTo_max = maxDate;

    // open datepicker
    this.toDoStartDateTo.toggle();
    this.on_searchStartDateFrom_focus();
  }

  public on_searchStartDateFrom_focus(): void {
    if (this.toDoExpiryDateTo.isOpen) {
      this.toDoExpiryDateTo.toggle();
    }
    if (this.toDoEffectiveDateTo.isOpen) {
      this.toDoEffectiveDateTo.toggle();
    }
  }

  public on_searchExpiryDateFrom_change(value: Date): void {
    if (this.model.expiryDateFrom === null) {
      this.model.expiryDateTo = undefined;
      this.toDoExpiryDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoExpiryDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
      return;
    }

    if (
      this.model.expiryDateTo &&
      this.model.expiryDateFrom &&
      this.model.expiryDateTo < this.model.expiryDateFrom
    ) {
      this.model.expiryDateTo = undefined;
      this.toDoExpiryDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoExpiryDateTo_max = new Date(2099, 12, 31, 0, 0, 0);
    }
    //  set range 6 months
    const maxDate = new Date(value);
    maxDate.setMonth(maxDate.getMonth() + 6);
    this.toDoExpiryDateTo_min = value;
    this.toDoExpiryDateTo_max = maxDate;

    // open datepicker
    this.toDoExpiryDateTo.toggle();
    this.on_searchExpiryDateFrom_focus();
  }

  public on_searchExpiryDateFrom_focus(): void {
    if (this.toDoStartDateTo.isOpen) {
      this.toDoStartDateTo.toggle();
    }
    if (this.toDoEffectiveDateTo.isOpen) {
      this.toDoEffectiveDateTo.toggle();
    }
  }

  public on_searchEffectiveDateFrom_change(value: Date): void {
    if (this.model.effectiveDateFrom === null) {
      this.toDoEffectiveDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoEffectiveDateTo_max = new Date(2099, 11, 31, 0, 0, 0);
      this.model.effectiveDateFrom = new Date(
        new Date().getFullYear(),
        0,
        1,
        0,
        0,
        0
      );
      // return;
    }
    if (
      this.model.effectiveDateTo !== null &&
      this.model.effectiveDateFrom !== null &&
      this.model.effectiveDateTo! < this.model.effectiveDateFrom!
    ) {
      this.toDoEffectiveDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoEffectiveDateTo_max = new Date(2099, 11, 31, 0, 0, 0);
      // this.model.effectiveDateTo = new Date(
      //   this.model.effectiveDateFrom!.getFullYear(),
      //   11,
      //   31,
      //   0,
      //   0,
      //   0
      // );
    }

    if (this.model.effectiveDateTo === null) {
      this.model.effectiveDateTo = new Date(
        this.model.effectiveDateFrom!.getFullYear(),
        11,
        31,
        0,
        0,
        0
      );
    }
    // open datepicker
    this.toDoEffectiveDateTo.toggle();
    this.on_searchEffectiveDateFrom_focus();
  }

  public on_searchEffectiveDateTo_change(value: Date): void {
    if (this.model.effectiveDateTo === null) {
      this.toDoEffectiveDateTo_min = new Date(1900, 1, 1, 0, 0, 0);
      this.toDoEffectiveDateTo_max = new Date(2099, 11, 31, 0, 0, 0);
      this.model.effectiveDateTo = new Date(
        this.model.effectiveDateFrom!.getFullYear(),
        11,
        31,
        0,
        0,
        0
      );
    }
  }

  public on_searchEffectiveDateFrom_focus(): void {
    if (this.toDoStartDateTo.isOpen) {
      this.toDoStartDateTo.toggle();
    }
    if (this.toDoExpiryDateTo.isOpen) {
      this.toDoExpiryDateTo.toggle();
    }
  }

  private validateMandatoryFields(): boolean {
    let isValid: boolean = false;

    // dates
    const dates: boolean =
      this.model.startDateFrom !== null ||
      this.model.startDateTo !== null ||
      this.model.expiryDateFrom !== null ||
      this.model.expiryDateTo !== null ||
      this.model.effectiveDateFrom !== null ||
      this.model.effectiveDateTo !== null;

    isValid = dates; // || etc...

    return isValid;
  }

  private checkDatesRange() {
    if (this.model.startDateFrom !== null && this.model.startDateTo === null) {
      this.model.startDateTo = this.model.startDateFrom;
    }

    if (
      this.model.expiryDateFrom !== null &&
      this.model.expiryDateTo === null
    ) {
      this.model.expiryDateTo = this.model.expiryDateFrom;
    }

    if (
      this.model.effectiveDateFrom !== null &&
      this.model.effectiveDateTo === null
    ) {
      this.model.effectiveDateTo = this.model.effectiveDateFrom;
    }
  }

  public onSubmit(): void {
    if (this.form.dirty) {
      //whenever the form is dirty, reset the skip to 0
      //paging event will not, and should not reach here
      this.skip = 0;
    }

    this.checkDatesRange();

    const isValid = this.validateMandatoryFields();

    if (isValid) {
      let warningParamters: warningZoneArgs = {
        showWarning: false,
        warningText: "",
      };
      this.warningZoneEvent.emit(warningParamters);
      //$('#warningZone').collapse('hide');
      this.submitted = true;
      this.refresh();
    } else {
      let warningParamters: warningZoneArgs = {
        showWarning: true,
        warningText: "Please select a date range.",
      };
      this.warningZoneEvent.emit(warningParamters);
      // $('#warningZone #message').text('Please select a date range.');
      // $('#warningZone').collapse('show');
      // httpNotifier.response_notifier$.next(new KeyValue('', e));
    }
  }

  private Action(command: Enum.ProposalCommandAction) {
    if (this.selectedRowIndexes.length > 10) {
      switch (command) {
        case Enum.ProposalCommandAction.Submit:
          this.msg_maxWarning =
            "Maximum of 10 policies are allowed to bind at a time, please select up to 10 policies before you proceed";
          break;
        case Enum.ProposalCommandAction.Accept:
          this.msg_maxWarning =
            "Maximum of 10 policies are allowed to bind at a time, please select up to 10 policies before you proceed";
          break;
        case Enum.ProposalCommandAction.Decline:
          this.msg_maxWarning =
            "Maximum of 10 policies are allowed to decline at a time, please select up to 10 policies before you proceed";
          break;
        case Enum.ProposalCommandAction.Approve:
          this.msg_maxWarning =
            "Maximum of 10 policies are allowed to approve at a time, please select up to 10 policies before you proceed";
          break;
      }

      this.WarningPopup(this.msg_maxWarning, command);
    } else if (this.selectedRowIndexes.length > 0) {
      var sortedArray: number[] = this.selectedRowIndexes.sort(
        (n1, n2) => n1 - n2
      );
      var dataItems = this.getDataItemsFromGridWithSelectedIndexes(sortedArray);
      this.showPrintOptionDialog(dataItems, command);
    }
  }

  getDataItemsFromGridWithSelectedIndexes(
    selectedIndexes: number[]
  ): TodoPolicyListingEntity[] {
    var currentPageData = this.gridView.data as TodoPolicyListingEntity[];
    var selectedDataItems: TodoPolicyListingEntity[] = [];

    currentPageData.forEach((item, idx) => {
      //selectedIndexes is a running number for whole/all pages, while the currentPageData only for selected current page
      //thus need to onset
      let allIdx = idx + this.skip;
      if (selectedIndexes.indexOf(allIdx) >= 0) {
        selectedDataItems.push(item);
      }
    });
    return selectedDataItems;
  }
  //#endregion

  //#region bulk actions
  WarningPopup(msg: string, command: Enum.ProposalCommandAction) {
    let v2InputData: v2DlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: command,
      message: msg,
    };

    const v2DialogRef = this.cdkDialogService.open(
      MdConfirmationMessageV2Component,
      {
        data: v2InputData,
      }
    );

    v2DialogRef.afterClosed().subscribe((result: any) => {});
  }

  ActionApprove(): void {
    this.Action(Enum.ProposalCommandAction.Approve);
  }
  ActionBind(): void {
    this.Action(Enum.ProposalCommandAction.Accept);
  }
  ActionDecline(): void {
    this.Action(Enum.ProposalCommandAction.Decline);
  }

  ActionSelect(): void {
    if (this.checkableRows.length > 10) {
      alert(
        "Maximum of 10 policies are allowed to either approve/decline/bind at a time, please select up to 10 policies before you proceed"
      );
    } else {
      this.checkableRows.forEach((idx) => {
        //selectedIndexes is a running number for whole/all pages, while the currentPageData only for selected current page
        //thus need to onset
        let allIdx = idx + this.skip;
        if (this.selectedRowIndexes.indexOf(allIdx) == -1) {
          this.selectedRowIndexes.push(allIdx);

          this.gridView.data[allIdx].checkboxChecked = true;
        }
      });
    }
  }

  SetSelectedItem(dataItem: TodoPolicyListingEntity, idx: number) {
    //revert
    dataItem.checkboxChecked = !dataItem.checkboxChecked;

    //selectedIndexes is a running number for whole/all pages, while the currentPageData only for selected current page
    //thus need to onset
    let allIdx = idx + this.skip;
    //add
    if (dataItem.checkboxChecked) {
      if (this.selectedRowIndexes.indexOf(allIdx) == -1) {
        this.selectedRowIndexes.push(allIdx);
      }
    } else {
      //remove
      this.selectedRowIndexes.forEach((item, loopIndex) => {
        if (item === allIdx) {
          //item is the one that store the actual index; loopIndex is local index
          this.selectedRowIndexes.splice(loopIndex, 1);
        }
      });
    }
  }

  showPrintOptionDialog = (
    grdData: any,
    command: Enum.ProposalCommandAction
  ): void => {
    switch (command) {
      case Enum.ProposalCommandAction.Submit:
        grdData = grdData.filter(
          (item: { proposalStatusId: Enum.ProposalStatus }) =>
            item.proposalStatusId === Enum.ProposalStatus.Approved
        );
        this.confirmationMsg = "Are you sure you wish to bind these Policies?";
        break;
      case Enum.ProposalCommandAction.Accept:
        grdData = grdData.filter(
          (item: { proposalStatusId: Enum.ProposalStatus }) =>
            item.proposalStatusId === Enum.ProposalStatus.Approved
        );
        this.confirmationMsg = "Are you sure you wish to bind these Policies?";
        break;
      case Enum.ProposalCommandAction.Decline:
        grdData = grdData.filter(
          (item: { proposalStatusId: Enum.ProposalStatus }) =>
            item.proposalStatusId === Enum.ProposalStatus.Referred
        );
        this.confirmationMsg =
          "Are you sure you wish to decline these submissions?";
        break;
      case Enum.ProposalCommandAction.Approve:
        grdData = grdData.filter(
          (item: { proposalStatusId: Enum.ProposalStatus }) =>
            item.proposalStatusId === Enum.ProposalStatus.Referred
        );
        this.confirmationMsg =
          "Referral transaction will be approved in bulk with this action, Do you wish to proceed?";
        break;
    }
    if (grdData.length > 0) {
      let v6InputData: v6DlgInputArgs = {
        dialogWidth:
          command == Enum.ProposalCommandAction.Submit ||
          command == Enum.ProposalCommandAction.Accept
            ? "lg"
            : "md",
        command: command,
        gridData: grdData,
      };

      const v6DialogRef = this.cdkDialogService.open(
        MdConfirmationMessageV6Component,
        {
          data: v6InputData,
        }
      );

      v6DialogRef.afterClosed().subscribe((result: any) => {
        // Subscription runs after the dialog closes
        if (result) {
          this.confirmationPrintOptionSelection(result);
        }
      });
    }
  };

  confirmationPrintOptionSelection(args: dlgModelArgs) {
    if (args.status === "yes") {
      let v1InputData: v1DlgInputArgs = {
        opened: true,
        dialogWidth: 600,
        data: args.data,
        message: this.confirmationMsg,
      };

      const v1DialogRef = this.cdkDialogService.open(
        MdConfirmationMessageV1Component,
        {
          data: v1InputData,
        }
      );

      v1DialogRef.afterClosed().subscribe((result: any) => {
        // Subscription runs after the dialog closes
        if (result) {
          this.confirmationModalCloseForActions(result);
        }
      });
    }
  }

  async confirmationModalCloseForActions(args: dlgModelArgs) {
    if (args.status === "yes") {
      if (args.data != null) {
        var result = await this.uploadService.doBulkAction(args.data);
        if (result.Status === true) {
          let tempData: any = null;
          tempData = result.ValidationMessage;
          var msg: string = "";
          switch (args.data[0].command) {
            case Enum.ProposalCommandAction.Submit:
              msg = "Bind " + tempData;
              break;
            case Enum.ProposalCommandAction.Accept:
              msg = "Bind " + tempData;
              break;
            case Enum.ProposalCommandAction.Decline:
              msg = "Decline " + tempData;
              break;
            case Enum.ProposalCommandAction.Approve:
              msg = "Approve " + tempData;
              break;
          }
          this.WarningPopup(msg, args.data[0].command);
          //reload when done
          this.refresh();
        }
      }
    }
  }

  ButtonShow(e: any): boolean {
    if (this.identityRolesService.isUnderwriter) {
      if (e === "All" || e === Enum.ProposalCommandAction.Accept) {
        return true;
      }
      if (!this.identityRolesService.isOperationUnderwriter) {
        if (
          e === Enum.ProposalCommandAction.Approve ||
          e === Enum.ProposalCommandAction.Decline
        ) {
          return true;
        }
      }
      if (this.identityRolesService.isOperationUnderwriter) {
        if (e === Enum.ProposalCommandAction.Accept) {
        }
      }
    }

    return false;
  }
  //#endregion
}
