import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID, ErrorHandler } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { CustomMessagesService } from "./services/custom-messages.service";
import {
  ExcelModule,
  GridModule,
  PDFModule,
} from "@progress/kendo-angular-grid";
import { LabelModule } from "@progress/kendo-angular-label";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { SchedulerModule } from "@progress/kendo-angular-scheduler";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { EditorModule } from "@progress/kendo-angular-editor";
import { FileSelectModule } from "@progress/kendo-angular-upload";
import { ChartsModule } from "@progress/kendo-angular-charts";
import { IntlModule } from "@progress/kendo-angular-intl";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { NotificationModule } from "@progress/kendo-angular-notification";
import { MessageService } from "@progress/kendo-angular-l10n";
import { MenusModule } from "@progress/kendo-angular-menu";
import { UploadsModule } from "@progress/kendo-angular-upload";
import { DialogsModule } from "@progress/kendo-angular-dialog";
import "hammerjs";
import "@progress/kendo-angular-intl/locales/en/all";
import "@progress/kendo-angular-intl/locales/es/all";
import "@progress/kendo-angular-intl/locales/fr/all";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { CustomHttpInterceptor } from "./interceptor/http-interceptor";
import { HorizMenuComponent } from "./components/horiz-menu/horiz-menu.component";
import { BouncedMailPolicyComponent } from "./components/product/bounced-mail-policy/bounced-mail-policy.component";
import { AutoLoanRedemptionComponent } from "./components/product/auto-loan-redemption/auto-loan-redemption.component";
import { MdConfirmationMessageV6Component } from "./components/shared/partials/md-confirmation-message-v6/md-confirmation-message-v6.component";
import { MdConfirmationMessageV1Component } from "./components/shared/partials/md-confirmation-message-v1/md-confirmation-message-v1.component";
import { MdConfirmationMessageV2Component } from "./components/shared/partials/md-confirmation-message-v2/md-confirmation-message-v2.component";
import { MdSuccessfulFileUploadComponent } from "./components/shared/partials/md-successful-file-upload/md-successful-file-upload.component";
import { MdProductSelectionDialogComponent } from "./components/shared/partials/md-product-selection-dialog/md-product-selection-dialog.component";
import { MfSgMainComponent } from "./components/new-product/sme/mortgage-fire/singapore/components/main/mf-sg-main.component";
import { MfSgBaseComponent } from "./components/new-product/sme/mortgage-fire/singapore/components/mf-sg-base.component";
import { MfSgButtonPanelComponent } from "./components/new-product/sme/mortgage-fire/singapore/components/button-panel/mf-sg-button-panel.component";
import { MfSgMasterComponent } from "./components/new-product/sme/mortgage-fire/singapore/components/master/mf-sg-master.component";
import { IbgSgUploadComponent } from "./components/product/ibg-sg-upload/ibg-sg-upload.component";
import { HomeComponent } from "./components/new-product/home/home.component";
import { ExportListComponent } from "./components/new-product/home/export-list/export-list.component";
import { PolicyListComponent } from "./components/new-product/home/policy-list/policy-list.component";
import { TodoListComponent } from "./components/new-product/home/todo-list/todo-list.component";
import { WarningZoneComponent } from "./components/new-product/home/warning-zone/warning-zone.component";
import { PolicyListDetailsComponent } from "./components/new-product/home/policy-list/policy-list-details/policy-list-details.component";
import { RmScreenComponent } from "./components/product/rm-screen/rm-screen.component";
import { RmGridEditFormComponent } from "./components/product/rm-screen/rm-grid-edit-form/rm-grid-edit-form.component";
import { ReviewRefundUploadComponent } from "./components/accounting/review-refund-upload/review-refund-upload.component";
import { RefundUploadComponent } from "./components/accounting/refund-upload/refund-upload.component";
import { ReconcileListComponent } from "./components/accounting/reconcile/reconcile-list.component";
import { AppRoutingModule } from "./app-routing.module";
import { OpenItemListComponent } from "./components/accounting/open-item/open-item-list.component";
import { OpenItemDetailsComponent } from "./components/accounting/open-item/view-details/open-item-details.component";
import { MsalModule, MsalService, MSAL_INSTANCE } from "@azure/msal-angular";
import {
  IPublicClientApplication,
  PublicClientApplication,
} from "@azure/msal-browser";
import { CustomerLoginComponent } from "./components/login/customer-login/customer-login.component";
import { CustomerEnterOtpComponent } from "./components/login/customer-login/customer-enter-otp.component";
import { CustomerAcceptOfferComponent } from "./components/login/customer-login/customer-accept-offer.component";
import { Adb2cLoginComponent } from "./components/login/adb2c-login/adb2c-login.component";
import { Adb2cLoginRedirectComponent } from "./components/login/adb2c-login-redirect/adb2c-login-redirect.component";
import { environment } from "src/environments/environment";
import { AuthGuard } from "./components/security/auth.guard";
import { DoManualPayComponent } from "./components/accounting/open-item/do-manual-pay/do-manual-pay.component";
import { DoRefundComponent } from "./components/accounting/open-item/do-refund/do-refund.component";
import { DoEFTComponent } from "./components/accounting/open-item/do-refund/eFT-editor/do-eft.component";
import { DoBounceChequeComponent } from "./components/accounting/open-item/do-bounce-cheque/do-bounce-cheque.component";
import { NgxIdleTimeoutModule } from "./components/shared/idle/ngx-idle-timeout.module";
import { MultiYearAutoDebitUploadComponent } from "./components/product/multi-year-auto-debit-upload/multi-year-auto-debit-upload.component";
import { BaseComponent } from "./components/shared/base/base.component";
import { PolicyWordigSetupComponent } from "./components/product/policyWordingSetUp/policy-wording-setup.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { PortalModule } from "@angular/cdk/portal";
import { PresetReportComponent } from "./components/new-product/home/preset-report/preset-report.component";
import { AutoDebitComponent } from "./components/new-product/home/preset-report/auto-debit/auto-debit.component";
import { BatchInvoiceListComponent } from "./components/accounting/batch-invoice/batch-invoice-list.component";
import { BatchInvoiceDetailsComponent } from "./components/accounting/batch-invoice/view-details/batch-invoice-details.component";
import { UpdateInvoiceNumberComponent } from "./components/accounting/batch-invoice/update-invoice-number/update-invoice-number.component";
import { BankTransComponent } from "./components/new-product/home/preset-report/bank-trans/bank-trans.component";
import { CitiMonthlyReportComponent } from "./components/new-product/home/preset-report/citi-monthly-report/citi-monthly-report.component";
import { CitiWeeklyInvoiceComponent } from "./components/new-product/home/preset-report/citi-weekly-invoice/citi-weekly-invoice.component";
import { CbgDailyDownloadComponent } from "./components/new-product/home/preset-report/cbg-daily-download/cbg-daily-download.component";
import { PrintDocumentReportComponent } from "./components/new-product/home/preset-report/daily-trans-document-print/print-document-report.component";
import { PrintDatapostReportComponent } from "./components/new-product/home/preset-report/datapost-document-print/print-datapost.component";
import { LstReportComponent } from "./components/new-product/home/preset-report/ibg-reinsurance/ibg-reinsurance-report.component";
import { IBGRenewalReportComponent } from "./components/new-product/home/preset-report/ibg-renewal-report/ibg-renewal-report.component";
import { LoanRedemptionReportComponent } from "./components/new-product/home/preset-report/loan-redemption/loan-redemption.component";
import { ManualPaidAutoDebitComponent } from "./components/new-product/home/preset-report/manual-paid-auto-debit/manual-paid-auto-debit.component";
import { RenewalReportComponent } from "./components/new-product/home/preset-report/renewal-report/renewal-report.component";
import { MfSgTabsComponent } from "./components/new-product/sme/mortgage-fire/singapore/components/tabs/mf-sg-tabs.component";
import { SummaryInvoiceAutoDebitComponent } from "./components/new-product/home/preset-report/summary-invoice-auto-debit/summary-invoice-auto-debit.component";
import { LstRateComponent } from "./components/product/lst-rate/lst-rate.component";
import { UserProfileComponent } from "./components/admin/user-profile/user-profile.component";
import { SearchUserComponent } from "./components/admin/user-profile/search-user/search-user.component";
import { CreateUserComponent } from "./components/admin/user-profile/create-user/create-user.component";
import { ViewUserComponent } from "./components/admin/user-profile/view-user/view-user.component";
import { ViewUserProfileComponent } from "./components/admin/user-profile/view-user/view-user-profile/view-user-profile.component";
import { ViewPersonalDetailsComponent } from "./components/admin/user-profile/view-user/view-personal-details/view-personal-details.component";
import { EditUserProfileComponent } from "./components/admin/user-profile/view-user/edit-user-profile/edit-user-profile.component";
import { EditPersonalDetailsComponent } from "./components/admin/user-profile/view-user/edit-personal-details/edit-personal-details.component";
import { CreateProfileComponent } from "./components/admin/user-profile/create-user/create-profile/create-profile.component";
import { TextAreaQuestionComponent } from "./components/shared/new-product/components/text-area-question/text-area-question.component";
import { TextBoxQuestionComponent } from "./components/shared/new-product/components/text-box-question/text-box-question.component";
import { DropDownListFilterQuestionComponent } from "./components/shared/new-product/components/drop-down-list-filter-question/drop-down-list-filter-question.component";
import { DateInputQuestionComponent } from "./components/shared/new-product/components/date-input-question/date-input-question.component";
import { RadioButtonV1QuestionComponent } from "./components/shared/new-product/components/radio-button-v1-question/radio-button-v1-question.component";
import { YesNoRadioQuestionComponent } from "./components/shared/new-product/components/yes-no-radio-question/yes-no-radio-question.component";
import { NumericBoxQuestionComponent } from "./components/shared/new-product/components/numeric-box-question/numeric-box-question.component";
import { DatePickerV1QuestionComponent } from "./components/shared/new-product/components/date-picker-v1-question/date-picker-v1-question.component";
import { DropDownListQuestionComponent } from "./components/shared/new-product/components/drop-down-list-question/drop-down-list-question.component";
import { CurrencyBoxV1QuestionComponent } from "./components/shared/new-product/components/currency-box-v1-question/currency-box-v1-question.component";
import { UserSuccessComponent } from "./components/admin/user-profile/user-success/user-success.component";
import { MfSgHeaderComponentComponent } from "./components/new-product/sme/mortgage-fire/singapore/components/header/mf-sg-header.component";
import { ClientSearchComponent } from "./components/shared/partials/mdClient/client-search/client-search.component";
import { ClientViewComponent } from "./components/shared/partials/mdClient/client-view/client-view.component";
import { ClientCreateComponent } from "./components/shared/partials/mdClient/client-create/client-create.component";
import { MfSgClientViewComponent } from "./components/new-product/sme/mortgage-fire/singapore/components/client/mf-sg-client-view/mf-sg-client-view.component";
import { MfSgQsFireInterestedPartiesComponent } from "./components/new-product/sme/mortgage-fire/singapore/components/master/mf-sg-qs-fire-interested-parties/mf-sg-qs-fire-interested-parties.component";
import { CurrencyBoxConditionalQuestionComponent } from "./components/shared/new-product/components/currency-box-conditional-question/currency-box-conditional-question.component";
import { MfSgPremiumSummaryComponent } from "./components/new-product/sme/mortgage-fire/singapore/components/premium-summary/mf-sg-premium-summary.component";
import { PercentageBoxV1QuestionComponent } from "./components/shared/new-product/components/percentage-box-v1-question/percentage-box-v1-question.component";
import { PercentagePipe } from "./pipes/percentage.pipe";
import { AusCurrencyPipe } from "./pipes/aus-currency.pipe";
import { NewLinesPipe } from "./pipes/new-lines.pipe";
import { ButtonDefaultComponent } from "./components/shared/button-default/button-default.component";
import { CtSgMasterComponent } from "./components/new-product/sme/mortgage-fire/singapore/components/master/citi/ct-sg-master.component";
import { CtSgPremiumSummaryComponent } from "./components/new-product/sme/mortgage-fire/singapore/components/premium-summary/citi/ct-sg-premium-summary.component";
import { MdProfileDetailsComponent } from "./components/shared/partials/md-menu-header/md-profile-details/md-profile-details.component";
import { AddressHelpFormComponent } from "./components/shared/products/address-help-form/address-help-form.component";
import { Adb2cUserTypeListingComponent } from "./components/login/adb2c-user-type-listing/adb2c-user-type-listing.component";
import { MdPreferredDeliveryMethodComponent } from "./components/shared/partials/md-preferred-delivery-method/md-preferred-delivery-method.component";
import { MfSgPreferredDeliveryComponent } from "./components/new-product/sme/mortgage-fire/singapore/components/mf-sg-preferred-delivery/mf-sg-preferred-delivery.component";
import { CtSgPremiumPeriodHistoryComponent } from "./components/new-product/sme/mortgage-fire/singapore/components/premium-period-history/citi/ct-sg-premium-period-history.component";
import { MfSgPendingTransactionPanelComponent } from "./components/new-product/sme/mortgage-fire/singapore/components/main/mf-sg-pending-transaction-panel/mf-sg-pending-transaction-panel.component";
import { MfSgEchoReferralValidationPanelComponent } from "./components/new-product/sme/mortgage-fire/singapore/components/main/mf-sg-referral-validation-echo-panel/mf-sg-echo-referral-validation-panel.component";
import { MfSgEchoFieldValidationPanelComponent } from "./components/new-product/sme/mortgage-fire/singapore/components/main/mf-sg-field-validation-echo-panel/mf-sg-echo-field-validation-panel.component";
import { MdInformationMessageComponent } from "./components/shared/partials/md-information-message/md-information-message.component";
import { FooterComponent } from "./components/footer/footer.component";
import { MfContactUsFormComponent } from "./components/shared/products/mf-contact-us-form/mf-contact-us-form.component";
import { MessageForumComponent } from "./components/product/messageBoard/message-forum/message-forum.component";
import { MdValidationMessageComponent } from "./components/product/sme/masterPolicy/md-validation-message/md-validation-message.component";
import { MessageBoardComponent } from "./components/product/messageBoard/message-board/message-board.component";
import { UnderwriterInternalCommentComponent } from "./components/product/messageBoard/underwriter-internal-comment/underwriter-internal-comment.component";
import { ClientTimelineComponent } from "./components/product/messageBoard/client-timeline/client-timeline.component";
import { MdConfirmationMessageWithReasonComponent } from "./components/shared/partials/md-confirmation-message-with-reason/md-confirmation-message-with-reason.component";
import { MdConfirmationMessageV4Component } from "./components/shared/partials/md-confirmation-message-v4/md-confirmation-message-v4.component";
import { OccupationSearchComponent } from "./components/shared/partials/md-occupation/occupation-search/occupation-search.component";
import { MdDetailsComponent } from "./components/new-product/sme/mortgage-fire/singapore/components/master/mf-sg-equipment-listing/md-details/md-details.component";
import { MfSgEquipmentListingComponent } from "./components/new-product/sme/mortgage-fire/singapore/components/master/mf-sg-equipment-listing/mf-sg-equipment-listing.component";
import { MfSgEquipmentListingEditComponent } from "./components/new-product/sme/mortgage-fire/singapore/components/master/mf-sg-equipment-listing/mf-sg-equipment-listing-edit-form/mf-sg-equipment-listing-edit-form";
import { MdConfirmationMessageV5Component } from "./components/shared/partials/md-confirmation-message-v5/md-confirmation-message-v5.component";
import { PercentNoSymbolPipe } from "./pipes/percent-no-symbol.pipe";
import { MdConfirmationMessageWithReasonAttachmentComponent } from "./components/shared/partials/md-confirmation-message-with-reason-attachment/md-confirmation-message-with-reason-attachment.component";
import { BaseUploadComponent } from "./components/accounting/base-upload/base-upload.component";
import { GstAdjustmentComponent } from "./components/accounting/gst-adjustment/gst-adjustment.component";
import { CustomErrorComponent } from "./components/shared/partials/custom-error/custom-error.component";
import { ErrorHandlerService } from "./services/error-handler/error-handler.service";
import { BaseListingComponent } from "./components/new-product/base/base-listing/base-listing.component";
import { UserRoleSelectionComponent } from "./components/login/user-role-selection/user-role-selection.component";
import { PolicyWordingComponent } from "./components/product/policyWordingSetUp/policy-wording.component";
import { CbgSgUploadComponent } from "./components/product/cbg-sg-upload/cbg-sg-upload.component";
import { CbgCLSLoanRedemptionUploadComponent } from "./components/product/cbg-cls-loan-redemption-upload/cbg-cls-loan-redemption-upload.component";
import { PaynowEditorComponent } from "./components/accounting/open-item/do-refund/paynow-editor/paynow-editor.component";
import { ViewPaynowDetailsComponent } from "./components/accounting/open-item/view-paynow-details/view-paynow-details.component";
import { RejectUploadComponent } from "./components/accounting/reject-upload/reject-upload.component";
import { ViewEFTDetailsComponent } from "./components/accounting/open-item/view-EFT-details/view-EFT-details.component";
export const ROUTE_LOGIN = ""; //todo, unify with "src/app/app.module"
export const ROUTE_HOME = "product/home"; //todo, unify with "src/app/app.module"
export const ROUTE_PROPOSAL = "proposal"; //todo, unify with "src/app/app.module"

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: environment.adb2cSettings,
  });
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HorizMenuComponent,
    BouncedMailPolicyComponent,
    AutoLoanRedemptionComponent,
    MdConfirmationMessageV1Component,
    MdConfirmationMessageV2Component,
    MdConfirmationMessageV6Component,
    MdSuccessfulFileUploadComponent,
    MdProductSelectionDialogComponent,
    MdProfileDetailsComponent,
    MfSgMainComponent,
    IbgSgUploadComponent,
    HomeComponent,
    ExportListComponent,
    PolicyListComponent,
    TodoListComponent,
    WarningZoneComponent,
    PolicyListDetailsComponent,
    RmScreenComponent,
    RmGridEditFormComponent,
    ReviewRefundUploadComponent,
    RefundUploadComponent,
    ReconcileListComponent,
    OpenItemListComponent,
    OpenItemDetailsComponent,
    CustomerLoginComponent,
    CustomerEnterOtpComponent,
    CustomerAcceptOfferComponent,
    Adb2cLoginComponent,
    Adb2cLoginRedirectComponent,
    Adb2cUserTypeListingComponent,
    DoManualPayComponent,
    DoRefundComponent,
    DoEFTComponent,
    DoBounceChequeComponent,
    MultiYearAutoDebitUploadComponent,
    BaseComponent,
    MfSgBaseComponent,
    MfSgButtonPanelComponent,
    MfSgTabsComponent,
    MfSgMasterComponent,
    PolicyWordigSetupComponent,
    PolicyWordingComponent,
    LstRateComponent,
    BatchInvoiceListComponent,
    BatchInvoiceDetailsComponent,
    UpdateInvoiceNumberComponent,
    //report component
    PresetReportComponent,
    AutoDebitComponent,
    BankTransComponent,
    CitiMonthlyReportComponent,
    CitiWeeklyInvoiceComponent,
    CbgDailyDownloadComponent,
    PrintDocumentReportComponent,
    PrintDatapostReportComponent,
    LstReportComponent,
    IBGRenewalReportComponent,
    LoanRedemptionReportComponent,
    ManualPaidAutoDebitComponent,
    RenewalReportComponent,
    SummaryInvoiceAutoDebitComponent,
    //Admin
    UserProfileComponent,
    SearchUserComponent,
    CreateUserComponent,
    ViewUserComponent,
    ViewUserProfileComponent,
    ViewPersonalDetailsComponent,
    EditUserProfileComponent,
    EditPersonalDetailsComponent,
    CreateProfileComponent,
    TextAreaQuestionComponent,
    TextBoxQuestionComponent,
    DropDownListFilterQuestionComponent,
    DateInputQuestionComponent,
    RadioButtonV1QuestionComponent,
    YesNoRadioQuestionComponent,
    NumericBoxQuestionComponent,
    DatePickerV1QuestionComponent,
    DropDownListQuestionComponent,
    CurrencyBoxV1QuestionComponent,
    UserSuccessComponent,
    MfSgHeaderComponentComponent,
    ClientSearchComponent,
    ClientViewComponent,
    ClientCreateComponent,
    MfSgClientViewComponent,
    MfSgQsFireInterestedPartiesComponent,
    CurrencyBoxConditionalQuestionComponent,
    MfSgPremiumSummaryComponent,
    PercentageBoxV1QuestionComponent,
    PercentagePipe,
    AusCurrencyPipe,
    ButtonDefaultComponent,
    CtSgMasterComponent,
    CtSgPremiumSummaryComponent,
    AddressHelpFormComponent,
    MdPreferredDeliveryMethodComponent,
    MfSgPreferredDeliveryComponent,
    CtSgPremiumPeriodHistoryComponent,
    MfSgPendingTransactionPanelComponent,
    MfSgEchoReferralValidationPanelComponent,
    MfSgEchoFieldValidationPanelComponent,
    MdInformationMessageComponent,
    FooterComponent,
    MfContactUsFormComponent,
    MessageForumComponent,
    NewLinesPipe,
    MdValidationMessageComponent,
    MessageBoardComponent,
    UnderwriterInternalCommentComponent,
    ClientTimelineComponent,
    MdConfirmationMessageWithReasonComponent,
    MdConfirmationMessageV4Component,
    OccupationSearchComponent,
    MfSgEquipmentListingComponent,
    MfSgEquipmentListingEditComponent,
    MdDetailsComponent,
    MdConfirmationMessageV5Component,
    PercentNoSymbolPipe,
    MdConfirmationMessageWithReasonAttachmentComponent,
    BaseUploadComponent,
    GstAdjustmentComponent,
    CustomErrorComponent,
    BaseListingComponent,
    UserRoleSelectionComponent,
    CbgSgUploadComponent,
    CbgCLSLoanRedemptionUploadComponent,
    PaynowEditorComponent,
    ViewPaynowDetailsComponent,
    RejectUploadComponent,
    ViewEFTDetailsComponent,
  ],
  imports: [
    GooglePlaceModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    PDFModule,
    ExcelModule,
    LabelModule,
    LayoutModule,
    SchedulerModule,
    ButtonsModule,
    EditorModule,
    FileSelectModule,
    HttpClientModule,
    ChartsModule,
    IntlModule,
    DateInputsModule,
    InputsModule,
    DropDownsModule,
    UploadsModule,
    //RouterModule.forRoot(drawerRoutes),
    //RouterModule.forRoot(routes),
    NotificationModule,
    MenusModule,
    DialogsModule,
    NotificationModule,
    MsalModule,
    AppRoutingModule,
    NgxIdleTimeoutModule,
    OverlayModule,
    PortalModule,
  ],
  providers: [
    { provide: MessageService, useClass: CustomMessagesService },
    { provide: LOCALE_ID, useValue: "en-US" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    MsalService,
    AuthGuard,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
