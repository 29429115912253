import { Observable, of as observableOf, of } from "rxjs";
//import * as Enum from "../framework/enum.shared";
import * as Enum from "../../framework/enum.shared";
//import * as Interface from "../framework/interface.shared";
import * as Interface from "../../framework/interface.shared";
import { Global } from "../global";
import * as lodash from "lodash";
import * as Enums from "../../framework/enum.shared";

//import { common } from "src/app/core/extensions/framework/utils/common";
import { common } from "src/app/framework/utils/common";
//import { List } from "src/app/core/extensions/linqts/linq";
import { List } from "../linqts/linq";
//import { IProduct } from "src/app/core/extensions/framework/interface.product";
import { IProduct } from "src/app/framework/interface.product";
import { Injectable } from "@angular/core";
import { SessionStorageService } from "src/app/services/storages/session-storage.service";
//import { LocalStorageService } from "src/app/core/services/storages/local-storage.service";
import { environment } from "src/environments/environment";
import { UserListService } from "src/app/services/user-list.service";
import { GUID } from "src/app/framework/domain-entity/guid";
import { IdentityRole } from "./identity-role";
import { BroadcastService } from "src/app/services/broadcast.service";
import { Router } from "@angular/router";
import { map, switchMap } from "rxjs/operators";
//import { UserListService } from "../../services/user-list.service";
//import { Application } from "src/app/application";

@Injectable({
  providedIn: "root",
})
export class Identity implements Interface.IIdentity {
  // Dependency injection
  //     static $inject = ['$q', 'localStorageService', 'AuthenticationEvent', 'User', 'Enums', '$state'];

  public static readonly AUTH_STORAGE_NAME: string = "authorizationData";
  public static readonly USER_STORAGE_NAME: string = "authorizedUserData";
  private _currentUser: Interface.IUser | any;

  private static _instance: Interface.IIdentity;

  static get instance(): Interface.IIdentity {
    if (Global.isUndefined(this._instance)) {
      // if (Application.mode === Enum.ApplicationMode.browser) {
      //   //todo
      //   //this._instance = Application.angularInjectionObject<Identity>('Identity');
      // }
    }

    return this._instance;
  }

  constructor(
    //         private $q: ng.IQService,
    //         private localStorageService: angular.localStorage.ILocalStorageService,
    private sessionStorageService: SessionStorageService,
    private userListService: UserListService,
    private broadcastService: BroadcastService,
    private router: Router //         private authenticationEvent: AuthenticationEvent, //         private User: any, //         private Enums: any, //         private $state: any
  ) {
    //todo
    //         this.authenticationEvent.onLogin(() => {
    //             Identity._instance = undefined;
    //             this.clearAuthData();
    //         });
    //         this.authenticationEvent.onLogout(() => {
    //             Identity._instance = undefined;
    //             this.clearAuthData();
    //         });
    //         this.authenticationEvent.onLoginFailed(() => {
    //             Identity._instance = undefined;
    //             this.clearAuthData();
    //         });
    //         this.authenticationEvent.onTokenRefreshFailed(() => {
    //             Identity._instance = undefined;
    //             this.clearAuthData();
    //         });
  }
  public role!: IdentityRole;
  isProposalTransactionReadOnly: boolean = true;

  private getStorageAuthorizationData(): Interface.IAuthenticationData {
    const authData: Interface.IAuthenticationData =
      this.sessionStorageService.get(Identity.AUTH_STORAGE_NAME);

    return authData;
  }

  public clearAuthData() {
    // clear local storage auth data
    this.sessionStorageService.remove(Identity.AUTH_STORAGE_NAME);
    this._currentUser = undefined;
  }

  public injectAuthData(authData: Interface.IAuthenticationData) {
    // set authentication session
    this.sessionStorageService.set(Identity.AUTH_STORAGE_NAME, authData);
  }

  public storeAuthData(authenticationResponse: Interface.IAuthenticationToken) {
    if (authenticationResponse) {
      if (
        authenticationResponse.access_token &&
        authenticationResponse.access_token.length > 0
      ) {
        this.sessionStorageService.remove(Identity.AUTH_STORAGE_NAME);

        const authData: Interface.IAuthenticationData = {
          token: authenticationResponse.access_token,
          refreshToken: authenticationResponse.refresh_token,
          expiresIn: authenticationResponse.expires_in,
          sessionToken: authenticationResponse.sessionToken,
        };

        if (
          common.isDefined(authenticationResponse.passwordExpired) &&
          common.isDefined(authenticationResponse.id)
        ) {
          authData.passwordExpired = true;
          authData.id = parseInt(authenticationResponse.id, 10);
        }

        if (common.isDefined(authenticationResponse.ssoFromEbiz)) {
          authData.ssoFromEbiz = true;
        } else {
          authData.ssoFromEbiz = false;
        }

        if (common.isDefined(authenticationResponse.sessionId)) {
          authData.sessionId = authenticationResponse.sessionId;
        }

        if (common.isDefined(authenticationResponse.impersonateBySuperAdmin)) {
          authData.impersonateBySuperAdmin = true;
        }

        // set authentication session
        this.sessionStorageService.set(Identity.AUTH_STORAGE_NAME, authData);
      }
    }
  }

  public isAuthenticated(): boolean {
    const authData = this.getStorageAuthorizationData();

    if (common.isDefined(authData) && authData != null) {
      if (!common.isDefined(authData.passwordExpired)) {
        return true;
      }
    }
    return false;
  }

  public getTokenExpiresIn(): number {
    const authData = this.getStorageAuthorizationData();
    if (authData) {
      return authData.expiresIn;
    }

    return 0;
  }

  public getRefreshToken(): string {
    const authData = this.getStorageAuthorizationData();
    if (authData) {
      return authData.refreshToken;
    }

    return "";
  }

  public getSessionToken(): string {
    let authData = this.getStorageAuthorizationData();
    if (authData) {
      return authData.sessionToken;
    }
    return "";
  }

  public getAuthenticateToken(): string {
    const authData = this.getStorageAuthorizationData();
    if (authData) {
      return authData.token;
    }

    return "";
  }

  public getCurrentUserType(): Enum.UserType {
    if (this.isAuthenticated()) {
      switch (this.currentUser().userType) {
        case 1:
          return Enum.UserType.Admin;
        case 2:
          return Enum.UserType.Broker;
        case 3:
          return Enum.UserType.Underwriter;
        case 4:
          return Enum.UserType.Client;
        case 5:
          return Enum.UserType.Agent;
        case 6:
          return Enum.UserType.Marketer;
      }
    }

    return Enum.UserType.Undefined;
  }

  public hasProduct(productId: GUID): boolean {
    const found = lodash.find(this.currentUser().products, (product) => {
      return productId.toString() === product.pdid.toUpperCase();
    });

    return common.isDefined(found);
  }
  //todo
  /** Method to populate mapped child organisation */
  // public populateUserMappedOrganisation() {
  //   //  const deferred = this.$q.defer<any>();

  //     if (this.isAuthenticated()) {
  //         // checked if populated before
  //         if (Global.isDefined(this._currentUser.mappedOrganisation)) {
  //             deferred.resolve(this._currentUser.mappedOrganisation);
  //         } else {
  //             /** Retrieved mapped organisations */
  //             this.User.getMappedOrganisations()
  //                 .then((mappedOrgnisations) => {
  //                     if (Global.isDefined(mappedOrgnisations)
  //                         && Global.isDefined(mappedOrgnisations.d)) {

  //                         const index = 0;
  //                         mappedOrgnisations.d.forEach(o => {
  //                             this._currentUser.mappedOrganisation = o;
  //                         });

  //                         this._currentUser.mappedOrganisation = mappedOrgnisations.d;
  //                     }
  //                     deferred.resolve(this._currentUser.mappedOrganisation);
  //                 }, (rejection) => {
  //                     deferred.reject(rejection);
  //                 });
  //         }
  //     } else {
  //         // Unauthorized
  //         deferred.reject(401);
  //         ExceptionManager.error('401 Unauthorized');
  //     }

  //     return deferred.promise;
  // }

  /** Method to populate current login user */
  public populateCurrentUser(forceRefresh: boolean): Observable<string> {
    //const deferred = this.$q.defer<any>();

    if (this.isAuthenticated()) {
      // check if user authenticated before retrieved user info
      if (!this._currentUser || forceRefresh) {
        // check if force refresh or _currentUser object is empty

        this.userListService
          .getUser() // retrieved current login user info
          .subscribe(
            (currentUser: any) => {
              this.initCurrentUser(currentUser);
              //todo: why not prod no need to set?
              //if (!environment.production) {
              this.sessionStorageService.set(
                Identity.USER_STORAGE_NAME,
                currentUser
              );

              this.broadcastService.onAutheticated.next({ loginStatus: true });
              return observableOf("true");
            }
            //TODO
            // ,
            // (rejection) => {
            //     deferred.reject(rejection);
            // });
          );
      }
    } else {
      const authData = this.getStorageAuthorizationData();
      if (authData && authData != null) {
        if (authData.passwordExpired) {
          const errorObj = { errorCode: 403, id: authData.id };
          // Forbidden, due to password expired
          //deferred.reject(errorObj);
        }
      }

      // Unauthorized
      //deferred.reject(401);
    }
    return observableOf("false");
    //return deferred.promise;
  }

  public populateCurrentUser_Promise(forceRefresh: boolean) {
    return this.userListService
      .getUser() // retrieved current login user info
      .pipe(
        map((currentUser: any) => {
          this.initCurrentUser(currentUser);
          //todo: why not prod no need to set?
          //if (!environment.production) {
          this.sessionStorageService.set(
            Identity.USER_STORAGE_NAME,
            currentUser
          );

          this.broadcastService.onAutheticated.next({ loginStatus: true });
        })
      )
      .toPromise();
  }

  public initCurrentUser(currentUser: any) {
    this._currentUser = currentUser;

    // reset to default value
    this.isProposalTransactionReadOnly = true;

    switch (this.getCurrentUserType()) {
      case Enum.UserType.Broker: // broker
      case Enum.UserType.Agent: // agent
      case Enum.UserType.Underwriter: // underwriter
        this.isProposalTransactionReadOnly = false;
        break;
    }

    this.role = new IdentityRole(this);
  }

  /** to retrieved current login user info */
  public currentUser(): Interface.IUser {
    if (
      !environment.production &&
      !common.isUndefinedOrNull(
        this.sessionStorageService.get(Identity.USER_STORAGE_NAME)
      )
    ) {
      return this.sessionStorageService.get(Identity.USER_STORAGE_NAME);
    }

    if (common.isUndefinedOrNull(this._currentUser) && this.isAuthenticated()) {
      //todo
      // let authorization = Application.angularInjectionObject<any>("Authorization");
      // authorization.logoutAndToDefault();
    }

    return this._currentUser!;
  }

  public isAllowedGenerateNewPolicy(): boolean {
    let isSuspended =
      common.isDefined(this._currentUser) &&
      common.isDefined(this._currentUser?.suspended) &&
      this._currentUser?.suspended;

    /* 2017-02-22 khleow: Some products such as AML
     * allow UW to create policies. */
    let isPolicyGenerator =
      common.isDefined(this._currentUser) &&
      (this._currentUser?.userType === Enum.UserType.Agent ||
        this._currentUser?.userType === Enum.UserType.Broker ||
        (this._currentUser?.userType === Enum.UserType.Underwriter &&
          new List<IProduct>(this._currentUser?.products).Any((p) =>
            p ? p.uwNewPolicyEnabled : false
          )));

    //Product Specify by SelectPlus SG

    if (
      common.isDefined(this._currentUser) &&
      common.isDefined(this._currentUser?.userRoles)
    ) {
      /*stop approval underwriter to create new policy*/
      if (this._currentUser?.userType === Enum.UserType.Underwriter) {
        if (common.isDefined(this._currentUser.userRolePermissions)) {
          isPolicyGenerator = this.isInUserRolePermissions("CreateNew");
        }
      }
    }

    return !isSuspended && isPolicyGenerator;
  }

  public isInUserRolePermissions(userRolePermissions: string): boolean {
    if (this.isAuthenticated()) {
      if (
        common.isDefined(this._currentUser) &&
        common.isDefined(this._currentUser?.userRolePermissions)
      ) {
        return lodash.includes(
          this._currentUser?.userRolePermissions,
          userRolePermissions
        );
      }
    }

    return false;
  }

  public isInUserRole(userRole: string): boolean {
    if (this.isAuthenticated()) {
      if (
        this._currentUser != undefined &&
        this._currentUser.userRoles != undefined
      ) {
        return lodash.includes(this._currentUser.userRoles, userRole);
      }
    }

    return false;
  }

  public isInRole(role: string): boolean {
    if (this.isAuthenticated()) {
      if (
        common.isDefined(this._currentUser) &&
        common.isDefined(this._currentUser?.roles) &&
        common.isDefined(this._currentUser?.roles[role])
      ) {
        return this._currentUser?.roles[role];
      }
    }

    return false;
  }

  public getSessionId(): string {
    const authData = this.getStorageAuthorizationData();

    if (common.isDefined(authData.sessionId)) {
      return authData.sessionId!;
    }

    return "";
  }

  public isImpersonate(): boolean {
    if (this.isAuthenticated()) {
      const authData = this.getStorageAuthorizationData();

      if (common.isDefined(authData.impersonateBySuperAdmin)) {
        return true;
      }
    }

    return false;
  }

  public isSsoFromEbiz(): boolean {
    const authData = this.getStorageAuthorizationData();

    if (
      common.isDefined(authData.ssoFromEbiz) &&
      authData.ssoFromEbiz === true
    ) {
      return true;
    }

    return false;
  }
}
