<kendo-dialog
  *ngIf="active"
  [width]="600"
  [height]="400"
  (close)="closeForm()"
  style="z-index: 1000"
>
  <kendo-dialog-titlebar class="modal-title-custom chubbLightOrange_bg">
    Confirmation
  </kendo-dialog-titlebar>
  <div class="col-md-8" [ngClass]="{ 'st-danger': invalidlabelheader }">
    <div class="form-group">
      <label class="marginTop5" for="header"
        >Are you sure you want to change the status ?</label
      >
    </div>
  </div>
  <div class="container-fluid" style="padding-top: 15px">
    <div *ngIf="invalidRequiredField" id="alertMessage" class="row">
      <div class="col-md-12">
        <div class="alert alert-danger displayBlock">
          <label class="margin0">
            <span>
              <i
                class="fa fa-exclamation-circle marginRight5"
                aria-hidden="true"
              ></i>
              Please fill in the required fields.
            </span>
          </label>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-4" [ngClass]="{ 'st-danger': invalidChangetoStatus }">
        <label class="marginTop5 required" for="ChangetoStatus">Status</label>
      </div>
      <div class="col-md-8" [ngClass]="{ 'st-danger': invalidChangetoStatus }">
        <kendo-dropdownlist
          id="ChangetoStatusId"
          [defaultItem]="defaultChangetoStatusItem"
          [kendoDropDownFilter]="{
            operator: 'contains'
          }"
          [data]="ChangetoStatusOptions"
          textField="text"
          valueField="value"
          [valuePrimitive]="true"
          [(ngModel)]="refundPayment.changestatus"
          (valueChange)="on_paymentrefundToInd_ChangetoStatus_change()"
          name="name_ChangetoStatusType"
        ></kendo-dropdownlist>
      </div>
    </div>

    <!-- Refund by Cheque fields    refundType === 1 && refundType_ChangetoStatus === 1 -->
    <div *ngIf="isHidePaymentMethodDDL">
      <div class="form-group row">
        <div
          class="col-md-4"
          [ngClass]="{
            'st-danger': invalidPaymentmethod
          }"
        >
          <label class="marginTop5 required" for="refundType"
            >payment Method</label
          >
        </div>
        <div class="col-md-8">
          <kendo-dropdownlist
            id="PaymentmethodId"
            [defaultItem]="defaultPaymentmethod"
            [kendoDropDownFilter]="{
              operator: 'contains'
            }"
            [data]="PaymentmethodOptions"
            textField="text"
            valueField="value"
            [valuePrimitive]="true"
            (valueChange)="on_PaymentMethod_change()"
            [(ngModel)]="refundPayment.paymentMethod"
            name="name_Paymentmethod"
          ></kendo-dropdownlist>
        </div>
      </div>

      <div *ngIf="isHidMobileNumber" class="form-group row">
        <div class="col-md-4" [ngClass]="{ 'st-danger': invalidMobileNumber }">
          <label class="marginTop5 required" for="payerName"
            >Mobile Number</label
          >
        </div>
        <div class="col-md-8" [ngClass]="{ 'st-danger': invalidMobileNumber }">
          <input
            type="text"
            class="form-control"
            id="MobileNumer"
            placeholder="Mobile Number"
            [(ngModel)]="refundPayment.paynowMobileNumber"
          />
        </div>
      </div>
      <!-- EFT container-->
      <div *ngIf="isHideEFT" class="form-group row">
        <div
          class="col-md-4"
          [ngClass]="{ 'st-danger': invalidbankAccountNumber }"
        >
          <label class="marginTop5 required" for="bankAccountNumber"
            >Bank Account Number
          </label>
        </div>
        <div
          class="col-md-8"
          [ngClass]="{ 'st-danger': invalidbankAccountNumber }"
        >
          <input
            type="text"
            class="form-control"
            id="bankAccountNumber"
            placeholder="Bank Account Number"
            [(ngModel)]="refundPayment.bankAccountNumber"
          />
        </div>
      </div>

      <div *ngIf="isHideEFT" class="form-group row">
        <div class="col-md-4" [ngClass]="{ 'st-danger': invalidbankName }">
          <label class="marginTop5 required" for="bankName">Bank Name</label>
        </div>
        <div class="col-md-8" [ngClass]="{ 'st-danger': invalidbankName }">
          <kendo-dropdownlist
            id="bankNameId"
            [defaultItem]="defaultbankNameItem"
            [kendoDropDownFilter]="{
              operator: 'contains'
            }"
            [data]="bankNameOptions"
            textField="text"
            valueField="value"
            [valuePrimitive]="true"
            (valueChange)="on_ChangeBankCode_change()"
            [(ngModel)]="refundPayment.bankcode"
            name="name_bankName"
          ></kendo-dropdownlist>
        </div>
      </div>

      <div *ngIf="isHideOtherBanktext" class="form-group row">
        <div class="col-md-4" [ngClass]="{ 'st-danger': invalidotherBankName }">
          <label class="marginTop5" for="otherBankName"> </label>
        </div>
        <div class="col-md-8" [ngClass]="{ 'st-danger': invalidotherBankName }">
          <input
            type="text"
            class="form-control"
            id="otherBankName"
            placeholder="Other Bank Name"
            [(ngModel)]="refundPayment.bankName"
          />
        </div>
      </div>

      <div *ngIf="isHideEFT" class="form-group row">
        <div class="col-md-4" [ngClass]="{ 'st-danger': invalidBankCode }">
          <label class="marginTop5 required" for="Branch">Bank Code</label>
        </div>
        <div class="col-md-8" [ngClass]="{ 'st-danger': invalidBankCode }">
          <input
            type="text"
            class="form-control"
            id="BankCode"
            maxlength="4"
            placeholder="BankCode"
            [(ngModel)]="paymentrefundToInd_UserEnteredbankcode"
          />
        </div>
      </div>

      <div *ngIf="isHideEFT" class="form-group row">
        <div class="col-md-4" [ngClass]="{ 'st-danger': invalidBranch }">
          <label class="marginTop5 required" for="Branch">Branch</label>
        </div>
        <div class="col-md-8" [ngClass]="{ 'st-danger': invalidBranch }">
          <input
            type="text"
            class="form-control"
            id="Branch"
            placeholder="Branch"
            [(ngModel)]="refundPayment.branchName"
          />
        </div>
      </div>
      <div *ngIf="isHideEFT" class="form-group row">
        <div class="col-md-4" [ngClass]="{ 'st-danger': invalidBranchCode }">
          <label class="marginTop5 required" for="Branch">Branch Code</label>
        </div>
        <div class="col-md-8" [ngClass]="{ 'st-danger': invalidBranchCode }">
          <input
            type="text"
            class="form-control"
            id="Branch"
            maxlength="3"
            placeholder="Branch Code"
            [(ngModel)]="refundPayment.branchCode"
          />
        </div>
      </div>
    </div>

    <kendo-dialog-actions>
      <button
        kendoButton
        class="btn chubbWhite chubbLightBlue_bg_btn"
        themeColor="primary"
        (click)="onSave($event)"
      >
        OK
      </button>
      <button
        kendoButton
        class="btn chubbWhite chubbRed_bg_btn"
        (click)="onCancel($event)"
      >
        Cancel
      </button>
    </kendo-dialog-actions>
  </div></kendo-dialog
>
