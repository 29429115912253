import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { DialogRef } from "src/app/components/shared/partials/dialog/dialog-ref";
import {
  OpenItemEntity,
  RefundPaymentEntity,
} from "../models/open-item-list.model";
import { OpenItemListService } from "src/app/services/open-item-list.service";
import { formatDate } from "@angular/common";
import { RefundPaymentPayNowType } from "src/app/framework/enum.shared";
import { v1DlgInputArgs } from "src/app/components/shared/partials/md-confirmation-message-v1/md-confirmation-message-v1.component";
import { DIALOG_DATA } from "src/app/components/shared/partials/dialog/dialog-tokens";

@Component({
  selector: "app-view-EFT-details",
  templateUrl: "./view-EFT-details.component.html",
  styleUrls: ["./view-EFT-details.component.scss"],
})
export class ViewEFTDetailsComponent implements OnInit {
  public refundPayment = new RefundPaymentEntity();
  public openItemEntity = new OpenItemEntity();

  @Input() public opened = false;

  @Output() closeDialogEvent = new EventEmitter<any>();

  constructor(
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA) public inputData: v1DlgInputArgs,
    private openItemListService: OpenItemListService
  ) {}

  ngOnInit(): void {
    if (this.inputData) {
      this.opened = true;
      this.refundPayment = this.inputData as any;

      this.getOpenItemDetails();
    }
  }

  private getOpenItemDetails() {
    this.openItemListService
      .getOpenItem(this.refundPayment.openItemId)
      .subscribe((response: OpenItemEntity) => {
        const openItem: OpenItemEntity = response;
        this.openItemEntity = openItem;
      });
  }

  public displayHaseDateFormat(simpleDate: any) {
    return formatDate(new Date(simpleDate), "dd/MM/yyyy", "en-US");
  }

  public close(status: any) {
    this.opened = false;

    let savParamters: dlgOutputModelArgs = {
      status: status,
      data: null,
    };
    this.closeDialogEvent.emit(savParamters);
    this.dialogRef?.close(savParamters);
  }

  public open() {
    this.opened = true;
  }
}

export interface dlgOutputModelArgs {
  status: string;
  data: any;
}
