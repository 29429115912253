import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "./dal/api.service";
import { DialogWrapperService } from "./dialog/dialog-wrapper.service";
import { ErrorHandlerService } from "./error-handler/error-handler.service";
import { saveAs, encodeBase64 } from "@progress/kendo-file-saver";
import {
    AutoUpdateEntity,
    BankTransCitiEntity,
    BankTransEntity,
    CbgDailyDownloadEntity,
    IBGRenewalEntity,
    LoanRedemptionFailureEntity,
    LoanRedemptionSuccessEntity,
    LSTEntity,
    ManualPaidAutoDebitEntity,
    MonthlyReportCitiEntity,
    PrintDocumentEntity,
    RenewalDraftDeletionEntity,
    RenewalEntity,
    SummaryInvoiceAutoDebitEntity,
} from "../components/new-product/home/preset-report/preset-report.model";

@Injectable({
    providedIn: "root",
})
export class PresetExportService extends ApiService {
    constructor(
        httpClient: HttpClient,
        errorHandlerService: ErrorHandlerService,
        dialog: DialogWrapperService
    ) {
        super(httpClient, errorHandlerService, dialog);
    }
    public downloadDocument(
        month: number,
        year: number,
        segmentDetails?: string
    ) {
        let params = new HttpParams();
        params = params.append("month", month);
        params = params.append("year", year);
        this.download(
            "api/product/home/presetExport/autoDebitDownload",
            params
        ).subscribe((response: HttpResponse<Blob>) => {
            const httpHeader = response.headers;
            let fileName = httpHeader.get("x-filename");
            if (fileName) this.downLoadFile(response.body, fileName);
        });
    }
    private downLoadFile(data: any, fileName: string) {
        let blob = new Blob([data]);
        let url = window.URL.createObjectURL(blob);
        saveAs(url, fileName);
    }
    public summaryInvoiceAutoDebit(
        month: number,
        year: number,
        segmentDetails: string
    ): Observable<SummaryInvoiceAutoDebitEntity[]> {
        const uri = "api/product/home/presetExport/summaryInvoiceAutoDebitDownload";
        const postData = {
            month: month,
            year: year,
            segmentDetails: segmentDetails,
        };
        return this.httpPostCall(
            this.getHeaderAsJson(),
            this.constructEndPoint(uri),
            postData
        );
    }
    public autoUpdateDownload(
        month: number,
        year: number,
        segmentDetails: string
    ): Observable<AutoUpdateEntity[]> {
        const uri = "api/product/home/presetExport/autoUpdateDownload";

        const postData = {
            month: month,
            year: year,
            segmentDetails: segmentDetails,
        };
        return this.httpPostCall(
            this.getHeaderAsJson(),
            this.constructEndPoint(uri),
            postData
        );
    }
    bankTransaction(
        dateFrom: Date,
        dateTo: Date,
        segments: Array<string>,
        products: Array<string>
    ): Observable<BankTransEntity[]> {
        const uri = "api/product/home/presetExport/bankTransaction";
        const postData = {
            dateFrom: dateFrom,
            dateTo: dateTo,
            segments: segments,
            products: products,
        };
        return this.httpPostCall(
            this.getHeaderAsJson(),
            this.constructEndPoint(uri),
            postData
        );
    }

    bankTransactionCiti(
        dateFrom?: Date,
        dateTo?: Date,
        products?: Array<string>,
        productRadio?: string,
        effectiveDateFrom?: Date,
        effectiveDateTo?: Date,
        transactionType?: Array<string>,
        customerType?: Array<string>,
        policyStatus?: Array<string>
    ): Observable<BankTransCitiEntity[]> {
        const uri = "api/product/home/presetExport/bankTransactionCiti";
        const postData = {
            customerType: customerType,
            transactionType: transactionType,
            transactionDateFrom: dateFrom,
            transactionDateTo: dateTo,
            effectiveDateFrom: effectiveDateFrom,
            effectiveDateTo: effectiveDateTo,
            products: products,
            policyStatus: policyStatus,
        };
        return this.httpPostCall(
            this.getHeaderAsJson(),
            this.constructEndPoint(uri),
            postData
        );
    }
    public monthlyReportCiti(
        dateFrom?: Date,
        dateTo?: Date,
        products?: Array<string>,
        transactionType?: string,
        customerType?: Array<string>,
        policyStatus?: Array<string>
    ): Observable<MonthlyReportCitiEntity[]> {
        const uri = "api/product/home/presetExport/monthlyReportCiti";
        const postData = {
            customerType: customerType,
            transactionType: transactionType,
            transactionDateFrom: dateFrom,
            transactionDateTo: dateTo,
            products: products,
            policyStatus: policyStatus,
        };
        return this.httpPostCall(
            this.getHeaderAsJson(),
            this.constructEndPoint(uri),
            postData
        );
    }
    public citiWeeklyInvoiceReport(ADweek: Date, transactionType: number) {
        const uri = "api/product/home/presetExport/citiWeeklyInvoiceReportDownload";
        let params = new HttpParams();
        // Begin assigning parameters
        params = params.append("ADWeek", ADweek.toDateString());
        params = params.append("transactionType", transactionType);
        this.download(uri, params).subscribe((response: HttpResponse<Blob>) => {
            const httpHeader = response.headers;
            let fileName = httpHeader.get("x-filename");
            if (fileName) this.downLoadFile(response.body, fileName);
        });
    }
    public newBusinessCBGDailyDownload(
        dateFrom?: Date,
        dateTo?: Date,
        segments?: Array<string>,
        userTeams?: Array<number>,
        productIds?: Array<string>
    ): Observable<CbgDailyDownloadEntity[]> {
        const uri = "api/product/home/presetExport/newBusinessCBGDailyDownload";
        const postData = {
            dateFrom: dateFrom,
            dateTo: dateTo,
            segments: segments,
            userTeams: userTeams,
            productIds: productIds,
        };
        return this.httpPostCall(
            this.getHeaderAsJson(),
            this.constructEndPoint(uri),
            postData
        );
    }
    public endorsementCBGDailyDownload(
        dateFrom?: Date,
        dateTo?: Date,
        segments?: Array<string>,
        userTeams?: Array<number>,
        productIds?: Array<string>
    ): Observable<CbgDailyDownloadEntity[]> {
        const uri = "api/product/home/presetExport/endorsementCBGDailyDownload";
        const postData = {
            dateFrom: dateFrom,
            dateTo: dateTo,
            segments: segments,
            userTeams: userTeams,
            productIds: productIds,
        };
        return this.httpPostCall(
            this.getHeaderAsJson(),
            this.constructEndPoint(uri),
            postData
        );
    }
    public cancellationCBGDailyDownload(
        dateFrom?: Date,
        dateTo?: Date,
        segments?: Array<string>,
        userTeams?: Array<number>,
        productIds?: Array<string>
    ): Observable<CbgDailyDownloadEntity[]> {
        const uri = "api/product/home/presetExport/cancellationCBGDailyDownload";
        const postData = {
            dateFrom: dateFrom,
            dateTo: dateTo,
            segments: segments,
            userTeams: userTeams,
            productIds: productIds,
        };
        return this.httpPostCall(
            this.getHeaderAsJson(),
            this.constructEndPoint(uri),
            postData
        );
    }
    public printDocumentReportSearch(querystring: string): Observable<any> {
        const uri = "api/product/home/printDocument/printDocumentReportSearch";
        return this.httpClient.post<any>(this.constructEndPoint(uri), querystring, {
            headers: this.getHeaderAsEncodedFormUrl(),
            reportProgress: true,
            observe: "events",
        });
    }
    public printDocumentReport(
        productRadio: string,
        channelTypeID: number,
        boundDateFrom?: Date,
        boundDateTo?: Date,
        policyNo?: string,
        insuredName?: string,
        productIds?: Array<string>
    ): Observable<PrintDocumentEntity[]> {
        //Change
        const uri = "api/product/home/presetExport/printDocumentReportDownload";
        const postData = {
            productRadio: productRadio,
            channelTypeID: channelTypeID,
            boundDateFrom: boundDateFrom,
            boundDateTo: boundDateTo,
            policyNo: policyNo,
            insuredName: insuredName,
            productIds: productIds,
        };
        return this.httpPostCall(
            this.getHeaderAsJson(),
            this.constructEndPoint(uri),
            postData
        );
    }
    public printDatapostReportSearch(querystring: string): Observable<any> {
        const uri =
            "api/product/home/printDocument/dataPostPrintDocumentReportSearch";
        return this.httpClient.post<any>(this.constructEndPoint(uri), querystring, {
            headers: this.getHeaderAsEncodedFormUrl(),
            reportProgress: true,
            observe: "events",
        });
    }
    public printDatapostReport(
        productRadio: string,
        boundDateFrom?: Date,
        boundDateTo?: Date,
        policyNo?: string,
        insuredName?: string,
        productIds?: Array<string>
    ): Observable<PrintDocumentEntity[]> {
        //Change
        const uri =
            "api/product/home/presetExport/dataPostPrintDocumentReportDownload";
        const postData = {
            productRadio: productRadio,
            boundDateFrom: boundDateFrom,
            boundDateTo: boundDateTo,
            policyNo: policyNo,
            insuredName: insuredName,
            productIds: productIds,
        };
        return this.httpPostCall(
            this.getHeaderAsJson(),
            this.constructEndPoint(uri),
            postData
        );
    }
    //lstReportSearch
    public lstReportSearch(querystring: string): Observable<any> {
        const uri = "api/product/home/lstReport/LSTReportSearch";
        return this.httpClient.post<any>(this.constructEndPoint(uri), querystring, {
            headers: this.getHeaderAsEncodedFormUrl(),
            reportProgress: true,
            observe: "events",
        });
    }
    public lstReport(
        lstEffectiveDateFrom?: Date,
        lstEffectiveDateTo?: Date,
        lstBoundDateFrom?: Date,
        lstBoundDateTo?: Date,
        lstPolicyStartDateFrom?: Date,
        lstPolicyStartDateTo?: Date,
        lstPolicyEndDateFrom?: Date,
        lstPolicyEndDateTo?: Date,
        lstPolicyNumber?: string,
        lstInsuredName?: string,
        lstTransactionType?: Array<string>,
        lstProduct?: Array<string>
    ): Observable<LSTEntity[]> {
        const uri = "api/product/home/presetExport/lSTDownload";
        const postData = {
            effectiveDateFrom: lstEffectiveDateFrom,
            effectiveDateTo: lstEffectiveDateTo,
            boundDateFrom: lstBoundDateFrom,
            boundDateTo: lstBoundDateTo,
            startDateFrom: lstPolicyStartDateFrom,
            startDateTo: lstPolicyStartDateTo,
            expiryDateFrom: lstPolicyEndDateFrom,
            expiryDateTo: lstPolicyEndDateTo,
            policyNo: lstPolicyNumber,
            insuredName: lstInsuredName,
            transType: lstTransactionType,
            productIds: lstProduct,
        };
        return this.httpPostCall(
            this.getHeaderAsJson(),
            this.constructEndPoint(uri),
            postData
        );
    }
    //ibg renewal
    public ibgRenewalReport(
        policyNo: string,
        CIN: string,
        insuredName: string,
        startDateFrom?: Date,
        startDateTo?: Date,
        expiryDateFrom?: Date,
        expiryDateTo?: Date,
        boundDateFrom?: Date,
        boundDateTo?: Date,
        effectiveDateFrom?: Date,
        effectiveDateTo?: Date,
        bankReferenceNo?: string,
        segment?: Array<string>,
        transType?: Array<string>,
        policyStatus?: Array<string>,
        proposalStatus?: Array<string>,
        productId?: Array<string>,
        postalCode?: string,
        MSIGNo?: string
    ): Observable<IBGRenewalEntity[]> {
        const uri = "api/product/home/presetExport/iBGRenewalReportDownload";
        const postData = {
            policyNo: policyNo,
            CIN: CIN,
            insuredName: insuredName,
            startDateFrom: startDateFrom,
            startDateTo: startDateTo,
            expiryDateFrom: expiryDateFrom,
            expiryDateTo: expiryDateTo,
            boundDateFrom: boundDateFrom,
            boundDateTo: boundDateTo,
            effectiveDateFrom: effectiveDateFrom,
            effectiveDateTo: effectiveDateTo,
            bankReferenceNo: bankReferenceNo,
            segment: segment,
            transType: transType,
            policyStatus: policyStatus,
            proposalStatus: proposalStatus,
            productId: productId,
            postalCode: postalCode,
            MSIGNo: MSIGNo,
        };
        return this.httpPostCall(
            this.getHeaderAsJson(),
            this.constructEndPoint(uri),
            postData
        );
    }
    //ingrenewalReportSearch
    public ibgRenewalReportSearch(querystring: string): Observable<any> {
        const uri = "api/product/home/renewalReport/IBGRenewalReportSearch";
        return this.httpClient.post<any>(this.constructEndPoint(uri), querystring, {
            headers: this.getHeaderAsEncodedFormUrl(),
            reportProgress: true,
            observe: "events",
        });
    }
    //loan-redemption report search
    public loanRedemptionReportSearch(querystring: string): Observable<any> {
        const uri = "api/product/home/LoanRedemption/generateReport";
        return this.httpClient.post<any>(this.constructEndPoint(uri), querystring, {
            headers: this.getHeaderAsEncodedFormUrl(),
            reportProgress: true,
            observe: "events",
        });
    }
    loanRedemptionSuccess(
        dateSearchType: string,
        effectiveDateFrom?: Date,
        effectiveDateTo?: Date,
        segmentDetails?: string,
        reportType?: string,
        productID?: string[],
        productRadio?: string,
        channel?: string
    ): Observable<LoanRedemptionSuccessEntity[]> {
        const uri = "api/product/home/presetExport/LoanRedemptionSuccessDownload";

        const postData = {
            dateSearchType: dateSearchType,
            effectiveDateFrom: effectiveDateFrom,
            effectiveDateTo: effectiveDateTo,
            segmentDetails: segmentDetails,
            reportType: reportType,
            productID: productID,
            productRadio: productRadio,
            channel: channel,
        };
        return this.httpPostCall(
            this.getHeaderAsJson(),
            this.constructEndPoint(uri),
            postData
        );
    }
    loanRedemptionFailure(
        dateSearchType: string,
        effectiveDateFrom?: Date,
        effectiveDateTo?: Date,
        segmentDetails?: string,
        reportType?: string,
        productID?: string[],
        productRadio?: string,
        channel?: string
    ): Observable<LoanRedemptionFailureEntity[]> {
        const uri = "api/product/home/presetExport/LoanRedemptionFailureDownload";

        const postData = {
            dateSearchType: dateSearchType,
            effectiveDateFrom: effectiveDateFrom,
            effectiveDateTo: effectiveDateTo,
            segmentDetails: segmentDetails,
            reportType: reportType,
            productID: productID,
            productRadio: productRadio,
            channel: channel,
        };
        return this.httpPostCall(
            this.getHeaderAsJson(),
            this.constructEndPoint(uri),
            postData
        );
    }
    manualPaidAutoDebit(
        month: number,
        year: number
    ): Observable<ManualPaidAutoDebitEntity[]> {
        const uri = "api/product/home/presetExport/manualPaidAutoDebit";
        let params = new HttpParams();
        // Begin assigning parameters
        params = params.append("month", month);
        params = params.append("year", year);
        return this.httpGetCall(
            this.getHeaderAsJson(),
            this.constructEndPoint(uri),
            params
        );
    }
    //renewalDraftReportSearch
    public renewalDraftReportSearch(querystring: string): Observable<any> {
        const uri =
            "api/product/home/renewalReport/renewalDraftDeletionReportSearch";
        return this.httpClient.post<any>(this.constructEndPoint(uri), querystring, {
            headers: this.getHeaderAsEncodedFormUrl(),
            reportProgress: true,
            observe: "events",
        });
    }
    public renewalReportSearch(querystring: string): Observable<any> {
        const uri = "api/product/home/renewalReport/renewalReportSearch";
        return this.httpClient.post<any>(this.constructEndPoint(uri), querystring, {
            headers: this.getHeaderAsEncodedFormUrl(),
            reportProgress: true,
            observe: "events",
        });
    }
    renewalDraftDeletionReport(
        month: number,
        year: number,
        segments: Array<string>,
        productIds: Array<string>
    ): Observable<RenewalDraftDeletionEntity[]> {
        const uri = "api/product/home/presetExport/renewalDraftDeletionReport";
        const postData = {
            month: month,
            year: year,
            segments: segments,
            productIds: productIds,
        };
        return this.httpPostCall(
            this.getHeaderAsJson(),
            this.constructEndPoint(uri),
            postData
        );
    }
    renewalReport(
        month: number,
        year: number,
        segments: Array<string>,
        productIds: Array<string>
    ): Observable<RenewalEntity[]> {
        /// CHNAGE CONTROLER PATH
        const uri = "api/product/home/presetExport/renewalReportDownload";
        const postData = {
            month: month,
            year: year,
            segments: segments,
            productIds: productIds,
        };
        return this.httpPostCall(
            this.getHeaderAsJson(),
            this.constructEndPoint(uri),
            postData
        );
    }
    //summaryInvoiceAutoDebitSearch
    public summaryInvoiceAutoDebitSearch(querystring: string): Observable<any> {
        const uri = "api/product/home/summaryInvoice/autoDebitSearch";
        return this.httpClient.post<any>(this.constructEndPoint(uri), querystring, {
            headers: this.getHeaderAsEncodedFormUrl(),
            reportProgress: true,
            observe: "events",
        });
    }
}