<div id="{{ question.key }}" [ngClass]="{ 'st-danger': question.theme === 1 }">
  <label
    for="{{ question.key }}"
    [ngClass]="{ required: question.required && !question.readOnly }"
    [textContent]="question.displayText"
  ></label>

  <div *ngIf="!question.readOnly">
    <!--https://stackoverflow.com/questions/39760956/how-to-bind-maxlength-attribute-->
    <input
      id="{{ question.key }}_textBox"
      name="{{ question.key }}"
      type="text"
      [(ngModel)]="question.answer"
      ngModel
      maxlength="{{ question.maxLength }}"
      class="k-textbox form-control"
      ng-change="change()"
      [disabled]="question.isDisabled!"
      ng-model-options="{ updateOn: 'default blur', debounce: {'default': 1500, 'blur': 0} }"
    />
  </div>

  <div *ngIf="question.readOnly">
    <p *ngIf="question.isAnswered()" class="wordBreak">{{ question.answer }}</p>
    <span class="unattended" *ngIf="!question.isAnswered()">{{
      noAnswerDefaultText
    }}</span>
  </div>
</div>
