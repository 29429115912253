export class LoanRedemptionSearchCriteria {
    constructor() {
        this.dateSearchType = "";
        //this.reportType = "";
        //this.effectiveDateFrom = null;
        //this.effectiveDateTo = null;
        this.segmentDetails = "";
        this.productIds = [];
        this.productRadio = "";
    }

    dateSearchType: string;
    reportType!: string;
    effectiveDateFrom?: Date;
    effectiveDateTo?: Date;
    segmentDetails: string;
    productIds: Array<string>;
    productRadio: string;
    channel?: string;
}