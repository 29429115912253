import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
} else {
}

//temp workaround for google maps key check until fully tested on turning ON for environment.production variable (configuration.production.json)
const isMyScriptLoaded = (url: string) => {
  var scripts = document.getElementsByTagName("script");
  for (var i = scripts.length; i--; ) {
    if (scripts[i].src == url) return true;
  }
  return false;
};

const s = document.createElement("script");
s.src =
  "https://maps.googleapis.com/maps/api/js?key=" +
  environment.googleApiKey +
  "&callback=Function.prototype&libraries=places&language=en";

if (!isMyScriptLoaded(s.src)) {
  const head = document.getElementsByTagName("head")[0];
  head.appendChild(s);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
