// header.service.ts
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BroadcastService {
  onAutheticated = new Subject<{}>();

  constructor() {}
}
