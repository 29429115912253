<div class="wrapper">
  <app-warning-zone
    [opened]="warningZoneOpened"
    [text]="warningText"
  ></app-warning-zone>
  <kendo-tabstrip
    (tabSelect)="onTabSelect($event)"
    id="tabstrip"
    style="padding-top: 30px"
  >
    <kendo-tabstrip-tab title="To Do" [selected]="true">
      <ng-template kendoTabContent>
        <div class="content">
          <app-todo-list
            (warningZoneEvent)="showWarningZoneEvent($event)"
          ></app-todo-list>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Policy">
      <ng-template kendoTabContent>
        <div class="content">
          <app-policy-list
            (warningZoneEvent)="showWarningZoneEvent($event)"
          ></app-policy-list>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab
      *ngIf="identityRolesService.isExportReporter"
      title="Export"
    >
      <ng-template kendoTabContent>
        <div class="content">
          <app-export-list
            (warningZoneEvent)="showWarningZoneEvent($event)"
          ></app-export-list>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab *ngIf="identityRolesService.isReporter" title="Report">
      <ng-template kendoTabContent>
        <div class="content">
          <div class="content">
            <app-preset-report
              (warningZoneEvent)="showWarningZoneEvent($event)"
            ></app-preset-report>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>
