<div class="wrapper">
  <kendo-tabstrip style="padding-top: 30px">
    <kendo-tabstrip-tab title="Bulk Refund Upload" [selected]="true">
      <ng-template kendoTabContent>
        <!--Error Log -->
        <div id="main">
          <kendo-expansionpanel
            *ngIf="errorLogs.Logs.length > 0"
            title="Error"
            [expanded]="true"
          >
            <div class="container-fluid">
              <div class="row">
                <header
                  class="fontBold chubbWhite"
                  [innerHtml]="errorLogs.Title"
                ></header>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <ul>
                      <li
                        *ngFor="let log of errorLogs.Logs"
                        [innerHtml]="log"
                      ></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </kendo-expansionpanel>
        </div>
        <!--Attachments -->
        <kendo-expansionpanel title="Bulk Refund Upload" [expanded]="true">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row lbl-align">
                  <div class="col-md-4">
                    <label>Refund Payment Type</label>
                  </div>
                  <div class="col-md-2">
                    <input
                      type="radio"
                      name="refundPaymentRadio"
                      #name="ngModel"
                      value="cheque"
                      [(ngModel)]="refundPaymentType"
                      (change)="onChange_refundPaymentRadio()"
                    />
                    <label class="lbl" for="oiRefundPaymentRadio">Cheque</label>
                  </div>
                  <div class="col-md-2">
                    <input
                      type="radio"
                      name="refundPaymentRadio"
                      #name="ngModel"
                      value="payNow"
                      [(ngModel)]="refundPaymentType"
                      (change)="onChange_refundPaymentRadio()"
                    />
                    <label class="lbl" for="oiRefundPaymentRadio">PayNow</label>
                  </div>
                  <div class="col-md-2">
                    <input
                      type="radio"
                      name="refundPaymentRadio"
                      #name="ngModel"
                      value="eft"
                      [(ngModel)]="refundPaymentType"
                      (change)="onChange_refundPaymentRadio()"
                    />
                    <label class="lbl" for="oiRefundPaymentRadio">EFT</label>
                  </div>
                </div>
              </div>
              <br />
              <br />
            </div>
            <div class="row" *ngIf="refundPaymentType === 'cheque'">
              <a class="cursorPointer" (click)="downloadTemplate()"
                >Download Refund Upload Template</a
              >
              <br />
              <br />
            </div>
            <div class="row" *ngIf="refundPaymentType === 'payNow'">
              <a class="cursorPointer" (click)="downloadPayNowTemplate()"
                >Download PayNow Refund Upload Template</a
              >
              <br />
              <br />
            </div>

            <div class="row" *ngIf="refundPaymentType === 'eft'">
              <a class="cursorPointer" (click)="downloadEFTTemplate()"
                >Download EFT Refund Upload Template</a
              >
              <br />
              <br />
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label
                    id="excelFilesErrorMessage"
                    style="color: red"
                    *ngIf="hasError"
                    >{{ excelFilesErrorMessage }}</label
                  >
                  <kendo-fileselect
                    [restrictions]="excelAndSizeRestrictions"
                    (select)="select($event)"
                    (remove)="removeEvent($event)"
                  >
                  </kendo-fileselect>
                </div>
              </div>
            </div>
            <div class="row">
              <button
                type="button"
                class="btn btn-primary ld-ext-left chubbGreen_bg chubbGreen_border_btn"
                (click)="add()"
                [disabled]="isProcessing"
              >
                <i
                  *ngIf="isProcessing"
                  class="fa fa-circle-o-notch fa-spin fa-fw"
                  aria-hidden="true"
                ></i>
                Upload Refund Excel
              </button>
            </div>
          </div>
        </kendo-expansionpanel>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>
<app-md-successful-file-upload
  *ngIf="redirectOpened"
  [opened]="redirectOpened"
  [message]="redirectMessage"
  [data]="redirectData"
  (closeDialogEvent)="confirmationModelForSuccessullExcelUpload($event)"
></app-md-successful-file-upload>
